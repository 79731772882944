import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/compat/app";
import { firestore } from "../firebaseConfig";
import {
  Timestamp,
  IOrder,
  IContactPerson,
  IRouteItem,
  ICustomer,
  IOrderProduct,
} from "../types";
import { query, doc } from "firebase/firestore";
import { Customer, OrderProduct } from ".";
import { OrderStatus } from "../types/order/IOrder";
import { IOrderValidated } from "../types/order";
import { CreateOrderStepsEnum } from "../pages/orders/create-order";
import {
  validateMobile,
  validateStringLength,
} from "../helpers/validationHelper";

type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;

class Order implements IOrder {
  id: string;
  customer: ICustomer;
  customerId: string;
  route: IRouteItem[];
  cargo: IOrderProduct[];
  driverId?: string;
  comment: string;
  status: OrderStatus;
  createdAt: Timestamp | null;
  createdBy: string | null;
  lastUpdated: Timestamp | null;
  workspaceId: string;
  price: number | null;
  pricing?: {
    priceModel: string | string[];
    total?: number | null;
  };

  static collectionName = "orders";

  static converter = {
    toFirestore(order: Order) {
      return order.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      let data = snapshot.data(options) as IOrder;
      return new Order({
        ...data,
        id: snapshot.id,
      });
    },
  };

  static createId() {
    return firestore.collection(Order.collectionName).doc().id;
  }

  constructor({
    id,
    customer,
    customerId,
    cargo,
    driverId,
    comment,
    status,
    workspaceId,
    createdAt,
    createdBy,
    lastUpdated,
    route,
    price,
    pricing,
  }: IOrder) {
    this.id = id || "";
    this.customer = customer || Customer.default();
    this.customerId = customerId || "";
    this.cargo = cargo || [];
    this.driverId = driverId || undefined;
    this.comment = comment || "";
    this.status = status || OrderStatus.Draft;
    this.workspaceId = workspaceId || "";
    this.createdAt = createdAt || null;
    this.createdBy = createdBy || null;
    this.lastUpdated = lastUpdated || null;
    this.price = price || null;
    this.pricing = pricing || { priceModel: [] };
    this.route = route || [];
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates: Partial<IOrder>) {
    return Object.assign(this, updates);
  }
  toPlainObj(): Order {
    return Object.assign({}, this);
  }

  data() {
    return {
      id: this.id,
      customer: this.customer || Customer.default(),
      customerId: this.customerId || "",
      cargo: this.cargo || [],
      driverId: this.driverId || undefined,
      comment: this.comment || "",
      status: this.status || OrderStatus.Draft,
      workspaceId: this.workspaceId || "",
      createdAt: this.createdAt || null,
      createdBy: this.createdBy || null,
      lastUpdated: this.lastUpdated || null,
      price: this.price || null,
      pricing: this.pricing || { priceModel: [] },
      route: this.route || []
    };
  }

  static default = (): IOrder => {
    return {
      id: "",
      customer: Customer.default() as Customer,
      customerId: "",
      cargo: [OrderProduct.defaultProduct()],
      driverId: undefined,
      comment: "",
      status: OrderStatus.Draft,
      workspaceId: "",
      createdAt: null,
      createdBy: null,
      lastUpdated: null,
      price: null,
      pricing: {priceModel: []},
      route: [],
    };
  };

  static get = (id: string) => {
    return doc(
      firestore.collection(Order.collectionName).withConverter(Order.converter),
      id,
    );
  };

  static list = (
    workspaceId: string,
    statuses: OrderStatus[] = [],
    additionalWhere: [string, firebase.firestore.WhereFilterOp, string][] = [],
  ) => {
    let request = firestore
      .collection(Order.collectionName)
      .where("workspaceId", "==", workspaceId);

    if (statuses.length > 0) {
      request = request.where("status", "in", statuses);
    }

    additionalWhere.forEach(([field, operator, value]) => {
      request = request.where(field, operator, value);
    });

    return query(request.withConverter(Order.converter));
  };

  static validateCustomer = (customer: ICustomer) => {
    return customer && customer.id !== "";
  };
  static validateRoute = (routes: IRouteItem[]) => {
    // Enkel validering: Sjekk at det finnes minst to stopp
    if (!routes || routes.length < 2) {
      return false;
    }
    
    // Sjekk at det finnes minst én henting og én levering
    let hasPickup = false;
    let hasDelivery = false;
    
    for (const route of routes) {
      if (route.isPickup === true) {
        hasPickup = true;
      } else if (route.isPickup === false) {
        hasDelivery = true;
      }
    }
    
    // Returner true bare hvis vi har både henting og levering
    return hasPickup && hasDelivery;
  };
  static validateCargo = (cargo: IOrderProduct[], route?: IRouteItem[]) => {
    // Sjekk at det finnes minst ett produkt
    if (!cargo || cargo.length === 0) {
      return false;
    }
    
    // Sjekk at alle produkter har grunnleggende informasjon
    let valid = true;
    for (const item of cargo) {
      // Sjekk at produktet har en beskrivelse og kvantitet
      if (!item.descriptionShort || item.quantity <= 0) {
        valid = false;
      }
      
      // Sjekk at produktet har en rute (pickup og delivery)
      if (!item.itinerary || 
          item.itinerary.pickupStopNumber === undefined || 
          item.itinerary.deliveryStopNumber === undefined) {
        valid = false;
      }
    }
    
    // Sjekk om alle lokasjoner i ruten blir brukt
    if (route && route.length > 0) {
      const unusedLocations = Order.validateAllLocationsUsed(cargo, route);
      if (unusedLocations.length > 0) {
        valid = false;
      }
    }
    
    return valid;
  };

  // Ny funksjon for å validere at alle lokasjoner i ruten blir brukt
  static validateAllLocationsUsed = (cargo: IOrderProduct[], route: IRouteItem[]) => {
    // Samle alle stopnumre som brukes i produktene
    const usedStopNumbers = new Set<number>();
    
    cargo.forEach(product => {
      if (product.itinerary) {
        if (product.itinerary.pickupStopNumber !== undefined) {
          usedStopNumbers.add(product.itinerary.pickupStopNumber);
        }
        if (product.itinerary.deliveryStopNumber !== undefined) {
          usedStopNumbers.add(product.itinerary.deliveryStopNumber);
        }
      }
    });
    
    // Finn lokasjoner i ruten som ikke brukes i noen produkter
    const unusedLocations = route.filter(location => 
      !usedStopNumbers.has(location.stopNumber)
    );
    
    return unusedLocations;
  };

  static validateContacts = (contacts: IContactPerson[]) => {
    let valid = true;
    for (const person of contacts) {
      if (
        !(
          person.location &&
          validateStringLength(person.name, 3) &&
          validateMobile(person.phoneNumber)
        )
      ) {
        valid = false;
      }
    }
    return contacts.length ? valid : false;
  };

  static validateDriver = (driverId: string | undefined) => {
    return driverId !== undefined && driverId !== "";
  };

  static validateStep = (order: IOrder, step: number) => {
    let validation = false;
    switch (step) {
      case CreateOrderStepsEnum.CUSTOMER_SELECTION:
        validation = Order.validateCustomer(order.customer);
        break;
      case CreateOrderStepsEnum.ROUTE_SELECTION:
        validation = Order.validateRoute(order.route);
        break;
      case CreateOrderStepsEnum.PRODUCT_MANAGEMENT:
        validation = Order.validateCargo(order.cargo, order.route);
        break;
      case CreateOrderStepsEnum.DRIVER_SELECTION:
        validation = Order.validateDriver(order.driverId);
        break;
      default:
        validation = false;
        break;
    }
    return validation;
  };

  static validateOrder(order: IOrder) {
    let validationValues = {
      customer:
        order.customerId &&
        order.customerId !== "" &&
        order.customer &&
        order.customer.id !== "",
      route: order.route && order.route.length > 0,
      cargo: order.cargo && order.cargo.length > 0,
    } as IOrderValidated;

    let invalid = Object.entries(validationValues);
    return {
      isValidOrder: invalid.filter(([key, value]) => !value).length === 0,
      invalidFields: Object.fromEntries(invalid),
    };
  }

  static create = async (
    isDraft: boolean,
    data: Omit<IOrder, "id | createdAt">,
  ) => {
    data = structuredClone(data);
    const newOrder = new Order({
      ...data,
      id: uuidv4().toString(),
      status: isDraft ? OrderStatus.Draft : OrderStatus.New,
      createdAt: Timestamp.now(),
      // createdBy: 'get user and set value here'
    });
    try {
      await firestore
        .collection(Order.collectionName)
        .doc(newOrder.id)
        .withConverter(Order.converter)
        .set(newOrder, { merge: true });
    } catch (e) {
      console.warn("Create order failed with error: ", e);
      return false;
    }
    return true;
  };

  static deleteRelatedRoutesById = async (orderId: string) => {
    try {
      const routesCollection = firestore.collection("routes");
      const snapshot = await routesCollection
        .where("orderId", "==", orderId)
        .get();

      if (!snapshot.empty) {
        snapshot.forEach(async (doc) => {
          await routesCollection.doc(doc.id).delete();
        });
      } else {
        console.log(`No route document found with orderId: ${orderId}`);
      }
    } catch (error) {
      console.error("Error deleting route document:", error);
    }
  };

  static delete = async (order: Order) => {
    try {
      await firestore.collection(Order.collectionName).doc(order.id).delete();
      await this.deleteRelatedRoutesById(order.id);
    } catch (e) {
      console.error("Delete failed with error: ", e);
    }
  };
  static update = async (order: Order, updates: Partial<IOrder>) => {
    order.setData({ ...updates });
    order = structuredClone(order);
    const updatedOrder = new Order({ ...order });
    try {
      await firestore
        .collection(Order.collectionName)
        .doc(order.id)
        .withConverter(Order.converter)
        .set(updatedOrder, { merge: true });
    } catch (e) {
      console.warn(e);
    }
    return updatedOrder;
  };
}

export default Order;
