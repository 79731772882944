/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DialogForm, LocationModal, SplitTable } from "../..";
import { Location, Order } from "../../../models";
import { ITableRow } from "../../../types";
import { DropdownButton } from "../../atoms/Button/DropdownButton";
import { HiOutlineChevronDown, HiChevronRight } from "react-icons/hi";
import { SplitTableRef } from "../../molecules/SplitTable/SplitTable";
import { HiTrash, HiPencilAlt, HiOutlineDocumentText } from "react-icons/hi";
import { LocationDetails } from "../../molecules/LocationDetails";
import { useCache } from "../../../context/CacheContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useOrders } from "../../../hooks/useOrders";
import { useWorkspace } from "../../../context/WorkspaceContext";
import { OrderStatus } from "../../../types/order/IOrder";

interface LocationsListProp {
  locations: Location[];
  isLoading: boolean;
  isOpen: (val: boolean) => void;
}

export const LocationsList: FC<LocationsListProp> = function (
  props: LocationsListProp,
) {
  const { t } = useTranslation(["common", "orders", "validation", "location"]);
  const [openedLocation, setOpenedLocation] = useState<Location | undefined>(
    undefined,
  );
  const [locationData, setLocationData] = useState<Location>(
    Location.defaultLocation(),
  );
  const [isInsertModalOpen, openInsertModal] = useState(false);
  const [isDeleteModalOpen, openDeleteModal] = useState(false);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const [hideColumns, setHideColumns] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { updateCacheKey } = useCache();
  const splitTableRef = useRef<SplitTableRef>(null);
  const { activeWorkspace } = useWorkspace();
  
  // Hent alle ordrer for å telle antall per lokasjon
  const ordersQuery = useOrders(
    activeWorkspace?.workspaceId ?? "", 
    Object.values(OrderStatus)
  );
  const orders = ordersQuery.data ?? [];
  
  // Funksjon for å telle antall ordrer per lokasjon
  const countOrdersForLocation = (locationId: string): number => {
    return orders.filter(order => 
      order.route && order.route.some(routeItem => 
        routeItem.location && routeItem.location.id === locationId
      )
    ).length;
  };

  // Function to open/close the details view and set URL parameters
  const updateOpenedLocation = (loc: Location | undefined) => {
    setOpenedLocation(loc);
    if (loc) {
      setHideColumns(true);
      props.isOpen(true);
      // Construct location parameter from addressLine, postCode and city
      const locationParam = `${loc.addressLine
        ?.toLowerCase()
        .replace(/\s+/g, "-")}-${loc.postCode}-${loc.city
        ?.toLowerCase()
        .replace(/\s+/g, "-")}`;
      // Update the URL
      navigate(`/resources/locations?location=${locationParam}`, {
        replace: true,
      });
      splitTableRef.current?.setSplit(true);
    } else {
      setHideColumns(false);
      props.isOpen(false);
      navigate(`/resources/locations`, { replace: true });
      splitTableRef.current?.setSplit(false);
    }
  };

  // Check if there's a ?location= param in the URL and open that location if found
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const locationParam = params.get("location");
    if (locationParam) {
      // Split the param back into [address, postcode, city]
      const parts = locationParam.split("-");
      if (parts.length >= 3) {
        const postcode = parts[parts.length - 2]; // second last
        const city = parts[parts.length - 1]; // last
        const address = parts.slice(0, parts.length - 2).join(" ");
        // Find matching location
        const matchingLocation = props.locations.find(
          (loc) =>
            loc.addressLine?.toLowerCase().replace(/\s+/g, "-") ===
              address.replace(/\s+/g, "-") &&
            loc.postCode === postcode &&
            loc.city?.toLowerCase().replace(/\s+/g, "-") === city,
        );
        if (matchingLocation) {
          updateOpenedLocation(matchingLocation);
        } else {
          // If no match, ensure we don't show a stale param
          navigate(`/resources/locations`, { replace: true });
        }
      } else {
        // If invalid param format, clear it
        navigate(`/resources/locations`, { replace: true });
      }
    }
  }, [location.search, props.locations]);

  useEffect(() => {
    if (!props.isLoading && props.locations) {
      setTableRows(
        props.locations.map((loc) => {
          // Teller antall ordrer for denne lokasjonen
          const orderCount = countOrdersForLocation(loc.id);
          
          return {
            id: loc.id,
            isActive: loc.id === openedLocation?.id,
            onRowClickData: loc,
            cells: [
              {
                id: "displayName",
                children: loc.displayName,
                showOnSmallScreen: true,
              },
              {
                id: "fullAddress",
                children: `${loc.addressLine}, ${loc.postCode} ${loc.city}`,
                hidden: hideColumns,
              },
              {
                id: "orderCount",
                children: (
                  <div className="flex items-center">
                    <HiOutlineDocumentText className="mr-2 h-5 w-5 text-gray-500" />
                    <span>{orderCount}</span>
                  </div>
                ),
                hidden: hideColumns,
              },
            ],
            actions: !openedLocation
              ? [
                  {
                    id: "edit",
                    icon: <HiPencilAlt />,
                    onActionClick: (e: any) => {
                      e.stopPropagation();
                      setLocationData(loc);
                      openInsertModal(true);
                    },
                  },
                  {
                    id: "delete",
                    icon: <HiTrash />,
                    color: "failure",
                    onActionClick: (e: any) => {
                      e.stopPropagation();
                      setLocationData(loc);
                      openDeleteModal(true);
                    },
                  },
                ]
              : [],
          };
        }),
      );
    }
  }, [props.isLoading, props.locations, t, openedLocation, hideColumns, orders]);

  return (
    <>
      <SplitTable
        ref={splitTableRef}
        searchable={false}
        hasActions
        isLoading={props.isLoading}
        tableRows={tableRows}
        onRowClick={(loc: Location | undefined) => updateOpenedLocation(loc)}
        tableHeaders={[
          {
            id: "displayName",
            children: t("common:name"),
            showOnSmallScreen: true,
          },
          {
            id: "fullAddress",
            children: t("common:address"),
            hidden: hideColumns,
          },
          {
            id: "orderCount",
            children: t("common:order_count", { defaultValue: "Ordrer" }),
            hidden: hideColumns,
          },
        ]}
        content={
          <LocationDetails
            location={openedLocation}
            edit={() => {
              if (openedLocation) setLocationData(openedLocation);
              openInsertModal(true);
            }}
            delete={() => {
              if (openedLocation) setLocationData(openedLocation);
              openDeleteModal(true);
            }}
          />
        }
        topRightContent={
          <div className="flex items-center">
            {openedLocation && (
              <DropdownButton
                placement="bottom-end"
                buttonText={t("common:actions")}
                buttonAppendIcon={<HiOutlineChevronDown className="ml-2" />}
                items={[
                  {
                    text: (
                      <p className="p-4 w-48">
                        {t("common:edit")}
                      </p>
                    ),
                    onClick: () => {
                      if (openedLocation) setLocationData(openedLocation);
                      openInsertModal(true);
                    },
                  },
                  {
                    text: (
                      <p className="p-4 w-48 text-lgb-red-300 dark:text-lgb-red-200 hover:text-lgb-red-200 hover:dark:text-lgb-red-300">
                        {t("common:delete")}
                      </p>
                    ),
                    onClick: () => {
                      if (openedLocation) setLocationData(openedLocation);
                      openDeleteModal(true);
                    },
                  },
                ]}
              />
            )}
          </div>
        }
      />

      <LocationModal
        data={locationData}
        isShowing={isInsertModalOpen}
        onConfirm={(location: Location) => {
          if (Location.isNew(locationData)) {
            Location.create(location)
              .then(() => {
                updateCacheKey();
                openInsertModal(false);
              })
              .catch((error) => {
                console.error("Error creating location:", error);
              });
          } else {
            Location.update(locationData, location)
              .then(() => {
                updateCacheKey();
                openInsertModal(false);
              })
              .catch((error) => {
                console.error("Error updating location:", error);
              });
          }
        }}
        onCancel={() => openInsertModal(false)}
      />

      <DialogForm
        show={isDeleteModalOpen}
        title={t("location:delete.title", { defaultValue: "Delete Location" })}
        toggleModal={() => openDeleteModal(false)}
        confirmButton={() => {
          Location.delete(locationData)
            .then(() => {
              updateCacheKey();
              openDeleteModal(false);
              updateOpenedLocation(undefined);
            })
            .catch((error) => {
              console.error("Error deleting location:", error);
            });
        }}
        buttonConfirmText={t("common:confirm")}
        buttonCloseText={t("common:cancel")}
        buttonConfirmColor="failure"
        showHeader={false}
      />
    </>
  );
};
