import type { FC } from "react";
import {
  DndContext,
  DragEndEvent,
  useSensors,
  useSensor,
  PointerSensor,
  DragOverlay,
  closestCenter,
  DragStartEvent,
  DragOverEvent,
  UniqueIdentifier
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IRouteItem } from "../../types/order/IRouteItem";
import { Timestamp } from "../../types";
import { useWorkspace } from "../../context/WorkspaceContext";
import { useUserSession } from "../../context/UserContext";
import { getDriverRoutes } from "../../api/client";
import moment from "moment";
import Driver from "../../models/Driver";
import { getRouteValidationError } from "./routeValidator";
import { RouteDroppableZone, StopCardWrapper, DriverCard } from "../../components/molecules/Planner";

interface RoutePlannerProps {
  selectedDriver: string;
  selectedDate: Date;
  onChange: (state: { 
    hasChanges: boolean; 
    changesCount: number;
    routeStops: (IRouteItem & { isAvailable: boolean })[];
    hasValidationErrors: boolean;
  }) => void;
  reset?: boolean;
  onSelectDriver: (driverId: string) => void;
  drivers: Driver[];
}

export const RoutePlanner: FC<RoutePlannerProps> = ({ 
  selectedDriver, 
  selectedDate, 
  onChange, 
  reset,
  onSelectDriver,
  drivers
}) => {
  const { t } = useTranslation();
  const { activeWorkspace } = useWorkspace();
  const { userSession } = useUserSession();
  const [loading, setLoading] = useState(false);
  const [availableStops, setAvailableStops] = useState<(IRouteItem & { 
    isAvailable: boolean;
    orderId?: string;
    orderDisplayName?: string;
    cargos?: {
      id: string;
      quantity: number;
      description?: string;
      itinerary: {
        pickupStopNumber?: number;
        deliveryStopNumber?: number;
      };
    }[];
  })[]>([]);
  const [routeStops, setRouteStops] = useState<(IRouteItem & { 
    isAvailable: boolean;
    orderId?: string;
    orderDisplayName?: string;
    cargos?: {
      id: string;
      quantity: number;
      description?: string;
      itinerary: {
        pickupStopNumber?: number;
        deliveryStopNumber?: number;
      };
    }[];
  })[]>([]);
  const [originalRouteStops, setOriginalRouteStops] = useState<(IRouteItem & { 
    isAvailable: boolean;
    orderId?: string;
    orderDisplayName?: string;
    cargos?: {
      id: string;
      quantity: number;
      description?: string;
      itinerary: {
        pickupStopNumber?: number;
        deliveryStopNumber?: number;
      };
    }[];
  })[]>([]);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [validationError, setValidationError] = useState<{ message: string; invalidStopIds: string[] } | null>(null);

  // Clear stops when driver changes
  useEffect(() => {
    setAvailableStops([]);
    setRouteStops([]);
    setOriginalRouteStops([]);
  }, [selectedDriver]);

  // Reset state when reset prop changes
  useEffect(() => {
    if (reset) {
      // Find items that were moved from available to route
      const itemsToRestore = routeStops.filter(stop => 
        !originalRouteStops.some(original => original.location.id === stop.location.id)
      );

      // Reset route stops to original state
      setRouteStops(originalRouteStops);

      // Add moved items back to available stops
      if (itemsToRestore.length > 0) {
        setAvailableStops(prev => [
          ...prev,
          ...itemsToRestore.map(stop => ({
            ...stop,
            stopNumber: 0,
            isAvailable: true
          }))
        ]);
      }
    }
  }, [reset, originalRouteStops, routeStops]);

  // Track changes by comparing current route stops with original
  useEffect(() => {
    // Find stops that have changed status (moved between available and scheduled)
    const statusChangedStops = [
      // Stops that were scheduled but are now available
      ...originalRouteStops.filter(originalStop => 
        !routeStops.some(currentStop => currentStop.routeId === originalStop.routeId)
      ),
      // Stops that were available but are now scheduled
      ...routeStops.filter(currentStop => 
        !originalRouteStops.some(originalStop => originalStop.routeId === currentStop.routeId)
      )
    ];

    // Find stops that have changed order (only for scheduled stops that moved position)
    const orderChangedStops = routeStops.filter(currentStop => {
      const originalStop = originalRouteStops.find(original => original.routeId === currentStop.routeId);
      // Only count as changed if the stop existed in original and its position changed
      return originalStop && 
             currentStop.stopNumber !== originalStop.stopNumber && 
             originalStop.stopNumber !== 0;  // Ignore stops that were just added
    });

    const hasChanges = statusChangedStops.length > 0 || orderChangedStops.length > 0;
    const changesCount = statusChangedStops.length + orderChangedStops.length;

    console.log('Change Tracking Debug:', {
      originalRouteStops,
      currentRouteStops: routeStops,
      availableStops,
      statusChangedStops,
      orderChangedStops,
      hasChanges,
      changesCount
    });

    onChange({ 
      hasChanges, 
      changesCount,
      routeStops: [...routeStops, ...availableStops],
      hasValidationErrors: validationError !== null
    });
  }, [routeStops, originalRouteStops, availableStops, onChange, validationError]);

  // Fetch routes when driver or date changes
  useEffect(() => {
    const fetchRoutes = async () => {
      if (!selectedDriver || !userSession?.token || !activeWorkspace?.workspaceId) return;
      
      setLoading(true);
      try {
        const formattedDate = moment(selectedDate).format('YYYY-MM-DD');

        const response = await getDriverRoutes(
          userSession.token.accessToken,
          activeWorkspace.workspaceId,
          selectedDriver,
          formattedDate
        );

        console.log('Routes response:', response);

        if (response) {
          // Split routes based on status
          const newStops = response
            .filter((item: any) => item.status === "New")
            .map((item: any) => ({
              routeId: item.id,
              orderId: item.orderId,
              location: {
                id: item.id,
                displayName: item.displayName,
                addressLine: item.stop.location?.addressLine,
                workspaceId: item.workspaceId,
                customerId: "",
              },
              stopNumber: 0,  // Available stops always have stopNumber 0
              stopDate: Timestamp.fromMoment(moment(item.routeDate)),
              timing: {
                earliest: false,
                latest: false
              },
              status: item.status,
              isPickup: item.isPickup,
              isAvailable: true,
              stopDisplayName: item.stop.location?.displayName,
              orderDisplayName: item.displayName,
              cargos: item.cargos?.map((cargo: any) => ({
                id: cargo.id,
                quantity: cargo.quantity,
                description: cargo.description,
                itinerary: {
                  pickupStopNumber: cargo.itinerary?.pickupStopNumber,
                  deliveryStopNumber: cargo.itinerary?.deliveryStopNumber
                }
              }))
            }));

          const scheduledStops = response
            .filter((item: any) => item.status === "Scheduled")
            .map((item: any, index: number) => ({  // Add index parameter
              routeId: item.id,
              orderId: item.orderId,
              location: {
                id: item.id,
                displayName: item.displayName,
                addressLine: item.stop.location?.addressLine,
                workspaceId: item.workspaceId,
                customerId: "",
              },
              stopNumber: index + 1,  // Start from 1 for scheduled stops
              stopDate: Timestamp.fromMoment(moment(item.routeDate)),
              timing: {
                earliest: false,
                latest: false
              },
              status: item.status,
              isPickup: item.isPickup,
              isAvailable: false,
              stopDisplayName: item.stop.location?.displayName,
              orderDisplayName: item.displayName,
              cargos: item.cargos?.map((cargo: any) => ({
                id: cargo.id,
                quantity: cargo.quantity,
                description: cargo.description,
                itinerary: {
                  pickupStopNumber: cargo.itinerary?.pickupStopNumber,
                  deliveryStopNumber: cargo.itinerary?.deliveryStopNumber
                }
              }))
            }));

            setAvailableStops(newStops);
            setRouteStops(scheduledStops);
            setOriginalRouteStops(scheduledStops);
        }
      } catch (error) {
        console.error('Error fetching driver routes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRoutes();
  }, [selectedDriver, selectedDate, userSession?.token, activeWorkspace?.workspaceId]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const updateStopNumbers = (stops: (IRouteItem & { 
    isAvailable: boolean;
    cargos?: {
      id: string;
      quantity: number;
      description?: string;
      itinerary: {
        pickupStopNumber?: number;
        deliveryStopNumber?: number;
      };
    }[];
  })[]): (IRouteItem & { isAvailable: boolean })[] => {
    const stopsWithNumbers = stops.map((stop, index) => ({
      ...stop,
      stopNumber: index + 1,
      isAvailable: false
    }));

    // Update cargo itineraries with new stop numbers
    return stopsWithNumbers.map(stop => {
      if (stop.cargos) {
        const updatedCargos = stop.cargos.map(cargo => ({
          ...cargo,
          itinerary: {
            ...cargo.itinerary,
            pickupStopNumber: stop.isPickup ? stop.stopNumber : cargo.itinerary.pickupStopNumber,
            deliveryStopNumber: !stop.isPickup ? stop.stopNumber : cargo.itinerary.deliveryStopNumber
          }
        }));
        return {
          ...stop,
          cargos: updatedCargos
        };
      }
      return stop;
    });
  };

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id as string);
  };

  const findContainer = (id: UniqueIdentifier) => {
    if (id === 'available-zone' || id === 'route-zone') return id;

    const stop = [...availableStops, ...routeStops].find(s => s.location.id === id);
    return stop?.isAvailable ? 'available-zone' : 'route-zone';
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    if (!over) return;

    const activeContainer = findContainer(active.id);
    const overContainer = findContainer(over.id);
    
    if (activeContainer === overContainer && activeContainer === 'route-zone') {
      const activeIndex = routeStops.findIndex(
        stop => stop.location.id === active.id
      );
      const overIndex = routeStops.findIndex(
        stop => stop.location.id === over.id
      );

      if (activeIndex !== overIndex) {
        setRouteStops(prev => {
          const reorderedStops = arrayMove(prev, activeIndex, overIndex);
          const updatedStops = updateStopNumbers(reorderedStops);
          
          // Check validation and set error message if invalid
          const error = getRouteValidationError(updatedStops, availableStops);
          
          // Update pickup stop numbers for delivery stops
          const updatedStopsWithPickupNumbers = updatedStops.map(stop => {
            if (!stop.isPickup && stop.orderId) {
              const pickupStop = updatedStops.find(s => s.orderId === stop.orderId && s.isPickup);
              return {
                ...stop,
                pickupStopNumber: pickupStop?.stopNumber
              };
            }
            return stop;
          });
          
          setValidationError(error);
          return updatedStopsWithPickupNumbers;
        });
      }
    }
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    setActiveId(null);
    
    if (!over) return;

    const targetContainer = findContainer(over.id);
    const stopId = active.id as string;

    // Check if we're dragging a group
    if (stopId.startsWith('group-')) {
      const orderId = stopId.replace('group-', '');
      const groupStops = availableStops.filter(stop => stop.orderId === orderId);
      
      if (targetContainer === 'route-zone') {
        // Move all stops in the group to route
        const newStops = [...routeStops, ...groupStops.map(stop => ({ ...stop, isAvailable: false }))];
        const updatedStops = updateStopNumbers(newStops);
        
        // Check validation and set error message if invalid
        // Exclude the stops being moved from available stops for validation
        const remainingAvailableStops = availableStops.filter(stop => !groupStops.some(gs => gs.location.id === stop.location.id));
        const error = getRouteValidationError(updatedStops, remainingAvailableStops);
        setValidationError(error);

        groupStops.forEach(stop => {
          setAvailableStops(prev => prev.filter(s => s.location.id !== stop.location.id));
        });
        
        setRouteStops(updatedStops);
      }
      return;
    }

    // Handle individual stop dragging
    if (targetContainer === 'available-zone' && !availableStops.find(s => s.location.id === stopId)) {
      // Moving to available
      const stopToMove = routeStops.find(stop => stop.location.id === stopId);
      if (stopToMove) {
        const newRouteStops = routeStops.filter(stop => stop.location.id !== stopId);
        const updatedStops = updateStopNumbers(newRouteStops);
        
        // Check validation and set error message if invalid
        const newAvailableStops = [...availableStops, { ...stopToMove, stopNumber: 0, isAvailable: true }];
        const error = getRouteValidationError(updatedStops, newAvailableStops);
        setValidationError(error);

        setRouteStops(updatedStops);
        setAvailableStops(prev => [...prev, { ...stopToMove, stopNumber: 0, isAvailable: true }]);
      }
    } else if (targetContainer === 'route-zone' && !routeStops.find(s => s.location.id === stopId)) {
      // Moving to route
      const stopToMove = availableStops.find(stop => stop.location.id === stopId);
      if (stopToMove) {
        const overIndex = over.id === 'route-zone' 
          ? routeStops.length 
          : routeStops.findIndex(stop => stop.location.id === over.id);

        const newStops = [...routeStops];
        newStops.splice(overIndex, 0, { ...stopToMove, isAvailable: false });
        const updatedStops = updateStopNumbers(newStops);

        // Check validation and set error message if invalid
        // Exclude the stop being moved from available stops for validation
        const remainingAvailableStops = availableStops.filter(stop => stop.location.id !== stopId);
        const error = getRouteValidationError(updatedStops, remainingAvailableStops);
        setValidationError(error);

        setAvailableStops(prev => prev.filter(stop => stop.location.id !== stopId));
        setRouteStops(updatedStops);
      }
    } else if (targetContainer === 'route-zone' && routeStops.find(s => s.location.id === stopId)) {
      // Reordering within route zone
      const activeIndex = routeStops.findIndex(stop => stop.location.id === active.id);
      const overIndex = routeStops.findIndex(stop => stop.location.id === over.id);

      if (activeIndex !== overIndex) {
        const newStops = arrayMove([...routeStops], activeIndex, overIndex);
        const updatedStops = updateStopNumbers(newStops);
        
        // Check validation and set error message if invalid
        const error = getRouteValidationError(updatedStops, availableStops);
        setValidationError(error);

        setRouteStops(updatedStops);
      }
    }
  };

  const handleMoveToRoute = (stopId: string) => {
    const stopToMove = availableStops.find(stop => stop.location.id === stopId);
    if (stopToMove) {
      const newStops = [...routeStops, { ...stopToMove, isAvailable: false }];
      const updatedStops = updateStopNumbers(newStops);
      
      // Check validation and set error message if invalid
      const remainingAvailableStops = availableStops.filter(stop => stop.location.id !== stopId);
      const error = getRouteValidationError(updatedStops, remainingAvailableStops);
      setValidationError(error);

      setAvailableStops(prev => prev.filter(stop => stop.location.id !== stopId));
      setRouteStops(updatedStops);
    }
  };

  const handleMoveToAvailable = (stopId: string) => {
    const stopToMove = routeStops.find(stop => stop.location.id === stopId);
    if (stopToMove) {
      const newRouteStops = routeStops.filter(stop => stop.location.id !== stopId);
      const updatedStops = updateStopNumbers(newRouteStops);
      
      // Check validation and set error message if invalid
      const newAvailableStops = [...availableStops, { ...stopToMove, stopNumber: 0, isAvailable: true }];
      const error = getRouteValidationError(updatedStops, newAvailableStops);
      setValidationError(error);

      setRouteStops(updatedStops);
      setAvailableStops(prev => [...prev, { ...stopToMove, stopNumber: 0, isAvailable: true }]);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <div className="flex flex-col md:grid md:grid-cols-[250px_1fr_1fr] gap-4">
          {/* Driver Selection */}
          <div>
            <h2 className="text-lg dark:text-white font-medium mb-2">{t('planner:route_planner.select_driver')}</h2>
            <div className="md:hidden">
              <select 
                className="w-full bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-3 text-gray-900 dark:text-white"
                value={selectedDriver}
                onChange={(e) => onSelectDriver(e.target.value)}
              >
                <option value="">{t('planner:route_planner.select_driver_placeholder')}</option>
                {drivers?.map(driver => (
                  <option key={driver.email} value={driver.email}>
                    {driver.firstName} {driver.lastName} ({driver.email})
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden md:flex md:flex-col gap-2 bg-gray-100/80 dark:bg-gray-900/50 p-4 rounded-lg min-h-[78px] border border-gray-200 dark:border-gray-700">
              {drivers?.map(driver => (
                <DriverCard
                  key={driver.email}
                  driver={driver}
                  onClick={() => onSelectDriver(driver.email)}
                  isSelected={selectedDriver === driver.email}
                />
              ))}
            </div>
          </div>

          {/* Available Stops */}
          <div>
            <h2 className="text-lg dark:text-white font-medium mb-2">{t('planner:route_planner.available_stops')}</h2>
            {loading ? (
              <div className="bg-gray-100/80 dark:bg-gray-900/50 p-4 rounded-lg min-h-[78px] flex items-center justify-center">
                <div className="animate-pulse text-gray-500">{t('planner:route_planner.loading_routes')}</div>
              </div>
            ) : (
              <RouteDroppableZone 
                id="available-zone" 
                stops={availableStops} 
                onMoveToRoute={handleMoveToRoute}
                invalidStopIds={validationError?.invalidStopIds}
              />
            )}
          </div>

          {/* Route Stops */}
          <div>
            <h2 className="text-lg dark:text-white font-medium mb-2">
              {t('planner:route_planner.route')}
            </h2>
            {loading ? (
              <div className="bg-gray-100/80 dark:bg-gray-900/50 p-4 rounded-lg min-h-[78px] flex items-center justify-center">
                <div className="animate-pulse text-gray-500">{t('planner:route_planner.loading_routes')}</div>
              </div>
            ) : (
              <RouteDroppableZone 
                id="route-zone" 
                stops={routeStops} 
                onMoveToAvailable={handleMoveToAvailable}
                invalidStopIds={validationError?.invalidStopIds}
              />
            )}
          </div>
        </div>

        <DragOverlay dropAnimation={null}>
          {activeId ? (
            activeId.startsWith('group-') ? (
              <div className="bg-white dark:bg-gray-800 p-4 rounded-lg border border-gray-200 dark:border-gray-700 shadow-lg">
                <div className="text-sm font-medium text-gray-700 dark:text-gray-300">
                  {availableStops.find(stop => stop.orderId === activeId.replace('group-', ''))?.orderDisplayName}
                </div>
              </div>
            ) : (
              <StopCardWrapper
                stop={[...availableStops, ...routeStops].find(stop => stop.location.id === activeId)!}
                isDragging={true}
                isInvalid={validationError?.invalidStopIds?.includes(activeId)}
                routeStops={routeStops}
              />
            )
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  );
};
