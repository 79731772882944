import { useMemo } from "react";
import MapPin from "../../../../assets/icons/MapPin.svg";
import MapPinStart from "../../../../assets/icons/MapPinStart.svg";
import MapPinDriver from "../../../../assets/icons/MapPinDriver.svg";
import { MapPinProps } from "../types";

export const useMapPins = ({ isLoaded }: MapPinProps) => {
  const waypointPin = useMemo<google.maps.Icon | undefined>(() => {
    if (!isLoaded) return undefined;
    return {
      url: MapPin,
      scaledSize: new window.google.maps.Size(28, 28),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(14, 14),
      labelOrigin: new window.google.maps.Point(14, 14),
    };
  }, [isLoaded]);

  const startPin = useMemo<google.maps.Icon | undefined>(() => {
    if (!isLoaded) return undefined;
    return {
      url: MapPinStart,
      scaledSize: new window.google.maps.Size(28, 28),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(14, 14),
      labelOrigin: new window.google.maps.Point(14, 14),
    };
  }, [isLoaded]);

  const driverPin = useMemo<google.maps.Icon | undefined>(() => {
    if (!isLoaded) return undefined;
    return {
      url: MapPinDriver,
      scaledSize: new window.google.maps.Size(28, 28),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(14, 14),
      labelOrigin: new window.google.maps.Point(14, 14),
    };
  }, [isLoaded]);

  return {
    waypointPin,
    startPin,
    driverPin,
  };
}; 