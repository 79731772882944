import { FC } from "react";
import { PageLayout } from "../../../../components";
import { Card } from "../../../../components/atoms/Card";
import { useTranslation } from "react-i18next";

export const DriverMapSkeleton: FC = () => {
  const { t } = useTranslation(["common", "map"]);

  return (
    <PageLayout>
      {/* Tabs og filtre skeleton */}
      <div className="border-b border-gray-200 dark:border-gray-700 mb-4">
        <div className="px-4">
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
            <li className="mr-2">
              <div className="h-8 bg-gray-200 dark:bg-gray-700 w-24 rounded-md animate-pulse"></div>
            </li>
            <li className="mr-2">
              <div className="h-8 bg-gray-200 dark:bg-gray-700 w-24 rounded-md animate-pulse"></div>
            </li>
          </ul>
        </div>
      </div>

      {/* Innhold skeleton */}
      <div className="px-4 pb-4">
        {/* Dato-velger skeleton */}
        <div className="flex justify-between items-center mb-4">
          <div className="h-8 bg-gray-200 dark:bg-gray-700 w-48 rounded-md animate-pulse"></div>
          <div className="h-8 bg-gray-200 dark:bg-gray-700 w-32 rounded-md animate-pulse"></div>
        </div>

        {/* Kart skeleton */}
        <Card bgColorClass="dark:bg-[#0b0f26]" containerClass="mb-4">
          <div className="h-[400px] bg-gray-200 dark:bg-gray-700 rounded animate-pulse"></div>
        </Card>

        {/* Liste skeleton */}
        <Card bgColorClass="dark:bg-[#0b0f26]">
          <div className="h-5 bg-gray-200 dark:bg-gray-700 rounded w-32 mb-4 animate-pulse"></div>
          <div className="space-y-4">
            {[...Array(4)].map((_, index) => (
              <div key={index} className="flex items-center justify-between">
                <div className="flex items-center space-x-3">
                  <div className="h-10 w-10 bg-gray-200 dark:bg-gray-700 rounded-full animate-pulse"></div>
                  <div>
                    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-32 animate-pulse mb-2"></div>
                    <div className="h-3 bg-gray-200 dark:bg-gray-700 rounded w-24 animate-pulse"></div>
                  </div>
                </div>
                <div className="h-8 bg-gray-200 dark:bg-gray-700 w-20 rounded animate-pulse"></div>
              </div>
            ))}
          </div>
        </Card>
      </div>
    </PageLayout>
  );
}; 