import { FC } from "react";
import { useTranslation } from "react-i18next";
import { LGBDatepicker, CustomSelect } from "../../../../components";
import { MapControlsProps } from "../types";
import { OrderStatus } from "../../../../types/order/IOrder";

export const MapControls: FC<MapControlsProps> = ({
  setSelectedTypes,
  selectedDate,
  setSelectedDate,
  showStatusFilter = true,
}) => {
  const { t } = useTranslation(["common", "orders", "stats"]);

  return (
    <div className="flex flex-col gap-3">
      <div className="w-full">
        <LGBDatepicker 
          select={setSelectedDate} 
          selectedDate={selectedDate} 
          allowPastDates={true} 
        />
      </div>
      {showStatusFilter && (
        <div className="w-full">
          <CustomSelect
            type="checkbox"
            label={t("orders:choose_status")}
            items={[
              {
                key: OrderStatus.InProgress,
                value: OrderStatus.InProgress,
                label: t("stats:orderStatus.in_progress"),
              },
              {
                key: OrderStatus.New,
                value: OrderStatus.New,
                label: t("stats:orderStatus.scheduled"),
              },
              {
                key: OrderStatus.Completed,
                value: OrderStatus.Completed,
                label: t("stats:orderStatus.completed"),
              },
            ]}
            onSelectChanged={(e: any) => {
              setSelectedTypes(e);
            }}
          />
        </div>
      )}
    </div>
  );
}; 