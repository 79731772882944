import type { FC } from "react";
import { useDroppable } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useTranslation } from "react-i18next";
import { IRouteItem } from "../../../types/order/IRouteItem";
import { SortableStopCard } from "./SortableStopCard";
import { SortableOrderGroup } from "./SortableOrderGroup";
import { EnhancedRouteItem } from "./types";
import { HiOutlineClipboardList, HiOutlineMap } from "react-icons/hi";

interface DroppableZoneProps {
  id: string;
  stops: EnhancedRouteItem[];
  onMoveToRoute?: (stopId: string) => void;
  onMoveToAvailable?: (stopId: string) => void;
  className?: string;
  invalidStopIds?: string[];
}

export const RouteDroppableZone: FC<DroppableZoneProps> = ({ 
  id, 
  stops, 
  onMoveToRoute, 
  onMoveToAvailable, 
  className, 
  invalidStopIds = [] 
}) => {
  const { setNodeRef, isOver, active } = useDroppable({
    id,
  });
  const { t } = useTranslation();

  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center h-32 text-gray-500 dark:text-gray-400 text-sm">
      <HiOutlineClipboardList className="h-8 w-8 mb-2 opacity-50" />
      {id === 'available-zone' 
        ? t('planner:route_planner.no_available_stops')
        : t('planner:route_planner.no_scheduled_stops')}
    </div>
  );

  // Only group available stops
  if (id === 'available-zone') {
    // Group stops by orderId
    const groupedStops = stops.reduce((groups, stop) => {
      const orderId = stop.orderId || 'unknown';
      if (!groups[orderId]) {
        groups[orderId] = [];
      }
      groups[orderId].push(stop);
      return groups;
    }, {} as Record<string, typeof stops>);

    return (
      <div
        ref={setNodeRef}
        className={`p-3 rounded-lg min-h-[78px] flex flex-col gap-3 
          ${isOver 
            ? 'border border-lgb-blue-200 dark:border-lgb-blue-400 bg-lgb-blue-50/30 dark:bg-lgb-blue-900/10' 
            : 'border border-gray-200 dark:border-gray-700 bg-gray-100/80 dark:bg-gray-900/50'
          } 
          transition-all duration-200 ease-in-out
          ${className || ''}`}
      >
        <SortableContext 
          items={[
            ...stops.map(stop => stop.location.id),
            ...Object.keys(groupedStops).map(orderId => `group-${orderId}`)
          ]} 
          strategy={verticalListSortingStrategy}
        >
          {Object.keys(groupedStops).length > 0 ? (
            Object.entries(groupedStops).map(([orderId, groupStops], index) => (
              <SortableOrderGroup
                key={orderId}
                orderId={orderId}
                groupStops={groupStops}
                orderDisplayName={groupStops[0]?.orderDisplayName || ''}
                onMoveToRoute={onMoveToRoute}
                onMoveToAvailable={onMoveToAvailable}
                invalidStopIds={invalidStopIds}
              />
            ))
          ) : (
            <EmptyState />
          )}
        </SortableContext>
      </div>
    );
  }

  // Regular non-grouped view for route zone with route sequence visualization
  return (
    <div
      ref={setNodeRef}
      className={`p-3 rounded-lg min-h-[78px] flex flex-col gap-2 
        ${isOver 
          ? 'border border-lgb-blue-200 dark:border-lgb-blue-400 bg-lgb-blue-50/30 dark:bg-lgb-blue-900/10' 
          : 'border border-gray-200 dark:border-gray-700 bg-gray-100/80 dark:bg-gray-900/50'
        } 
        transition-all duration-200 ease-in-out
        ${className || ''}`}
    >
      {/* Rute-header med informasjon */}
      {stops.length > 0 && (
        <div className={`flex items-center justify-between mb-1 px-2 ${isOver ? 'text-lgb-blue-600 dark:text-lgb-blue-300' : ''}`}>
          <div className="flex items-center gap-2">
            <HiOutlineMap className={`h-4 w-4 ${isOver ? 'text-lgb-blue-400 dark:text-lgb-blue-300' : 'text-gray-500 dark:text-gray-400'}`} />
            <span className={`text-sm font-medium ${isOver ? 'text-lgb-blue-600 dark:text-lgb-blue-300' : 'text-gray-700 dark:text-gray-300'}`}>
              {t('planner:route_planner.route_sequence')}
            </span>
          </div>
          <span className={`text-xs ${isOver ? 'text-lgb-blue-500 dark:text-lgb-blue-300' : 'text-gray-500 dark:text-gray-400'}`}>
            {stops.length} {stops.length === 1 ? t('planner:route_planner.stop') : t('planner:route_planner.stops')}
          </span>
        </div>
      )}
      
      {/* Visuell indikator for ruten */}
      {stops.length > 0 && (
        <div className={`relative ml-3 pl-3 border-l-2 border-dashed 
          ${isOver ? 'border-lgb-blue-200 dark:border-lgb-blue-400' : 'border-gray-300 dark:border-gray-600'}`}>
          <SortableContext 
            items={stops.map(stop => stop.location.id)} 
            strategy={verticalListSortingStrategy}
          >
            {stops.map((stop) => (
              <SortableStopCard 
                key={stop.location.id} 
                stop={stop} 
                onMoveToRoute={onMoveToRoute ? () => onMoveToRoute(stop.location.id) : undefined}
                onMoveToAvailable={onMoveToAvailable ? () => onMoveToAvailable(stop.location.id) : undefined}
                isInvalid={invalidStopIds.includes(stop.location.id)}
                routeStops={stops}
              />
            ))}
          </SortableContext>
        </div>
      )}
      
      {stops.length === 0 && (
        <SortableContext 
          items={stops.map(stop => stop.location.id)} 
          strategy={verticalListSortingStrategy}
        >
          <EmptyState />
        </SortableContext>
      )}
    </div>
  );
}; 