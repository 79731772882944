/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import classNames from "classnames";
import { Sidebar } from "flowbite-react";
import { useEffect, useState } from "react";
import { BottomMenu } from "../../molecules/BottomMenu";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./sidebar-override.css";

import { useSidebarContext } from "../../../context/SidebarContext";
import isSmallScreen from "../../../helpers/is-small-screen";
import { useUserSession } from "../../../context/UserContext";
import {
  administrationIcon,
  customersIcon,
  dashboardIcon,
  inventoryIcon,
  orderIcon,
  WorkSpaceIcon,
  UserProfile,
  mapIcon,
  InvoiceIcon,
  reportsIcon,
} from "../../atoms/Icons/Icons";
import { Button } from "flowbite-react";
import PlusIcon from "../../../assets/icons/plus_icon.svg";
import { HiOutlineCalendar } from "react-icons/hi";

export const SidebarMenu: FC = function () {
  const {
    isOpenOnSmallScreens: isSidebarOpenOnSmallScreens,
    openSidebarDropdowns,
    setOpenSidebarDropdowns,
  } = useSidebarContext();

  const [currentPage, setCurrentPage] = useState("");
  const { userSession } = useUserSession();
  const navigate = useNavigate();

  const { t } = useTranslation(["navigation", "orders"]);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, [setCurrentPage]);

  const setDropdowns = (route: string) => {
    if (openSidebarDropdowns.includes(route)) {
      setOpenSidebarDropdowns(openSidebarDropdowns.filter((x) => x !== route));
    } else {
      setOpenSidebarDropdowns(openSidebarDropdowns.concat([route]));
    }
  };

  const handleNewOrderClick = () => {
    navigate("/orders/new");
  };

  // Felles klasser for alle menypunkter
  const baseClasses = "cursor-pointer transition-colors duration-200";
  const activeClasses = "bg-lgb-blue-100 dark:bg-opacity-20 dark:bg-purple-600 text-lgb-blue-300 dark:text-white font-medium hover:bg-lgb-blue-100 dark:hover:bg-purple-600 dark:hover:bg-opacity-20";
  const inactiveClasses = "hover:bg-lgb-blue-100 dark:hover:bg-purple-500 dark:hover:bg-opacity-15 dark:text-gray-300 dark:hover:text-white";

  return (
    <div
      className={classNames("lg:!block", {
        hidden: !isSidebarOpenOnSmallScreens,
      })}
    >
      <Sidebar
        style={{ paddingTop: `${isSmallScreen() ? "85" : "86"}px` }}
        className="flex fixed top-0 left-0 z-20 flex-col rounded-none flex-shrink-0 pt-16 h-full duration-75 border-r border-gray-100 lg:flex transition-width dark:border-gray-800 shadow-sm"
        aria-label="Sidebar with multi-level dropdown example"
        collapsed={isSidebarOpenOnSmallScreens && !isSmallScreen()}
      >
        <div className="flex h-full flex-col justify-between py-2">
          <div>
            <Sidebar.Items>
              <Sidebar.ItemGroup className="space-y-1">
                <Sidebar.Item
                  onClick={() => navigate("/")}
                  icon={dashboardIcon}
                  className={
                    "/" === currentPage || "/realtime-dashboard" === currentPage || "/dashboard" === currentPage
                      ? `${baseClasses} ${activeClasses}`
                      : `${baseClasses} ${inactiveClasses}`
                  }
                >
                  {t("sidebar.dashboard")}
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={() => navigate("/orders")}
                  icon={orderIcon}
                  className={
                    "/orders" === currentPage || currentPage.startsWith("/orders/")
                      ? `${baseClasses} ${activeClasses}`
                      : `${baseClasses} ${inactiveClasses}`
                  }
                >
                  {t("sidebar.orders")}
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={() => navigate("/planner")}
                  icon={() => <HiOutlineCalendar className="w-6 h-6" />}
                  className={
                    "/planner" === currentPage
                      ? `${baseClasses} ${activeClasses}`
                      : `${baseClasses} ${inactiveClasses}`
                  }
                >
                  {t("sidebar.planner")}
                </Sidebar.Item>

                <Sidebar.Item
                  onClick={() => navigate("/invoices")}
                  icon={InvoiceIcon}
                  className={
                    "/invoices" === currentPage
                      ? `${baseClasses} ${activeClasses}`
                      : `${baseClasses} ${inactiveClasses}`
                  }
                >
                  {t("sidebar.invoices")}
                </Sidebar.Item>

                <Sidebar.Item
                  onClick={() => navigate("/customers")}
                  icon={customersIcon}
                  className={
                    "/customers" === currentPage
                      ? `${baseClasses} ${activeClasses}`
                      : `${baseClasses} ${inactiveClasses}`
                  }
                >
                  {t("sidebar.customers")}
                </Sidebar.Item>
                <Sidebar.Item
                  onClick={() => navigate("/map")}
                  icon={mapIcon}
                  className={
                    "/map" === currentPage
                      ? `${baseClasses} ${activeClasses}`
                      : `${baseClasses} ${inactiveClasses}`
                  }
                >
                  {t("sidebar.map")}
                </Sidebar.Item>
                <Sidebar.Collapse
                  icon={inventoryIcon}
                  label={t("sidebar.resources")}
                  onClick={() => setDropdowns("resources")}
                  open={openSidebarDropdowns.includes("resources")}
                  className={
                    currentPage.startsWith("/resources/")
                      ? `${baseClasses} ${activeClasses}`
                      : `${baseClasses} ${inactiveClasses}`
                  }
                >
                  <Sidebar.Item
                    onClick={() => navigate("/resources/products")}
                    className={
                      "/resources/products" === currentPage
                        ? `${baseClasses} ${activeClasses}`
                        : `${baseClasses} ${inactiveClasses}`
                    }
                  >
                    {t("sidebar.products")}
                  </Sidebar.Item>

                  <Sidebar.Item
                    onClick={() => navigate("/resources/locations")}
                    className={
                      "/resources/locations" === currentPage
                        ? `${baseClasses} ${activeClasses}`
                        : `${baseClasses} ${inactiveClasses}`
                    }
                  >
                    {t("sidebar.locations")}
                  </Sidebar.Item>

                  <Sidebar.Item
                    onClick={() => navigate("/resources/vehicles")}
                    className={
                      "/resources/vehicles" === currentPage
                        ? `${baseClasses} ${activeClasses}`
                        : `${baseClasses} ${inactiveClasses}`
                    }
                  >
                    {t("sidebar.vehicles")}
                  </Sidebar.Item>
                  <Sidebar.Item
                    onClick={() => navigate("/resources/drivers")}
                    className={
                      "/resources/drivers" === currentPage
                        ? `${baseClasses} ${activeClasses}`
                        : `${baseClasses} ${inactiveClasses}`
                    }
                  >
                    {t("sidebar.drivers")}
                  </Sidebar.Item>
                </Sidebar.Collapse>
                <Sidebar.Collapse
                  icon={WorkSpaceIcon}
                  label={t("sidebar.workspace")}
                  open={openSidebarDropdowns.includes("workspace")}
                  onClick={() => setDropdowns("workspace")}
                  className={
                    currentPage.startsWith("/workspace/")
                      ? `${baseClasses} ${activeClasses}`
                      : `${baseClasses} ${inactiveClasses}`
                  }
                >
                  <Sidebar.Item
                    onClick={() => navigate("/workspace/resource-types")}
                    className={
                      "/workspace/resource-types" === currentPage
                        ? `${baseClasses} ${activeClasses}`
                        : `${baseClasses} ${inactiveClasses}`
                    }
                  >
                    {t("sidebar.parameters")}
                  </Sidebar.Item>

                  <Sidebar.Item
                    onClick={() => navigate("/workspace/users")}
                    className={
                      "/workspace/users" === currentPage
                        ? `${baseClasses} ${activeClasses}`
                        : `${baseClasses} ${inactiveClasses}`
                    }
                  >
                    {t("sidebar.users")}
                  </Sidebar.Item>
                  <Sidebar.Item
                    onClick={() => navigate("/workspace/companies")}
                    className={
                      "/workspace/companies" === currentPage
                        ? `${baseClasses} ${activeClasses}`
                        : `${baseClasses} ${inactiveClasses}`
                    }
                  >
                    {t("sidebar.companies")}
                  </Sidebar.Item>
                </Sidebar.Collapse>
                {userSession?.user.isAdmin ? (
                  <Sidebar.Collapse
                    icon={administrationIcon}
                    label={t("sidebar.administration")}
                    onClick={() => setDropdowns("administration")}
                    open={openSidebarDropdowns.includes("administration")}
                    className={
                      currentPage.startsWith("/administration/")
                        ? `${baseClasses} ${activeClasses}`
                        : `${baseClasses} ${inactiveClasses}`
                    }
                  >
                    <Sidebar.Item
                      onClick={() => navigate("/administration/users")}
                      className={
                        "/administration/users" === currentPage
                          ? `${baseClasses} ${activeClasses}`
                          : `${baseClasses} ${inactiveClasses}`
                      }
                    >
                      {t("sidebar.all_users")}
                    </Sidebar.Item>
                    <Sidebar.Item
                      onClick={() => navigate("/administration/workspaces")}
                      className={
                        "/administration/workspaces" === currentPage
                          ? `${baseClasses} ${activeClasses}`
                          : `${baseClasses} ${inactiveClasses}`
                      }
                    >
                      {t("sidebar.all_workspaces")}
                    </Sidebar.Item>
                    <Sidebar.Item
                      onClick={() => navigate("/administration/reports")}
                      className={
                        "/administration/reports" === currentPage
                          ? `${baseClasses} ${activeClasses}`
                          : `${baseClasses} ${inactiveClasses}`
                      }
                    >
                      {t("sidebar.reports")}
                    </Sidebar.Item>
                  </Sidebar.Collapse>
                ) : null}
                <Sidebar.Item
                  onClick={() => navigate("/profile")}
                  icon={UserProfile}
                  className={
                    "/profile" === currentPage
                      ? `${baseClasses} ${activeClasses}`
                      : `${baseClasses} ${inactiveClasses}`
                  }
                >
                  {t("sidebar.profile")}
                </Sidebar.Item>
              </Sidebar.ItemGroup>

              {!isSidebarOpenOnSmallScreens && ( // Check if the sidebar is collapsed
                <div className="w-full flex justify-center items-center">
                  <div className="w-full flex justify-center items-center"></div>
                </div>
              )}
            </Sidebar.Items>
          </div>
          {isSmallScreen() && (
            <Button
              onClick={handleNewOrderClick}
              className="mt-6 mb-6 mx-auto flex justify-center items-center active:scale-95 bg-lgb-blue-300 hover:bg-lgb-blue-350 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:bg-lgb-blue-300 dark:hover:bg-lgb-blue-350 dark:focus:ring-blue-700 transition-all duration-200 shadow-sm"
            >
              <img
                src={PlusIcon}
                className="w-3.5 h-3.5 me-2"
                alt="New Order Icon"
              />
              {t("orders:create.new")}
            </Button>
          )}
          {!isSidebarOpenOnSmallScreens && (
          <BottomMenu />
        )}
        </div>
      </Sidebar>
    </div>
  );
};
