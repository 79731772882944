// src/components/molecules/CustomerDetails/CustomerDetails.tsx

import { FC, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Tooltip, Badge } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import { FaRegCopy } from "react-icons/fa";
import { SimpleList } from "../../molecules/SimpleList";
import { Product, Parameter } from "../../../models";
import { getProductTypeAsArray, formatDimension } from "../../../utils/productHelpers";

interface ProductDetailsProps {
  product: Product | undefined;
  categories?: Parameter[];
}

export const ProductDetails: FC<ProductDetailsProps> = ({ product, categories = [] }) => {
  const { t } = useTranslation(["common", "products"]);
  const [hasCopied, setHasCopied] = useState(false);

  // Placeholder for when no product is selected
  const noProductSelected = useMemo(() => (
    <div className="p-6 text-gray-600 dark:text-gray-300">
      {t("products:split.no_product_selected")}
    </div>
  ), [t]);

  // Memoize product type badges to avoid unnecessary re-renders
  const productTypeBadges = useMemo(() => {
    if (!product) return null;
    
    const types = getProductTypeAsArray(product);
    
    if (types.length === 0) {
      return (
        <Badge color="gray" className="text-sm">
          {t("products:categories.uncategorized")}
        </Badge>
      );
    }
    
    return types.map((typeId) => {
      // Find the category name from the categories array
      const category = categories.find(c => c.id === typeId);
      const displayName = category ? category.code : typeId;
      
      return (
        <Badge key={typeId} color="purple" className="text-sm">
          {displayName}
        </Badge>
      );
    });
  }, [product, categories, t]);

  // Memoize list items for better performance
  const listItems = useMemo(() => {
    if (!product) return [];
    
    return [
      {
        title: t("products:details.height"),
        value: formatDimension(product.height),
      },
      {
        title: t("products:details.length"),
        value: formatDimension(product.length),
      },
      {
        title: t("products:details.width"),
        value: formatDimension(product.width),
      },
      {
        title: t("products:details.weight"),
        value: formatDimension(product.weight, "kg"),
      },
      {
        title: t("common:description"),
        value: product.description || t("products:no_description"),
      },
    ];
  }, [product, t]);

  const handleCopy = () => {
    if (!product) return;
    
    navigator.clipboard.writeText(product.descriptionShort ?? "");
    setHasCopied(true);
    setTimeout(() => setHasCopied(false), 2000); // Reset after 2 seconds
  };

  // If no product is selected, show a placeholder
  if (!product) {
    return noProductSelected;
  }

  return (
    <div className="p-6 md:p-8">
      {/* Product Header */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
        <div className="flex items-center gap-4 w-full">
          <div className="flex-1 min-w-0">
            <h2 className="text-xl sm:text-2xl font-medium dark:text-lgb-grey-100 truncate">
              {product.descriptionShort}
            </h2>
          </div>

          {!hasCopied ? (
            <Tooltip content={t("common:copy")}>
              <div
                className="bg-none rounded-md relative flex-shrink-0"
                style={{ width: "34px", height: "34px" }}
              >
                <FaRegCopy
                  className="w-6 h-6 dark:text-white cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  onClick={handleCopy}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="flex items-center text-lgb-grey-400 dark:text-lgb-grey-100 flex-shrink-0">
              <HiCheck className="mr-2 h-5 w-5" />
              <p>{t("common:copied")}</p>
            </div>
          )}
        </div>
      </div>

      {/* Product Type Badges */}
      <div className="mb-6">
        <div className="flex flex-wrap gap-2">
          {productTypeBadges}
        </div>
      </div>

      {/* Basic Info */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm border border-gray-200 dark:border-gray-700 overflow-hidden">
        <SimpleList items={listItems} />
      </div>
    </div>
  );
};
