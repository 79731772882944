/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { HiOutlinePlus, HiX } from "react-icons/hi";
import {
  StandardForm,
  StyledTextInput,
  StyledTextArea,
  CustomSelect,
} from "../..";
import { Button } from "flowbite-react";
import { IProduct, IParameter, InputValidationResult } from "../../../types";
import { useTranslation } from "react-i18next";
import { Product } from "../../../models";
import {
  validateHasValue,
  validateNumberBetween,
  validateStringLength,
  hasValidationError,
} from "../../../helpers/validationHelper";
import { IPriceModel } from "../../../types/Product";

export interface ProductModalProp {
  data: IProduct;
  productTypes: IParameter[];
  isShowing: boolean;
  onConfirm: (data: IProduct) => void;
  onCancel: () => void;
  showSaveToggle?: boolean;
  shouldSaveProduct?: boolean;
  onSaveToggleChange?: (shouldSave: boolean) => void;
}

// Forbedret custom modal-komponent som bruker React Portal
const CustomModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
}> = ({ isOpen, onClose, title, children }) => {
  // Opprett portal-target hvis det ikke finnes
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);
  
  useEffect(() => {
    // Opprett portal-container hvis den ikke finnes
    if (isOpen) {
      let element = document.getElementById('product-modal-portal');
      if (!element) {
        element = document.createElement('div');
        element.id = 'product-modal-portal';
        document.body.appendChild(element);
      }
      setPortalElement(element);
      
      // Fjern overflow på body når modal er åpen
      document.body.style.overflow = 'hidden';
      
      // Cleanup
      return () => {
        document.body.style.overflow = '';
      };
    }
  }, [isOpen]);
  
  // Ikke render noe hvis modal er lukket eller portal ikke er klar
  if (!isOpen || !portalElement) return null;
  
  // Bruk createPortal for å rendre modal utenfor normal DOM-flyt
  return createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-gray-900 bg-opacity-50 transition-opacity">
      <div className="relative w-full max-w-3xl max-h-[90vh] bg-white dark:bg-gray-800 rounded-lg shadow-xl overflow-hidden animate-in fade-in duration-300">
        {/* Header */}
        <div className="flex justify-between items-center p-6 pb-2 border-b dark:border-gray-700">
          <h3 className="text-xl font-medium text-gray-900 dark:text-white">
            {title}
          </h3>
          <button
            type="button"
            onClick={onClose}
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
          >
            <HiX className="w-5 h-5" />
          </button>
        </div>
        
        {/* Body */}
        <div className="p-6">
          {children}
        </div>
      </div>
    </div>,
    portalElement
  );
};

// Hjelpefunksjon for å trygt konvertere verdier til tall
const safeParseFloat = (value: string | number | undefined | null): number => {
  if (value === undefined || value === null || value === '') return 0;
  const parsed = typeof value === 'string' ? parseFloat(value) : value;
  return isNaN(parsed) ? 0 : parsed;
};

export const ProductForm: FC<ProductModalProp> = function (
  props: ProductModalProp,
) {
  const { t } = useTranslation(["common", "inventory", "modals"]);
  const [isNew] = useState(Product.isNew(props.data));
  const [pricingModels, setPricingModels] = useState<IPriceModel[]>(
    props.data.pricing?.priceModel ?? [],
  );
  const [productType, setProductType] = useState<string[]>(
    props.data.productType ?? [],
  );
  const [isValidated, setIsValidated] = useState(false);
  const [validations, setValidations] = useState([] as InputValidationResult[]);
  const [formData, setFormData] = useState<any>({});
  
  // Bruk en ref for å holde toggle-tilstanden i stedet for state
  const saveProductRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<HTMLFormElement>(null);
  
  // Initialiser toggle-tilstanden
  useEffect(() => {
    if (saveProductRef.current) {
      saveProductRef.current.checked = props.shouldSaveProduct ?? true;
    }
  }, [props.shouldSaveProduct]);
  
  // Oppdater productType når props.data endres
  useEffect(() => {
    if (props.data.productType && props.data.productType.length > 0) {
      setProductType(props.data.productType);
      
      // Oppdater også formData med produkttypen
      setFormData((prevData: Record<string, any>) => ({
        ...prevData,
        productType: props.data.productType
      }));
    }
  }, [props.data]);

  const getValidationResults = (product: IProduct) => {
    // Sjekk om descriptionShort er gyldig - ikke valider hvis vi er i redigeringsmodus og feltet ikke er endret
    const isDescriptionShortValid = 
      product.descriptionShort !== undefined && 
      product.descriptionShort !== null && 
      typeof product.descriptionShort === 'string' && 
      product.descriptionShort.trim() !== "" && 
      product.descriptionShort.trim().length >= 2;
    
    // Sjekk om productType er gyldig
    const isProductTypeValid = 
      product.productType !== undefined && 
      Array.isArray(product.productType) && 
      product.productType.length > 0;

    
    return [
      {
        id: "descriptionShort",
        show: isValidated,
        isInvalid: !isDescriptionShortValid,
        errorMessage: t("common:errors.fields.not_empty"),
      },
      {
        id: "productType",
        show: isValidated,
        isInvalid: !isProductTypeValid,
        errorMessage: t("common:errors.fields.not_empty"),
      },
      {
        id: "weight",
        show: isValidated,
        isInvalid:
          product.weight !== undefined && 
          product.weight !== null && 
          product.weight !== 0 && 
          !validateNumberBetween(Number(product.weight), 0, 10000),
        errorMessage: t("common:errors.fields.number"),
      },
      {
        id: "height",
        show: isValidated,
        isInvalid:
          product.height !== undefined && 
          product.height !== null && 
          product.height !== 0 && 
          !validateNumberBetween(Number(product.height), 0, 10000),
        errorMessage: t("common:errors.fields.number"),
      },
      {
        id: "width",
        show: isValidated,
        isInvalid:
          product.width !== undefined && 
          product.width !== null && 
          product.width !== 0 && 
          !validateNumberBetween(Number(product.width), 0, 10000),
        errorMessage: t("common:errors.fields.number"),
      },
      {
        id: "length",
        show: isValidated,
        isInvalid:
          product.length !== undefined && 
          product.length !== null && 
          product.length !== 0 && 
          !validateNumberBetween(Number(product.length), 0, 10000),
        errorMessage: t("common:errors.fields.number"),
      },
      {
        id: "pricing.priceModel",
        show: isValidated,
        isInvalid:
          product.pricing === undefined ||
          product.pricing.priceModel === undefined ||
          product.pricing.priceModel.length === 0,
        errorMessage: t("common:errors.fields.not_empty"),
      },
    ];
  };

  // Sikre at productType alltid er et array
  const ensureProductTypeIsArray = (productType: string | string[] | undefined | null): string[] => {
    if (!productType) return [];
    if (Array.isArray(productType)) return productType;
    return [productType];
  };

  const handleFormSubmit = () => {
    if (!formData) return;    
    // Sikre at descriptionShort er trimmet og ikke tom
    let trimmedDescriptionShort = "";
    if (typeof formData.descriptionShort === 'string') {
      trimmedDescriptionShort = formData.descriptionShort.trim();
    } else if (formData.descriptionShort) {
      trimmedDescriptionShort = String(formData.descriptionShort).trim();
    } else if (props.data.descriptionShort) {
      // Behold eksisterende verdi hvis formData ikke har en ny verdi
      trimmedDescriptionShort = props.data.descriptionShort;
    }
        
    // Sikre at numeriske verdier er tall
    const weight = formData.weight !== undefined ? safeParseFloat(formData.weight) : props.data.weight;
    const length = formData.length !== undefined ? safeParseFloat(formData.length) : props.data.length;
    const height = formData.height !== undefined ? safeParseFloat(formData.height) : props.data.height;
    const width = formData.width !== undefined ? safeParseFloat(formData.width) : props.data.width;
        
    // Håndter priser
    const pricePerCargoUnit = formData["pricing.pricePerCargoUnit"] !== undefined ? 
      safeParseFloat(formData["pricing.pricePerCargoUnit"]) : 
      props.data.pricing?.pricePerCargoUnit;
      
    const pricePerDistanceUnit = formData["pricing.pricePerDistanceUnit"] !== undefined ? 
      safeParseFloat(formData["pricing.pricePerDistanceUnit"]) : 
      props.data.pricing?.pricePerDistanceUnit;
      
    const pricePerVolumeUnit = formData["pricing.pricePerVolumeUnit"] !== undefined ? 
      safeParseFloat(formData["pricing.pricePerVolumeUnit"]) : 
      props.data.pricing?.pricePerVolumeUnit;
      
    const pricePerWeightUnit = formData["pricing.pricePerWeightUnit"] !== undefined ? 
      safeParseFloat(formData["pricing.pricePerWeightUnit"]) : 
      props.data.pricing?.pricePerWeightUnit;
    
    // Håndter produkttype
    let finalProductType = productType;
    if (formData.productType) {
      // Hvis formData har en produkttype, bruk den
      finalProductType = Array.isArray(formData.productType) ? formData.productType : [formData.productType];
    } else if (!finalProductType || finalProductType.length === 0) {
      // Hvis ingen produkttype er valgt, behold den eksisterende
      finalProductType = props.data.productType || [];
    }
        
    const product = {
      ...props.data,
      descriptionShort: trimmedDescriptionShort,
      description: formData.description ?? props.data.description ?? "",
      // Konverter numeriske verdier til tall
      weight,
      length,
      height,
      width,
      // Sett produkttype
      productType: finalProductType,
      pricing: {
        ...props.data.pricing,
        priceModel: pricingModels,
        pricePerCargoUnit,
        pricePerDistanceUnit,
        pricePerVolumeUnit,
        pricePerWeightUnit,
      },
    } as IProduct;
        
    validateAndConfirm(product);
  };

  const validateAndConfirm = (product: IProduct) => {
    
    // Trigger validering
    const validationResults = getValidationResults(product);
    
    // Sjekk om det er valideringsfeil
    if (hasValidationError(validationResults)) {
      return;
    }

    // Hent toggle-status
    const isToggleChecked = saveProductRef.current?.checked || false;
    
    // Informer parent om toggle-status hvis nødvendig
    if (props.onSaveToggleChange) {
      props.onSaveToggleChange(isToggleChecked);
    }

    // Konverter numeriske verdier til tall
    const finalProduct: IProduct = {
      ...product,
      weight: safeParseFloat(product.weight),
      length: safeParseFloat(product.length),
      height: safeParseFloat(product.height),
      width: safeParseFloat(product.width),
      productType: ensureProductTypeIsArray(product.productType),
      pricing: {
        ...product.pricing,
        priceModel: product.pricing?.priceModel || [],
        pricePerCargoUnit: safeParseFloat(product.pricing?.pricePerCargoUnit),
        pricePerVolumeUnit: safeParseFloat(product.pricing?.pricePerVolumeUnit),
        pricePerWeightUnit: safeParseFloat(product.pricing?.pricePerWeightUnit),
        pricePerDistanceUnit: safeParseFloat(product.pricing?.pricePerDistanceUnit)
      }
    };
    
    props.onConfirm(finalProduct);
  };

  useEffect(() => {
    setIsValidated(true);
  }, [setValidations, validations]);

  // Bygger feltene for skjemaet
  const fields = [
    {
      id: "descriptionShort",
      label: t("inventory:product_name"),
      grid_style: "col-span-2",
      input: (
        <StyledTextInput
          id="descriptionShort"
          placeholder={t("inventory:product_description_example")}
          defaultValue={props.data.descriptionShort ?? ""}
          validation={validations.find(
            (validation) => validation.id === "descriptionShort",
          )}
          onInputChanged={(value) => {
            // Oppdater formData direkte når brukeren skriver
            setFormData((prevData: Record<string, any>) => {
              return {
                ...prevData,
                descriptionShort: value
              };
            });
          }}
        />
      ),
    },
    {
      id: "description",
      label: t("inventory:product_description"),
      grid_style: "col-span-2",
      input: (
        <StyledTextArea
          id="description"
          defaultValue={props.data.description ?? ""}
          placeholder={t("modals:placeholders.text_area")}
          onInputChanged={(value) => {
            // Oppdater formData direkte når brukeren skriver
            setFormData((prevData: Record<string, any>) => {
              return {
                ...prevData,
                description: value
              };
            });
          }}
        />
      ),
    },
    {
      id: "weight",
      label: t("common:weight"),
      input: (
        <div className="relative">
          <StyledTextInput
            id="weight"
            placeholder={t("common:placeholder.weight")}
            defaultValue={props.data.weight && props.data.weight > 0 ? props.data.weight : ""}
            type="number"
            validation={validations.find(
              (validation) => validation.id === "weight"
            )}
            onInputChanged={(value) => {
              // Oppdater formData direkte når brukeren skriver
              setFormData((prevData: Record<string, any>) => {
                const numValue = value === "" ? 0 : parseFloat(value);
                return {
                  ...prevData,
                  weight: numValue
                };
              });
            }}
          />
          <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
            Kilo
          </span>
        </div>
      ),
    },
    {
      id: "length",
      label: t("common:length"),
      input: (
        <div className="relative">
          <StyledTextInput
            id="length"
            placeholder={t("common:placeholder.length")}
            defaultValue={props.data.length && props.data.length > 0 ? props.data.length : ""}
            type="number"
            validation={validations.find((validation) => validation.id === "length")}
            onInputChanged={(value) => {
              // Oppdater formData direkte når brukeren skriver
              setFormData((prevData: Record<string, any>) => {
                const numValue = value === "" ? 0 : parseFloat(value);
                return {
                  ...prevData,
                  length: numValue
                };
              });
            }}
          />
          <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
            cm
          </span>
        </div>
      ),
    },
    {
      id: "height",
      label: t("common:height"),
      input: (
        <div className="relative">
          <StyledTextInput
            id="height"
            placeholder={t("common:placeholder.height")}
            defaultValue={props.data.height && props.data.height > 0 ? props.data.height : ""}
            type="number"
            validation={validations.find((validation) => validation.id === "height")}
            onInputChanged={(value) => {
              // Oppdater formData direkte når brukeren skriver
              setFormData((prevData: Record<string, any>) => {
                const numValue = value === "" ? 0 : parseFloat(value);
                return {
                  ...prevData,
                  height: numValue
                };
              });
            }}
          />
          <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
            cm
          </span>
        </div>
      ),
    },
    {
      id: "width",
      label: t("common:width"),
      input: (
        <div className="relative">
          <StyledTextInput
            id="width"
            placeholder={t("common:placeholder.width")}
            defaultValue={props.data.width && props.data.width > 0 ? props.data.width : ""}
            type="number"
            validation={validations.find((validation) => validation.id === "width")}
            onInputChanged={(value) => {
              // Oppdater formData direkte når brukeren skriver
              setFormData((prevData: Record<string, any>) => {
                const numValue = value === "" ? 0 : parseFloat(value);
                return {
                  ...prevData,
                  width: numValue
                };
              });
            }}
          />
          <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
            cm
          </span>
        </div>
      ),
    },
    {
      id: "productType",
      label: t("common:productType_label"),
      tooltip: t("common:productType_tooltip"),
      input: (
        <CustomSelect
          type="radio"
          defaultValue={props.data.productType && props.data.productType.length > 0 ? props.data.productType[0] : ""}
          label={t("modals:placeholders.product_type")}
          onSelectChanged={(value) => {
            setProductType([value] as string[]);
            // Oppdater formData direkte når brukeren velger
            setFormData((prevData: Record<string, any>) => {
              return {
                ...prevData,
                productType: [value]
              };
            });
          }}
          items={props.productTypes.map((type) => ({
            key: type.id,
            label: type.code,
            value: type.id,
          }))}
          validation={validations.find(
            (validation) => validation.id === "productType",
          )}
        />
      ),
    },
    {
      id: "pricing.priceModel",
      label: t("common:priceModel_label"),
      tooltip: t("common:priceModel_tooltip"),
      input: (
        <CustomSelect
          type="checkbox"
          defaultValue={
            props.data.pricing?.priceModel ?? pricingModels ?? []
          } // Ensure default value is correctly set
          label={t("modals:placeholders.price_model")}
          onSelectChanged={(values) => {
            setPricingModels(values as IPriceModel[]);
          }}
          items={[
            {
              key: IPriceModel.FIXED,
              label: t("common:price_model.fixed.title"),
              value: IPriceModel.FIXED,
              description: t("common:price_model.fixed.description"),
            },
            {
              key: IPriceModel.VOLUME,
              label: t("common:price_model.volume.title"),
              value: IPriceModel.VOLUME,
              description: t("common:price_model.volume.description"),
            },
            {
              key: IPriceModel.WEIGHT,
              label: t("common:price_model.weight.title"),
              value: IPriceModel.WEIGHT,
              description: t("common:price_model.weight.description"),
            },
            {
              key: IPriceModel.DISTANCE,
              label: t("common:price_model.distance.title"),
              value: IPriceModel.DISTANCE,
              description: t("common:price_model.distance.description"),
            },
          ]}
          validation={validations.find(
            (validation) => validation.id === "pricing.priceModel",
          )}
        />
      ),
    },
    {
      id: "pricing.pricePerCargoUnit",
      label: t("common:price_model.fixed.title"),
      hidden: !pricingModels?.includes(IPriceModel.FIXED),
      input: (
        <StyledTextInput
          id="pricing.pricePerCargoUnit"
          placeholder={t("common:price_model.fixed.placeholder")}
          defaultValue={props.data.pricing?.pricePerCargoUnit && props.data.pricing.pricePerCargoUnit > 0 ? props.data.pricing.pricePerCargoUnit : ""}
          type="number"
          validation={validations.find(
            (validation) => validation.id === "pricing.pricePerCargoUnit",
          )}
          onInputChanged={(value) => {
            // Oppdater formData direkte når brukeren skriver
            setFormData((prevData: Record<string, any>) => {
              const numValue = value === "" ? 0 : parseFloat(value);
              return {
                ...prevData,
                "pricing.pricePerCargoUnit": numValue
              };
            });
          }}
        />
      ),
    },
    {
      id: "pricing.pricePerDistanceUnit",
      label: t("common:price_model.distance.title"),
      hidden: !pricingModels?.includes(IPriceModel.DISTANCE),
      input: (
        <StyledTextInput
          id="pricing.pricePerDistanceUnit"
          placeholder={t("common:price_model.distance.placeholder")}
          defaultValue={props.data.pricing?.pricePerDistanceUnit && props.data.pricing.pricePerDistanceUnit > 0 ? props.data.pricing.pricePerDistanceUnit : ""}
          type="number"
          validation={validations.find(
            (validation) =>
              validation.id === "pricing.pricePerDistanceUnit",
          )}
          onInputChanged={(value) => {
            // Oppdater formData direkte når brukeren skriver
            setFormData((prevData: Record<string, any>) => {
              const numValue = value === "" ? 0 : parseFloat(value);
              return {
                ...prevData,
                "pricing.pricePerDistanceUnit": numValue
              };
            });
          }}
        />
      ),
    },
    {
      id: "pricing.pricePerVolumeUnit",
      label: t("common:price_model.volume.title"),
      hidden: !pricingModels?.includes(IPriceModel.VOLUME),
      input: (
        <StyledTextInput
          id="pricing.pricePerVolumeUnit"
          placeholder={t("common:price_model.volume.placeholder")}
          defaultValue={props.data.pricing?.pricePerVolumeUnit && props.data.pricing.pricePerVolumeUnit > 0 ? props.data.pricing.pricePerVolumeUnit : ""}
          type="number"
          validation={validations.find(
            (validation) => validation.id === "pricing.pricePerVolumeUnit",
          )}
          onInputChanged={(value) => {
            // Oppdater formData direkte når brukeren skriver
            setFormData((prevData: Record<string, any>) => {
              const numValue = value === "" ? 0 : parseFloat(value);
              return {
                ...prevData,
                "pricing.pricePerVolumeUnit": numValue
              };
            });
          }}
        />
      ),
    },
    {
      id: "pricing.pricePerWeightUnit",
      label: t("common:price_model.weight.title"),
      hidden: !pricingModels?.includes(IPriceModel.WEIGHT),
      input: (
        <StyledTextInput
          id="pricing.pricePerWeightUnit"
          placeholder={t("common:price_model.weight.placeholder")}
          defaultValue={props.data.pricing?.pricePerWeightUnit && props.data.pricing.pricePerWeightUnit > 0 ? props.data.pricing.pricePerWeightUnit : ""}
          type="number"
          validation={validations.find(
            (validation) => validation.id === "pricing.pricePerWeightUnit",
          )}
          onInputChanged={(value) => {
            // Oppdater formData direkte når brukeren skriver
            setFormData((prevData: Record<string, any>) => {
              const numValue = value === "" ? 0 : parseFloat(value);
              return {
                ...prevData,
                "pricing.pricePerWeightUnit": numValue
              };
            });
          }}
        />
      ),
    },
  ];

  return (
    <div className="overflow-auto max-h-[70vh] p-1">
      <form 
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          const formData = new FormData(e.currentTarget);
          const data: any = {};
          
          // Logg alle form-verdier for debugging
          const formValues: Record<string, any> = {};
          fields.forEach(field => {
            if (!field.hidden) {
              const value = formData.get(field.id);
              formValues[field.id] = value;
            }
          });
          
          fields.forEach((field) => {
            // Bypass hidden fields
            if (field.hidden) return;
            
            const fieldId = field.id;
            let fieldData: any = formData.getAll(field.id);
            if (fieldData.length === 1) {
              fieldData = fieldData[0];
            }
            
            // Trim string values to remove whitespace
            if (typeof fieldData === 'string') {
              fieldData = fieldData.trim();
            }
            
            // Konverter numeriske verdier til tall
            if (field.id === 'weight' || field.id === 'height' || field.id === 'width' || field.id === 'length') {
              if (fieldData === '') {
                fieldData = 0;
              } else if (typeof fieldData === 'string') {
                const parsed = parseFloat(fieldData);
                fieldData = isNaN(parsed) ? 0 : parsed;
              }
            }
            
            data[fieldId] = fieldData;
          });
                    
          setFormData(data);
          setTimeout(() => {
            handleFormSubmit();
          }, 0);
        }}
      >
        <StandardForm
          showHeader={true}
          description={t("modals:descriptions.new_product")}
          fields={fields}
          hideButtons={true}
        />
        
        {/* Legg til toggle-knappen utenfor StandardForm */}
        {props.showSaveToggle && (
          <div className="border-t border-gray-200 dark:border-gray-700">
            <div className="flex items-center py-3">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  ref={saveProductRef}
                  type="checkbox"
                  defaultChecked={props.shouldSaveProduct ?? true}
                  className="sr-only peer"
                  onChange={(e) => {
                    // Oppdater toggle-tilstanden og informer parent-komponenten
                    const isChecked = e.target.checked;
                    
                    // Oppdater toggle-label
                    const toggleLabel = document.getElementById('toggle-label-text');
                    if (toggleLabel) {
                      toggleLabel.textContent = isChecked 
                        ? t("inventory:save_for_reuse") 
                        : t("inventory:one_time_use");
                    }
                    
                    // Informer parent-komponenten om endringen
                    if (props.onSaveToggleChange) {
                      props.onSaveToggleChange(isChecked);
                    }
                  }}
                />
                <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-gradient-to-r peer-checked:from-blue-400 peer-checked:to-lgb-blue-300"></div>
                <span id="toggle-label-text" className="ml-4 text-sm font-medium text-gray-900 dark:text-gray-300">
                  {(props.shouldSaveProduct ?? true) ? t("inventory:save_for_reuse") : t("inventory:one_time_use")}
                </span>
              </label>
            </div>
          </div>
        )}
        
        {/* Legg til knappene nederst */}
        <div className="pt-6 flex gap-4">
          <Button
            color="primary"
            type="submit"
          >
            {isNew ? <HiOutlinePlus className="mr-2 h-4 w-4" /> : null}
            {t("modals:button_texts.product")}
          </Button>
          <Button
            color="gray"
            onClick={props.onCancel}
          >
            {t("common:close")}
          </Button>
        </div>
      </form>
    </div>
  );
};

export const ProductModal: FC<ProductModalProp> = function (
  props: ProductModalProp,
) {
  const { t } = useTranslation(["common", "inventory", "modals"]);

  return (
    <CustomModal
      isOpen={props.isShowing}
      onClose={props.onCancel}
      title={
        Product.isNew(props.data)
          ? t("inventory:new_product")
          : t("inventory:update_product")
      }
    >
      <ProductForm {...props} />
    </CustomModal>
  );
};
