/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useRef, useEffect } from "react";
import { Textarea, TextInput, Button } from "flowbite-react";
import { HiOutlinePlus } from "react-icons/hi";
import { StandardForm, Dialog } from "../..";
import { useTranslation } from "react-i18next";
import { Location } from "../../../models";

export interface LocationModalProp {
  data: Location;
  isShowing: boolean;
  onConfirm: (data: Location) => void;
  onCancel: () => void;
  showSaveToggle?: boolean;
  shouldSaveLocation?: boolean;
  onSaveToggleChange?: (shouldSave: boolean) => void;
}

export const LocationForm: FC<LocationModalProp> = function (
  props: LocationModalProp,
) {
  const { t } = useTranslation(["common", "location", "modals"]);

  const [isNew] = useState(Location.isNew(props.data));
  
  // Bruk en ref for å holde toggle-tilstanden
  const saveLocationRef = useRef<HTMLInputElement>(null);
  
  // Initialiser toggle-tilstanden
  useEffect(() => {
    if (saveLocationRef.current) {
      saveLocationRef.current.checked = props.shouldSaveLocation ?? true;
    }
  }, [props.shouldSaveLocation]);

  return (
    <>
      <StandardForm
        showHeader={false}
        description={t("modals:descriptions.new_location")}
        fields={[
          {
            id: "displayName",
            label: t("location:modal.display_name"),
            input: (
              <TextInput
                id="displayName"
                name="displayName"
                placeholder={t("location:modal.display_name")}
                defaultValue={props.data.displayName ?? ""}
                type="text"
                required={true}
              />
            ),
          },
          {
            id: "addressLine",
            label: t("common:address_line"),
            input: (
              <TextInput
                id="addressLine"
                name="addressLine"
                placeholder={t("common:placeholder.address_line")}
                defaultValue={props.data.addressLine ?? ""}
              />
            ),
          },
          {
            id: "postCode",
            label: t("common:postcode"),
            input: (
              <TextInput
                id="postCode"
                name="postCode"
                placeholder={t("common:placeholder.postcode")}
                defaultValue={props.data.postCode ?? ""}
              />
            ),
          },
          {
            id: "city",
            label: t("common:city"),
            input: (
              <TextInput
                id="city"
                name="city"
                placeholder={t("common:placeholder.city")}
                defaultValue={props.data.city ?? ""}
              />
            ),
          },
          {
            id: "description",
            label: t("common:placeholder.description"),
            grid_style: "col-span-2",
            input: (
              <Textarea
                id="description"
                key="description"
                name="description"
                className="text-sm"
                placeholder={t("common:placeholder.description")}
                defaultValue={props.data.description ?? ""}
              />
            ),
          },
        ]}
        onConfirm={props.onConfirm}
        onCancel={props.onCancel}
        buttonConfirmText={t("modals:button_texts.location")}
        buttonConfirmPrependIcon={
          isNew ? <HiOutlinePlus className="mr-2 h-4 w-4" /> : null
        }
        buttonCloseText={t("common:close")}
      />
      
      {/* Legg til toggle-knappen hvis showSaveToggle er true */}
      {props.showSaveToggle && (
        <div className="border-t border-gray-200 dark:border-gray-700 pt-4 mt-4">
          <div className="flex items-center py-3">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                ref={saveLocationRef}
                type="checkbox"
                defaultChecked={props.shouldSaveLocation ?? true}
                className="sr-only peer"
                onChange={(e) => {
                  // Oppdater toggle-tilstanden og informer parent-komponenten
                  const isChecked = e.target.checked;
                  
                  // Oppdater toggle-label
                  const toggleLabel = document.getElementById('location-toggle-label-text');
                  if (toggleLabel) {
                    toggleLabel.textContent = isChecked 
                      ? t("location:save_for_reuse") 
                      : t("location:one_time_use");
                  }
                  
                  // Informer parent-komponenten om endringen
                  if (props.onSaveToggleChange) {
                    props.onSaveToggleChange(isChecked);
                  }
                }}
              />
              <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-gradient-to-r peer-checked:from-blue-400 peer-checked:to-lgb-blue-300"></div>
              <span id="location-toggle-label-text" className="ml-4 text-sm font-medium text-gray-900 dark:text-gray-300">
                {(props.shouldSaveLocation ?? true) ? t("location:save_for_reuse") : t("location:one_time_use")}
              </span>
            </label>
          </div>
        </div>
      )}
    </>
  );
};

export const LocationModal: FC<LocationModalProp> = function (
  props: LocationModalProp,
) {
  const { t } = useTranslation(["location"]);

  return (
    <Dialog
      title={
        Location.isNew(props.data)
          ? t("location:modal.create_location")
          : t("location:modal.update_location")
      }
      content={<LocationForm {...props} />}
      show={props.isShowing}
      toggleModal={props.onCancel}
      hideButtons={true}
    />
  );
};
