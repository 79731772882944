import type { FC } from "react";
import { HiInformationCircle, HiLocationMarker, HiKey, HiTag, HiClock, HiPhone } from "react-icons/hi";
import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { IMapRoute } from "../types";
import { OrderStatus } from "../../../../types/order/IOrder";
import moment from "moment";

interface OrderCardProps {
  order: IMapRoute;
  onClick?: () => void;
  isSelected?: boolean;
}

export const OrderCard: FC<OrderCardProps> = ({ order, onClick, isSelected }) => {
  const { t } = useTranslation(["planner", "stats"]);

  // Format the first pickup and last delivery times/locations for the tooltip
  const firstStop = order.route?.[0];
  const lastStop = order.route?.[order.route.length - 1];
  
  // Format time for display
  const formatTime = (timestamp: any) => {
    if (!timestamp) return "";
    
    try {
      let date;
      if (timestamp.toDate) {
        date = timestamp.toDate();
      } else if (timestamp.seconds) {
        date = new Date(timestamp.seconds * 1000);
      } else {
        return "";
      }
      
      return moment(date).format('HH:mm');
    } catch (error) {
      return "";
    }
  };

  // Get status color
  const getStatusColor = () => {
    switch (order.status) {
      case OrderStatus.New:
        return "bg-lgb-blue-100 text-lgb-blue-300";
      case OrderStatus.InProgress:
        return "bg-lgb-yellow-100 text-lgb-yellow-300";
      case OrderStatus.Completed:
        return "bg-lgb-green-100 text-lgb-green-300";
      case OrderStatus.Cancelled:
        return "bg-lgb-red-100 text-lgb-red-300";
      default:
        return "bg-gray-100 text-gray-500";
    }
  };

  const tooltipContent = (
    <div className="space-y-3 p-1 max-w-xs">
      <div className="flex items-center gap-2">
        <HiKey className="h-4 w-4 text-gray-400 flex-shrink-0" />
        <div className="truncate">
          <div className="text-xs opacity-75">{t("planner:order_details.order_id")}</div>
          <div className="truncate font-medium">{order.id || ''}</div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <HiLocationMarker className="h-4 w-4 text-gray-400 flex-shrink-0" />
        <div className="truncate">
          <div className="text-xs opacity-75">{t("planner:order_details.first_stop")}</div>
          <div className="truncate font-medium">{firstStop?.location?.addressLine || ''}</div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <HiLocationMarker className="h-4 w-4 text-gray-400 flex-shrink-0" />
        <div className="truncate">
          <div className="text-xs opacity-75">{t("planner:order_details.last_stop")}</div>
          <div className="truncate font-medium">{lastStop?.location?.addressLine || ''}</div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <HiPhone className="h-4 w-4 text-gray-400 flex-shrink-0" />
        <div className="truncate">
          <div className="text-xs opacity-75">{t("planner:order_details.contact")}</div>
          <div className="truncate font-medium">{order.customer.phoneNumber || ''}</div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <HiTag className="h-4 w-4 text-gray-400 flex-shrink-0" />
        <div className="truncate">
          <div className="text-xs opacity-75">{t("planner:order_details.status")}</div>
          <div className="truncate font-medium">{t(`stats:orderStatus.${order.status === 'InProgress' ? 'in_progress' : order.status.toLowerCase()}`)}</div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      onClick={onClick}
      className={`py-2 px-3 transition-colors duration-200 flex items-center gap-2 cursor-pointer border-l-2 ${
        isSelected 
          ? 'bg-lgb-blue-100 dark:bg-lgb-blue-400/10 border-lgb-blue-300' 
          : 'hover:bg-gray-50 dark:hover:bg-gray-800 border-transparent'
      }`}
    >
      <div className="bg-lgb-blue-300 text-white rounded-full w-7 h-7 flex items-center justify-center text-xs">
        {order.customerName?.[0] || "O"}
      </div>
      <div className="flex-1 min-w-0">
        <div className="flex items-center justify-between">
          <h3 className="font-medium text-gray-900 dark:text-white text-sm truncate">
            {order.customerName}
          </h3>
          <Tooltip content={tooltipContent} trigger="hover">
            <HiInformationCircle 
              className="h-4 w-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 cursor-pointer ml-1"
            />
          </Tooltip>
        </div>
        <div className="flex items-center text-xs text-gray-500 dark:text-gray-400 mt-0.5">
          <HiClock className="h-3 w-3 mr-1" />
          <span className="mr-2">{formatTime(firstStop?.stopDate)}</span>
          <HiLocationMarker className="h-3 w-3 mr-1" />
          <span className="truncate">{firstStop?.location?.city || ''}</span>
        </div>
      </div>
    </div>
  );
}; 