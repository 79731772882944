/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from "flowbite-react";
import { StyledButton } from "../../../../components/atoms/Button";
import {
  useState,
  useMemo,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineShoppingCart, HiOutlineClipboardList, HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import {
  Dialog,
  DialogNotify,
  DropdownSearch,
  DropdownSearchListItem,
  SavedProducts,
  ValidationToast,
} from "../../../../components";
import { ProductModal } from "../../../../components";
import { Product, OrderProduct, Parameter } from "../../../../models";
import {
  ParameterType,
  IProduct,
  IRouteItem,
  IOrderProduct,
} from "../../../../types";
import { IPriceModel } from "../../../../types/Product";
import { useWorkspace } from "../../../../context/WorkspaceContext";
import { useCache } from "../../../../context/CacheContext";
import { useProducts, useParameters } from "../../../../hooks";
import { TableSkeletonsByAmount } from "../../../../components/atoms/Skeleton/TableSkeleton";
import { Badge } from "flowbite-react";

export interface ProductSelectionProps {
  orderItems: IOrderProduct[];
  route: IRouteItem[];
  hideSearch?: boolean;
  validationFailed: boolean;
  update: (products: IOrderProduct[]) => void;
}
export interface ProductSelectionRef {
  updateOrderProducts: () => boolean;
}
const ProductManagement = forwardRef<ProductSelectionRef, ProductSelectionProps>(
  (props, ref) => {
    const { t } = useTranslation(["orders", "validation"]);
    const [currentOrderItems, setCurrentOrderItems] = useState<IOrderProduct[]>(
      props.orderItems.length > 0 && !productIsEmpty(props.orderItems[0]) 
        ? props.orderItems 
        : []
    );
    const [showNewProductDialog, setShowNewProductDialog] = useState(false);
    const [showEditProductDialog, setShowEditProductDialog] = useState(false);
    const [editingProductIndex, setEditingProductIndex] = useState<number>(-1);
    const [shouldSaveProduct, setShouldSaveProduct] = useState(true);
    const { activeWorkspace } = useWorkspace();
    const { updateCacheKey } = useCache();
    const [productCategories, setProductCategories] = useState(
      [] as Parameter[],
    );
    const [showRemoveDialog, setShowRemoveDialog] = useState(false);
    const [toBeRemoved, setToBeRemoved] = useState(-1);
    const [showValidationToast, setShowValidationToast] = useState(false);
    const [validationMessage, setValidationMessage] = useState("");

    const [searchText, setSearchText] = useState<string>("");
    const [searchResults, setSearchResults] = useState([] as IOrderProduct[]);
    const [showAllProducts, setShowAllProducts] = useState(false);
    const [inputFocused, setInputFocused] = useState(false);

    const query = useProducts(activeWorkspace?.workspaceId ?? "");
    const categoriesQuery = useParameters(
      activeWorkspace?.workspaceId ?? "",
      ParameterType.ProductType,
    );

    const products = useMemo(() => query.data ?? [], [query]) as OrderProduct[];

    const isQueryLoading = useMemo(
      () =>
        query.isLoading ||
        query.isRefetching ||
        categoriesQuery.isLoading ||
        categoriesQuery.isRefetching,
      [query, categoriesQuery],
    );

    const categories = useMemo(
      () => categoriesQuery.data ?? [],
      [categoriesQuery],
    );

    useEffect(() => {
      setCurrentOrderItems(
        props.orderItems.length > 0 && !productIsEmpty(props.orderItems[0]) 
          ? props.orderItems 
          : []
      );
    }, [props.orderItems]);

    // Lukk validerings-toast når produktlisten endres
    useEffect(() => {
      if (showValidationToast) {
        setShowValidationToast(false);
      }
    }, [currentOrderItems]);

    useEffect(() => {
      if (!isQueryLoading) {
        setProductCategories(categories);
      }
    }, [isQueryLoading, categories]);

    useEffect(() => {
      if (searchText.length) {
        let res =
          products?.filter(
            (x) =>
              !currentOrderItems.map((x) => x.id).includes(x.id) &&
              (x.description + " " + x.descriptionShort)
                ?.toLowerCase()
                .includes(searchText.toLowerCase()),
          ) || [];
        setSearchResults(res.slice(0, 3));
      } else if (inputFocused) {
        // When input is focused and empty, show all available products
        const availableProducts =
          products?.filter(
            (x) => !currentOrderItems.map((x) => x.id).includes(x.id),
          ) || [];
        setSearchResults(availableProducts);
      } else {
        setSearchResults([]);
      }
    }, [searchText, products, currentOrderItems, inputFocused]);

    // Sikre at bare én modal er åpen om gangen
    useEffect(() => {
      if (showNewProductDialog) {
        setShowEditProductDialog(false);
        setShowAllProducts(false);
        setShowRemoveDialog(false);
      }
    }, [showNewProductDialog]);
    
    useEffect(() => {
      if (showEditProductDialog) {
        setShowNewProductDialog(false);
        setShowAllProducts(false);
        setShowRemoveDialog(false);
      }
    }, [showEditProductDialog]);

    // Lukk alle dialoger
    const closeAllDialogs = () => {
      setShowNewProductDialog(false);
      setShowEditProductDialog(false);
      setShowAllProducts(false);
      setShowRemoveDialog(false);
    };

    const NewProductDialog = (
      <ProductModal
        data={Product.default()}
        productTypes={productCategories}
        isShowing={showNewProductDialog}
        showSaveToggle={true}
        shouldSaveProduct={shouldSaveProduct}
        onSaveToggleChange={(shouldSave) => {
          setShouldSaveProduct(shouldSave);
        }}
        onConfirm={(formData: IProduct) => {
          // Opprett et nytt produkt for ordren uansett om det lagres eller ikke
          const orderProduct = getEmptyProduct();
          
          // Hjelpefunksjon for å konvertere verdier til tall på en sikker måte
          const safeParseFloat = (value: any): number => {
            if (value === undefined || value === null || value === '') return 0;
            const parsed = parseFloat(value.toString());
            return isNaN(parsed) ? 0 : parsed;
          };
                    
          // Kopier alle egenskaper fra formData til orderProduct
          orderProduct.descriptionShort = formData.descriptionShort || "";
          orderProduct.description = formData.description || "";
          
          // Sikre at numeriske verdier er tall
          orderProduct.weight = safeParseFloat(formData.weight);
          orderProduct.length = safeParseFloat(formData.length);
          orderProduct.height = safeParseFloat(formData.height);
          orderProduct.width = safeParseFloat(formData.width);
          
          // Sikre at productType alltid er en array
          orderProduct.productType = Array.isArray(formData.productType) 
            ? formData.productType 
            : (formData.productType ? [formData.productType] : []);
          
          // Sikre at pricing-objektet er korrekt kopiert
          if (formData.pricing) {
            orderProduct.pricing = {
              priceModel: formData.pricing.priceModel || [IPriceModel.FIXED],
              pricePerCargoUnit: safeParseFloat(formData.pricing.pricePerCargoUnit),
              pricePerDistanceUnit: safeParseFloat(formData.pricing.pricePerDistanceUnit),
              pricePerVolumeUnit: safeParseFloat(formData.pricing.pricePerVolumeUnit),
              pricePerWeightUnit: safeParseFloat(formData.pricing.pricePerWeightUnit)
            };
          }
          
          // Sett pris basert på pricing-modellen
          if (orderProduct.pricing?.pricePerCargoUnit) {
            orderProduct.price = orderProduct.pricing.pricePerCargoUnit;
          }
                    
          // Hvis produktet skal lagres (toggle er PÅ), gjør det i bakgrunnen
          let updatedItems = [...currentOrderItems];

          if (shouldSaveProduct) {
            // Lagre produktet i databasen
            Product.create(activeWorkspace?.workspaceId ?? "", formData).then((newProduct: Product | null) => {
              if (newProduct) {
                // Oppdater produktet i listen med den lagrede versjonen
                orderProduct.id = newProduct.id;
                // Sett productId også for å markere at dette er et lagret produkt
                orderProduct.productId = newProduct.id;
                updateCacheKey();
              }
            }).catch(error => {
              console.error("Feil ved lagring av produkt:", error);
            });
          }

          updatedItems = [...updatedItems, orderProduct];
          setCurrentOrderItems(updatedItems);
          props.update(updatedItems);

          // Lukk dialogen
          closeAllDialogs();
        }}
        onCancel={() => closeAllDialogs()}
      />
    );

    const EditProductDialog = (
      <ProductModal
        data={editingProductIndex >= 0 ? currentOrderItems[editingProductIndex] : Product.default()}
        productTypes={productCategories}
        isShowing={showEditProductDialog}
        onConfirm={(formData: IProduct) => {
          if (editingProductIndex >= 0) {
            try {              
              // Hjelpefunksjon for å konvertere verdier til tall på en sikker måte
              const safeParseFloat = (value: any): number => {
                if (value === undefined || value === null || value === '') return 0;
                const parsed = parseFloat(value.toString());
                return isNaN(parsed) ? 0 : parsed;
              };
              
              // Behold OrderProduct-spesifikke egenskaper
              const currentProduct = currentOrderItems[editingProductIndex];
              
              // Sikre at alle egenskaper blir korrekt oppdatert
              const updatedProduct = {
                ...currentProduct,
                descriptionShort: formData.descriptionShort || "",
                description: formData.description || "",
                
                // Sikre at numeriske verdier er tall
                weight: safeParseFloat(formData.weight),
                length: safeParseFloat(formData.length),
                height: safeParseFloat(formData.height),
                width: safeParseFloat(formData.width),
                
                // Sikre at productType alltid er en array
                productType: Array.isArray(formData.productType) 
                  ? formData.productType 
                  : (formData.productType ? [formData.productType] : currentProduct.productType),
                
                // Behold disse egenskapene fra det opprinnelige produktet
                itinerary: currentProduct.itinerary,
                status: currentProduct.status,
                quantity: currentProduct.quantity,
                deliveredAt: currentProduct.deliveredAt,
                deliveredQuantity: currentProduct.deliveredQuantity,
                pickedUpAt: currentProduct.pickedUpAt,
                pickedUpQuantity: currentProduct.pickedUpQuantity,
                lastUpdated: currentProduct.lastUpdated,
                // Behold productId for å indikere om produktet er lagret eller ikke
                productId: currentProduct.productId
              };
              
              // Sikre at pricing-objektet er korrekt oppdatert
              if (formData.pricing) {
                updatedProduct.pricing = {
                  priceModel: formData.pricing.priceModel || [IPriceModel.FIXED],
                  pricePerCargoUnit: safeParseFloat(formData.pricing.pricePerCargoUnit),
                  pricePerDistanceUnit: safeParseFloat(formData.pricing.pricePerDistanceUnit),
                  pricePerVolumeUnit: safeParseFloat(formData.pricing.pricePerVolumeUnit),
                  pricePerWeightUnit: safeParseFloat(formData.pricing.pricePerWeightUnit)
                };
                
                // Oppdater pris basert på pricing-modellen
                if (updatedProduct.pricing.pricePerCargoUnit !== undefined && updatedProduct.pricing.pricePerCargoUnit > 0) {
                  updatedProduct.price = updatedProduct.pricing.pricePerCargoUnit;
                }
              }
                          
              const updatedItems = [...currentOrderItems];
              updatedItems[editingProductIndex] = updatedProduct;
              setCurrentOrderItems(updatedItems);
              props.update(updatedItems);
              
            } catch (error) {
              console.error("Feil ved redigering av produkt:", error);
            }
          }
          
          // Lukk dialogen
          closeAllDialogs();
        }}
        onCancel={() => closeAllDialogs()}
      />
    );

    /**
     * Exposed to the parent so it can be called through ref.
     */
    useImperativeHandle(ref, () => ({
      updateOrderProducts,
    }));

    const updateOrderProducts = (): boolean => {
      // Sjekk om vi har minst ett produkt
      if (currentOrderItems.length === 0) {
        // Vis feilmelding hvis det ikke er lagt til noen produkter
        setValidationMessage(t("validation:products_required"));
        setShowValidationToast(true);
        // Oppdater parent-komponenten med produktene
        props.update(currentOrderItems);
        // Returner false for å indikere at valideringen feilet
        return false;
      }
      
      // Sjekk om noen produkter har samme hente- og leveringsadresse
      // Vi sjekker bare dette hvis det faktisk finnes produkter
      const invalidProducts = currentOrderItems.filter(
        product => product.itinerary?.pickupStopNumber === product.itinerary?.deliveryStopNumber
      );
      
      if (invalidProducts.length > 0) {
        setValidationMessage(t("validation:same_pickup_delivery"));
        setShowValidationToast(true);
        // Oppdater parent-komponenten med produktene
        props.update(currentOrderItems);
        // Returner false for å indikere at valideringen feilet
        return false;
      }
      
      // Sjekk om alle lokasjoner i ruten blir brukt i produktene
      const usedStopNumbers = new Set<number>();
      
      currentOrderItems.forEach(product => {
        if (product.itinerary) {
          if (product.itinerary.pickupStopNumber !== undefined) {
            usedStopNumbers.add(product.itinerary.pickupStopNumber);
          }
          if (product.itinerary.deliveryStopNumber !== undefined) {
            usedStopNumbers.add(product.itinerary.deliveryStopNumber);
          }
        }
      });
      
      // Finn lokasjoner i ruten som ikke brukes i noen produkter
      const unusedLocations = props.route.filter(location => 
        !usedStopNumbers.has(location.stopNumber)
      );
      
      if (unusedLocations.length > 0) {
        setValidationMessage(t("validation:unused_locations"));
        setShowValidationToast(true);
        // Oppdater parent-komponenten med produktene
        props.update(currentOrderItems);
        // Returner false for å indikere at valideringen feilet
        return false;
      }
      
      // Hvis vi kommer hit, har valideringen lyktes
      setShowValidationToast(false);
      // Oppdater parent-komponenten med produktene
      props.update(currentOrderItems);
      // Returner true for å indikere at valideringen var vellykket
      return true;
    };

    // When the dropdown input is focused, set inputFocused to true.
    function handleFocus() {
      setInputFocused(true);
    }

    // Delay hiding the dropdown on blur to allow click events to process.
    function handleBlur() {
      setTimeout(() => {
        setInputFocused(false);
      }, 150); // Adjust the delay as needed.
    }

    function handleSearch(val: string) {
      setSearchText(val);
    }

    function productIsEmpty(product: IOrderProduct) {
      return (
        !product.description &&
        !product.descriptionShort &&
        product.weight === 0 &&
        product.length === 0 &&
        product.height === 0 &&
        product.width === 0 &&
        (!product.productType || product.productType.length === 0)
      );
    }

    function addSavedProduct(id: string) {
      let product = products.find((x) => x.id === id);
      if (product) {
        // Hjelpefunksjon for å konvertere verdier til tall på en sikker måte
        const safeParseFloat = (value: any): number => {
          if (value === undefined || value === null || value === '') return 0;
          const parsed = parseFloat(value.toString());
          return isNaN(parsed) ? 0 : parsed;
        };
                
        // Sikre at produktet har riktige standardverdier
        const orderProduct = {
          ...product,
          // Sikre at numeriske verdier er tall, ikke strenger
          weight: safeParseFloat(product.weight),
          length: safeParseFloat(product.length),
          height: safeParseFloat(product.height),
          width: safeParseFloat(product.width),
          // Sikre at productType er en array
          productType: Array.isArray(product.productType) ? product.productType : (product.productType ? [product.productType] : []),
          // Sett opp itinerary hvis det ikke finnes
          itinerary: product.itinerary || {
            pickupStopNumber: props.route.at(0)?.stopNumber ?? 0,
            deliveryStopNumber: props.route.at(-1)?.stopNumber ?? 0
          },
          // Sett standard antall
          quantity: product.quantity || 1,
          // Sett produktID for å markere at dette er et lagret produkt
          productId: product.id
        };
        
        const updatedItems = [...currentOrderItems, orderProduct];
        setCurrentOrderItems(updatedItems);
        props.update(updatedItems);
      }
    }

    function toggleShowAllDialog() {
      setSearchText("");
      setShowAllProducts(!showAllProducts);
    }

    function getEmptyProduct() {
      let emptyProduct = OrderProduct.defaultProduct();
      
      // Sett opp itinerary
      emptyProduct.itinerary = OrderProduct.defaultItinerary();
      emptyProduct.itinerary.pickupStopNumber =
        props.route.at(0)?.stopNumber ?? 0;
      emptyProduct.itinerary.deliveryStopNumber =
        props.route.at(-1)?.stopNumber ?? 0;
        
      // Sett opp standard pricing-objekt med tomme verdier
      if (!emptyProduct.pricing) {
        emptyProduct.pricing = {
          priceModel: [IPriceModel.FIXED],
          pricePerCargoUnit: 0,
          pricePerDistanceUnit: 0,
          pricePerVolumeUnit: 0,
          pricePerWeightUnit: 0
        };
      }
      
      // Sett standardverdier for numeriske felt
      emptyProduct.weight = 0;
      emptyProduct.length = 0;
      emptyProduct.height = 0;
      emptyProduct.width = 0;
      emptyProduct.price = 0;
      emptyProduct.quantity = 1; // Standard antall er 1
      
      // Sikre at produktet har tomme strenger for tekstfelt i stedet for undefined
      emptyProduct.descriptionShort = "";
      emptyProduct.description = "";
      emptyProduct.productType = [];
        
      return emptyProduct as OrderProduct;
    }

    const removeProduct = (productIdx: number) => {
      setToBeRemoved(productIdx);
      setShowRemoveDialog(true);
    };

    const confirmRemoveProduct = () => {
      if (toBeRemoved >= 0) {
        const updatedItems = [...currentOrderItems];
        updatedItems.splice(toBeRemoved, 1);
        setCurrentOrderItems(updatedItems);
        props.update(updatedItems);
        setShowRemoveDialog(false);
        setToBeRemoved(-1);
      }
    };

    const editProduct = (productIdx: number) => {
      setEditingProductIndex(productIdx);
      setShowEditProductDialog(true);
    };

    function addSavedProductFromDialog(id: string) {
      addSavedProduct(id);
      toggleShowAllDialog();
    }

    const showAllProductsDialog = (
      <Dialog
        title={t("create.product_management.add_saved_product_title")}
        show={showAllProducts}
        toggleModal={() => toggleShowAllDialog()}
        dismissible
        hideButtons
        content={
          <SavedProducts
            products={
              products?.filter(
                (x) => !currentOrderItems.map((x) => x.id).includes(x.id),
              ) || ([] as IOrderProduct[])
            }
            currentSelected={currentOrderItems}
            addProduct={addSavedProductFromDialog}
            close={toggleShowAllDialog}
          />
        }
      />
    );

    const removeProductDialog = (
      <DialogNotify
        title={t("create.product_management.remove")}
        show={showRemoveDialog}
        isLoading={false}
        toggleModal={() => setShowRemoveDialog(false)}
        content={
          <div>
            <p>{t("create.product_management.remove_text")}</p>
          </div>
        }
        buttonConfirmText={t("create.product_management.remove_confirm")}
        buttonCloseText={t("create.product_management.remove_cancel")}
        confirmButton={() => confirmRemoveProduct()}
      />
    );

    // Render produktkort for visning
    const renderProductCard = (product: IOrderProduct, index: number) => {      
      // Hjelpefunksjon for å konvertere verdier til tall på en sikker måte
      const safeParseFloat = (value: any): number => {
        if (value === undefined || value === null || value === '') return 0;
        const parsed = parseFloat(value.toString());
        return isNaN(parsed) ? 0 : parsed;
      };
      
      // Sikre at numeriske verdier er tall
      const weight = safeParseFloat(product.weight);
      const length = safeParseFloat(product.length);
      const width = safeParseFloat(product.width);
      const height = safeParseFloat(product.height);
            
      return (
        <div 
          key={`product-view-${index}`}
          className="mb-4 p-4 bg-white dark:bg-lgb-on-dark-background rounded-lg border border-lgb-grey-200 dark:border-[#2c3353]"
        >
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center">
              <Badge color="success" className="mr-2">
                {t("create.product_management.product")} {index + 1}
              </Badge>
              {!product.productId && (
                <Badge color="warning" className="mr-2">
                  {t("inventory:one_time_use")}
                </Badge>
              )}
            </div>
            <div className="flex items-center space-x-2">
              <Button 
                size="xs" 
                color="light"
                onClick={() => editProduct(index)}
              >
                <HiOutlinePencil className="mr-1 h-4 w-4" />
                {t("common:edit")}
              </Button>
              <Button 
                size="xs" 
                color="light"
                onClick={() => removeProduct(index)}
              >
                <HiOutlineTrash className="mr-1 h-4 w-4" />
                {t("common:remove")}
              </Button>
            </div>
          </div>
          
          <div className="mt-2">
            <h3 className="text-lg font-medium dark:text-white">
              {product.descriptionShort || t("common:unnamed")}
            </h3>
            <p className="text-sm text-lgb-grey-600 dark:text-lgb-grey-400">
              {product.description || t("products:no_description_available")}
            </p>
            
            <div className="mt-3 grid grid-cols-2 gap-2">
              <div>
                <p className="text-xs text-lgb-grey-500 dark:text-lgb-grey-400">
                  {t("common:weight")}
                </p>
                <p className="text-sm font-medium dark:text-white">
                  {weight > 0 ? `${weight} kg` : "-"}
                </p>
              </div>
              <div>
                <p className="text-xs text-lgb-grey-500 dark:text-lgb-grey-400">
                  {t("common:dimensions")}
                </p>
                <p className="text-sm font-medium dark:text-white">
                  {(length > 0 && width > 0 && height > 0) 
                    ? `${length}x${width}x${height} cm` 
                    : "-"}
                </p>
              </div>
              <div>
                <p className="text-xs text-lgb-grey-500 dark:text-lgb-grey-400">
                  {t("common:price")}
                </p>
                <p className="text-sm font-medium dark:text-white">
                  {product.pricing?.pricePerCargoUnit !== undefined && product.pricing.pricePerCargoUnit > 0 
                    ? `${product.pricing.pricePerCargoUnit} kr` 
                    : (product.price !== undefined && product.price !== null && product.price > 0) 
                      ? `${product.price} kr` 
                      : "-"}
                </p>
              </div>
              {product.productType && product.productType.length > 0 && (
                <div>
                  <p className="text-xs text-lgb-grey-500 dark:text-lgb-grey-400">
                    {t("common:productType_label")}
                  </p>
                  <p className="text-sm font-medium dark:text-white">
                    {product.productType.map(typeId => {
                      // Finn produkttypen i productCategories
                      const productType = productCategories.find(cat => cat.id === typeId);
                      // Returner koden (navnet) hvis funnet, ellers ID-en
                      return productType ? productType.code : typeId;
                    }).join(", ")}
                  </p>
                </div>
              )}
            </div>
            
            {/* Ny seksjon for antall og lokasjon */}
            <div className="mt-4 pt-4 border-t border-lgb-grey-200 dark:border-[#2c3353]">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {/* Antall */}
                <div>
                  <label htmlFor={`quantity-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    {t("common:quantity")}
                  </label>
                  <input
                    id={`quantity-${index}`}
                    type="number"
                    min="1"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-[#2c3353] dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={product.quantity || 1}
                    onChange={(e) => {
                      const newQuantity = parseInt(e.target.value) || 1;
                      const updatedProduct = { ...product, quantity: newQuantity };
                      const updatedItems = [...currentOrderItems];
                      updatedItems[index] = updatedProduct;
                      setCurrentOrderItems(updatedItems);
                      props.update(updatedItems);
                    }}
                  />
                </div>
                
                {/* Henteadresse */}
                <div>
                  <label htmlFor={`pickup-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    {t("common:pickupLocation")}
                  </label>
                  <select
                    id={`pickup-${index}`}
                    className={`bg-gray-50 border ${product.itinerary?.pickupStopNumber === product.itinerary?.deliveryStopNumber ? 'border-lgb-red-400 dark:border-lgb-red-400' : 'border-gray-300 dark:border-[#2c3353]'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    value={product.itinerary?.pickupStopNumber || 0}
                    onChange={(e) => {
                      const pickupStopNumber = parseInt(e.target.value);
                      
                      // Fjerner validering her, lar brukeren velge samme adresse i UI
                      const updatedItinerary = { 
                        ...(product.itinerary || OrderProduct.defaultItinerary()),
                        pickupStopNumber
                      };
                      const updatedProduct = { ...product, itinerary: updatedItinerary };
                      const updatedItems = [...currentOrderItems];
                      updatedItems[index] = updatedProduct;
                      setCurrentOrderItems(updatedItems);
                      props.update(updatedItems);
                    }}
                  >
                    {props.route.map((item, idx) => (
                      <option key={`pickup-${index}-${idx}`} value={item.stopNumber}>
                        {item.location?.displayName || item.location?.addressLine || `Stopp ${item.stopNumber + 1}`}
                      </option>
                    ))}
                  </select>
                </div>
                
                {/* Leveringsadresse */}
                <div>
                  <label htmlFor={`delivery-${index}`} className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    {t("common:deliveryLocation")}
                  </label>
                  <select
                    id={`delivery-${index}`}
                    className={`bg-gray-50 border ${product.itinerary?.pickupStopNumber === product.itinerary?.deliveryStopNumber ? 'border-lgb-red-400 dark:border-lgb-red-400' : 'border-gray-300 dark:border-[#2c3353]'} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                    value={product.itinerary?.deliveryStopNumber || 0}
                    onChange={(e) => {
                      const deliveryStopNumber = parseInt(e.target.value);
                      
                      // Fjerner validering her, lar brukeren velge samme adresse i UI
                      const updatedItinerary = { 
                        ...(product.itinerary || OrderProduct.defaultItinerary()),
                        deliveryStopNumber
                      };
                      const updatedProduct = { ...product, itinerary: updatedItinerary };
                      const updatedItems = [...currentOrderItems];
                      updatedItems[index] = updatedProduct;
                      setCurrentOrderItems(updatedItems);
                      props.update(updatedItems);
                    }}
                  >
                    {props.route.map((item, idx) => (
                      <option key={`delivery-${index}-${idx}`} value={item.stopNumber}>
                        {item.location?.displayName || item.location?.addressLine || `Stopp ${item.stopNumber + 1}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            
            {/* Advarsel om samme hente- og leveringsadresse */}
            {product.itinerary?.pickupStopNumber === product.itinerary?.deliveryStopNumber && (
              <div className="mt-2 text-sm text-lgb-red-400 dark:text-lgb-red-300">
                <p>{t("validation:same_pickup_delivery")}</p>
              </div>
            )}
          </div>
        </div>
      );
    };

    return (
      <div>
        {/* ValidationToast for å vise feilmeldinger */}
        <ValidationToast
          show={showValidationToast}
          title={t("validation:validation_error")}
          message={validationMessage}
          onClose={() => setShowValidationToast(false)}
          position="top-right"
        />

        {/* Søkefelt og knapper */}
        <div className="mb-6 bg-white dark:bg-lgb-on-dark-background rounded-lg p-6 border border-lgb-grey-200 dark:border-[#2c3353]">
          <h2 className="text-xl font-medium mb-2 dark:text-white">
            {t("create.product_management.title")}
          </h2>
          
          <p className="text-lgb-grey-600 dark:text-lgb-grey-400 mb-4">
            {t("create.product_management.step_description")}
          </p>
          
          {!props.hideSearch && (
            <div className="mb-4">
              <DropdownSearch
                placeholder={t("create.product_management.search_ph")}
                inputChanged={(val) => handleSearch(val)}
                showResults={searchResults.length > 0}
                onFocus={handleFocus}
                onBlur={handleBlur}
                childrenAbsolute
                paginate
                pageSize={10}
              >
                {query.isLoading && <TableSkeletonsByAmount quantity={3} />}
                {!query.isLoading && searchResults.length > 0 && (
                  <div>
                    {searchResults.map((product: IOrderProduct) => {
                      return (
                        <DropdownSearchListItem
                          key={product.id}
                          id={product.id}
                          title={product.descriptionShort} //title
                          subtitle={product.descriptionShort || ""} //type
                          buttonClickEvent={addSavedProduct}
                          inlineSubtitle
                          iconType="product"
                        ></DropdownSearchListItem>
                      );
                    })}
                    {searchResults.length === 3 && (
                      <p
                        className="pt-4 text-md underline font-normal cursor-pointer hover:text-lgb-primary"
                        onClick={() => toggleShowAllDialog()}
                      >
                        {t("create.product_management.see_all")}
                      </p>
                    )}
                  </div>
                )}
              </DropdownSearch>
            </div>
          )}

          {/* Knapper for å legge til produkter */}
          <div className="flex flex-col md:flex-row flex-wrap gap-2">
            <StyledButton
              variant="secondary"
              onClick={() => toggleShowAllDialog()}
              className="flex items-center w-full md:w-auto border-lgb-grey-200 dark:border dark:border-gray-500"
              icon={<HiOutlineClipboardList className="dark:text-white" />}
            >
              {t("create.product_management.existing_product_button")}
            </StyledButton>
            
            <StyledButton
              variant="secondary"
              onClick={() => {
                setShowNewProductDialog(true);
              }}
              className="flex items-center w-full md:w-auto border-lgb-grey-200 dark:border dark:border-gray-500"
              icon={<HiOutlineShoppingCart className="dark:text-white" />}
            >
              {t("create.product_management.new_product_button")}
            </StyledButton>
          </div>
        </div>

        {/* Produktliste eller tom tilstand */}
        {currentOrderItems.length > 0 ? (
          <div className="w-full">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium dark:text-white">
                {t("create.product_management.product_list")} ({currentOrderItems.length})
              </h3>
            </div>
            
            {currentOrderItems.map((product, index) => 
              renderProductCard(product, index)
            )}
          </div>
        ) : (
          <div className="text-center p-8 bg-white dark:bg-lgb-on-dark-background rounded-lg border border-lgb-grey-200 dark:border-gray-500">
            <HiOutlineShoppingCart className="mx-auto h-12 w-12 text-lgb-grey-400 dark:text-lgb-grey-600" />
            <h3 className="mt-2 text-lg font-medium dark:text-white">
              {t("create.product_management.no_products_added")}
            </h3>
            <p className="mt-1 text-lgb-grey-600 dark:text-lgb-grey-400">
              {t("create.product_management.start_by_adding_product")}
            </p>
          </div>
        )}

        {/* Dialoger */}
        {NewProductDialog}
        {EditProductDialog}
        {showAllProductsDialog}
        {removeProductDialog}
      </div>
    );
  },
);

export default ProductManagement;