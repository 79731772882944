/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from "flowbite-react";
import { ForwardedRef, forwardRef, useImperativeHandle, useState } from "react";
import { HiChevronLeft } from "react-icons/hi";
import { ITableCell, ITableRow } from "../../../types";
import { StandardTable } from "../Table";
import { TableSkeleton } from "../../atoms/Skeleton";
import { Search } from "../Search/Search";
import { useTranslation } from "react-i18next";

// Stil for å forhindre horisontal scrolling
const noHorizontalScrollStyle = `
  .no-horizontal-scroll {
    overflow-x: hidden !important;
    max-width: 100vw;
  }
  .vertical-scroll-only {
    overflow-x: hidden !important;
    overflow-y: auto;
  }
  @media (max-width: 768px) {
    .mobile-compact-spacing {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
    .mobile-button-row {
      margin-bottom: 6px !important;
      padding: 0 !important;
    }
    .mobile-content-spacing {
      padding-top: 0 !important;
      margin-top: 0 !important;
    }
    .mobile-split-spacing {
      margin-top: 8px !important;
    }
  }
`;

export interface SplitTableProps {
  tableRows: ITableRow[];
  tableHeaders: ITableCell[];
  isLoading?: boolean;
  topRightContent?: React.ReactNode;
  contentOverTable?: React.ReactNode;
  content?: React.ReactNode;
  searchable?: boolean;
  forceOpen?: boolean;
  tableWidth?: string;
  contentWidth?: string;
  hasActions?: boolean;
  updateTable?: (searchText: string) => void;
  onRowClick: (item: any) => void;
}

export interface SplitTableRef {
  setSplit: (val: boolean) => void;
}
export const SplitTable = forwardRef(
  (props: SplitTableProps, ref: ForwardedRef<SplitTableRef>) => {
    const { t } = useTranslation(["common", "orders"]);
    const [split, setSplit] = useState(props.forceOpen ? true : false);

    /**
     * Exposed to the parent so it can be called through ref.
     */
    useImperativeHandle(ref, () => ({
      setSplit,
    }));

    const transition = "lg:transition-all lg:duration-200 lg:ease-in";

    const handleRowClick = (item: any) => {
      if (split && props.forceOpen !== true) {
        if (props.tableRows.some((row) => row.id === item.id && row.isActive)) {
          // If the same row is clicked again, close the split view
          setSplit(false);
          props.onRowClick(undefined);
        } else {
          // If a different row is clicked, update the content without closing the split view
          props.onRowClick(item);
        }
      } else {
        // Open split view and pass the selected item
        setSplit(true);
        props.onRowClick(item);
      }
    };

    const closeSplit = () => {
      if (!props.forceOpen) {
        props.onRowClick(undefined);
        setSplit(false);
      }
    };

    return (
      <>
        <style>{noHorizontalScrollStyle}</style>
        <div className="flex  flex-col lg:flex-row no-horizontal-scroll">
          {/* Main Table Section */}
          <div
            className={`${split ? `${props.tableWidth ? props.tableWidth : "w-full lg:w-2/5"} ${split && !props.forceOpen ? "hidden lg:block" : ""} overflow-hidden` : "w-full"} ${transition}`}
          >
            {props.searchable && (
              <Search
              placeholder={t("common:search")}
              inputChanged={(input) =>
                props.updateTable ? props.updateTable(input) : null
              }
            />
            )}
            {props.contentOverTable && (
              <div className="w-full">{props.contentOverTable}</div>
            )}
            {props.isLoading ? (
              <TableSkeleton />
            ) : (
              <div className="vertical-scroll-only">
                <StandardTable
                  onRowClick={(item) => handleRowClick(item)}
                  header={props.tableHeaders}
                  rows={props.tableRows}
                  hasActions={props.hasActions}
                />
              </div>
            )}
          </div>
          <div
            className={`${
              split
              ? `w-full ${props.contentWidth ? props.contentWidth : "lg:w-3/5"} lg:ml-4 lg:border-l lg:dark:border-l-gray-700 mt-2 lg:mt-0 vertical-scroll-only mobile-split-spacing`
              : "w-0 h-0 overflow-hidden"
            } ${transition}`}
          >
            <div className="lg:pl-4">
              {!props.forceOpen && (
                <div className="flex justify-between items-center mb-2 mobile-button-row">
                <div className="flex items-center">
                  <Button
                    size="sm"
                    color="light"
                    className="flex items-center px-2 py-2 h-[44px] sm:text-sm text-base"
                    onClick={closeSplit}
                  >
                    <HiChevronLeft size={20} className="h-6 w-6 sm:h-5 sm:w-5" />
                    <span className="ml-1">{t("common:back")}</span>
                  </Button>
                </div>
                {props.topRightContent}
              </div>
              )}
              <div className="mobile-content-spacing">
                {props.content}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);
