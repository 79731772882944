import { FC, useState } from "react";
import { IMapRoute, IRouteItem } from "../types";
import { MapMarkers } from "./MapMarkers";
import { MapPolyline } from "./MapPolyline";
import { MapStopOverlay } from "./MapStopOverlay";
import { useMapPins } from "./MapPins";

interface MapRouteProps {
  selectedRoute?: IMapRoute;
  driverPosition?: google.maps.LatLngLiteral;
}

export const MapRoute: FC<MapRouteProps> = ({
  selectedRoute,
  driverPosition,
}) => {
  const { waypointPin, startPin, driverPin } = useMapPins({ isLoaded: true });
  const [selectedStop, setSelectedStop] = useState<IRouteItem>();

  return (
    <>
      <MapMarkers
        selectedRoute={selectedRoute}
        driverPosition={driverPosition}
        startPin={startPin}
        waypointPin={waypointPin}
        driverPin={driverPin}
        onStopSelect={setSelectedStop}
      />
      <MapPolyline
        selectedRoute={selectedRoute}
      />
      <MapStopOverlay
        selectedRoute={selectedRoute}
        selectedStop={selectedStop}
        onClose={() => setSelectedStop(undefined)}
      />
    </>
  );
}; 