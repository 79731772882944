import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Button, Modal } from "flowbite-react";
import { HiOutlinePencilAlt, HiOutlineTrash } from "react-icons/hi";
import { Parameter, Vehicle, Company } from "../../../models";
import { formatFirestoreDate } from "../../../utils/productHelpers";
import { NorwayRectangle, StatensVegvesen } from "../../atoms/Icons/Flags";

interface VehicleDetailModalProps {
  vehicle: Vehicle;
  categories: Parameter[];
  company?: Company;
  isOpen: boolean;
  onClose: () => void;
  onEdit: (vehicle: Vehicle) => void;
  onDelete: (vehicle: Vehicle) => void;
}

// Separate component for vehicle details to improve readability
const DetailItem: FC<{ label: string; value: string | number | null }> = ({ label, value }) => (
  <div className="bg-gray-50 dark:bg-lgb-modal-bg rounded-lg p-2 flex flex-col">
    <h4 className="text-xs font-medium text-gray-500 dark:text-gray-400">
      {label}
    </h4>
    <p className="text-sm font-medium text-gray-900 dark:text-white mt-0.5">
      {value ? value : "-"}
    </p>
  </div>
);

// Format plate number with space after first two characters
const formatPlateNumber = (plate: string): string => {
  const upperPlate = plate.toUpperCase();
  return upperPlate.length > 2 ? upperPlate.slice(0, 2) + " " + upperPlate.slice(2) : upperPlate;
};

// License Plate component for reusability
interface LicensePlateProps {
  registrationNumber: string;
  size?: "small" | "medium" | "large";
  unknownText?: string;
}

const LicensePlate: FC<LicensePlateProps> = ({ 
  registrationNumber, 
  size = "small",
  unknownText = "Unknown" 
}) => {
  // Format plate number with space after first two characters
  const plateNumber = useMemo(() => {
    const upperPlate = registrationNumber.toUpperCase();
    return upperPlate.length > 2 ? upperPlate.slice(0, 2) + " " + upperPlate.slice(2) : upperPlate || unknownText;
  }, [registrationNumber, unknownText]);

  // Size configurations
  const sizeConfig = useMemo(() => {
    switch (size) {
      case "large":
        return {
          height: "50px",
          width: "50px",
          padding: "p-1",
          textSize: "text-xs",
          plateTextSize: "text-2xl"
        };
      case "medium":
        return {
          height: "40px",
          width: "40px",
          padding: "p-1",
          textSize: "text-xs",
          plateTextSize: "text-xl"
        };
      case "small":
      default:
        return {
          height: "30px",
          width: "30px",
          padding: "p-0.5",
          textSize: "text-[8px]",
          plateTextSize: "text-sm"
        };
    }
  }, [size]);

  return (
    <div className="flex items-center bg-white dark:bg-backgroundBlue border border-solid border-gray-800 dark:border-gray-700 rounded overflow-hidden" style={{ height: sizeConfig.height }}>
      {/* Blue side panel with the Norwegian flag and "N" */}
      <div
        className={`flex flex-col items-center justify-center bg-blue-700 ${sizeConfig.padding}`}
        style={{ width: sizeConfig.width, height: sizeConfig.height }}
      >
        <NorwayRectangle />
        <span className={`text-white font-bold ${sizeConfig.textSize}`}>N</span>
      </div>

      {/* Main plate area with the registration number */}
      <div className="flex items-center px-2">
        <p className={`font-medium dark:text-white ${sizeConfig.plateTextSize}`}>{plateNumber}</p>
      </div>
    </div>
  );
};

export const VehicleDetailModal: FC<VehicleDetailModalProps> = ({
  vehicle,
  categories,
  company,
  isOpen,
  onClose,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation(["common", "vehicles"]);

  // Find category name
  const categoryBadge = useMemo(() => {
    if (!vehicle.categoryCode) {
      return (
        <Badge color="gray" className="text-xs px-2 py-1">
          {t("vehicles:categories.uncategorized")}
        </Badge>
      );
    }
    
    const category = categories.find(c => c.code === vehicle.categoryCode);
    return category ? (
      <Badge className="text-xs px-2 py-1 bg-purple-100 text-purple-800 dark:bg-purple-800 dark:text-purple-200">
        {category.code}
      </Badge>
    ) : (
      <Badge color="gray" className="text-xs px-2 py-1">
        {vehicle.categoryCode}
      </Badge>
    );
  }, [vehicle, categories, t]);

  // Format creation date
  const formattedDate = useMemo(() => {
    return formatFirestoreDate(vehicle.createdAt);
  }, [vehicle.createdAt]);

  // Format registration number for URL
  const registrationForUrl = useMemo(() => {
    return vehicle.registrationNumber !== ""
      ? vehicle.registrationNumber.replace(/\s/g, "")
      : "";
  }, [vehicle.registrationNumber]);

  return (
    <Modal 
      show={isOpen} 
      onClose={onClose} 
      size="lg" 
      popup={false}
      theme={{
        root: {
          base: "fixed top-0 right-0 left-0 z-50 h-modal h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full",
          show: {
            on: "flex bg-gray-900 bg-opacity-70 dark:bg-opacity-90",
            off: "hidden"
          }
        },
        content: {
          base: "relative h-full w-full p-2 sm:p-4 md:h-auto",
          inner: "relative rounded-lg bg-white shadow-lg dark:bg-darkBlue border dark:border-gray-700 flex flex-col max-h-[95vh] sm:max-h-[90vh] shadow-xl dark:shadow-gray-900/50"
        }
      }}
    >
      <Modal.Header className="border-b border-gray-200 dark:border-gray-700 py-2 sm:py-3">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-3">
            {/* License Plate using the reusable component */}
            <LicensePlate 
              registrationNumber={vehicle.registrationNumber} 
              size="small" 
              unknownText={t("vehicles:unknown_vehicle")}
            />
            
            {/* Category Badge */}
            {categoryBadge}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0 overflow-y-auto">
        <div className="p-2 sm:p-3">
          {/* Vehicle Details */}
          <div className="space-y-3">
            {/* Mobile view - stacked layout */}
            <div className="block lg:hidden space-y-3">
              {/* Vehicle Details */}
              <div className="grid grid-cols-2 gap-2">
                <DetailItem 
                  label={t("vehicles:details.load_capacity")} 
                  value={`${vehicle.maxWeight} kg`} 
                />
                <DetailItem 
                  label={t("vehicles:details.vehicle_type")} 
                  value={vehicle.categoryCode || "-"} 
                />
                <DetailItem 
                  label={t("common:created_at")} 
                  value={formattedDate} 
                />
                <DetailItem 
                  label={t("vehicles:details.company")} 
                  value={company?.name || "-"} 
                />
              </div>
              
              {/* Description */}
              <div className="bg-gray-50 dark:bg-lgb-modal-bg rounded-lg p-2 sm:p-3">
                <h4 className="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1">
                  {t("common:description")}
                </h4>
                <p className="text-sm font-medium text-gray-900 dark:text-white break-words">
                  {vehicle.name || t("vehicles:no_description")}
                </p>
              </div>
            </div>
            
            {/* Desktop view - two column layout */}
            <div className="hidden lg:grid lg:grid-cols-2 gap-3">
              {/* Left column - Description and created date */}
              <div className="space-y-3">
                <div className="bg-gray-50 dark:bg-lgb-modal-bg rounded-lg p-3">
                  <h4 className="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1.5">
                    {t("common:description")}
                  </h4>
                  <p className="text-sm font-medium text-gray-900 dark:text-white break-words">
                    {vehicle.name || t("vehicles:no_description")}
                  </p>
                </div>
                
                {/* Company */}
                <div className="bg-gray-50 dark:bg-lgb-modal-bg rounded-lg p-3">
                  <h4 className="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1">
                    {t("vehicles:details.company")}
                  </h4>
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    {company?.name || "-"}
                  </p>
                </div>
              </div>
              
              {/* Right column - Vehicle details */}
              <div className="space-y-3">
                {/* Vehicle Details */}
                <div className="grid grid-cols-2 gap-2">
                  <DetailItem 
                    label={t("vehicles:details.load_capacity")} 
                    value={`${vehicle.maxWeight} kg`} 
                  />
                  <DetailItem 
                    label={t("vehicles:details.vehicle_type")} 
                    value={vehicle.categoryCode || "-"} 
                  />
                  <DetailItem 
                    label={t("common:created_at")} 
                    value={formattedDate} 
                  />
                </div>
                
                {/* External link to vehicle information */}
                <div className="bg-gray-50 dark:bg-lgb-modal-bg rounded-lg p-3">
                  <a
                    href={`https://www.vegvesen.no/kjoretoy/kjop-og-salg/kjoretoyopplysninger/sjekk-kjoretoyopplysninger/?registreringsnummer=${registrationForUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center gap-2 bg-blue-50 text-blue-700 dark:bg-blue-900/50 dark:text-blue-200 p-2 rounded border border-blue-200 dark:border-blue-800 hover:bg-blue-100 dark:hover:bg-blue-800/70 transition-colors"
                  >
                    <StatensVegvesen />
                    <span className="text-sm font-medium">Kjøretøyopplysninger</span>
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex flex-col sm:flex-row justify-between border-t border-gray-200 dark:border-gray-700 gap-2 py-2 sm:py-3 dark:bg-darkBlue">
        <Button 
          color="failure" 
          onClick={() => onDelete(vehicle)}
          className="flex items-center w-full sm:w-auto"
          size="sm"
        >
          <HiOutlineTrash className="mr-2 h-4 w-4" />
          {t("vehicles:actions.delete")}
        </Button>
        <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
          <Button 
            color="gray" 
            onClick={onClose}
            className="w-full sm:w-auto"
            size="sm"
          >
            {t("common:close")}
          </Button>
          <Button 
            color="purple" 
            onClick={() => onEdit(vehicle)}
            className="flex items-center justify-center w-full sm:w-auto"
            size="sm"
          >
            <HiOutlinePencilAlt className="mr-2 h-4 w-4" />
            {t("vehicles:actions.edit")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}; 