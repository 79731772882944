/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, useRef, type FC } from "react";
import { useTranslation } from "react-i18next";
import Datepicker, { DateValueType } from "react-tailwindcss-datepicker";
import { HiOutlineCalendar } from "react-icons/hi";

export interface LGBDatepickerProp {
  inputId?: string;
  selectedDate?: Date;
  showError?: boolean;
  select: (selectedDate: Date) => void;
  allowPastDates?: boolean;
}

export const LGBDatepicker: FC<LGBDatepickerProp> = (props) => {
  const { t } = useTranslation("common");
  const [value, setValue] = useState<DateValueType>({
    startDate: null,
    endDate: null,
  });
  
  // Bruk en ref for å spore om vi allerede har oppdatert fra props
  const isInitializedRef = useRef(false);
  const datepickerRef = useRef<any>(null);

  useEffect(() => {
    // Hvis selectedDate er definert og vi ikke allerede har initialisert
    if (props.selectedDate && !isInitializedRef.current) {
      setValue({
        startDate: props.selectedDate,
        endDate: props.selectedDate,
      });
      isInitializedRef.current = true;
    } else if (props.selectedDate) {
      // Sammenlign datoer for å unngå unødvendige oppdateringer
      const currentStartDate = value?.startDate instanceof Date ? value.startDate.getTime() : null;
      const newStartDate = props.selectedDate instanceof Date ? props.selectedDate.getTime() : null;
      
      if (currentStartDate !== newStartDate && newStartDate !== null) {
        setValue({
          startDate: props.selectedDate,
          endDate: props.selectedDate,
        });
      }
    }
  }, [props.selectedDate]);

  const handleValueChange = (newValue: DateValueType) => {
    setValue(newValue);
    if (newValue?.startDate) {
      switch (typeof newValue.startDate) {
        case "string":
          props.select(new Date(newValue.startDate));
          break;
        case "object":
          props.select(newValue.startDate as Date);
          break;
      }
    }
  };

  // Funksjon for å åpne datepickeren når man klikker på hele feltet
  const handleContainerClick = () => {
    if (datepickerRef.current) {
      // Finn input-elementet og simuler et klikk
      const inputElement = datepickerRef.current.querySelector('input');
      if (inputElement) {
        inputElement.click();
      }
    }
  };

  const maxDate = new Date();
  // Sett minDate til null hvis allowPastDates er true, ellers bruk dagens dato
  const minDate = props.allowPastDates ? undefined : new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1);
  const errorStyle =
    "border-lgb-red-200 bg-red-50 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400 dark:bg-lgb-red-100 dark:focus:border-red-500 dark:focus:ring-red-500";
  
  // Legg til en stil for å sikre at datepicker-popupen vises over alle andre elementer
  useEffect(() => {
    // Legg til en CSS-regel for å sikre at datepicker-popupen vises over alle andre elementer
    const style = document.createElement('style');
    style.innerHTML = `
      /* Sikre at datepicker-popupen vises over alle andre elementer */
      .react-tailwindcss-datepicker-container {
        position: fixed !important;
        z-index: 9999 !important;
      }
      
      /* Fjern overflow: hidden fra alle potensielle foreldre-containere */
      .absolute.inset-4.w-\\[280px\\].z-20,
      .bg-white.dark\\:bg-darkBlue.rounded-lg.shadow-sm.h-full.flex.flex-col.overflow-hidden.border.border-gray-200.dark\\:border-gray-700,
      .p-2.flex-shrink-0.border-b.border-gray-200.dark\\:border-gray-700 {
        overflow: visible !important;
      }
      
      /* Endre fargen på valgt dato til lgb-blue-300 */
      .bg-indigo-500, 
      .bg-indigo-600,
      button.bg-indigo-500, 
      button.bg-indigo-600 {
        background-color: #7214FF !important; /* lgb-blue-300 */
      }
      
      /* Sikre at teksten på valgt dato er synlig */
      .bg-indigo-500 span, 
      .bg-indigo-600 span,
      button.bg-indigo-500 span, 
      button.bg-indigo-600 span {
        color: white !important;
      }
      
      /* Endre fargen på dagens dato (border) */
      .border-indigo-500,
      .border-indigo-600,
      button.border-indigo-500,
      button.border-indigo-600,
      .today {
        border-color: #7214FF !important; /* lgb-blue-300 */
      }
      
      /* Endre fargen på hover-effekten */
      .hover\\:bg-indigo-100:hover,
      button.hover\\:bg-indigo-100:hover {
        background-color: rgba(114, 20, 255, 0.1) !important; /* lgb-blue-300 med opacity */
      }
      
      /* Legg til cursor: pointer på hover for date input */
      .react-tailwindcss-datepicker-input-container input {
        cursor: pointer !important;
        padding-right: 40px !important; /* Gi plass til kalender-ikonet */
      }

      /* Skjul clear-knappen */
      .react-tailwindcss-datepicker-input-container button {
        display: none !important;
      }
      
      /* Skjul SVG-ikoner fra datepicker */
      .react-tailwindcss-datepicker-input-container svg {
        display: none !important;
      }
      
      /* Stil for kalender-ikonet */
      .calendar-icon {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        color: #6B7280;
      }
      
      .dark .calendar-icon {
        color: #9CA3AF;
      }
    `;
    document.head.appendChild(style);
    
    return () => {
      document.head.removeChild(style);
    };
  }, []);
  
  return (
    <div className="relative">
      <Datepicker
        inputClassName={
          (props.showError && !props.selectedDate ? errorStyle : "") +
          " border p-2.5 text-sm rounded-lg relative border-gray-300 dark:border-gray-700 dark:bg-lgb-dark-background dark:text-white w-full cursor-pointer"
        }
        i18n={t("locale")}
        minDate={minDate}
        maxDate={maxDate}
        displayFormat={"DD.MM.YYYY"}
        asSingle
        startWeekOn="mon"
        useRange={false}
        value={value}
        onChange={(e) => handleValueChange(e)}
        primaryColor={"indigo"}
        containerClassName="lgb-datepicker-container"
        toggleClassName="hidden"
        readOnly={true}
      />
      {/* Kalender-ikon */}
      <div className="calendar-icon">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
          <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
        </svg>
      </div>
      {props.showError && !props.selectedDate && (
        <span className="absolute left-0 -bottom-6 text-sm text-red-600 dark:text-red-500">
          {t("validation:route_info.date")}
        </span>
      )}
    </div>
  );
};
