/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useMemo, useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { Order } from "../../models";
import { useOrders, useDrivers } from "../../hooks";
import { useWorkspace } from "../../context/WorkspaceContext";
import { OrderList } from "../../components";
import { Button, Toast } from "flowbite-react";
import { 
  HiOutlinePlus, 
  HiOutlineX, 
  HiOutlineUser, 
  HiOutlineTag,
  HiSearch,
  HiOutlineFilter,
  HiCheck,
  HiArrowLeft
} from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { EmptyState } from "../../components";
import { emptyOrdersIllustration } from "../../components/atoms/Icons/illustrations";
import { OrderStatus } from "../../types/order/IOrder";
import firebase from "firebase/compat/app";
import { StyledButton } from "../../components/atoms/Button";

export const AllOrdersPage: FC = function () {
  const [searchParams, setSearchParams] = useSearchParams();
  const [splitIsOpen, setSplitIsOpen] = useState(false);
  const { activeWorkspace } = useWorkspace();
  const { t } = useTranslation(["common", "orders", "validation", "stats"]);
  const navigate = useNavigate();
  const location = useLocation();

  // Sjekk om vi har en from-state for tilbakeknappen
  const fromPath = location.state?.from;

  // Funksjon for å navigere tilbake
  const handleGoBack = () => {    
    if (fromPath) {
      // Naviger tilbake til den opprinnelige stien
      navigate(fromPath);
    } else if (location.state?.driverId) {
      // Hvis vi har en driverId i state, naviger til sjåførdetaljene
      navigate(`/resources/drivers?driver=${encodeURIComponent(location.state.driverId)}`);
    } else {
      // Fallback hvis fromPath ikke er definert
      navigate("/resources/drivers");
    }
  };

  // Filter states
  const [statusFilter, setStatusFilter] = useState<OrderStatus[]>([]);
  const [driverFilter, setDriverFilter] = useState<string | undefined>(undefined);
  const [customerSearchText, setCustomerSearchText] = useState<string>("");
  const [additionalWhere, setAdditionalWhere] = useState<[string, firebase.firestore.WhereFilterOp, string][]>([]);
  
  // Flag to track if filtering is in progress
  const [isFiltering, setIsFiltering] = useState(false);
  
  // Dropdown states to control opening/closing
  const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
  const [driverDropdownOpen, setDriverDropdownOpen] = useState(false);
  
  // Refs for dropdown elements
  const statusDropdownRef = useRef<HTMLDivElement>(null);
  const driverDropdownRef = useRef<HTMLDivElement>(null);

  // Get drivers for filter dropdown
  const driversQuery = useDrivers(activeWorkspace?.workspaceId ?? "");
  const drivers = useMemo(() => driversQuery.data ?? [], [driversQuery]);

  // Filter options
  const statusOptions = [
    OrderStatus.New,
    OrderStatus.Scheduled,
    OrderStatus.InProgress,
    OrderStatus.Cancelled,
    OrderStatus.Completed
  ];

  // Initialize filters from URL parameters when component mounts
  useEffect(() => {
    // Get status filter from URL
    const statusParam = searchParams.get('status');
    if (statusParam) {
      const statuses = statusParam.split(',') as OrderStatus[];
      const validStatuses = statuses.filter(status => 
        Object.values(OrderStatus).includes(status as OrderStatus)
      ) as OrderStatus[];
      
      if (validStatuses.length > 0) {
        setStatusFilter(validStatuses);
        setIsFiltering(true);
      }
    }
    
    // Get driver filter from URL
    const driverParam = searchParams.get('driver');
    if (driverParam) {
      setDriverFilter(driverParam);
      setIsFiltering(true);
    }
    
    // Get customer search from URL
    const customerParam = searchParams.get('customer');
    if (customerParam) {
      setCustomerSearchText(customerParam);
      setIsFiltering(true);
    }
  }, [searchParams]);

  // Lukk dropdown-menyer når språket endres for å forhindre ResizeObserver-feil
  useEffect(() => {
    // Når t-funksjonen endres (språket endres), lukk alle dropdown-menyer
    setStatusDropdownOpen(false);
    setDriverDropdownOpen(false);
  }, [t]);

  // Update URL when filters change - using a separate debounced state to avoid updating URL too frequently
  const [debouncedCustomerSearchText, setDebouncedCustomerSearchText] = useState<string>("");
  
  // Debounce the customer search text
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedCustomerSearchText(customerSearchText);
    }, 300);
    
    return () => {
      clearTimeout(timer);
    };
  }, [customerSearchText]);
  
  // Update URL based on the debounced search text
  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    
    // Preserve the order ID if it exists (gjør dette først for å sikre at ID-parameteren alltid er med)
    const orderId = searchParams.get('id');
    if (orderId) {
      newSearchParams.set('id', orderId);
    }
    
    // Update status parameter
    if (statusFilter.length > 0) {
      newSearchParams.set('status', statusFilter.join(','));
    }
    
    // Update driver parameter
    if (driverFilter) {
      newSearchParams.set('driver', driverFilter);
    }
    
    // Update customer parameter
    if (debouncedCustomerSearchText) {
      newSearchParams.set('customer', debouncedCustomerSearchText);
    }
    
    // Update URL without reloading the page
    setSearchParams(newSearchParams, { replace: true });
    
    // Set filtering flag when any filters are active
    if (statusFilter.length > 0 || driverFilter || debouncedCustomerSearchText) {
      setIsFiltering(true);
    }
  }, [statusFilter, driverFilter, debouncedCustomerSearchText, setSearchParams]);

  // Update additionalWhere when driverFilter changes
  useEffect(() => {
    // Set filtering flag when filter changes
    setIsFiltering(true);
    
    const newAdditionalWhere: [string, firebase.firestore.WhereFilterOp, string][] = [];
    
    if (driverFilter) {
      newAdditionalWhere.push(["driverId", "==", driverFilter]);
    }
    
    // We'll handle customer search in the OrderList component
    // since it's more complex and requires client-side filtering
    
    setAdditionalWhere(newAdditionalWhere);
  }, [driverFilter]);

  // Set filtering flag when status filter changes
  useEffect(() => {
    setIsFiltering(true);
  }, [statusFilter]);

  // Set filtering flag when customer search changes
  useEffect(() => {
    if (customerSearchText !== "") {
      setIsFiltering(true);
    }
  }, [customerSearchText]);

  // Handle click outside to close dropdowns
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // Close status dropdown if click is outside
      if (
        statusDropdownRef.current && 
        !statusDropdownRef.current.contains(event.target as Node) &&
        statusDropdownOpen
      ) {
        setStatusDropdownOpen(false);
      }
      
      // Close driver dropdown if click is outside
      if (
        driverDropdownRef.current && 
        !driverDropdownRef.current.contains(event.target as Node) &&
        driverDropdownOpen
      ) {
        setDriverDropdownOpen(false);
      }
    }

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);
    
    // Clean up
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [statusDropdownOpen, driverDropdownOpen]);

  // Get orders with filters
  const query = useOrders(activeWorkspace?.workspaceId ?? "", statusFilter, additionalWhere);
  const orders = useMemo(() => query.data ?? [], [query]) as Order[];
  
  // Get all orders without filters to check if workspace has any orders
  const allOrdersQuery = useOrders(activeWorkspace?.workspaceId ?? "", [], []);
  const allOrders = useMemo(() => allOrdersQuery.data ?? [], [allOrdersQuery]) as Order[];

  const isQueryLoading = useMemo(
    () => query.isLoading || query.isRefetching || driversQuery.isLoading || driversQuery.isRefetching || allOrdersQuery.isLoading || allOrdersQuery.isRefetching,
    [query, driversQuery, allOrdersQuery],
  );

  // Reset isFiltering flag when query is done loading
  useEffect(() => {
    if (!isQueryLoading && isFiltering) {
      // Øk forsinkelsen for å sikre at filtreringen er fullført
      // og at tabellen ikke åpner seg for tidlig
      const timer = setTimeout(() => {
        // Sjekk om det finnes en ordre-ID i URL-en før vi setter isFiltering til false
        // Dette sikrer at split-visningen ikke åpnes automatisk når filtrering er ferdig
        // hvis det finnes en ordre-ID i URL-en
        const orderId = searchParams.get('id');
        setIsFiltering(false);
      }, 1000); // Økt fra 500ms til 1000ms
      
      return () => clearTimeout(timer);
    }
  }, [isQueryLoading, isFiltering, searchParams]);

  const hasOrders = orders.length > 0;
  const hasAnyOrders = allOrders.length > 0;
  const hasActiveFilters = statusFilter.length > 0 || driverFilter !== undefined || customerSearchText.length > 0;

  // Handle status filter change
  const handleStatusFilterChange = (status: OrderStatus, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent click from propagating
    setIsFiltering(true); // Set filtering flag
    
    // Update status filter
    let newStatusFilter: OrderStatus[];
    if (statusFilter.includes(status)) {
      newStatusFilter = statusFilter.filter(s => s !== status);
    } else {
      newStatusFilter = [...statusFilter, status];
    }
    
    setStatusFilter(newStatusFilter);
  };

  // Handle driver filter change
  const handleDriverFilterChange = (driverId: string, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent click from propagating
    setIsFiltering(true); // Set filtering flag
    
    // Hvis samme driver er valgt, fjern filteret (toggle off)
    // Ellers, sett filteret til den valgte driveren (single select)
    if (driverFilter === driverId) {
      setDriverFilter(undefined);
    } else {
      setDriverFilter(driverId);
    }
    
    // Lukk dropdown etter valg
    setDriverDropdownOpen(false);
  };

  // Handle customer search - update immediately for responsive UI
  const handleCustomerSearch = (searchText: string) => {
    setCustomerSearchText(searchText);
  };

  // Clear all filters
  const clearFilters = () => {
    setIsFiltering(true); // Set filtering flag
    setStatusFilter([]);
    setDriverFilter(undefined);
    setCustomerSearchText("");
    
    // Preserve the order ID when clearing filters
    const orderId = searchParams.get('id');
    if (orderId) {
      const newSearchParams = new URLSearchParams();
      newSearchParams.set('id', orderId);
      setSearchParams(newSearchParams, { replace: true });
    } else {
      // Hvis det ikke er noen ID-parameter, fjern alle parametere
      setSearchParams({}, { replace: true });
    }
  };

  // Get active filter count for each filter type
  const statusFilterCount = statusFilter.length;
  const driverFilterActive = driverFilter ? 1 : 0;

  // Custom dropdown toggle handlers med debounce for å forhindre ResizeObserver-feil
  const toggleStatusDropdown = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent click from propagating to elements behind
    // Bruk setTimeout for å forhindre at ResizeObserver-hendelser stables opp
    setTimeout(() => {
      setStatusDropdownOpen(!statusDropdownOpen);
      if (!statusDropdownOpen) {
        setDriverDropdownOpen(false); // Close other dropdown when opening this one
      }
    }, 10);
  };

  const toggleDriverDropdown = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent click from propagating to elements behind
    // Bruk setTimeout for å forhindre at ResizeObserver-hendelser stables opp
    setTimeout(() => {
      setDriverDropdownOpen(!driverDropdownOpen);
      if (!driverDropdownOpen) {
        setStatusDropdownOpen(false); // Close other dropdown when opening this one
      }
    }, 10);
  };

  // Function to generate a shareable link with current filters
  const getShareableLink = () => {
    const baseUrl = window.location.origin + window.location.pathname;
    const queryParams = new URLSearchParams();
    
    // Preserve the order ID if it exists (gjør dette først for å sikre at ID-parameteren alltid er med)
    const orderId = searchParams.get('id');
    if (orderId) {
      queryParams.set('id', orderId);
    }
    
    if (statusFilter.length > 0) {
      queryParams.set('status', statusFilter.join(','));
    }
    
    if (driverFilter) {
      queryParams.set('driver', driverFilter);
    }
    
    if (customerSearchText) {
      queryParams.set('customer', customerSearchText);
    }
    
    return baseUrl + '?' + queryParams.toString();
  };

  // State for toast notification
  const [showLinkCopiedToast, setShowLinkCopiedToast] = useState(false);

  // Function to copy shareable link to clipboard
  const copyShareableLink = () => {
    const link = getShareableLink();
    navigator.clipboard.writeText(link)
      .then(() => {
        // Show toast notification
        setShowLinkCopiedToast(true);
        // Hide toast after 3 seconds
        setTimeout(() => setShowLinkCopiedToast(false), 3000);
      })
      .catch(err => {
        console.error('Failed to copy link: ', err);
      });
  };

  return (
    <>
      {/* Toast notification for link copied */}
      {showLinkCopiedToast && (
        <div className="fixed bottom-5 right-5 z-50">
          <Toast>
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
              <HiCheck className="h-5 w-5" />
            </div>
            <div className="ml-3 text-sm font-normal">
              {t("common:link_copied")}
            </div>
            <button
              type="button"
              className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
              onClick={() => setShowLinkCopiedToast(false)}
              aria-label={t("common:close")}
            >
              <span className="sr-only">{t("common:close")}</span>
              <HiOutlineX className="w-5 h-5" />
            </button>
          </Toast>
        </div>
      )}

      {/* Always show the header and filters if there are any orders in the workspace */}
      {(hasOrders || (hasAnyOrders && !isQueryLoading)) && (
        <div className="flex flex-col px-4 py-2">
          <div className={splitIsOpen ? "hidden lg:block" : ""}>
            {/* Tilbakeknapp hvis vi har en from-state */}
            {fromPath && (
              <Button
                color="light"
                onClick={handleGoBack}
                className="mb-2"
              >
                <HiArrowLeft className="mr-2 h-5 w-5" />
                {t("common:back")}
              </Button>
            )}
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
              {t("orders:all_orders.page_title")}
            </h1>
            <p className="text-lgb-grey-600 text-base pt-1 pb-2 dark:text-lgb-grey-200">
              {t("orders:all_orders.page_description")}
            </p>
          </div>
          
          {/* Filter bar */}
          <div className="flex flex-col md:flex-row gap-2 mb-2">
            {/* Customer search */}
            <div className="flex-grow relative">
              <div className="flex">
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <HiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                  </div>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder={t("common:search_customer")}
                    value={customerSearchText}
                    onChange={(e) => handleCustomerSearch(e.target.value)}
                  />
                  {customerSearchText && (
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 flex items-center pr-3"
                      onClick={() => handleCustomerSearch("")}
                    >
                      <HiOutlineX className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                    </button>
                  )}
                </div>
              </div>
            </div>
            
            {/* Filter row - Status and Driver filters */}
            <div className="grid grid-cols-2 gap-2 md:gap-3">
              {/* Status filter dropdown */}
              <div className="relative" ref={statusDropdownRef}>
                <button
                  onClick={toggleStatusDropdown}
                  className={`flex items-center justify-center md:justify-between bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${statusFilterCount > 0 ? 'border-lgb-blue-300 dark:border-lgb-blue-300' : ''}`}
                >
                  <div className="flex items-center">
                    <HiOutlineTag className="h-5 w-5" />
                    <span className="hidden md:inline ml-2">{t("common:status")}</span>
                    {statusFilterCount > 0 && (
                      <span className="ml-2 bg-lgb-blue-300 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                        {statusFilterCount}
                      </span>
                    )}
                  </div>
                </button>
                
                {statusDropdownOpen && (
                  <div 
                    className="absolute z-10 mt-1 w-60 bg-white rounded-lg shadow-lg dark:bg-gray-700 dark:border-gray-600"
                    onClick={(e) => e.stopPropagation()}
                    style={{ width: '240px', height: 'auto', minHeight: '200px', maxHeight: '300px' }}
                  >
                    <div className="p-3 border-b border-gray-200 dark:border-gray-600">
                      <div className="flex justify-between items-center">
                        <span className="font-medium dark:text-white">{t("common:status")}</span>
                        {statusFilterCount > 0 && (
                          <button
                            className="text-sm text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
                            onClick={(e) => {
                              e.stopPropagation();
                              setStatusFilter([]);
                            }}
                          >
                            <HiOutlineX className="mr-1 h-4 w-4 inline" />
                            {t("common:Clear")}
                          </button>
                        )}
                      </div>
                    </div>
                    <ul className="py-2 max-h-60 overflow-y-auto">
                      {statusOptions.map((status) => {
                        return (
                          <li key={status}>
                            <button
                              onClick={(e) => handleStatusFilterChange(status, e)}
                              className={`flex items-center w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 ${statusFilter.includes(status) ? 'bg-lgb-blue-100 dark:bg-lgb-blue-400/30' : ''}`}
                            >
                              <input
                                type="checkbox"
                                checked={statusFilter.includes(status)}
                                onChange={() => {}}
                                className="mr-2 w-4 h-4 text-lgb-blue-300 bg-gray-100 border-gray-300 rounded focus:ring-lgb-blue-300 dark:focus:ring-lgb-blue-300 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <span className="dark:text-white">
                                {status === OrderStatus.New && t("stats:order_status.new")}
                                {status === OrderStatus.Scheduled && t("stats:order_status.scheduled")}
                                {status === OrderStatus.InProgress && t("stats:order_status.in_progress")}
                                {status === OrderStatus.Cancelled && t("stats:order_status.cancelled")}
                                {status === OrderStatus.Completed && t("stats:order_status.completed")}
                              </span>
                            </button>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
              
              {/* Driver filter dropdown */}
              <div className="relative" ref={driverDropdownRef}>
                <button
                  onClick={toggleDriverDropdown}
                  className={`flex items-center justify-center md:justify-between bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${driverFilter ? 'border-lgb-blue-300 dark:border-lgb-blue-300' : ''}`}
                >
                  <div className="flex items-center">
                    <HiOutlineUser className="h-5 w-5" />
                    <span className="hidden md:inline ml-2">{t("common:driver")}</span>
                    {driverFilter && (
                      <span className="ml-2 bg-lgb-blue-300 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                        1
                      </span>
                    )}
                  </div>
                </button>
                
                {driverDropdownOpen && (
                  <div 
                    className="absolute z-10 mt-1 w-60 bg-white rounded-lg shadow-lg dark:bg-gray-700 dark:border-gray-600"
                    onClick={(e) => e.stopPropagation()}
                    style={{ width: '240px', height: 'auto', minHeight: '200px', maxHeight: '300px' }}
                  >
                    <div className="p-3 border-b border-gray-200 dark:border-gray-600">
                      <div className="flex justify-between items-center">
                        <span className="font-medium dark:text-white">{t("common:drivers")}</span>
                        {driverFilter && (
                          <button
                            className="text-sm text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
                            onClick={(e) => {
                              e.stopPropagation();
                              setDriverFilter(undefined);
                            }}
                          >
                            <HiOutlineX className="mr-1 h-4 w-4 inline" />
                            {t("common:Clear")}
                          </button>
                        )}
                      </div>
                    </div>
                    <ul className="py-2 max-h-60 overflow-y-auto">
                      {drivers.map((driver) => (
                        <li key={driver.id}>
                          <button
                            onClick={(e) => handleDriverFilterChange(driver.email, e)}
                            className={`flex items-center w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 ${driverFilter === driver.email ? 'bg-lgb-blue-100 dark:bg-lgb-blue-400/30' : ''}`}
                          >
                            <input
                              type="radio"
                              checked={driverFilter === driver.email}
                              onChange={() => {}}
                              className="mr-2 w-4 h-4 text-lgb-blue-300 bg-gray-100 border-gray-300 rounded-full focus:ring-lgb-blue-300 dark:focus:ring-lgb-blue-300 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              name="driverFilter"
                            />
                            <span className="dark:text-white">{driver.firstName} {driver.lastName}</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            
            {/* Action buttons row - Create new order, Clear filters, Share link */}
            <div className="flex gap-2 md:gap-3 mt-2 md:mt-0">
              {/* Create new order button */}
              <button
                onClick={() => navigate("/orders/new")}
                className={`flex items-center justify-center bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg hover:bg-gray-100 focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:border-blue-500 w-full ${splitIsOpen ? "hidden lg:flex" : ""}`}
              >
                <HiOutlinePlus className="h-5 w-5" />
                <span className="hidden md:inline ml-2">{t("orders:create.title")}</span>
              </button>
              
              {/* Action buttons - only shown when filters are active */}
              {(statusFilterCount > 0 || driverFilterActive > 0 || customerSearchText) && (
                <>
                  <button
                    onClick={clearFilters}
                    className="flex-1 md:flex-auto flex items-center justify-center p-2.5 text-red-500 hover:text-red-700 bg-gray-50 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-red-400 dark:hover:text-red-300"
                    title={t("common:clear_filters")}
                  >
                    <HiOutlineX className="h-5 w-5" />
                  </button>
                  
                  {/* Share link button */}
                  <button
                    onClick={copyShareableLink}
                    className="flex-1 md:flex-auto flex items-center justify-center p-2.5 text-gray-700 hover:text-gray-900 bg-gray-50 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 dark:hover:text-gray-100"
                    title={t("common:copy_link")}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                    </svg>
                  </button>
                </>
              )}
              
              {/* Create new order button */}
              <StyledButton
                variant="add"
                onClick={() => navigate("/orders/new")}
                className={splitIsOpen ? "hidden lg:flex" : ""}
                size="md"
              >
                {t("orders:create.title")}
              </StyledButton>
            </div>
          </div>
        </div>
      )}

      {/* Render appropriate empty state or order list */}
      {!isQueryLoading && !hasOrders ? (
        hasAnyOrders ? (
          // Empty state for when there are orders in the workspace but none match the current filters
          <EmptyState
            icon={
              <div className="flex justify-center items-center w-20 h-20 rounded-full bg-gray-100 dark:bg-gray-700">
                <HiOutlineFilter className="w-10 h-10 text-gray-500 dark:text-gray-400" />
              </div>
            }
            title={t("orders:empty_state.filtered.title")}
            description={t("orders:empty_state.filtered.description")}
            buttonText={t("orders:empty_state.filtered.button_text")}
            buttonClick={clearFilters}
            buttonIcon="x"
            buttonColor="gray"
          />
        ) : (
          // Empty state for when there are no orders at all in the workspace
          <EmptyState
            icon={emptyOrdersIllustration}
            title={t("orders:empty_state.general.title")}
            description={t("orders:empty_state.general.description")}
            buttonText={t("orders:empty_state.general.button_text")}
            buttonClick={() => navigate("/orders/new")}
            buttonIcon="plus"
            buttonColor="primary"
          />
        )
      ) : (
        <div className="px-4">
          <OrderList
            orders={orders}
            isLoading={isQueryLoading}
            isOpen={setSplitIsOpen}
            customerSearchText={customerSearchText}
            onCustomerSearch={handleCustomerSearch}
            preventAutoOpen={(hasActiveFilters || isFiltering) && !searchParams.get('id')}
          />
        </div>
      )}
    </>
  );
};
