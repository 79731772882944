/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, type FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  CustomerModal,
  EmptyState,
  DropdownSearch,
  DropdownSearchRadioItem,
  DialogNotify,
  Pagination,
} from "../../../../components";
import { Customer } from "../../../../models";
import { useWorkspace } from "../../../../context/WorkspaceContext";
import { useCache } from "../../../../context/CacheContext";
import { useCustomers } from "../../../../hooks";
import customersEmptyState from "../../../../assets/customersEmptyState.svg";
import { TableSkeletonsByAmount } from "../../../../components/atoms/Skeleton/TableSkeleton";
import { ICustomer } from "../../../../types";
import { HiCheck } from "react-icons/hi";

export interface CustomerSelectionProps {
  selectedCustomer: Customer;
  validationFailed: boolean;
  update: (customer: Customer) => void;
}

const CustomerSelection: FC<CustomerSelectionProps> = (props) => {
  const { t } = useTranslation(["orders", "validation"]);
  const [showDialog, setShowDialog] = useState(false);
  const [current, setCurrent] = useState(props.selectedCustomer);
  const { activeWorkspace } = useWorkspace();
  const { updateCacheKey } = useCache();
  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState([] as Customer[]);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const resultsPerPage = 10; // Antall resultater per side
  const query = useCustomers(activeWorkspace?.workspaceId ?? "");
  const customers = query.data;

  useEffect(() => {
    if (searchText.length) {
      let companies =
        customers?.filter(
          (x) =>
            x.isCompany &&
            x.name?.toLowerCase().includes(searchText.toLowerCase()),
        ) || [];
      let privatePersons =
        customers?.filter(
          (x) =>
            !x.isCompany &&
            (x.firstName + " " + x.lastName)
              .toLowerCase()
              .includes(searchText.toLowerCase()),
        ) || [];
      setSearchResults(companies.concat(privatePersons));
    } else {
      setSearchResults(customers || []);
    }
    // Tilbakestill til første side når søket endres
    setCurrentPage(1);
  }, [searchText, customers]);

  useEffect(() => {
    setCurrent(props.selectedCustomer);
  }, [props.selectedCustomer]);

  const toggleDialog = () => {
    setShowDialog(!showDialog);
  };

  const handleSearch = (val: string) => {
    setSearchText(val);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Sorter resultater alfabetisk og beregn paginerte resultater
  const sortedAndPaginatedResults = useMemo(() => {
    // Sorter resultatene alfabetisk
    const sortedResults = [...searchResults].sort((a, b) => {
      const nameA = a.isCompany 
        ? (a.name || '').toLowerCase() 
        : `${a.firstName} ${a.lastName}`.toLowerCase();
      const nameB = b.isCompany 
        ? (b.name || '').toLowerCase() 
        : `${b.firstName} ${b.lastName}`.toLowerCase();
      return nameA.localeCompare(nameB);
    });

    // Beregn start- og sluttindeks for gjeldende side
    const startIndex = (currentPage - 1) * resultsPerPage;
    const endIndex = startIndex + resultsPerPage;

    // Returner bare resultatene for gjeldende side
    return sortedResults.slice(startIndex, endIndex);
  }, [searchResults, currentPage, resultsPerPage]);

  // Beregn totalt antall sider
  const totalPages = useMemo(() => {
    return Math.ceil(searchResults.length / resultsPerPage);
  }, [searchResults, resultsPerPage]);

  const CreateCustomerDialog = (
    <CustomerModal
      data={Customer.default()}
      isShowing={showDialog}
      onConfirm={(formData: ICustomer) => {
        Customer.create(activeWorkspace?.workspaceId ?? "", formData).then(
          (res) => {
            if (res) {
              updateCacheKey();
              setShowDialog(false);
              setShowSuccessDialog(true);
            }
          },
        );
      }}
      onCancel={() => setShowDialog(false)}
    />
  );

  const SuccessDialog = (
    <DialogNotify
      show={showSuccessDialog}
      isLoading={false}
      icon={<HiCheck size={"24px"} className="text-green-500" />}
      buttonCloseText={t("common:close")}
      title={t("create.customer_selection.create_customer_dialog.add_success_text")}
      toggleModal={() => setShowSuccessDialog(false)}
      confirmButton={() => {}}
    />
  );

  const selectCustomer = (val: Customer) => {
    setCurrent(val);
    props.update(val);
  };
  
  return (
    <div className="bg-white dark:bg-lgb-on-dark-background rounded-lg p-6 border border-lgb-grey-200 dark:border-[#2c3353]">
      <h2 className="text-xl font-medium mb-2 dark:text-white">
        {t("create.customer_selection.title")}
      </h2>
      
      <p className="text-lgb-grey-600 dark:text-lgb-grey-400 mb-4">
        {t("create.customer_selection.step_description")}
      </p>
      
      <DropdownSearch
        placeholder={t("create.customer_selection.search_ph")}
        inputChanged={(val) => handleSearch(val)}
        buttonText={t("create.customer_selection.create_customer_button")}
        buttonClick={toggleDialog}
        showResults
        plain
      >
        <div>
          {query.isLoading && <TableSkeletonsByAmount quantity={6} />}
          {!query.isLoading && sortedAndPaginatedResults.length === 0 && (
            <div className="p-4 text-center text-gray-500 dark:text-gray-400">
              {t("create.customer_selection.no_results")}
            </div>
          )}
          {!query.isLoading &&
            sortedAndPaginatedResults.map((customer: Customer) => {
              return (
                <DropdownSearchRadioItem
                  key={customer.id}
                  selected={current.id === customer.id}
                  buttonClickEvent={() => selectCustomer(customer)}
                  id={customer.id}
                  iconType="user"
                  title={
                    customer.isCompany
                      ? (customer.name ?? "")
                      : customer.firstName + " " + customer.lastName
                  }
                ></DropdownSearchRadioItem>
              );
            })}
          
          {/* Paginering */}
          {!query.isLoading && searchResults.length > resultsPerPage && (
            <div className="border-t border-gray-200 dark:border-[#2c3353] pt-2 mt-2">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </DropdownSearch>
      {CreateCustomerDialog}
      {SuccessDialog}
    </div>
  );
};

export default CustomerSelection;
