import { useQuery } from "react-query";
import { useUserSession } from "../context/UserContext";
import axios from "axios";
import { IOrder, OrderStatus } from "../types/order/IOrder";
import { Driver } from "../models";

export interface ActiveDriverInfo {
  id: string;
  name: string;
  orderCount: number;
  orders: Set<string>;
  hasInProgressOrders: boolean;
  hasCompletedOrders: boolean;
  hasOtherOrders: boolean; // Includes New, Scheduled, etc.
}

interface DriverResponse {
  success: boolean;
  error?: string;
  drivers: Driver[];
}

/**
 * Hook to fetch drivers from the API
 * @param workspaceId - The ID of the current workspace
 * @param enabled - Whether the query should run automatically
 * @returns Query object with drivers data
 */
export const useDriversApi = (workspaceId: string, enabled = true) => {
  const { userSession, isAuthenticated } = useUserSession();
  const baseUrl = process.env.REACT_APP_CLOUD_API_URL || "http://localhost:8080";
  
  return useQuery<DriverResponse, Error>(
    ["driversApi", workspaceId],
    async () => {
      if (!userSession?.token || !workspaceId || !isAuthenticated()) {
        throw new Error("Authentication or workspace data missing");
      }
      
      try {
        const response = await axios.get(`${baseUrl}/api/drivers/`, {
          headers: {
            Authorization: `Bearer ${userSession.token.accessToken}`,
            "X-Workspace-Id": workspaceId,
          },
        });
        
        return response.data;
      } catch (error) {
        console.error("Error fetching drivers:", error);
        throw error;
      }
    },
    {
      enabled: enabled && !!userSession?.token && !!workspaceId && isAuthenticated(),
      // refetchInterval: 60000, // Refetch data every 60 seconds - Temporarily disabled for debugging
      // staleTime: 30000, // Consider data stale after 30 seconds - Temporarily disabled for debugging
    }
  );
};

/**
 * Helper to get active drivers based on today's orders
 * @param drivers - List of drivers
 * @param orders - Today's orders (typed as IOrder[])
 * @returns List of active drivers with status information (typed as ActiveDriverInfo[])
 */
export const getActiveDriversFromOrders = (drivers: Driver[], orders: IOrder[]): ActiveDriverInfo[] => {
  if (!orders?.length) {
    return [];
  }
  
  const driversMap = new Map<string, ActiveDriverInfo>();
  
  orders.forEach(order => {
    if (order.driverId && typeof order.driverId === 'string' && order.driverId.trim() !== '') {
      const currentDriverId = order.driverId; 

      if (!driversMap.has(currentDriverId)) {
        const matchingDriver = drivers.find(d => 
          d.email === currentDriverId || 
          d.id === currentDriverId
        );
        
        let driverName = currentDriverId; // Default to driverId (email)
        if (matchingDriver) {
          const nameFromParts = `${matchingDriver.firstName || ''} ${matchingDriver.lastName || ''}`.trim();
          driverName = nameFromParts || matchingDriver.email; // Use constructed name or fallback to email
        }
        
        driversMap.set(currentDriverId, {
          id: currentDriverId,
          name: driverName,
          orderCount: 0,
          orders: new Set<string>(), 
          hasInProgressOrders: false,
          hasCompletedOrders: false,
          hasOtherOrders: false
        });
      }
      
      const driverInfo = driversMap.get(currentDriverId)!; // Add non-null assertion
      driverInfo.orders.add(order.id);
      driverInfo.orderCount = driverInfo.orders.size;
      
      // Check order status - Use OrderStatus enum for clarity if possible
      if (order.status === OrderStatus.InProgress) { 
        driverInfo.hasInProgressOrders = true;
      } else if (order.status === OrderStatus.Completed) {
        driverInfo.hasCompletedOrders = true;
      } else {
        // Any other status (New, Scheduled, Draft, etc.) counts as "Other"
        driverInfo.hasOtherOrders = true; 
      }
    }
  });
  
  // Sort the resulting drivers based on the NEW status priority
  const result = Array.from(driversMap.values()).sort((a, b) => {
    // Define states for clarity
    const aInProgress = a.hasInProgressOrders;
    const bInProgress = b.hasInProgressOrders;
    const aCompleted = a.hasCompletedOrders;
    const bCompleted = b.hasCompletedOrders;
    const aOther = a.hasOtherOrders;
    const bOther = b.hasOtherOrders;

    const aActiveMixed = aCompleted && aOther && !aInProgress;
    const bActiveMixed = bCompleted && bOther && !bInProgress;
    const aFinished = aCompleted && !aOther && !aInProgress;
    const bFinished = bCompleted && !bOther && !bInProgress;
    const aNotStarted = aOther && !aCompleted && !aInProgress;
    const bNotStarted = bOther && !bCompleted && !bInProgress;

    // Priority 1: InProgress
    if (aInProgress && !bInProgress) return -1;
    if (!aInProgress && bInProgress) return 1;

    // Priority 2: Active (Mixed - Completed some, has others pending)
    if (aActiveMixed && !bActiveMixed) return -1;
    if (!aActiveMixed && bActiveMixed) return 1;

    // Priority 3: Finished (All assigned orders completed)
    if (aFinished && !bFinished) return -1;
    if (!aFinished && bFinished) return 1;

    // Priority 4: Not Started (Only has pending/other orders)
    if (aNotStarted && !bNotStarted) return -1;
    if (!aNotStarted && bNotStarted) return 1;

    // Fallback: sort by name if statuses are equivalent
    return a.name.localeCompare(b.name);
  });
  
  return result;
}; 