import { FC, useRef, useEffect, useState } from "react";
import { Polyline } from "@react-google-maps/api";
import { MapPolylineProps } from "../types";
import axios from "axios";
import { useDarkMode } from "../../../../context/DarkModeContext";

export const MapPolyline: FC<MapPolylineProps> = ({
  selectedRoute,
}) => {
  const polylineRef = useRef<google.maps.Polyline | null>(null);
  const [path, setPath] = useState<google.maps.LatLngLiteral[]>([]);
  const [pathKey, setPathKey] = useState<number>(0);
  const [animationComplete, setAnimationComplete] = useState(false);
  const { isDarkMode } = useDarkMode();
  const animationTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [animationProgress, setAnimationProgress] = useState(0);

  useEffect(() => {
    // Remove existing polyline from the map
    if (polylineRef.current) {
      polylineRef.current.setMap(null);
      polylineRef.current = null;
    }
    setPath([]);
    setPathKey(prev => prev + 1);
    setAnimationComplete(false);
    setAnimationProgress(0);

    // Clear any existing animation timer
    if (animationTimerRef.current) {
      clearInterval(animationTimerRef.current);
      animationTimerRef.current = null;
    }

    // Fetch new path if we have a route
    if (selectedRoute?.route) {
      const coordinates = selectedRoute.route
        .filter(stop => stop.location.coordinate?.lat && stop.location.coordinate?.lon)
        .map(stop => ({
          lat: stop.location.coordinate?.lat ?? 0,
          lng: stop.location.coordinate?.lon ?? 0,
        }));

      if (coordinates.length >= 2) {
        fetchRoutePath(coordinates);
      }
    }

    return () => {
      if (animationTimerRef.current) {
        clearInterval(animationTimerRef.current);
      }
    };
  }, [selectedRoute?.id, selectedRoute?.route]);

  const fetchRoutePath = async (coordinates: google.maps.LatLngLiteral[]) => {
    try {
      const response = await axios.post(
        `https://routes.googleapis.com/directions/v2:computeRoutes`,
        {
          origin: {
            location: {
              latLng: {
                latitude: coordinates[0].lat,
                longitude: coordinates[0].lng,
              },
            },
          },
          destination: {
            location: {
              latLng: {
                latitude: coordinates[coordinates.length - 1].lat,
                longitude: coordinates[coordinates.length - 1].lng,
              },
            },
          },
          intermediates: coordinates.slice(1, -1).map((stop) => ({
            location: {
              latLng: {
                latitude: stop.lat,
                longitude: stop.lng,
              },
            },
          })),
          travelMode: "DRIVE",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-Goog-Api-Key": process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
            "X-Goog-FieldMask": "routes.polyline",
          },
        }
      );

      if (response.data?.routes?.[0]) {
        const route = response.data.routes[0];
        const decodedPath = window.google.maps.geometry.encoding
          .decodePath(route.polyline.encodedPolyline)
          .map((point) => ({
            lat: point.lat(),
            lng: point.lng(),
          }));

        setPath(decodedPath);
        setPathKey(prev => prev + 1);
        
        // Start animation
        animatePolyline(decodedPath);
      }
    } catch (error) {
      console.error("Error fetching route path:", error);
    }
  };

  const animatePolyline = (fullPath: google.maps.LatLngLiteral[]) => {
    // Reset animation state
    setAnimationProgress(0);
    setAnimationComplete(false);
    
    // Clear any existing animation
    if (animationTimerRef.current) {
      clearInterval(animationTimerRef.current);
    }
    
    // Set up animation
    const animationDuration = 1500; // ms
    const steps = 20;
    const stepDuration = animationDuration / steps;
    
    let step = 0;
    
    animationTimerRef.current = setInterval(() => {
      step++;
      const progress = step / steps;
      setAnimationProgress(progress);
      
      if (step >= steps) {
        clearInterval(animationTimerRef.current as NodeJS.Timeout);
        animationTimerRef.current = null;
        setAnimationComplete(true);
      }
    }, stepDuration);
  };

  const handlePolylineLoad = (polyline: google.maps.Polyline) => {
    // Remove existing polyline from the map if any
    if (polylineRef.current) {
      polylineRef.current.setMap(null);
    }
    polylineRef.current = polyline;
  };

  // Create polyline with solid color
  const createPolyline = () => {
    if (!path.length) return null;
    
    // Apply animation progress to show the polyline gradually
    const animatedPath = animationComplete 
      ? path 
      : path.slice(0, Math.ceil(path.length * animationProgress));
    
    if (animatedPath.length < 2) return null;
    
    return (
      <Polyline
        key={`polyline-${pathKey}`}
        path={animatedPath}
        options={{
          strokeColor: '#7214FF', // lgb-blue-300
          strokeOpacity: 0.9,
          strokeWeight: 4,
          zIndex: 10,
          icons: [{
            icon: {
              path: window.google.maps.SymbolPath.CIRCLE,
              fillColor: '#7214FF',
              fillOpacity: 1,
              scale: 4,
              strokeColor: '#FFFFFF',
              strokeWeight: 2,
            },
            offset: '100%',
          }],
        }}
      />
    );
  };

  // Create subtle glow effect
  const createGlowEffect = () => {
    if (!path.length || !animationComplete) return null;
    
    return (
      <Polyline
        key={`polyline-glow-${pathKey}`}
        path={path}
        options={{
          strokeColor: '#7214FF', // lgb-blue-300
          strokeOpacity: 0.3,
          strokeWeight: 6,
          zIndex: 5,
        }}
      />
    );
  };

  if (!selectedRoute) return null;

  return (
    <>
      {createGlowEffect()}
      {createPolyline()}
    </>
  );
}; 