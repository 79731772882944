import { IRouteItem } from "../../../types/order/IRouteItem";

export interface ICargo {
  id: string;
  quantity: number;
  description?: string;
  itinerary: {
    pickupStopNumber?: number;
    deliveryStopNumber?: number;
  };
}

export interface BaseStopProps {
  isAvailable?: boolean;
  stopDisplayName?: string;
  orderDisplayName?: string;
  pickupStopNumber?: number;
}

export interface EnhancedRouteItem extends IRouteItem {
  isAvailable: boolean;
  orderDisplayName?: string;
  stopDisplayName?: string;
  orderId?: string;
  cargos?: ICargo[];
}

export interface ValidationError {
  message: string;
  invalidStopIds: string[];
}

export const ZONE_IDS = {
  AVAILABLE: 'available-zone',
  ROUTE: 'route-zone',
} as const;

export const STYLE_CLASSES = {
  CONTAINER: 'bg-gray-100/80 dark:bg-gray-900/50 p-3 rounded-lg min-h-[78px] flex border border-gray-200 dark:border-gray-700 shadow-[inset_0_0_8px_rgba(0,0,0,0.02)] transition-all duration-150',
  CARD: 'group relative bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 transition-all duration-150 cursor-move border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm hover:shadow-md',
  TOOLTIP: 'absolute right-0 top-full mt-2 z-50 bg-gray-900 text-white text-xs rounded-lg py-2 px-3 shadow-lg w-[250px] backdrop-blur-sm bg-opacity-90',
} as const; 