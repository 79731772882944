import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import {
  HiOutlineClipboardList,
  HiOutlineClock,
  HiOutlineBadgeCheck,
} from "react-icons/hi";
import { HiOutlineMapPin } from "react-icons/hi2";
import InfoCard from "../../molecules/Cards/InfoCard";
import { OrderStatus } from "../../../types/order/IOrder";
import { IRouteItem } from "../../../types";
import { Order } from "../../../models";

interface OrderKPIProps {
  orders: Order[];
  loading: boolean;
  driverId?: string;
  isLocationDetails?: boolean; // Ny prop for å indikere om komponenten vises i LocationDetails
}

export const OrderKPIs: FC<OrderKPIProps> = ({ orders, loading, driverId, isLocationDetails = false }) => {
  const { t } = useTranslation(["common", "customer"]);
  const location = useLocation();
  
  // KPIs
  const [totalOrders, setTotalOrders] = useState(0);
  const [upcomingOrdersCount, setUpcomingOrdersCount] = useState(0);
  const [uniqueLocationsCount, setUniqueLocationsCount] = useState(0);

  const [allCompletedOrdersCount, setAllCompletedOrdersCount] = useState(0);

  // Fetch KPIs and initial orders
  useEffect(() => {
    if (!orders) return;

    const calculateKPIs = async () => {
      try {
        const completed = orders.filter(
          (order) => order.status === OrderStatus.Completed,
        );

        const upcoming = orders.filter(
          (order) =>
            order.status === OrderStatus.New ||
            order.status === OrderStatus.Scheduled,
        );

        // For en spesifikk lokasjon, viser vi antall unike ordrer som bruker denne lokasjonen
        // istedenfor å telle alle unike lokasjoner i alle ordrer
        setUpcomingOrdersCount(upcoming.length);
        setUniqueLocationsCount(orders.length); // Antall ordrer som bruker denne lokasjonen
        setAllCompletedOrdersCount(completed.length);
        setTotalOrders(orders.length);
      } catch (error) {
        console.error("Error fetching customer data:", error);
        // Optionally, set error state to display a message to the user
      }
    };

    calculateKPIs();
  }, [orders, t]);

  // Generer URL med query-parametere for sjåfør og status
  const getOrdersUrlWithParams = (statuses: OrderStatus[]) => {
    const params = new URLSearchParams();
    
    if (driverId) {
      params.set('driver', driverId);
    }
    
    if (statuses.length > 0) {
      params.set('status', statuses.join(','));
    }
    
    return `/orders?${params.toString()}`;
  };

  // Hent nåværende URL for å bruke som state i lenkene
  const currentPath = location.pathname + location.search;

  return (
    <>
      {/* KPIs */}
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-4 my-4 sm:my-6">
        <Link 
          to={getOrdersUrlWithParams([OrderStatus.Completed])} 
          state={{ from: currentPath, driverId: driverId }}
          className="w-full"
        >
          <InfoCard
            icon={<HiOutlineBadgeCheck className="h-6 w-6 sm:h-7 sm:w-7 text-lgb-grey-600" />}
            title={t("customer:completed_orders")}
            description={`${allCompletedOrdersCount}`}
            titleClass="text-lgb-grey-600 text-xs sm:text-sm truncate"
            descriptionClass="text-lgb-grey-600 text-center text-lg sm:text-xl"
            containerClass="bg-lgb-green-100 hover:shadow-md transition-shadow duration-200 cursor-pointer p-2 sm:p-3 h-full flex flex-col items-center"
            loading={loading}
          />
        </Link>
        <Link 
          to={getOrdersUrlWithParams([OrderStatus.New, OrderStatus.Scheduled])} 
          state={{ from: currentPath, driverId: driverId }}
          className="w-full"
        >
          <InfoCard
            icon={<HiOutlineClock className="h-6 w-6 sm:h-7 sm:w-7 text-lgb-grey-600" />}
            title={t("customer:upcoming_orders")}
            description={`${upcomingOrdersCount}`}
            titleClass="text-lgb-grey-600 text-xs sm:text-sm truncate"
            descriptionClass="text-lgb-grey-600 text-center text-lg sm:text-xl"
            containerClass="bg-lgb-yellow-100 hover:shadow-md transition-shadow duration-200 cursor-pointer p-2 sm:p-3 h-full flex flex-col items-center"
            loading={loading}
          />
        </Link>
        {!isLocationDetails && (
          <Link 
            to="/resources/locations" 
            state={{ from: currentPath, driverId: driverId }}
            className="w-full h-full sm:w-auto"
          >
            <InfoCard
              icon={<HiOutlineMapPin className="h-6 w-6 sm:h-7 sm:w-7 text-lgb-grey-600" />}
              title={t("customer:unique_locations", { defaultValue: "Lokasjoner" })}
              description={`${uniqueLocationsCount}`}
            titleClass="text-lgb-grey-600 text-xs sm:text-sm truncate"
            descriptionClass="text-lgb-grey-600 text-center text-lg sm:text-xl"
              containerClass="bg-lgb-blue-100 hover:shadow-md transition-shadow duration-200 cursor-pointer"
              loading={loading}
            />
          </Link>
        )}
        <Link 
          to={getOrdersUrlWithParams([])} 
          state={{ from: currentPath, driverId: driverId }}
          className="w-full h-full sm:w-auto"
        >
          <InfoCard
            icon={
              <HiOutlineClipboardList className="h-6 w-6 sm:h-7 sm:w-7 text-lgb-grey-600" />
            }
            title={t("customer:total_orders")}
            description={`${totalOrders}`}
            titleClass="text-lgb-grey-600 text-xs sm:text-sm truncate"
            descriptionClass="text-lgb-grey-600 text-center text-lg sm:text-xl"
            containerClass="bg-lgb-grey-100 hover:shadow-md transition-shadow duration-200 cursor-pointer p-2 sm:p-3 h-full flex flex-col items-center"
            loading={loading}
          />
        </Link>
      </div>
    </>
  );
};
