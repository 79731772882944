/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { DropdownSearch, DropdownSearchListItem } from "../Search";
import { Button } from "flowbite-react";
import { HiOutlinePlus, HiOutlineX } from "react-icons/hi";
import { IOrderProduct } from "../../../types";

export interface SavedProductsProp {
  products: IOrderProduct[];
  currentSelected: IOrderProduct[];
  addProduct: (id: string) => void;
  close: () => void;
}

export const SavedProducts: FC<SavedProductsProp> = (props) => {
  const { t } = useTranslation(["orders", "validation", "modals"]);
  const [selectedProduct, setSelectedProduct] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState(
    props.products as IOrderProduct[],
  );

  useEffect(() => {
    if (props.products.length) {
      setSearchResults(props.products);
    }
  }, [props.products]);

  useEffect(() => {
    if (searchText.length) {
      let res =
        props.products?.filter((x) =>
          (x.description + " " + x.descriptionShort)
            ?.toLowerCase()
            .includes(searchText.toLowerCase()),
        ) || [];
      setSearchResults(res.slice(0, 3));
    } else {
      setSearchResults(props.products);
    }
  }, [searchText, props.products, props.currentSelected]);

  function handleSearch(val: string) {
    setSearchText(val);
  }

  function addProduct() {
    props.addProduct(selectedProduct);
    setSelectedProduct("");
    setSearchText(searchText);
  }

  return (
    <div>
      <p className="text-lgb-grey-600 text-base pb-5">
        {t("descriptions.saved_product", { ns: "modals" })}
      </p>
      <div className="relative mb-14">
        <DropdownSearch
          placeholder={t("create.product_management.search_ph")}
          inputChanged={(val) => handleSearch(val)}
          showResults
          plain
        >
          <div className="pt-2">
            {searchResults.map((product: IOrderProduct) => {
              return (
                <DropdownSearchListItem
                  key={product.id}
                  id={product.id}
                  title={product.descriptionShort}
                  subtitle={product.description || ""}
                  selected={
                    selectedProduct ? product.id === selectedProduct : false
                  }
                  buttonClickEvent={(id) => setSelectedProduct(id)}
                  iconType="product"
                ></DropdownSearchListItem>
              );
            })}
          </div>
        </DropdownSearch>
      </div>
      <div
        className="absolute bottom-0 pb-6 bg-white rounded-lg"
        style={{ width: "calc(100% - 24px)" }}
      >
        <div className={"flex bg-white gap-4 justify-start mt-4"}>
          <Button
            disabled={!selectedProduct}
            color="primary"
            onClick={() => addProduct()}
          >
            <HiOutlinePlus className="mr-2 h-5 w-5" />
            {t("create.product_management.add_saved_product_confirm")}
          </Button>
          <Button color="gray" onClick={props.close}>
            <HiOutlineX className="mr-2 h-5 w-5" />
            {t("common:close")}
          </Button>
        </div>
      </div>
    </div>
  );
};
