/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useMemo, useEffect } from "react";
import { HiTrash, HiPencilAlt } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import {
  PageLayout,
  TableSkeleton,
  DialogForm,
  DriverModal,
} from "../../components";
import { useWorkspace } from "../../context/WorkspaceContext";
import { useCache } from "../../context/CacheContext";
import { useDrivers, useCompanies, useVehicles } from "../../hooks";
import { useTranslation } from "react-i18next";
import { IDriver, ITableRow } from "../../types";
import { Driver, Company, Vehicle } from "../../models";
import { EmptyState } from "../../components";
import { emptyDriverIllustration } from "../../components/atoms/Icons/illustrations";
import { DriversList } from "../../components/organisms/Driver";

export const DriversPage: FC = function () {
  const [searchParams] = useSearchParams();
  const [isUpsertModalOpen, openUpsertModal] = useState(false);
  const [isDeleteModalOpen, openDeleteModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const [driverData, setDriverData] = useState(Driver.default());
  const [workspaceCompanies, setWorkspaceCompanies] = useState([] as Company[]);
  const [vehicleList, setVehicleList] = useState([] as Vehicle[]);
  const [splitIsOpen, setSplitIsOpen] = useState(false);
  const [initialDriverEmail, setInitialDriverEmail] = useState<string | null>(null);

  const { updateCacheKey } = useCache();
  const { activeWorkspace } = useWorkspace();
  const companiesQuery = useCompanies(activeWorkspace?.workspaceId ?? "");
  const vehiclesQuery = useVehicles(activeWorkspace?.workspaceId ?? "");
  const query = useDrivers(activeWorkspace?.workspaceId ?? "");
  const { t } = useTranslation(["common", "user", "driver"]);

  // Hent sjåfør-ID fra URL-en
  useEffect(() => {
    const driverParam = searchParams.get('driver');
    if (driverParam) {
      setInitialDriverEmail(driverParam);
    }
  }, [searchParams]);

  const isQueryLoading = useMemo(
    () =>
      query.isLoading ||
      query.isRefetching ||
      companiesQuery.isLoading ||
      companiesQuery.isRefetching ||
      vehiclesQuery.isLoading ||
      vehiclesQuery.isRefetching,
    [query, companiesQuery, vehiclesQuery],
  );

  const drivers = useMemo(() => query.data ?? [], [query]);

  const companies = useMemo(() => companiesQuery.data ?? [], [companiesQuery]);

  const vehicles = useMemo(() => vehiclesQuery.data ?? [], [vehiclesQuery]);

  // Åpne sjåførdetaljene når initialDriverEmail er satt og data er lastet
  useEffect(() => {
    if (!isQueryLoading && initialDriverEmail && drivers.length > 0) {
      const driverToOpen = drivers.find(driver => driver.email === initialDriverEmail);
      if (driverToOpen) {
        setSplitIsOpen(true);
      }
    }
  }, [isQueryLoading, initialDriverEmail, drivers]);

  useEffect(() => {
    if (!isQueryLoading) {
      setWorkspaceCompanies(companies);
    }
  }, [isQueryLoading, companies]);

  useEffect(() => {
    if (!isQueryLoading) {
      setVehicleList(vehicles);
    }
  }, [isQueryLoading, vehicles]);

  useEffect(() => {
    if (!isQueryLoading) {
      setLoading(false);
      setTableRows(
        drivers.map((driver) => {
          return {
            id: driver.id,
            cells: [
              {
                id: "firstname",
                children: driver.firstName,
                showOnSmallScreen: true,
              },
              {
                id: "lastname",
                children: driver.lastName,
                showOnSmallScreen: true,
              },
              {
                id: "email",
                children: driver.email,
              },
              {
                id: "phone",
                children: driver.phoneNumber,
              },
            ],
            actions: [
              {
                id: "edit",
                icon: <HiPencilAlt />,
                onActionClick: () => {
                  setDriverData(driver);
                  openUpsertModal(true);
                },
              },
              {
                id: "delete",
                icon: <HiTrash />,
                color: "failure",
                onActionClick: () => {
                  setDriverData(driver);
                  openDeleteModal(true);
                },
              },
            ],
          };
        }),
      );
    }
  }, [isQueryLoading, drivers]);

  return (
    <PageLayout>
      {isLoading ? (
        <TableSkeleton />
      ) : tableRows.length === 0 ? (
        // Render empty state when there are no table rows
        <EmptyState
          icon={emptyDriverIllustration}
          title={t("driver:empty_state.title")}
          description={t("driver:empty_state.description")}
          buttonText={t("driver:empty_state.button_text")}
          buttonClick={() => {
            setDriverData(Driver.default());
            openUpsertModal(true);
          }}
        />
      ) : (
        <DriversList
          drivers={drivers}
          vehicles={vehicles}
          companies={companies}
          isLoading={isQueryLoading}
          isOpen={setSplitIsOpen}
          initialDriverEmail={initialDriverEmail}
          createOrUpdate={(formData: Driver) => {
            setDriverData(formData);
            openUpsertModal(true);
          }}
        />
      )}

      <DriverModal
        data={driverData}
        companies={workspaceCompanies}
        vehicles={vehicleList}
        isShowing={isUpsertModalOpen}
        onConfirm={(formData: IDriver) => {
          // Helper function to capitalize the first letter of First name and Last name
          const capitalizeFirstLetter = (name: string) =>
            name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

          // Ensure email is lowercase and names are capitalized correctly
          const updatedFormData = {
            ...formData,
            email: formData.email.toLowerCase(), // Convert email to lowercase
            firstName: capitalizeFirstLetter(formData.firstName), // Capitalize first letter of the first name
            lastName: capitalizeFirstLetter(formData.lastName), // Capitalize first letter of the last name
          };

          if (Driver.isNew(driverData)) {
            Driver.create(activeWorkspace?.workspaceId ?? "", updatedFormData); // Create the new driver with updated data
          } else {
            Driver.update(driverData, updatedFormData); // Update the existing driver with updated data
          }

          openUpsertModal(false); // Close the modal
          updateCacheKey(); // Refresh the cache
        }}
        onCancel={() => openUpsertModal(false)}
      />

      <DialogForm
        title={t("user:delete_driver") + " " + driverData.firstName + "?"}
        show={isDeleteModalOpen}
        toggleModal={() => openDeleteModal(false)}
        showHeader={false}
        dismissible
        buttonConfirmColor="failure"
        confirmButton={() => {
          Driver.delete(driverData);
          openDeleteModal(false);
          updateCacheKey();
        }}
        buttonConfirmText={t("common:confirm")}
        buttonConfirmPrependIcon={<HiTrash className="mr-2 h-4 w-4" />}
        buttonCloseText={t("common:close")}
      />
    </PageLayout>
  );
};
