/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useMemo } from "react";
import { Dropdown } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { HiOutlineGlobeAlt } from "react-icons/hi";

interface Language {
  code: string;
  name: string;
}

// Definerer tilgjengelige språk
const availableLanguages: Language[] = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "no",
    name: "Norsk",
  },
];

export const LanguageDropdown: FC = function () {
  const { i18n } = useTranslation();
  
  // Finn aktivt språk med useMemo for bedre ytelse
  const activeLanguage = useMemo(() => {
    const defaultLang = availableLanguages[0];
    return availableLanguages.find((item) => item.code === i18n.language) ?? defaultLang;
  }, [i18n.language]);

  // Håndterer språkendring
  const handleLanguageChange = (lang: string): void => {
    i18n.changeLanguage(lang);
  };

  return (
    <Dropdown
      arrowIcon={false}
      inline
      label={
        <div className="inline-flex items-center justify-center rounded border border-gray-200 p-1 text-gray-600 hover:bg-gray-50 hover:text-gray-700 dark:border-gray-600 dark:bg-lgb-on-dark-background dark:text-gray-200 dark:hover:bg-lgb-dark-background dark:hover:text-white active:scale-95 transition-all duration-150">
          <span className="sr-only">Velg språk</span>
          
          {/* Alternativt globus-ikon fra react-icons/hi */}
          <HiOutlineGlobeAlt className="w-6 h-6 text-gray-500 dark:text-gray-300" />
          
          {/* Kode for aktivt språk */}
          <span className="ml-1 text-xs font-medium uppercase">{activeLanguage.code}</span>
        </div>
      }
      className="z-50"
    >
      {availableLanguages.map((language) => (
        <Dropdown.Item
          key={language.code}
          onClick={() => handleLanguageChange(language.code)}
          className="flex items-center gap-2 px-4 py-2 text-sm"
        >
          <span className="font-medium">{language.name}</span>
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
};
