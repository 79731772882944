import { Spinner } from "flowbite-react";
import { FC, ReactNode } from "react";

interface InfoCardProps {
  icon?: ReactNode; // Accepts any React node
  title?: string | number;
  titleClass?: string;
  description?: string;
  descriptionClass?: string;
  containerClass?: string;
  children?: React.ReactNode;
  loading?: boolean;
}

const InfoCard: FC<InfoCardProps> = (props) => {
  return (
    <div className={`${props.containerClass || ""} rounded-lg p-4 w-full flex flex-col h-full`}>
      <div className="flex items-center justify-center mb-1">
        {props.icon && <div className="mr-2 flex-shrink-0">{props.icon}</div>}
        <p className={`${props.titleClass || ""} text-center truncate max-w-full`}>{props.title}</p>
      </div>

      <div className="flex-grow flex items-center justify-center">
        {props.loading ? (
            <Spinner
              size={"sm"}
              color="purple"
              aria-label="Purple spinner example"
            />
        ) : (
          <p className={`${props.descriptionClass || ""} font-bold mt-1`}>
            {props.description}
          </p>
        )}
      </div>
      {props.children && <div className="mt-2">{props.children}</div>}
    </div>
  );
};

export default InfoCard;
