import React, { useMemo, useState } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '../../components';
import { HiChartPie, HiCurrencyDollar, HiTruck, HiLocationMarker, HiCash } from 'react-icons/hi';
import { useUserSession } from '../../context/UserContext';
import { useWorkspace } from '../../context/WorkspaceContext';
import { useOrders } from '../../hooks';
import { useDrivers } from '../../hooks';
import { Navigate } from 'react-router-dom';
import { IOrder, OrderStatus } from '../../types/order/IOrder';
import { IPriceModel } from '../../types/Product';
import { TabButton } from '../../components/atoms/TabButton';

// Registrer Chart.js komponenter
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

// Hjelpefunksjon for å generere testpriser basert på order ID
const getTestPrice = (order: IOrder, index: number = 0) => {
  // Bruk order.id som en seed for å generere konsistente priser
  const seed = order.id ? order.id.charCodeAt(0) + order.id.charCodeAt(order.id.length - 1) : 0;
  // Generer et tall mellom 500 og 2000 basert på seed og index
  return 500 + (Math.abs(seed + index) % 1500);
};

// Hjelpefunksjon for å beregne volum
const calculateVolume = (length: number = 0, width: number = 0, height: number = 0) => {
  return length * width * height;
};

// Hjelpefunksjon for å beregne pris basert på prismodell
const calculatePriceByModel = (item: any, model: string): number => {
  if (!item.pricing) return 0;
  
  switch (model) {
    case IPriceModel.FIXED:
      return item.pricing.pricePerCargoUnit || 0;
    case IPriceModel.VOLUME:
      if (item.pricing.pricePerVolumeUnit) {
        const volume = calculateVolume(item.length, item.width, item.height);
        return volume * item.pricing.pricePerVolumeUnit;
      }
      return 0;
    case IPriceModel.WEIGHT:
      return (item.weight || 0) * (item.pricing.pricePerWeightUnit || 0);
    case IPriceModel.DISTANCE:
      return (item.distance || 0) * (item.pricing.pricePerDistanceUnit || 0);
    default:
      return 0;
  }
};

// Hjelpefunksjon for å beregne faktisk pris basert på cargo
const calculateOrderPrice = (order: IOrder): number => {
  // Hvis ordren har en eksplisitt pris, bruk den
  if (order.price && order.price > 0) {
    return order.price;
  }
  
  // Beregn pris basert på cargo-elementer
  if (order.cargo && order.cargo.length > 0) {
    const cargoTotal = order.cargo.reduce((sum, item) => {
      // Sjekk om item.price finnes og er større enn 0
      if (item.price && item.price > 0) {
        return sum + (item.price * (item.quantity || 1));
      }
      
      // Hvis item.pricing finnes, beregn pris basert på prismodell
      if (item.pricing && item.pricing.priceModel) {
        let calculatedPrice = 0;
        
        // Håndter ulike prismodeller
        if (Array.isArray(item.pricing.priceModel)) {
          // Håndter array av prismodeller
          for (const model of item.pricing.priceModel) {
            calculatedPrice += calculatePriceByModel(item, model);
          }
        } else if (typeof item.pricing.priceModel === 'string') {
          // Håndter enkelt prismodell
          calculatedPrice += calculatePriceByModel(item, item.pricing.priceModel);
        }
        
        // Multipliser med antall
        return sum + (calculatedPrice * (item.quantity || 1));
      }
      
      // Hvis ingen pris er funnet, bruk 0
      return sum;
    }, 0);
    
    // Returner beregnet pris hvis den er større enn 0
    if (cargoTotal > 0) {
      return cargoTotal;
    }
  }
  
  // Fallback til testpris hvis ingen cargo eller priser er tilgjengelige
  return getTestPrice(order);
};

// Konstanter for graffarger
const CHART_COLORS = {
  primary: 'rgb(75, 192, 192)',
  primaryLight: 'rgba(75, 192, 192, 0.5)',
  driverColors: [
    'rgba(255, 99, 132, 0.5)',
    'rgba(54, 162, 235, 0.5)',
    'rgba(255, 206, 86, 0.5)',
    'rgba(75, 192, 192, 0.5)',
    'rgba(153, 102, 255, 0.5)',
    'rgba(255, 159, 64, 0.5)',
    'rgba(199, 199, 199, 0.5)',
    'rgba(83, 102, 255, 0.5)',
    'rgba(78, 252, 154, 0.5)',
    'rgba(255, 99, 255, 0.5)',
  ]
};

const Reports: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'general' | 'revenue'>('general');
  const { t } = useTranslation(['reports', 'common']);
  const { userSession } = useUserSession();
  const { activeWorkspace } = useWorkspace();
  
  // Sett faste datoer for rapportperioden (siste 30 dager)
  const endDate = new Date();
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 30);

  // Hent ordrer og sjåfører for workspace - spesifiser at vi bare vil ha fullførte ordrer
  const workspaceId = activeWorkspace?.workspaceId ?? "";
  const ordersQuery = useOrders(workspaceId, [OrderStatus.Completed]);
  const driversQuery = useDrivers(workspaceId);

  const orders = ordersQuery.data ?? [];
  const drivers = driversQuery.data ?? [];
  
  // Beregn KPI-er - memoiser for å unngå unødvendige rekalkulasjoner
  const { completedOrders, totalDeliveries, totalLocations, totalRevenue } = useMemo(() => {
    const completedOrders = orders.filter(order => order.status === OrderStatus.Completed);
    const totalDeliveries = completedOrders.length;
    
    // Beregn unike lokasjoner (både hente- og leveringsadresser)
    const uniqueLocations = new Set();
    completedOrders.forEach(order => {
      order.route.forEach(stop => {
        uniqueLocations.add(JSON.stringify(stop.location));
      });
    });
    const totalLocations = uniqueLocations.size;

    // Beregn total inntekt med faktiske priser fra cargo
    const totalRevenue = completedOrders.reduce((sum, order) => {
      return sum + calculateOrderPrice(order);
    }, 0);
    
    return { completedOrders, totalDeliveries, totalLocations, totalRevenue };
  }, [orders]);

  // Beregn månedlige data med faktiske priser fra cargo
  const monthlyData = useMemo(() => {
    const data = new Map();
    const now = new Date();
    for (let i = 11; i >= 0; i--) {
      const month = new Date(now.getFullYear(), now.getMonth() - i, 1);
      const monthKey = month.toLocaleString('no', { month: 'short' });
      data.set(monthKey, { orders: 0, revenue: 0 });
    }

    completedOrders.forEach(order => {
      if (order.createdAt) {
        const orderDate = new Date(order.createdAt.seconds * 1000);
        const monthKey = orderDate.toLocaleString('no', { month: 'short' });
        if (data.has(monthKey)) {
          const monthData = data.get(monthKey);
          monthData.orders += 1;
          // Bruk faktisk pris beregnet fra cargo
          monthData.revenue += calculateOrderPrice(order);
        }
      }
    });

    return {
      labels: Array.from(data.keys()),
      orders: Array.from(data.values()).map(d => d.orders),
      revenue: Array.from(data.values()).map(d => d.revenue)
    };
  }, [completedOrders]);

  // Beregn inntekt per sjåfør med faktiske priser fra cargo
  const driverData = useMemo(() => {
    // Opprett et map for sjåfører basert på e-post
    const driverEmailMap = new Map();
    const driverRevenue = new Map();
    
    // Initialiser map med alle sjåfører, både med ID og e-post som nøkkel
    drivers.forEach((driver) => {
      const driverName = `${driver.firstName} ${driver.lastName}`;
      driverRevenue.set(driver.id, { name: driverName, revenue: 0 });
      
      // Legg også til sjåføren med e-post som nøkkel hvis e-post finnes
      if (driver.email) {
        driverEmailMap.set(driver.email, { id: driver.id, name: driverName });
      }
    });

    // Legg til inntekt basert på FULLFØRTE ordrer med sjåfør
    const completedOrdersWithDrivers = completedOrders.filter(order => order.driverId);
    
    completedOrdersWithDrivers.forEach((order) => {
      const orderPrice = calculateOrderPrice(order);
      
      // Sjekk om driverId er en e-post og om vi har en sjåfør med denne e-posten
      if (driverEmailMap.has(order.driverId!)) {
        const driverInfo = driverEmailMap.get(order.driverId!);
        
        // Legg til inntekt for sjåføren basert på ID
        const driverRevenueInfo = driverRevenue.get(driverInfo.id);
        if (driverRevenueInfo) {
          driverRevenueInfo.revenue += orderPrice;
        }
      } 
      // Sjekk om driverId finnes direkte i driverRevenue
      else if (driverRevenue.has(order.driverId!)) {
        const driverInfo = driverRevenue.get(order.driverId!);
        if (driverInfo) {
          driverInfo.revenue += orderPrice;
        }
      }
    });
    
    // Sorter data etter verdi (høyest først) og begrens til topp 5
    // Filtrer ut sjåfører uten tilknyttede ordrer (revenue = 0)
    const sortedEntries = Array.from(driverRevenue.values())
      .filter(entry => entry.revenue > 0) // Bare inkluder sjåfører med inntekt
      .sort((a, b) => b.revenue - a.revenue)
      .slice(0, 5);
    
    // Sjekk om vi har noen data, hvis ikke, legg til testdata
    if (sortedEntries.length === 0) {
      // Legg til testdata hvis ingen faktiske data finnes
      return {
        labels: ['Sjåfør 1', 'Sjåfør 2', 'Sjåfør 3', 'Sjåfør 4', 'Sjåfør 5'],
        data: [2000, 1800, 1500, 1200, 1000]
      };
    }
    
    // Returner faktiske data
    return {
      labels: sortedEntries.map(entry => entry.name),
      data: sortedEntries.map(entry => entry.revenue)
    };
  }, [drivers, completedOrders]);

  // Beregn chart data direkte med useMemo
  const orderData = useMemo(() => ({
    labels: monthlyData.labels,
    datasets: [
      {
        label: t('orders_per_month'),
        data: monthlyData.orders,
        borderColor: CHART_COLORS.primary,
        backgroundColor: CHART_COLORS.primaryLight,
      }
    ],
  }), [monthlyData, t]);

  const revenueData = useMemo(() => ({
    labels: monthlyData.labels,
    datasets: [
      {
        label: t('revenue_per_month'),
        data: monthlyData.revenue,
        borderColor: CHART_COLORS.primary,
        backgroundColor: CHART_COLORS.primaryLight,
      }
    ],
  }), [monthlyData, t]);

  const revenueByDriverData = useMemo(() => ({
    labels: driverData.labels,
    datasets: [
      {
        label: t('revenue_by_driver'),
        data: driverData.data,
        backgroundColor: CHART_COLORS.driverColors,
      }
    ],
  }), [driverData, t]);

  // Konfigurasjon for grafene
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  };

  // Spesiell konfigurasjon for inntektsgrafen
  const revenueOptions = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
    scales: {
      y: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        title: {
          display: true,
          text: t('revenue_per_month'),
        },
      }
    },
  };

  // Redirect hvis ikke admin
  if (!userSession?.user.isAdmin) {
    return <Navigate to="/" />;
  }

  // Redirect hvis ikke workspace er valgt
  if (!activeWorkspace) {
    return <Navigate to="/my-workspaces" />;
  }

  const renderGeneralTab = () => (
    <>
      {/* KPIer */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
          <div className="flex items-center">
            <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-pink-500 to-voilet-500 rounded-lg">
              <HiTruck className="w-6 h-6" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
                {t('total_deliveries')}
              </p>
              <p className="text-2xl font-bold text-gray-900 dark:text-white">
                {totalDeliveries}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
          <div className="flex items-center">
            <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-green-500 to-teal-500 rounded-lg">
              <HiLocationMarker className="w-6 h-6" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
                {t('locations_visited')}
              </p>
              <p className="text-2xl font-bold text-gray-900 dark:text-white">
                {totalLocations}
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
          <div className="flex items-center">
            <div className="inline-flex flex-shrink-0 justify-center items-center w-12 h-12 text-white bg-gradient-to-br from-blue-500 to-purple-500 rounded-lg">
              <HiCash className="w-6 h-6" />
            </div>
            <div className="ml-4">
              <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
                {t('total_revenue')}
              </p>
              <p className="text-2xl font-bold text-gray-900 dark:text-white">
                kr {totalRevenue.toLocaleString('no')}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4 dark:text-white">{t('orders_over_time')}</h2>
          <Line data={orderData} options={options} />
        </div>
      </div>
    </>
  );

  const renderRevenueTab = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4 dark:text-white">{t('revenue_over_time')}</h2>
        <Line data={revenueData} options={revenueOptions} />
      </div>

      <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4 dark:text-white">{t('revenue_by_driver')}</h2>
        <Bar data={revenueByDriverData} options={options} />
      </div>
    </div>
  );

  return (
    <PageLayout>
      <div className="p-4">
        {/* Header */}
        <div className="mb-6">
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            {t('title')} - {activeWorkspace.workspaceName}
          </h1>
          <p className="text-lgb-grey-600 text-base pt-2 pb-4 dark:text-lgb-grey-200">
            {t('description')}
          </p>
        </div>

        {/* Tabs */}
        <div className="border-b border-gray-200 dark:border-gray-700">
          <div className="px-4">
            <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
              <TabButton
                label={t('general')}
                icon={<HiChartPie className="w-5 h-5" />}
                isSelected={activeTab === 'general'}
                onClick={() => setActiveTab('general')}
              />
              <TabButton
                label={t('revenue')}
                icon={<HiCurrencyDollar className="w-5 h-5" />}
                isSelected={activeTab === 'revenue'}
                onClick={() => setActiveTab('revenue')}
              />
            </ul>
          </div>
        </div>

        {/* Tab Content */}
        <div className="mt-6">
          {activeTab === 'general' ? renderGeneralTab() : renderRevenueTab()}
        </div>
      </div>
    </PageLayout>
  );
};

export default Reports; 