/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState } from "react";
import { TextInput, Textarea, Select } from "flowbite-react";
import { HiOutlinePlus } from "react-icons/hi";
import { StandardForm, Dialog } from "../..";
import { IParameter, ParameterType } from "../../../types";
import { useTranslation } from "react-i18next";
import { Parameter } from "../../../models";

export interface ParameterModalProp {
  data: IParameter;
  isShowing: boolean;
  onConfirm: (data: IParameter) => void;
  onCancel: () => void;
  predefinedType?: ParameterType;
}

export const ParameterForm: FC<ParameterModalProp> = function (
  props: ParameterModalProp,
) {
  const { t } = useTranslation(["common", "configuration", "modals", "products", "vehicles"]);

  const parameterOptions = [
    {
      value: ParameterType.VehicleType,
      label: t("configuration:parameter_types.vehicle"),
    },
    {
      value: ParameterType.ProductType,
      label: t("configuration:parameter_types.product"),
    },
  ];

  const [isNew] = useState(Parameter.isNew(props.data));

  const typeValue = props.predefinedType || props.data.type;

  const formFields = [
    ...(props.predefinedType ? [] : [
      {
        id: "type",
        label: t("configuration:fields.type"),
        input: (
          <Select
            id="type"
            key="type"
            name="type"
            defaultValue={props.data.type}
            required={true}
          >
            {parameterOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        ),
      }
    ]),
    {
      id: "code",
      label: t("configuration:fields.code"),
      input: (
        <TextInput
          id="code"
          key="code"
          name="code"
          placeholder={t("configuration:parameter_example")}
          defaultValue={props.data.code}
          type="text"
          required={true}
        />
      ),
    },
    {
      id: "description",
      label: t("configuration:fields.description_title"),
      grid_style: "col-span-2",
      input: (
        <Textarea
          id="description"
          key="description"
          name="description"
          className="text-sm"
          placeholder={t("configuration:fields.description")}
          defaultValue={props.data.description ?? ""}
        />
      ),
    },
  ];

  const handleConfirm = (data: any) => {
    if (props.predefinedType) {
      data.type = props.predefinedType;
    }
    props.onConfirm(data);
  };

  // Bestem knappetekst basert på type
  let buttonText = t("modals:button_texts.resource_type");
  if (props.predefinedType === ParameterType.ProductType) {
    buttonText = isNew ? t("products:categories.add_category") : t("products:categories.edit_category");
  } else if (props.predefinedType === ParameterType.VehicleType) {
    buttonText = isNew ? t("vehicles:categories.add_category") : t("vehicles:categories.edit_category");
  }

  // Bestem beskrivelse basert på type
  let description = t("modals:descriptions.resource_types");
  if (props.predefinedType === ParameterType.ProductType) {
    description = t("products:categories.category_description");
  } else if (props.predefinedType === ParameterType.VehicleType) {
    description = t("vehicles:categories.category_description");
  }

  return (
    <StandardForm
      description={description}
      showHeader={false}
      fields={formFields}
      onConfirm={handleConfirm}
      onCancel={props.onCancel}
      buttonConfirmText={buttonText}
      buttonConfirmPrependIcon={
        isNew ? <HiOutlinePlus className="mr-2 h-4 w-4" /> : null
      }
      buttonCloseText={t("common:close")}
    />
  );
};

export const ParameterModal: FC<ParameterModalProp> = function (
  props: ParameterModalProp,
) {
  const { t } = useTranslation(["common", "configuration", "products", "vehicles"]);

  // Bestem tittel basert på type
  let title = Parameter.isNew(props.data)
    ? t("configuration:create_parameter")
    : t("configuration:edit_parameter");
    
  if (props.predefinedType === ParameterType.ProductType) {
    title = Parameter.isNew(props.data)
      ? t("products:categories.create_category")
      : t("products:categories.edit_category");
  } else if (props.predefinedType === ParameterType.VehicleType) {
    title = Parameter.isNew(props.data)
      ? t("vehicles:categories.create_category")
      : t("vehicles:categories.edit_category");
  }

  return (
    <Dialog
      title={title}
      content={<ParameterForm {...props} />}
      show={props.isShowing}
      toggleModal={props.onCancel}
      hideButtons={true}
    />
  );
};
