import { FC, ReactNode } from "react";
import { Button } from "flowbite-react";
import { HiOutlinePlus } from "react-icons/hi";

export type ButtonVariant = 'primary' | 'secondary' | 'add' | 'danger' | 'outline' | 'success';
export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg';
export type IconPosition = 'left' | 'right' | 'only';

interface StyledButtonProps {
  onClick: () => void;
  children?: ReactNode;
  variant?: ButtonVariant;
  icon?: ReactNode;
  iconPosition?: IconPosition;
  disabled?: boolean;
  className?: string;
  size?: ButtonSize;
  type?: 'button' | 'submit' | 'reset';
}

export const StyledButton: FC<StyledButtonProps> = ({ 
  onClick, 
  children, 
  variant = 'primary',
  icon,
  iconPosition = 'left',
  disabled = false,
  className = '',
  size = 'md',
  type = 'button'
}) => {
  // Bestem størrelse basert på size prop
  const getSizeClasses = () => {
    switch (size) {
      case 'xs':
        return 'text-xs h-[32px]';
      case 'sm':
        return 'text-sm h-[38px]';
      case 'md':
        return 'text-sm h-[42px]';
      case 'lg':
        return 'text-base h-[48px]';
      default:
        return 'text-sm h-[42px]';
    }
  };

  // Bestem utseende basert på variant prop
  const getVariantProps = () => {
    switch (variant) {
      case 'primary':
        return {
          color: 'blue',
          className: `${getSizeClasses()} ${className}`
        };
      case 'secondary':
        return {
          color: 'light',
          className: `${getSizeClasses()} ${className}`
        };
      case 'add':
        return {
          color: 'light',
          className: `${getSizeClasses()} ${className}`
        };
      case 'danger':
        return {
          color: 'failure',
          className: `${getSizeClasses()} ${className}`
        };
      case 'outline':
        return {
          color: 'light',
          className: `border border-gray-300 ${getSizeClasses()} ${className}`
        };
      case 'success':
        return {
          color: 'custom',
          className: `bg-lgb-green-400 hover:bg-lgb-green-500 text-white ${getSizeClasses()} ${className}`
        };
      default:
        return {
          color: 'blue',
          className: `${getSizeClasses()} ${className}`
        };
    }
  };

  const variantProps = getVariantProps();
  
  // Bestem ikon basert på variant og icon prop
  const getIcon = () => {
    if (icon) {
      return icon;
    }
    
    if (variant === 'add') {
      return <HiOutlinePlus className="h-5 w-5" />;
    }
    
    return null;
  };

  // Render knappen med riktig ikonplassering
  const renderButtonContent = () => {
    const iconElement = getIcon();
    
    if (iconPosition === 'only' && iconElement) {
      return iconElement;
    }
    
    if (iconPosition === 'left' && iconElement) {
      return (
        <>
          <span className="mr-1">{iconElement}</span>
          {children}
        </>
      );
    }
    
    if (iconPosition === 'right' && iconElement) {
      return (
        <>
          {children}
          <span className="ml-1">{iconElement}</span>
        </>
      );
    }
    
    return children;
  };

  // Bestem padding basert på størrelse og om det er et ikon
  const getPaddingClasses = () => {
    const hasIcon = getIcon() !== null;
    const isIconOnly = iconPosition === 'only' && hasIcon;
    
    if (isIconOnly) {
      return '!px-1.5 !py-0';
    }
    
    switch (size) {
      case 'xs':
        return '!px-1.5 !py-0';
      case 'sm':
        return '!px-2 !py-0';
      case 'md':
        return '!px-2 !py-0';
      case 'lg':
        return '!px-2.5 !py-0';
      default:
        return '!px-2 !py-0';
    }
  };

  return (
    <Button
      color={variantProps.color as any}
      onClick={onClick}
      disabled={disabled}
      className={`inline-flex items-center justify-center whitespace-nowrap focus:ring-0 focus:outline-none w-auto dark:border-0 ${getPaddingClasses()} ${variantProps.className}`}
      type={type}
      pill={false}
    >
      {renderButtonContent()}
    </Button>
  );
}; 