/**
 * Formatterer en dato til en lesbar streng
 * @param date Dato som skal formateres
 * @returns Formatert datostreng
 */
export const formatDate = (date: Date | string): string => {
  if (!date) return '';
  
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  
  return dateObj.toLocaleDateString('no-NO', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

/**
 * Formatterer en dato med tid til en lesbar streng
 * @param date Dato som skal formateres
 * @returns Formatert dato og tid-streng
 */
export const formatDateTime = (date: Date | string): string => {
  if (!date) return '';
  
  const dateObj = typeof date === 'string' ? new Date(date) : date;
  
  return dateObj.toLocaleDateString('no-NO', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

/**
 * Formatterer en tid til en lesbar streng
 * @param time Tid som skal formateres (HH:MM format)
 * @returns Formatert tid-streng
 */
export const formatTime = (time: string): string => {
  if (!time) return '';
  
  return time;
}; 