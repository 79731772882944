import { FC, ReactNode } from 'react';

export interface TabButtonProps {
  label: string;
  isSelected: boolean;
  onClick: () => void;
  icon?: ReactNode;
  className?: string;
}

export const TabButton: FC<TabButtonProps> = ({ label, isSelected, onClick, icon, className = '' }) => (
  <li className={`mr-2 ${className}`}>
    <button
      className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg ${
        isSelected 
          ? 'text-purple-600 border-purple-600 dark:text-purple-500 dark:border-purple-500 bg-purple-50 dark:bg-purple-900/20 font-semibold' 
          : 'border-transparent text-gray-500 dark:text-gray-400 hover:text-gray-700 hover:border-gray-300 dark:hover:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800/30'
      }`}
      onClick={onClick}
      aria-current={isSelected ? "page" : undefined}
    >
      {icon && <span className="mr-2">{icon}</span>}
      <span>{label}</span>
    </button>
  </li>
); 