import { FC, useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Dropdown, Button, ToggleSwitch, Modal, TextInput } from "flowbite-react";
import { HiOutlineChevronRight, HiOutlineChevronDown, HiOutlinePlus, HiOutlineTrash, HiSearch } from "react-icons/hi";
import { Product, Parameter } from "../../../models";
import { getProductTypeAsArray } from "../../../utils/productHelpers";
import { EmptyState } from "../../molecules/EmptyState";
import { emptyProductIllustration } from "../../atoms/Icons/illustrations";

interface ProductCategoriesProps {
  products: Product[];
  categories: Parameter[];
  isLoading: boolean;
  onSelectCategory: (categoryId: string | null) => void;
  onSelectProduct: (product: Product) => void;
  selectedCategoryId: string | null;
  onAddCategory?: () => void;
  onDeleteCategory?: (categoryId: string) => void;
}

// Separate component for category tab to improve readability and reusability
interface CategoryTabProps {
  id: string;
  label: string;
  count: number;
  isSelected: boolean;
  onClick: () => void;
}

const CategoryTab: FC<CategoryTabProps> = ({ id, label, count, isSelected, onClick }) => {
  return (
    <li className="mr-2 relative">
      <div className="flex items-center">
        <button
          className={`inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg ${
            isSelected 
              ? 'text-purple-600 border-purple-600 dark:text-purple-500 dark:border-purple-500 bg-purple-50 dark:bg-purple-900/20 font-semibold' 
              : 'border-transparent text-gray-500 dark:text-gray-400 hover:text-gray-700 hover:border-gray-300 dark:hover:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-800/30'
          }`}
          onClick={onClick}
          aria-current={isSelected ? "page" : undefined}
        >
          <span>{label}</span>
          <Badge className={`ml-2 ${isSelected ? 'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300' : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300'}`}>
            {count}
          </Badge>
        </button>
      </div>
    </li>
  );
};

// Separate component for product card to improve readability and reusability
interface ProductCardProps {
  product: Product;
  categories: Parameter[];
  onClick: () => void;
  t: any;
}

const ProductCard: FC<ProductCardProps> = ({ product, categories, onClick, t }) => {
  // Memoize category badges to avoid unnecessary re-renders
  const categoryBadges = useMemo(() => {
    const productTypes = getProductTypeAsArray(product);
    
    if (productTypes.length === 0) {
      return (
        <Badge key="uncategorized" color="gray" className="text-xs">
          {t("products:categories.uncategorized")}
        </Badge>
      );
    }
    
    return productTypes.map((typeId) => {
      const category = categories.find(c => c.id === typeId);
      return category ? (
        <Badge key={typeId} color="purple" className="text-xs">
          {category.code}
        </Badge>
      ) : null;
    });
  }, [product, categories, t]);

  return (
    <div
      className="bg-white dark:bg-darkBlue hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors cursor-pointer border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm p-4"
      onClick={onClick}
    >
      <div className="flex justify-between items-start">
        <div className="w-full">
          <h5 className="text-lg font-medium tracking-tight text-gray-900 dark:text-white line-clamp-1">
            {product.descriptionShort}
          </h5>
          <p className="text-sm text-gray-500 dark:text-gray-400 mt-1 line-clamp-2">
            {product.description || t("products:no_description")}
          </p>
          
          <div className="flex flex-wrap gap-2 mt-3">
            {categoryBadges}
          </div>
        </div>
        <HiOutlineChevronRight className="h-5 w-5 text-gray-400 flex-shrink-0 ml-2" />
      </div>
    </div>
  );
};

// Mobile category dropdown component
interface MobileCategoryDropdownProps {
  categories: Parameter[];
  productsByCategory: Record<string, Product[]>;
  selectedCategoryId: string | null;
  onSelectCategory: (categoryId: string | null) => void;
  allProductsCount: number;
  t: any;
  onAddCategory?: () => void;
  showEmptyCategories: boolean;
  onToggleEmptyCategories: () => void;
}

const MobileCategoryDropdown: FC<MobileCategoryDropdownProps> = ({
  categories,
  productsByCategory,
  selectedCategoryId,
  onSelectCategory,
  allProductsCount,
  t,
  onAddCategory,
  showEmptyCategories,
  onToggleEmptyCategories
}) => {
  // Get sorted categories for dropdown
  const sortedCategories = useMemo(() => 
    [...categories].sort((a, b) => a.code.localeCompare(b.code)),
    [categories]
  );

  // Get current category label
  const currentCategoryLabel = useMemo(() => {
    if (selectedCategoryId === null) {
      return t("products:categories.all_products");
    } else if (selectedCategoryId === "uncategorized") {
      return t("products:categories.uncategorized");
    } else {
      const category = categories.find(c => c.id === selectedCategoryId);
      return category ? category.code : t("products:categories.all_products");
    }
  }, [selectedCategoryId, categories, t]);

  return (
    <div className="w-full mb-4">
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <Dropdown
            label={
              <div className="flex items-center justify-between w-full">
                <span>{currentCategoryLabel}</span>
                <Badge className={`ml-2 ${selectedCategoryId ? 'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300' : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300'}`}>
                  {selectedCategoryId === null 
                    ? allProductsCount 
                    : productsByCategory[selectedCategoryId]?.length || 0}
                </Badge>
                <HiOutlineChevronDown className="ml-2 h-5 w-5" />
              </div>
            }
            color="light"
            className="w-full"
          >
            <Dropdown.Item onClick={() => onSelectCategory(null)}>
              <div className="flex items-center justify-between w-full">
                <span>{t("products:categories.all_products")}</span>
                <Badge className={selectedCategoryId === null ? 'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300' : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300'}>
                  {allProductsCount}
                </Badge>
              </div>
            </Dropdown.Item>
            
            {sortedCategories.map((category) => {
              const count = productsByCategory[category.id]?.length || 0;
              if (count === 0 && !showEmptyCategories) return null;
              
              return (
                <Dropdown.Item 
                  key={category.id} 
                  onClick={() => onSelectCategory(category.id)}
                >
                  <div className="flex items-center justify-between w-full">
                    <span>{category.code}</span>
                    <Badge className={selectedCategoryId === category.id ? 'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300' : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300'}>
                      {count}
                    </Badge>
                  </div>
                </Dropdown.Item>
              );
            })}
            
            {productsByCategory["uncategorized"] && productsByCategory["uncategorized"].length > 0 && (
              <Dropdown.Item onClick={() => onSelectCategory("uncategorized")}>
                <div className="flex items-center justify-between w-full">
                  <span>{t("products:categories.uncategorized")}</span>
                  <Badge className={selectedCategoryId === "uncategorized" ? 'bg-purple-100 text-purple-800 dark:bg-purple-900 dark:text-purple-300' : 'bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300'}>
                    {productsByCategory["uncategorized"].length}
                  </Badge>
                </div>
              </Dropdown.Item>
            )}
          </Dropdown>
          
          {onAddCategory && (
            <Button 
              color="light" 
              size="sm" 
              onClick={onAddCategory}
              className="flex-shrink-0"
              title={t("products:categories.add_category")}
            >
              <HiOutlinePlus className="h-5 w-5" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export const ProductCategories: FC<ProductCategoriesProps> = ({
  products,
  categories,
  isLoading,
  onSelectCategory,
  onSelectProduct,
  selectedCategoryId,
  onAddCategory,
  onDeleteCategory
}) => {
  const { t } = useTranslation(["common", "products"]);
  const [productsByCategory, setProductsByCategory] = useState<Record<string, Product[]>>({});
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 768);
  const [showEmptyCategories, setShowEmptyCategories] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Check for mobile view on resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Group products by category - memoized to avoid unnecessary recalculations
  useEffect(() => {
    if (!isLoading && products.length > 0) {
      const groupedProducts: Record<string, Product[]> = {};
      const uncategorized: Product[] = [];

      // Initialize categories with empty arrays
      categories.forEach((category) => {
        groupedProducts[category.id] = [];
      });

      // Group products by category
      products.forEach((product) => {
        const productTypeArray = getProductTypeAsArray(product);
        
        if (productTypeArray.length > 0) {
          const categoryId = productTypeArray[0];
          if (groupedProducts[categoryId]) {
            groupedProducts[categoryId].push(product);
          } else {
            uncategorized.push(product);
          }
        } else {
          uncategorized.push(product);
        }
      });

      // Add uncategorized products if any
      if (uncategorized.length > 0) {
        groupedProducts["uncategorized"] = uncategorized;
      }

      setProductsByCategory(groupedProducts);
      setAllProducts(products);
    } else if (!isLoading && products.length === 0) {
      // Reset state when there are no products
      setProductsByCategory({});
      setAllProducts([]);
    }
  }, [products, categories, isLoading]);

  // Sort categories alphabetically
  const sortedCategories = useMemo(() => 
    [...categories].sort((a, b) => a.code.localeCompare(b.code)),
    [categories]
  );

  // Filter products based on search term and selected category
  const filteredProducts = useMemo(() => {
    let products = selectedCategoryId === null
      ? allProducts
      : selectedCategoryId === "uncategorized"
        ? productsByCategory["uncategorized"] || []
        : productsByCategory[selectedCategoryId] || [];
    
    if (debouncedSearchTerm.trim() !== "") {
      const searchLower = debouncedSearchTerm.toLowerCase();
      products = products.filter(product => 
        product.descriptionShort.toLowerCase().includes(searchLower) || 
        (product.description && product.description.toLowerCase().includes(searchLower))
      );
    }
    
    return products;
  }, [selectedCategoryId, allProducts, productsByCategory, debouncedSearchTerm]);

  // Empty placeholder for no products in category
  const emptyProductsPlaceholder = useMemo(() => {
    const selectedCategory = categories.find(c => c.id === selectedCategoryId);
    
    return (
      <EmptyState
        icon={<div className="mx-auto w-16 h-16 text-purple-600 mb-2">
          <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16m-7 6h7" />
          </svg>
        </div>}
        title={t("products:categories.empty_category.title")}
        description={
          selectedCategory 
            ? t("products:categories.empty_category.description", { category: selectedCategory.code }) 
            : t("products:categories.empty_category.description_generic")
        }
        buttonText={t("products:categories.empty_category.button_text")}
        buttonClick={() => {
          // Create a new product with the category pre-filled
          const newProduct = Product.default();
          if (selectedCategoryId && selectedCategoryId !== "uncategorized") {
            newProduct.productType = [selectedCategoryId];
          }
          onSelectProduct(newProduct);
        }}
      />
    );
  }, [t, selectedCategoryId, categories, onSelectProduct]);

  // Prepare loading skeleton
  const loadingSkeleton = useMemo(() => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 animate-pulse">
      {[1, 2, 3, 4, 5, 6].map((i) => (
        <div key={i} className="h-24 bg-gray-200 dark:bg-gray-700 rounded-lg"></div>
      ))}
    </div>
  ), []);

  // Get selected category name for delete modal
  const selectedCategoryName = useMemo(() => {
    if (!selectedCategoryId || selectedCategoryId === "uncategorized") return "";
    const category = categories.find(c => c.id === selectedCategoryId);
    return category ? category.code : "";
  }, [selectedCategoryId, categories]);

  // Check if selected category can be deleted
  const canDeleteSelectedCategory = useMemo(() => {
    return selectedCategoryId !== null && 
           selectedCategoryId !== "uncategorized" && 
           selectedCategoryId !== "all";
  }, [selectedCategoryId]);

  // Handle delete category
  const handleDeleteCategory = () => {
    if (canDeleteSelectedCategory && onDeleteCategory) {
      onDeleteCategory(selectedCategoryId!);
      setShowDeleteModal(false);
    }
  };

  // Handle search input with debounce
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    
    // Clear any existing timeout
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    
    // Set a new timeout
    searchTimeoutRef.current = setTimeout(() => {
      setDebouncedSearchTerm(value);
    }, 300); // 300ms delay
  };

  // Clear timeout on unmount
  useEffect(() => {
    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, []);

  // Handle toggle empty categories
  const onToggleEmptyCategories = useCallback(() => {
    const newShowEmptyValue = !showEmptyCategories;
    setShowEmptyCategories(newShowEmptyValue);
    
    // Hvis vi slår av visning av tomme kategorier og den valgte kategorien er tom,
    // bytter vi tilbake til "alle kategorier"
    if (!newShowEmptyValue && selectedCategoryId !== null && selectedCategoryId !== "all") {
      const categoryProducts = productsByCategory[selectedCategoryId]?.length || 0;
      if (categoryProducts === 0) {
        onSelectCategory(null);
      }
    }
  }, [showEmptyCategories, selectedCategoryId, productsByCategory, onSelectCategory]);

  // Render content based on loading state
  const content = useMemo(() => {
    if (isLoading) {
      return loadingSkeleton;
    }

    const hasProducts = allProducts.length > 0;
    const hasCategories = categories.length > 0;

    if (!hasProducts && !isLoading) {
      return (
        <EmptyState
          icon={emptyProductIllustration}
          title={t("products:no_products.title")}
          description={t("products:no_products.description")}
          buttonText={t("products:no_products.button_text")}
          buttonClick={() => onSelectProduct(Product.default())}
        />
      );
    }

    return (
      <div className="space-y-6">
        {/* Categories Navigation - Tabs for desktop, Dropdown for mobile */}
        {isMobile ? (
          <MobileCategoryDropdown
            categories={categories}
            productsByCategory={productsByCategory}
            selectedCategoryId={selectedCategoryId}
            onSelectCategory={onSelectCategory}
            allProductsCount={allProducts.length}
            t={t}
            onAddCategory={onAddCategory}
            showEmptyCategories={showEmptyCategories}
            onToggleEmptyCategories={onToggleEmptyCategories}
          />
        ) : (
          <div className="flex flex-col gap-2">
            {/* Toggle for showing empty categories - moved outside the scrollable area */}
            <div className="flex items-center border-b border-gray-200 dark:border-gray-700 overflow-x-auto pb-1">
              <ul className="flex flex-nowrap -mb-px text-sm font-medium text-center min-w-max">
                <CategoryTab
                  id="all"
                  label={t("products:categories.all_products")}
                  count={allProducts.length}
                  isSelected={selectedCategoryId === null}
                  onClick={() => onSelectCategory(null)}
                />
                
                {sortedCategories.map((category) => {
                  const count = productsByCategory[category.id]?.length || 0;
                  if (count === 0 && !showEmptyCategories) return null;
                  
                  return (
                    <CategoryTab
                      key={category.id}
                      id={category.id}
                      label={category.code}
                      count={count}
                      isSelected={selectedCategoryId === category.id}
                      onClick={() => onSelectCategory(category.id)}
                    />
                  );
                })}
                
                {productsByCategory["uncategorized"] && productsByCategory["uncategorized"].length > 0 && (
                  <CategoryTab
                    id="uncategorized"
                    label={t("products:categories.uncategorized")}
                    count={productsByCategory["uncategorized"].length}
                    isSelected={selectedCategoryId === "uncategorized"}
                    onClick={() => onSelectCategory("uncategorized")}
                  />
                )}
              </ul>
            </div>
          </div>
        )}

        {/* Search bar and action buttons */}
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-2">
          <div className="w-full sm:w-1/2">
            <TextInput
              id="productSearch"
              type="text"
              icon={HiSearch}
              placeholder={t("common:search")}
              value={searchTerm}
              onChange={handleSearchChange}
              className="w-full"
              aria-label={t("common:search")}
            />
          </div>
          <div className={`flex items-center gap-2 ${filteredProducts.length === 0 ? 'ml-auto' : ''}`}>
            <div className="flex items-center gap-2 mr-2">
              <span className="text-sm text-gray-500 dark:text-gray-400">
                {t("products:categories.show_empty")}
              </span>
              <div 
                className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none cursor-pointer ${
                  showEmptyCategories ? 'bg-lgb-blue-300' : 'bg-gray-200 dark:bg-gray-700'
                }`}
                onClick={onToggleEmptyCategories}
                role="switch"
                aria-checked={showEmptyCategories}
                tabIndex={0}
                aria-label={t("products:categories.show_empty")}
              >
                <span 
                  className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
                    showEmptyCategories ? 'translate-x-6' : 'translate-x-1'
                  }`} 
                />
              </div>
            </div>
            
            {onAddCategory && (
              <Button 
                color="light" 
                size="sm" 
                onClick={onAddCategory}
                title={t("products:categories.add_category")}
                aria-label={t("products:categories.add_category")}
              >
                <HiOutlinePlus className="h-5 w-5" />
              </Button>
            )}
            
            {onDeleteCategory && (
              <Button 
                color="light" 
                size="sm" 
                onClick={() => setShowDeleteModal(true)}
                title={t("products:categories.delete_category")}
                disabled={!canDeleteSelectedCategory}
                aria-label={t("products:categories.delete_category")}
              >
                <HiOutlineTrash className="h-5 w-5 text-red-600 dark:text-red-400" />
              </Button>
            )}
          </div>
        </div>

        {/* Delete Category Modal */}
        <Modal
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          size="md"
          aria-labelledby="delete-category-modal"
          theme={{
            root: {
              base: "fixed top-0 right-0 left-0 z-50 h-modal h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full",
              show: {
                on: "flex bg-gray-900 bg-opacity-70 dark:bg-opacity-90",
                off: "hidden"
              }
            },
            content: {
              base: "relative h-full w-full p-2 sm:p-4 md:h-auto",
              inner: "relative rounded-lg bg-white shadow-lg dark:bg-darkBlue border dark:border-gray-700 flex flex-col max-h-[95vh] sm:max-h-[90vh] shadow-xl dark:shadow-gray-900/50"
            }
          }}
        >
          <Modal.Header id="delete-category-modal" className="border-b border-gray-200 dark:border-gray-700">
            {t("products:categories.delete_modal.title")}
          </Modal.Header>
          <Modal.Body className="dark:bg-darkBlue">
            <div className="space-y-6">
              <p className="text-base leading-relaxed text-gray-600 dark:text-gray-300">
                {t("products:categories.delete_modal.description", { category: selectedCategoryName })}
              </p>
              <div className="mt-2 p-3 bg-gray-100 dark:bg-lgb-modal-bg rounded-lg">
                <p className="text-center font-medium text-gray-800 dark:text-white">
                  {selectedCategoryName}
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-t border-gray-200 dark:border-gray-700 dark:bg-darkBlue">
            <Button
              color="gray"
              onClick={() => setShowDeleteModal(false)}
              size="sm"
            >
              {t("common:cancel")}
            </Button>
            <Button
              color="failure"
              onClick={handleDeleteCategory}
              size="sm"
            >
              {t("common:delete")}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Products Grid - Adjust columns based on screen size */}
        <div className="mt-4">
          {filteredProducts.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {filteredProducts.map((product) => (
                <ProductCard
                  key={product.id}
                  product={product}
                  categories={categories}
                  onClick={() => onSelectProduct(product)}
                  t={t}
                />
              ))}
            </div>
          ) : debouncedSearchTerm.trim() !== "" ? (
            // Viser en melding når søket ikke gir resultater
            <EmptyState
              icon={<div className="mx-auto w-16 h-16 text-purple-600 mb-2">
                <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                </svg>
              </div>}
              title={t("products:search.no_results")}
              description={t("common:no_results_for_search", { search: debouncedSearchTerm })}
              buttonText={t("common:clear_search")}
              buttonClick={() => {
                // Nullstiller søket
                setSearchTerm("");
                setDebouncedSearchTerm("");
              }}
              secondaryButtonText={t("products:categories.empty_category.button_text")}
              secondaryButtonClick={() => {
                // Nullstiller søket og lar brukeren legge til et nytt produkt
                setSearchTerm("");
                setDebouncedSearchTerm("");
                onSelectProduct(Product.default());
              }}
            />
          ) : (
            // Viser melding for tom kategori (hvis vi er i en kategori)
            selectedCategoryId !== null && emptyProductsPlaceholder
          )}
        </div>
      </div>
    );
  }, [
    isLoading, 
    loadingSkeleton, 
    isMobile, 
    categories, 
    productsByCategory, 
    selectedCategoryId, 
    onSelectCategory, 
    allProducts, 
    t, 
    sortedCategories, 
    filteredProducts, 
    onSelectProduct, 
    onAddCategory, 
    onDeleteCategory, 
    showEmptyCategories, 
    showDeleteModal, 
    selectedCategoryName, 
    canDeleteSelectedCategory, 
    handleDeleteCategory,
    searchTerm,
    emptyProductsPlaceholder,
    onToggleEmptyCategories,
    debouncedSearchTerm
  ]);

  return content;
}; 