/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  useState,
  useEffect,
  forwardRef,
  ForwardedRef,
  useImperativeHandle,
} from "react";
import { useTranslation } from "react-i18next";
import {
  DropdownSearch,
  DropdownSearchRadioItem,
} from "../../../../components";
import { Driver } from "../../../../models";
import { useWorkspace } from "../../../../context/WorkspaceContext";
import { useDrivers } from "../../../../hooks";
import { TableSkeletonsByAmount } from "../../../../components/atoms/Skeleton/TableSkeleton";
import { IOrder } from "../../../../types";

export interface DriverSelectionProps {
  order: IOrder;
  comment?: string;
  emitOnClick?: boolean;
  validationFailed?: boolean;
  update: (driverId: string) => void;
  updateComment?: (comment: string) => void;
}
export interface DriverSelectionRef {
  updateDriver: () => void;
}
export const DriverSelection = forwardRef(
  (props: DriverSelectionProps, ref: ForwardedRef<DriverSelectionRef>) => {
    const { t } = useTranslation("orders");
    const [current, setCurrent] = useState(props.order.driverId);
    const { activeWorkspace } = useWorkspace();
    const [searchText, setSearchText] = useState<string>("");
    const [searchResults, setSearchResults] = useState([] as Driver[]);

    const query = useDrivers(activeWorkspace?.workspaceId ?? "");
    const drivers = query.data;

    /**
     * Exposed to the parent so it can be called through ref.
     */
    useImperativeHandle(ref, () => ({
      updateDriver,
    }));

    function updateDriver() {
      props.update(current || "");
    }

    useEffect(() => {
      if (searchText.length) {
        setSearchResults(
          drivers?.filter((x) =>
            (x.firstName + x.lastName)
              ?.toLowerCase()
              .includes(searchText.toLowerCase()),
          ) || [],
        );
      } else {
        setSearchResults(drivers || []);
      }
    }, [searchText, drivers]);

    const selectDriver = (val: Driver) => {
      // Check if the selected driver has a valid companyId
      if (!val.companyId) {
        console.error("Selected driver has no companyId. Cannot proceed.");
        alert(t("create.driver_selection.missing_companyId")); // Show user feedback
        return;
      }
      setCurrent(val.email);

      // In order creation we emit this immediately when selecting.
      // In order details editing we perform the action when parent click 'Save'.
      if (props.emitOnClick) {
        props.update(val.email || "");
      }
    };

    return (
      <div className="bg-white dark:bg-lgb-on-dark-background rounded-lg p-6 border border-lgb-grey-200 dark:border-[#2c3353]">
        <h2 className="text-xl font-medium mb-2 dark:text-white">
          {t("create.driver_selection.title")}
        </h2>
        
        <p className="text-lgb-grey-600 dark:text-lgb-grey-400 mb-4">
          {t("create.driver_selection.step_description")}
        </p>
        
        <div className="mb-6">
          <DropdownSearch
            placeholder={t("create.driver_selection.search_placeholder")}
            inputChanged={(val) => setSearchText(val)}
            showResults
            plain
          >
            <div>
              {query.isLoading && <TableSkeletonsByAmount quantity={6} />}
              {!query.isLoading &&
                searchResults.map((driver: Driver) => {
                  return (
                    <DropdownSearchRadioItem
                      key={driver.id}
                      selected={current === driver.email}
                      buttonClickEvent={() => selectDriver(driver)}
                      id={driver.id}
                      title={driver.firstName + " " + driver.lastName}
                    ></DropdownSearchRadioItem>
                  );
                })}
            </div>
          </DropdownSearch>
        </div>
      </div>
    );
  },
);

export default DriverSelection;
