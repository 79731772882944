/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from "flowbite-react";
import { type FC } from "react";
import { HiOutlinePlus, HiOutlineX } from "react-icons/hi";

export interface EmptyStateProps {
  icon?: React.ReactNode;
  title: string;
  description?: string;
  buttonText?: string;
  buttonClick?: () => void;
  secondaryButtonText?: string;
  secondaryButtonClick?: () => void;
  buttonIcon?: "plus" | "x" | "none";
  buttonColor?: "primary" | "gray" | "red";
}

export const EmptyState: FC<EmptyStateProps> = ({
  icon,
  title,
  description,
  buttonText,
  buttonClick,
  secondaryButtonText,
  secondaryButtonClick,
  buttonIcon = "none",
  buttonColor = "primary"
}) => {
  const getButtonIcon = () => {
    switch (buttonIcon) {
      case "plus":
        return <HiOutlinePlus className="mr-2 h-5 w-5" />;
      case "x":
        return <HiOutlineX className="mr-2 h-5 w-5" />;
      case "none":
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-full p-6 text-center bg-white dark:bg-darkBlue border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm">
      {icon && <div className="mb-4">{icon}</div>}
      <h3 className="mb-2 text-xl font-medium text-gray-900 dark:text-white">{title}</h3>
      {description && <p className="mb-4 text-gray-500 dark:text-gray-400 max-w-md mx-auto">{description}</p>}
      {buttonText && buttonClick && (
        <div className="flex gap-3">
          <Button color={buttonColor} size="sm" onClick={buttonClick}>
            {getButtonIcon()}
            {buttonText}
          </Button>
          {secondaryButtonText && secondaryButtonClick && (
            <Button color="light" size="sm" onClick={secondaryButtonClick}>
              {secondaryButtonText}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
