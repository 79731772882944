import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IMapRoute, IRouteItem } from "../types";
import { OrderCard } from "./OrderCard";
import { Map } from "./Map";
import { MapRoute } from "./MapRoute";
import { useSearchParams } from "react-router-dom";
import { Timestamp } from "../../../../types";
import moment from "moment";
import { MapControls } from "./MapControls";

interface OrdersViewProps {
  filteredOrders: IMapRoute[];
  selectedDate: Date;
}

export const OrdersView: FC<OrdersViewProps> = ({
  filteredOrders,
  selectedDate: initialDate
}) => {
  const { t } = useTranslation(["map"]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRoute, setSelectedRoute] = useState<IMapRoute>();
  const [dateFilteredOrders, setDateFilteredOrders] = useState<IMapRoute[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(initialDate);

  useEffect(() => {
    const filtered = filteredOrders.filter((order) => {
      const orderDate = Timestamp.toMoment(order.route[0].stopDate).startOf("day");
      const selectedDateMoment = moment(selectedDate).startOf("day");
      const dateMatches = orderDate.isSame(selectedDateMoment);
      const statusMatches = selectedTypes.length === 0 || selectedTypes.includes(order.status);
      return dateMatches && statusMatches;
    });
    setDateFilteredOrders(filtered);
    
    // Automatisk velg den første ordren hvis det finnes ordre og ingen er valgt
    if (filtered.length > 0 && !selectedRoute) {
      handleOrderSelect(filtered[0]);
    }
  }, [selectedDate, filteredOrders, selectedTypes]);

  // Sjekk om det finnes en ordreId i URL-en
  useEffect(() => {
    const orderId = searchParams.get('orderId');
    if (orderId && !selectedRoute) {
      const orderFromUrl = dateFilteredOrders.find(order => order.id === orderId);
      if (orderFromUrl) {
        handleOrderSelect(orderFromUrl);
      }
    }
  }, [dateFilteredOrders, searchParams]);

  const handleOrderSelect = (order: IMapRoute) => {
    setIsLoading(true);
    setSelectedRoute(order);
    setSearchParams({ orderId: order.id });
    setTimeout(() => setIsLoading(false), 500);
  };

  return (
    <div className="relative h-full">
      <Map 
        selectedRoute={selectedRoute} 
        isLoading={isLoading}
        message={selectedRoute ? undefined : t("map:orders.select_order")}
        messageTitle={t("map:orders.dialog_title")}
        hasListOverlay={true}
      >
        <MapRoute
          selectedRoute={selectedRoute}
        />
      </Map>

      {/* Orders List Overlay */}
      <div className="absolute inset-4 w-[280px] z-20">
        <div className="bg-white dark:bg-darkBlue rounded-lg shadow-sm h-full flex flex-col overflow-hidden border border-gray-200 dark:border-gray-700">
          <div className="bg-white dark:bg-darkBlue border-b border-gray-200 dark:border-gray-700 p-3">
            <h2 className="text-base font-medium text-gray-900 dark:text-white">{t('map:orders.title')}</h2>
          </div>
          
          <div className="p-2 flex-shrink-0 border-b border-gray-200 dark:border-gray-700">
            <MapControls
              setSelectedTypes={setSelectedTypes}
              selectedDate={selectedDate}
              setSelectedDate={(date) => {
                if (date) {
                  setSelectedDate(date);
                  setSelectedRoute(undefined);
                  setSearchParams({});
                }
              }}
              showStatusFilter={true}
            />
          </div>
          
          <div className="flex-1 overflow-y-auto">
            <div className="flex flex-col">
              {dateFilteredOrders.length === 0 ? (
                <div className="text-gray-500 p-3 text-center text-sm">{t('orders.no_orders')}</div>
              ) : (
                dateFilteredOrders.map(order => (
                  <OrderCard
                    key={order.id}
                    order={order}
                    onClick={() => handleOrderSelect(order)}
                    isSelected={selectedRoute?.id === order.id}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}; 