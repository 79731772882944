import firebase from "firebase/compat/app";
import { firestore } from "../firebaseConfig";
import { Timestamp, IProduct } from "../types";
import { query, doc } from "firebase/firestore";
import { IProductPricing } from "../types/Product";

type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;

class Product implements IProduct {
  id: string;
  workspaceId: string;
  descriptionShort: string;
  description: string;
  imgUrls?: string[];
  productType: string[];
  pricing?: IProductPricing;
  weight: number;
  length: number;
  height: number;
  width: number;
  createdAt?: Timestamp;
  productId?: string;

  static collectionName = "products";

  static converter = {
    toFirestore(Product: Product) {
      return Product.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as IProduct;
      return new Product({ ...data, id: snapshot.id });
    },
  };

  static createId() {
    return firestore.collection(Product.collectionName).doc().id;
  }

  constructor({
    id,
    workspaceId,
    descriptionShort,
    description,
    imgUrls,
    productType,
    pricing,
    weight,
    length,
    height,
    width,
    productId,
    createdAt,
  }: IProduct) {
    this.id = id || "";
    this.descriptionShort = descriptionShort || "";
    this.description = description || "";
    this.imgUrls = imgUrls || [];
    this.weight = weight || 0;
    this.productType = productType || [];
    this.pricing = pricing || undefined;
    this.length = length || 0;
    this.height = height || 0;
    this.width = width || 0;
    this.createdAt = createdAt;
    this.productId = productId;
    this.workspaceId = workspaceId || "";
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates: Partial<IProduct>) {
    return Object.assign(this, updates);
  }

  data() {
    return {
      id: this.id || "",
      descriptionShort: this.descriptionShort || "",
      description: this.description || "",
      imgUrls: this.imgUrls || [],
      productType: this.productType || [],
      pricing: this.pricing || undefined,
      weight: this.weight || 0,
      length: this.length || 0,
      height: this.height || 0,
      width: this.width || 0,
      createdAt: this.createdAt || null,
      productId: this.productId || "",
      workspaceId: this.workspaceId || "",
    };
  }

  static default() {
    return new Product({
      id: "",
      workspaceId: "",
      descriptionShort: "",
      description: "",
      imgUrls: [],
      productType: [],
      pricing: undefined,
      weight: 0,
      length: 0,
      height: 0,
      productId: "",
      width: 0,
    });
  }

  static isNew(data: IProduct): boolean {
    return data.id.length === 0;
  }

  static get = (customerId: string) => {
    return doc(
      firestore
        .collection(Product.collectionName)
        .withConverter(Product.converter),
      customerId,
    );
  };

  static list = (workspaceId: string) => {
    return query(
      firestore
        .collection(Product.collectionName)
        .where("workspaceId", "==", workspaceId)
        .withConverter(Product.converter),
    );
  };

  static create = async (
    workspaceId: string,
    data: Omit<IProduct, "id" | "createdAt">,
  ) => {
    const newProduct = new Product({
      ...data,
      workspaceId: workspaceId,
      id: Product.createId(),
      createdAt: Timestamp.now(),
    });
    try {
      await firestore
        .collection(Product.collectionName)
        .doc(newProduct.id)
        .withConverter(Product.converter)
        .set(newProduct, { merge: true });
    } catch (e) {
      console.warn("Create product failed with error: ", e);
      return null;
    }
    return newProduct;
  };

  static update = async (product: Product, updates: Partial<IProduct>) => {
    const updateType =
      (product.clone && product.clone()) || new Product({ ...product });

    updateType.setData({
      ...updates,
    });

    await firestore
      .collection(Product.collectionName)
      .doc(product.id)
      .withConverter(Product.converter)
      .set(updateType, { merge: true });

    return updateType;
  };

  static delete = async (product: Product) => {
    return await firestore
      .collection(Product.collectionName)
      .doc(product.id)
      .delete();
  };
}

export default Product;
