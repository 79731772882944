import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { DialogForm, SplitTable } from "../..";
import { SplitTableRef } from "../../molecules/SplitTable/SplitTable";
import { DropdownButton } from "../../atoms/Button/DropdownButton";
import {
  HiOutlineChevronDown,
  HiTrash,
  HiPencilAlt,
  HiOutlinePlus,
} from "react-icons/hi";

import { Company, Vehicle, Parameter } from "../../../models";
import { useCache } from "../../../context/CacheContext";
import { Button } from "flowbite-react";
import { VehicleDetails } from "./VehicleDetails";

interface VehicleListProps {
  vehicles: Vehicle[];
  companies: Company[];
  categories: Parameter[];
  isLoading: boolean;
  isOpen: (val: boolean) => void; // toggles the right pane
  createOrUpdate: (formData: Vehicle) => void;
}

export const VehicleList: FC<VehicleListProps> = ({
  vehicles,
  companies,
  categories,
  isLoading,
  isOpen,
  createOrUpdate,
}) => {
  const { t } = useTranslation(["common", "vehicles"]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { updateCacheKey } = useCache();

  const [openedVehicle, setOpenedVehicle] = useState<Vehicle | undefined>();
  const [vehicleCompany, setVehicleCompany] = useState<Company | undefined>();
  const [vehicleData, setVehicleData] = useState<Vehicle>(Vehicle.default());
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [filteredVehicles, setFilteredVehicles] = useState<Vehicle[]>(vehicles);
  const [initialVehicleReg, setInitialVehicleReg] = useState<string | null>(null);

  const splitTableRef = useRef<SplitTableRef>(null);

  // Hent kjøretøy-registreringsnummer fra URL-en
  useEffect(() => {
    const regParam = searchParams.get('reg');
    if (regParam) {
      setInitialVehicleReg(regParam);
    }
  }, [searchParams]);

  // Åpne kjøretøyet når initialVehicleReg er satt og data er lastet
  useEffect(() => {
    if (!isLoading && initialVehicleReg && vehicles.length > 0) {
      const vehicleToOpen = vehicles.find(vehicle => 
        vehicle.registrationNumber && 
        vehicle.registrationNumber.replace(/\s+/g, '') === initialVehicleReg.replace(/\s+/g, '')
      );
      
      if (vehicleToOpen) {
        updateOpenedVehicle(vehicleToOpen);
      }
    }
  }, [isLoading, initialVehicleReg, vehicles]);

  // Filter vehicles based on search text
  useEffect(() => {
    if (searchText.trim().length > 0) {
      const lowerCaseSearchText = searchText.toLowerCase();
      setFilteredVehicles(
        vehicles.filter((vehicle) =>
          vehicle.name.toLowerCase().includes(lowerCaseSearchText),
        ),
      );
    } else {
      setFilteredVehicles(vehicles);
    }
  }, [searchText, vehicles]);

  // Table rows
  const tableRows = filteredVehicles.map((vehicle) => {
    // Get vehicle category
    let categoryDisplay;
    
    if (!vehicle.categoryCode) {
      categoryDisplay = (
        <span className="text-xs px-2 py-0.5 rounded bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 inline-block">
          {t("vehicles:categories.uncategorized")}
        </span>
      );
    } else {
      const category = categories.find(c => c.code === vehicle.categoryCode);
      categoryDisplay = category ? (
        <span className="text-xs px-2 py-0.5 rounded bg-purple-200 dark:bg-purple-700 text-purple-800 dark:text-purple-200 inline-block">
          {category.code}
        </span>
      ) : (
        <span className="text-xs px-2 py-0.5 rounded bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 inline-block">
          {t("vehicles:categories.uncategorized")}
        </span>
      );
    }
    
    return {
      id: vehicle.id,
      isActive: vehicle.id === openedVehicle?.id,
      onRowClickData: vehicle,
      cells: [
        {
          id: "registrationNumber",
          children: vehicle.registrationNumber || "",
        },
        {
          id: "category",
          children: categoryDisplay,
          classOverride:
            "whitespace-normal p-4 text-base font-normal text-darkBlue dark:text-white overflow-hidden text-ellipsis" +
            (openedVehicle !== undefined ? " hidden" : ""),
        },
      ],
      actions: !openedVehicle
        ? [
            {
              id: "edit",
              icon: <HiPencilAlt />,
              onActionClick: (e: React.MouseEvent) => {
                e.stopPropagation();
                createOrUpdate(vehicle);
              },
            },
            {
              id: "delete",
              icon: <HiTrash />,
              color: "failure",
              onActionClick: (e: React.MouseEvent) => {
                e.stopPropagation();
                setVehicleData(vehicle);
                setDeleteModalOpen(true);
              },
            },
          ]
        : [],
    };
  });

  // Handle vehicle deletion
  const handleDelete = async () => {
    try {
      await Vehicle.delete(vehicleData);
      setDeleteModalOpen(false);
      updateCacheKey();

      if (openedVehicle?.id === vehicleData.id) {
        updateOpenedVehicle(undefined);
      }
    } catch (error) {
      console.error("Error deleting vehicle:", error);
    }
  };

  // Update opened vehicle
  const updateOpenedVehicle = (vehicle: Vehicle | undefined) => {
    setOpenedVehicle(vehicle);
    setVehicleCompany(companies.find((x) => x.id === vehicle?.companyId));

    if (vehicle) {
      isOpen(true);
      const vehicleReg = vehicle.registrationNumber || "unknown";
      const paramSafeName = vehicleReg.toLowerCase().replace(/\s+/g, "-");
      navigate(`/resources/vehicles?reg=${paramSafeName}`, {
        replace: true,
      });
      splitTableRef.current?.setSplit(true);
    } else {
      isOpen(false);
      navigate(`/resources/vehicles`, { replace: true });
      splitTableRef.current?.setSplit(false);
    }
  };

  return (
    <>
      {/* SplitTable */}
      <SplitTable
        ref={splitTableRef}
        searchable
        hasActions
        isLoading={isLoading}
        tableRows={tableRows}
        tableHeaders={[
          { id: "registrationNumber", children: t("vehicles:details.registration_number") },
          {
            id: "category",
            children: t("common:category"),
            hidden: openedVehicle !== undefined,
          },
        ]}
        onRowClick={(vehicle: Vehicle | undefined) =>
          updateOpenedVehicle(vehicle)
        }
        updateTable={(text) => setSearchText(text)}
        topRightContent={
          <div className="flex items-center">
            {openedVehicle && (
              <DropdownButton
                placement="bottom-end"
                buttonText={t("vehicles:actions.buttonText")}
                buttonAppendIcon={<HiOutlineChevronDown className="ml-2" />}
                items={[
                  {
                    text: (
                      <p className="p-4 w-48">{t("vehicles:actions.edit")}</p>
                    ),
                    onClick: () => {
                      createOrUpdate(openedVehicle);
                    },
                  },
                  {
                    text: (
                      <p className="p-4 w-48 text-lgb-red-300 dark:text-lgb-red-200 hover:text-lgb-red-200 hover:dark:text-lgb-red-300">
                        {t("vehicles:actions.delete")}
                      </p>
                    ),
                    onClick: () => {
                      setVehicleData(openedVehicle);
                      setDeleteModalOpen(true);
                    },
                  },
                ]}
              />
            )}
          </div>
        }
        content={
          openedVehicle ? (
            <VehicleDetails vehicle={openedVehicle} company={vehicleCompany} />
          ) : (
            <div className="p-4 text-gray-600">
              {t("vehicles:split.no_vehicle_selected")}
            </div>
          )
        }
      />

      {/* Delete Confirmation Dialog */}
      <DialogForm
        title={`${t("vehicles:actions.delete_dialogTitle")} ${
          vehicleData.registrationNumber ?? ""
        }?`}
        dismissible
        show={isDeleteModalOpen}
        toggleModal={() => setDeleteModalOpen(false)}
        buttonConfirmColor="failure"
        confirmButton={handleDelete}
        buttonConfirmText={t("common:confirm")}
        buttonCloseText={t("common:close")}
      />
    </>
  );
};
