import { FC } from 'react';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  className?: string;
}

const Pagination: FC<PaginationProps> = ({ 
  currentPage, 
  totalPages, 
  onPageChange,
  className = ''
}) => {
  // Beregn hvilke sider som skal vises i pagineringen
  const getPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 5; // Vis maks 5 sidetall om gangen
    
    if (totalPages <= maxPagesToShow) {
      // Hvis det er færre sider enn maxPagesToShow, vis alle
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Ellers, vis et begrenset antall sider med ellipsis
      if (currentPage <= 3) {
        // Hvis vi er nær starten
        for (let i = 1; i <= 4; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push('ellipsis');
        pageNumbers.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        // Hvis vi er nær slutten
        pageNumbers.push(1);
        pageNumbers.push('ellipsis');
        for (let i = totalPages - 3; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        // Hvis vi er i midten
        pageNumbers.push(1);
        pageNumbers.push('ellipsis');
        pageNumbers.push(currentPage - 1);
        pageNumbers.push(currentPage);
        pageNumbers.push(currentPage + 1);
        pageNumbers.push('ellipsis');
        pageNumbers.push(totalPages);
      }
    }
    
    return pageNumbers;
  };

  // Håndter klikk på forrige side
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  // Håndter klikk på neste side
  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  // Hvis det bare er én side, ikke vis paginering
  if (totalPages <= 1) return null;

  return (
    <nav className={`flex items-center justify-center mt-4 ${className}`} aria-label="Paginering">
      <ul className="flex items-center -space-x-px">
        {/* Forrige-knapp */}
        <li>
          <button
            onClick={handlePrevious}
            disabled={currentPage === 1}
            className={`flex items-center justify-center px-3 h-8 ms-0 leading-tight rounded-l-lg border ${
              currentPage === 1
                ? 'text-gray-300 bg-white border-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-500 cursor-not-allowed'
                : 'text-gray-600 bg-white border-gray-300 hover:bg-lgb-blue-100 hover:text-lgb-blue-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
            }`}
            aria-label="Forrige"
          >
            <HiChevronLeft className="w-5 h-5" />
          </button>
        </li>
        
        {/* Sidetall */}
        {getPageNumbers().map((page, index) => (
          <li key={index}>
            {page === 'ellipsis' ? (
              <span className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400">
                ...
              </span>
            ) : (
              <button
                onClick={() => typeof page === 'number' && onPageChange(page)}
                className={`flex items-center justify-center px-3 h-8 leading-tight border ${
                  currentPage === page
                    ? 'text-lgb-blue-300 border-lgb-blue-100 bg-lgb-blue-100 hover:bg-lgb-blue-100 dark:border-lgb-blue-200 dark:bg-lgb-blue-100 dark:text-lgb-blue-300 dark:hover:bg-lgb-blue-100'
                    : 'text-gray-600 bg-white border-gray-300 hover:bg-lgb-blue-100 hover:text-lgb-blue-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                }`}
                aria-current={currentPage === page ? 'page' : undefined}
              >
                {page}
              </button>
            )}
          </li>
        ))}
        
        {/* Neste-knapp */}
        <li>
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            className={`flex items-center justify-center px-3 h-8 leading-tight rounded-r-lg border ${
              currentPage === totalPages
                ? 'text-gray-300 bg-white border-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-500 cursor-not-allowed'
                : 'text-gray-600 bg-white border-gray-300 hover:bg-lgb-blue-100 hover:text-lgb-blue-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
            }`}
            aria-label="Neste"
          >
            <HiChevronRight className="w-5 h-5" />
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination; 