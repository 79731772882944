import { Product } from "../models";

/**
 * Safely converts product type to an array regardless of whether it's a string or array
 * @param product The product object
 * @returns An array of product type strings
 */
export const getProductTypeAsArray = (product: Product): string[] => {
  if (!product.productType) return [];
  return Array.isArray(product.productType) 
    ? product.productType 
    : [product.productType as unknown as string];
};

/**
 * Format product type for display
 * @param product The product object
 * @returns Formatted product type string
 */
export const formatProductType = (product: Product): string => {
  const types = getProductTypeAsArray(product);
  if (types.length === 0) return "-";
  
  // If it's just a string, return it directly
  if (typeof product.productType === 'string') {
    return product.productType;
  }
  
  // Otherwise, join the array
  return types.join(", ");
};

/**
 * Format dimension with unit
 * @param value The dimension value
 * @param unit The unit (default: cm)
 * @returns Formatted dimension string
 */
export const formatDimension = (value: number | null | undefined, unit: string = "cm"): string => {
  return value ? `${value} ${unit}` : "-";
};

/**
 * Format date from Firestore timestamp
 * @param timestamp Firestore timestamp
 * @returns Formatted date string
 */
export const formatFirestoreDate = (timestamp: any): string => {
  if (!timestamp) return "-";
  try {
    return new Date(timestamp.toDate()).toLocaleDateString();
  } catch (error) {
    console.error("Error formatting date:", error);
    return "-";
  }
}; 