import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import {
  Dashboard,
  OrderOutlet,
  WorkspacePage,
  UserPage,
  ProfilePage,
  CompaniesPage,
  WorkspaceUserPage,
  DriversPage,
  VehiclesPage,
  ConfigurationPage,
  Customers,
  ProductsPage,
  MyWorkspaces,
  CreateOrder,
  AllOrdersPage,
  Invoices,
  Locations,
  RealtimeDashboardPage,
  Reports,
  PlannerPage
} from "./pages";
import {
  LoginPage,
  LogoutPage,
  SignUpPage,
  PasswordResetPage,
  WorkspaceLoader,
} from "./pages/auth";
import { NotFoundPage, ServerErrorPage, MaintenancePage } from "./pages/utils";
import { useDarkMode } from "./context/DarkModeContext";
import { Suspense } from "react";
import CouponPage from "./pages/CouponPage";
import { DriverMapPage } from "./pages/orders/Map/DriverMap";

// Ny komponent for global lastingsvisning
const GlobalLoadingFallback = () => {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      {/* Navbar mockup */}
      <div className="fixed top-0 left-0 right-0 h-[85px] bg-white dark:bg-darkBlue border-b border-gray-200 dark:border-gray-700 z-50 flex items-center justify-between px-4">
        {/* Logo */}
        <div className="h-8 bg-gray-200 dark:bg-gray-700 w-32 rounded-md animate-pulse"></div>
        
        {/* Høyre side av navbar */}
        <div className="flex items-center space-x-4">
          <div className="h-8 w-8 bg-gray-200 dark:bg-gray-700 rounded-full animate-pulse"></div>
          <div className="h-8 w-8 bg-gray-200 dark:bg-gray-700 rounded-full animate-pulse"></div>
          <div className="h-8 w-8 bg-gray-200 dark:bg-gray-700 rounded-full animate-pulse"></div>
        </div>
      </div>
      
      {/* Sidebar mockup */}
      <div className="fixed top-[85px] left-0 bottom-0 w-64 bg-white dark:bg-darkBlue border-r border-gray-200 dark:border-gray-700 hidden lg:block">
        {/* Sidebar menu items */}
        <div className="p-4 space-y-4">
          {[...Array(8)].map((_, index) => (
            <div key={index} className="flex items-center space-x-3">
              <div className="h-6 w-6 bg-gray-200 dark:bg-gray-700 rounded animate-pulse"></div>
              <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-32 animate-pulse"></div>
            </div>
          ))}
        </div>
      </div>
      
      {/* Main content area */}
      <div className="pt-[85px] lg:ml-64 min-h-screen">
        <div className="px-4 sm:px-8 pt-6 sm:pt-12 flex justify-center">
          <div className="w-full max-w-[1370px]">
            {/* Header mockup */}
            <div className="flex justify-between items-center mb-8">
              <div>
                <div className="h-8 bg-gray-200 dark:bg-gray-700 w-48 rounded-md animate-pulse mb-2"></div>
                <div className="h-4 bg-gray-200 dark:bg-gray-700 w-64 rounded-md animate-pulse"></div>
              </div>
              <div className="h-10 bg-gray-200 dark:bg-gray-700 w-32 rounded-md animate-pulse"></div>
            </div>
            
            {/* Content mockup */}
            <div className="space-y-4">
              {/* Search bar mockup */}
              <div className="h-12 bg-gray-200 dark:bg-gray-700 w-full max-w-md rounded-md animate-pulse mb-6"></div>
              
              {/* Table mockup */}
              <div className="border border-gray-200 dark:border-gray-700 rounded-lg overflow-hidden">
                {/* Table header */}
                <div className="h-12 bg-gray-100 dark:bg-gray-800 flex items-center px-4">
                  {[...Array(3)].map((_, index) => (
                    <div key={index} className="h-4 bg-gray-200 dark:bg-gray-700 w-32 rounded-md animate-pulse mr-8"></div>
                  ))}
                </div>
                
                {/* Table rows */}
                {[...Array(5)].map((_, index) => (
                  <div key={index} className="h-16 border-t border-gray-200 dark:border-gray-700 flex items-center px-4">
                    {[...Array(3)].map((_, colIndex) => (
                      <div key={colIndex} className="h-4 bg-gray-200 dark:bg-gray-700 w-32 rounded-md animate-pulse mr-8"></div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function App() {
  const { isDarkMode } = useDarkMode();

  if (isDarkMode) {
    document.getElementById("root")?.classList.add("dark");
    document
      .getElementsByTagName("body")[0]
      .classList.remove("light-scrollbar");
    document.getElementsByTagName("body")[0].classList.add("dark-scrollbar");
  } else {
    document.getElementById("root")?.classList.remove("dark");
    document.getElementsByTagName("body")[0].classList.remove("dark-scrollbar");
    document.getElementsByTagName("body")[0].classList.add("light-scrollbar");
  }

  return (
    <Suspense fallback={<GlobalLoadingFallback />}>
      <Routes>
        <Route path="/login" element={<LoginPage />}></Route>
        <Route path="/logout" element={<LogoutPage />}></Route>
        <Route path="/signup" element={<SignUpPage />}></Route>
        <Route path="/load_workspace" element={<WorkspaceLoader />}></Route>
        <Route path="/reset_password" element={<PasswordResetPage />}></Route>
        <Route path="/" element={<RealtimeDashboardPage />} />
        <Route path="/dashboard" element={<RealtimeDashboardPage />} />
        <Route path="/realtime-dashboard" element={<RealtimeDashboardPage />} />
        <Route path="/administration">
          <Route path="users" element={<UserPage />} />
          <Route path="workspaces" element={<WorkspacePage />} />
          <Route path="reports" element={<Reports />} />
        </Route>
        <Route path="/orders" element={<OrderOutlet />}>
          <Route index element={<AllOrdersPage />} />
          <Route path="new" element={<CreateOrder />} />
          <Route path="scheduled" element={<Navigate to="/orders" replace />} />
          <Route path="ongoing" element={<Navigate to="/orders" replace />} />
          <Route path="completed" element={<Navigate to="/orders" replace />} />
          <Route path="all" element={<Navigate to="/orders" replace />} />
        </Route>
        <Route path="/planner" element={<PlannerPage />} />
        <Route path="/invoices" element={<Invoices />} />
        <Route path="map" element={<DriverMapPage />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/resources">
          <Route path="drivers" element={<DriversPage />} />
          <Route path="vehicles" element={<VehiclesPage />} />
          <Route path="products" element={<ProductsPage />} />
          <Route path="locations" element={<Locations />} />
        </Route>

        <Route path="/workspace">
          <Route path="resource-types" element={<ConfigurationPage />} />
          <Route path="users" element={<WorkspaceUserPage />} />
          <Route path="companies" element={<CompaniesPage />} />
          <Route path="coupons" element={<CouponPage />} />
        </Route>
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/my_workspaces" element={<MyWorkspaces />} />
        <Route path="/maintenance" element={<MaintenancePage />} />
        <Route path="/404" element={<NotFoundPage />} />
        <Route path="/500" element={<ServerErrorPage />} />
        <Route path="/reports" element={<Reports />} />
      </Routes>
    </Suspense>
  );
}

export default App;
