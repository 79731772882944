/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DialogNotify,
  OrderDetails,
  SplitTable,
  StatusBadge,
} from "../../../components";
import { Order } from "../../../models";
import { ITableRow, Timestamp } from "../../../types";
import { HiChevronRight } from "react-icons/hi";
import { Button, Dropdown } from "flowbite-react";
import { DropdownButton } from "../../atoms/Button/DropdownButton";
import { HiOutlineChevronDown } from "react-icons/hi";
import { IOrder, OrderStatus } from "../../../types/order/IOrder";
import { useDrivers } from "../../../hooks";
import { useWorkspace } from "../../../context/WorkspaceContext";
import { EditDriver } from "./EditDriver";
import { DeleteOrderIcon, StartOrderIcon } from "../../atoms/Icons/Icons";
import { EditRoute } from "./EditRoute";
import { UpdateStatus } from "./UpdateStatus";
import { SendDeliveryLetter } from "./SendDeliveryLetter";
import { EditCargo } from "./EditCargo";
import { cancelOrder as cancelOrderApi } from "../../../api";

import { useUserSession } from "../../../context/UserContext";
import { useCache } from "../../../context/CacheContext";
import { SplitTableRef } from "../../molecules/SplitTable/SplitTable";
import { useNavigate, useLocation } from "react-router-dom";
import { StyledButton } from "../../../components/atoms/Button";
import { 
  HiOutlineChevronRight,
  HiOutlineClipboardCheck,
  HiOutlineClipboard,
  HiOutlineX,
  HiOutlineClock,
  HiOutlineExclamationCircle,
  HiOutlineCheck,
  HiOutlineTrash,
  HiOutlinePencilAlt,
  HiOutlineDocumentDuplicate,
  HiOutlineDocumentText,
  HiOutlineCalendar,
  HiOutlineUser,
  HiOutlineTruck,
  HiOutlineLocationMarker,
  HiOutlineShoppingCart
} from "react-icons/hi";

interface OrderListProp {
  orders: Order[];
  isLoading: boolean;
  isOpen: (val: boolean) => void;
  customerSearchText?: string;
  onCustomerSearch?: (searchText: string) => void;
  preventAutoOpen?: boolean;
}

export const OrderList: FC<OrderListProp> = function (props: OrderListProp) {
  const { t } = useTranslation(["common", "orders", "validation"]);
  const [openedOrder, setOpenedOrder] = useState<Order | undefined>(undefined);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const [hideColumns, setHideColumns] = useState(false);
  const [searchText, setSearchText] = useState<string>(props.customerSearchText || "");
  const [searchResults, setSearchResults] = useState<Order[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [showStartOrderDialog, setShowStartOrderDialog] = useState(false);
  const [showEditDriver, setShowEditDriver] = useState(false);
  const [showEditRoute, setShowEditRoute] = useState(false);
  const [showEditCargo, setShowEditCargo] = useState(false);
  const [showUpdateStatus, setShowUpdateStatus] = useState(false);
  const [showDeleteOrderDialog, setShowDeleteOrderDialog] = useState(false);
  const [showCancelOrderDialog, setShowCancelOrderDialog] = useState(false);
  const [showDeliveryLetterDialog, setShowDeliveryLetterDialog] =
    useState(false);
  const { activeWorkspace } = useWorkspace();
  const { userSession } = useUserSession();
  const { updateCacheKey } = useCache();
  const splitTableRef = useRef<SplitTableRef>(null);

  const query = useDrivers(activeWorkspace?.workspaceId ?? "");

  const drivers = useMemo(() => query.data ?? [], [query]);

  const navigate = useNavigate(); // Add navigate
  const location = useLocation(); // Add location

  // Sorter ordrene etter dato, nyeste først
  const sortedOrders = useMemo(() => {
    if (!props.orders || props.orders.length === 0) return [];
    
    return [...props.orders].sort((a, b) => {
      try {
        // Hent første stopp-dato for hver ordre
        const routeA = a.route && a.route.length > 0 ? a.route[0] : null;
        const routeB = b.route && b.route.length > 0 ? b.route[0] : null;
        
        // Hvis ingen ruter finnes, behold opprinnelig rekkefølge
        if (!routeA || !routeB) {
          if (routeA) return -1;
          if (routeB) return 1;
          return 0;
        }
        
        // Bruk createdAt som fallback hvis stopDate ikke finnes
        const dateA = routeA.stopDate || a.createdAt;
        const dateB = routeB.stopDate || b.createdAt;
        
        // Hvis ingen datoer finnes, behold opprinnelig rekkefølge
        if (!dateA && !dateB) return 0;
        if (dateA && !dateB) return -1;
        if (!dateA && dateB) return 1;
        
        // Sammenlign datoer basert på seconds-feltet (som alle Timestamp-objekter har)
        if (dateA && dateB && dateA.seconds !== undefined && dateB.seconds !== undefined) {
          return dateB.seconds - dateA.seconds;
        }
        
        // Fallback til opprinnelig rekkefølge
        return 0;
      } catch (error) {
        console.error("Feil ved sortering av ordrer:", error);
        return 0; // Behold opprinnelig rekkefølge ved feil
      }
    });
  }, [props.orders]);

  // Oppdater searchResults basert på sortedOrders og søketekst
  useEffect(() => {
    if (searchText && searchText.length > 0) {
      // Filtrer de sorterte ordrene basert på søketekst
      const filteredOrders = sortedOrders.filter((order) => {
        const customerName = order.customer.isCompany
          ? (order.customer.name || "").toLowerCase()
          : `${order.customer.firstName || ""} ${order.customer.lastName || ""}`.toLowerCase();
        
        return customerName.includes(searchText.toLowerCase());
      });
      
      setSearchResults(filteredOrders);
    } else {
      // Hvis ingen søketekst, bruk alle sorterte ordrer
      setSearchResults(sortedOrders);
    }
  }, [sortedOrders, searchText]);

  // Update searchText when customerSearchText prop changes
  useEffect(() => {
    if (props.customerSearchText !== undefined) {
      setSearchText(props.customerSearchText);
    }
  }, [props.customerSearchText]);

  const updateOpenedOrder = (order: Order | undefined) => {
    if (order !== undefined) {
      // Always update the URL parameters when an order is clicked
      const currentParams = new URLSearchParams(location.search);
      currentParams.set('id', order.id);
      
      // Navigate to same page with updated parameters
      navigate({
        pathname: '/orders',
        search: currentParams.toString()
      }, { replace: true });

      // Only update the UI state if preventAutoOpen is false
      if (!props.preventAutoOpen) {
        setOpenedOrder(order);
        setHideColumns(true);
        props.isOpen(true);
        splitTableRef.current?.setSplit(true);
      }
    } else {
      // Close split view
      setOpenedOrder(undefined);
      setHideColumns(false);
      props.isOpen(false);
      splitTableRef.current?.setSplit(false);
      
      // When closing split view, remove id parameter from URL
      const currentParams = new URLSearchParams(location.search);
      currentParams.delete('id');
      
      // Navigate to same page with updated parameters
      navigate({
        pathname: '/orders',
        search: currentParams.toString()
      }, { replace: true });
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const orderId = queryParams.get("id");
    
    // Hvis det finnes en ordre-ID i URL-en
    if (orderId) {
      // Finn ordren som matcher ID-en
      const matchingOrder = props.orders.find((order) => order.id === orderId);
      
      // Hvis vi fant en matchende ordre og den er forskjellig fra den åpne ordren
      if (matchingOrder && (!openedOrder || openedOrder.id !== orderId)) {
        // Oppdater UI-tilstanden uten å endre URL-en
        setOpenedOrder(matchingOrder);
        setHideColumns(true);
        props.isOpen(true);
        splitTableRef.current?.setSplit(true);
      }
    } else if (openedOrder) {
      // Hvis det ikke finnes en ordre-ID i URL-en, men det er en åpen ordre,
      // lukk split-visningen
      setOpenedOrder(undefined);
      setHideColumns(false);
      props.isOpen(false);
      splitTableRef.current?.setSplit(false);
    }
  }, [location.search, props.orders, openedOrder, props]);

  useEffect(() => {
    if (!props.isLoading && searchResults) {
      setTableRows(
        searchResults.map((order) => {
          return {
            id: order.id,
            isActive: order.id === openedOrder?.id,
            onRowClickData: order,
            cells: [
              {
                id: "customer",
                children: order.customer.isCompany
                  ? order.customer.name
                  : order.customer.firstName + " " + order.customer.lastName,
                showOnSmallScreen: true,
              },
              {
                id: "status",
                children: <StatusBadge status={order.status} />,
                showOnSmallScreen: true,
              },
              {
                id: "date",
                children: Timestamp.toDisplayDate(order.route.at(0)?.stopDate),
              },
              {
                id: "from",
                children: order?.route[0].location.addressLine || "",
                hidden: hideColumns,
              },
              {
                id: "to",
                children:
                  order?.route[order.route.length - 1].location.addressLine ||
                  "",
                hidden: hideColumns,
              },
              {
                id: "open",
                children: <HiChevronRight size={20} />,
                hidden: hideColumns,
              },
            ],
          };
        }),
      );
    }
  }, [props.isLoading, searchResults, t, openedOrder, hideColumns]);

  const cancelOrder = async () => {
    if (openedOrder) {
      setIsLoading(true);
      await cancelOrderApi(
        userSession?.token.accessToken ?? "",
        activeWorkspace?.workspaceId ?? "",
        openedOrder.id,
      ).then((res) => {
        updateOpenedOrder(res);
        updateCacheKey();
        setShowCancelOrderDialog(!showCancelOrderDialog);
      });
      setIsLoading(false);
    }
  };
  const update = async (partial: Partial<IOrder>) => {
    if (openedOrder) {
      setIsLoading(true);
      let res = await Order.update(openedOrder, partial);
      updateOpenedOrder(res);
      updateCacheKey();
      setIsLoading(false);
    }
  };
  const deleteOrder = () => {
    if (openedOrder) {
      setIsLoading(true);
      Order.delete(openedOrder);
      updateCacheKey();
      setShowDeleteOrderDialog(!showDeleteOrderDialog);
      if (splitTableRef.current) {
        splitTableRef.current.setSplit(false); //Close split table
      }
      updateOpenedOrder(undefined);
      setIsLoading(false);
    }
  };
  const sendDeliveryLetter = (email: string) => {
    console.log("Send delivery letter to", email);
  };

  const downloadPDF = () => {};


  const orderTableHeaders = hideColumns
  ? [
      {
        id: "customer",
        children: t("common:customer"),
        showOnSmallScreen: true,
      },
      {
        id: "date",
        children: t("common:date"),
      },
    ]
  : [
      {
        id: "customer",
        children: t("common:customer"),
        showOnSmallScreen: true,
      },
      {
        id: "status",
        children: t("common:status"),
        showOnSmallScreen: true,
      },
      {
        id: "date",
        children: t("common:date"),
      },
      {
        id: "from",
        children: t("common:from"),
      },
      {
        id: "to",
        children: t("common:to"),
      },
      {
        id: "open",
        children: <></>,
      },
    ];

    useEffect(() => {
      if (!props.isLoading && searchResults) {
        setTableRows(
          searchResults.map((order) => {
            // Build the customer name string:
            const customerName = order.customer.isCompany
              ? order.customer.name
              : order.customer.firstName + " " + order.customer.lastName;
    
            return {
              id: order.id,
              isActive: order.id === openedOrder?.id,
              onRowClickData: order,
              cells: hideColumns
                ? [
                    {
                      id: "customer",
                      children: customerName,
                      showOnSmallScreen: true,
                    },
                    {
                      id: "date",
                      children: Timestamp.toDisplayDate(order.route.at(0)?.stopDate),
                    },
                  ]
                : [
                    {
                      id: "customer",
                      children: customerName,
                      showOnSmallScreen: true,
                    },
                    {
                      id: "status",
                      children: <StatusBadge status={order.status} />,
                      showOnSmallScreen: true,
                    },
                    {
                      id: "date",
                      children: Timestamp.toDisplayDate(order.route.at(0)?.stopDate),
                    },
                    {
                      id: "from",
                      children: order?.route[0].location.addressLine || "",
                    },
                    {
                      id: "to",
                      children:
                        order?.route[order.route.length - 1].location.addressLine ||
                        "",
                    },
                    {
                      id: "open",
                      children: <HiChevronRight size={20} />,
                    },
                  ],
            };
          })
        );
      }
    }, [props.isLoading, searchResults, t, openedOrder, hideColumns]);

  // Handle search text change and propagate to parent if needed
  const handleSearchTextChange = (text: string) => {
    setSearchText(text);
    
    if (props.onCustomerSearch) {
      props.onCustomerSearch(text);
    }
    
    // Vis lasteindikatoren kort for å gi visuell tilbakemelding
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  return (
    <>
      <SplitTable
        ref={splitTableRef}
        searchable={false}
        isLoading={props.isLoading}
        tableRows={tableRows}
        tableHeaders={orderTableHeaders} 
        onRowClick={(order: Order | undefined) => updateOpenedOrder(order)}
        updateTable={(val) => handleSearchTextChange(val)}
        content={
          <OrderDetails
            editCargo={() => setShowEditCargo(true)}
            order={openedOrder}
            drivers={drivers}
          />
        }
        topRightContent={
          <div className="flex items-center">
            {openedOrder &&
              (openedOrder.status !== OrderStatus.Cancelled ||
                userSession?.user.isAdmin) && (
                <DropdownButton
                  placement="bottom-end"
                  buttonText={t("orders:actions")}
                  buttonSize="sm"
                  className="h-[44px]"
                  buttonAppendIcon={<HiOutlineChevronDown className="ml-1 sm:ml-2 h-5 w-5 sm:h-4 sm:w-4" />}
                  items={[
                    ...([
                      OrderStatus.Draft,
                      OrderStatus.New,
                      OrderStatus.Scheduled,
                    ].includes(openedOrder?.status)
                      ? [
                          {
                            text: (
                              <p className="p-4 w-48">
                                {t("orders:scheduled_orders.start")}
                              </p>
                            ),
                            onClick: () => {
                              setShowStartOrderDialog(true);
                            },
                          },
                          {
                            text: (
                              <p className="p-4 w-48">
                                {t("orders:ongoing_orders.edit_cargo")}
                              </p>
                            ),
                            onClick: () => {
                              setShowEditCargo(true);
                            },
                          },
                          {
                            text: (
                              <p className="p-4 w-48">
                                {t("orders:ongoing_orders.edit_route")}
                              </p>
                            ),
                            onClick: () => {
                              setShowEditRoute(true);
                            },
                          },
                          {
                            text: (
                              <p className="p-4 w-48">
                                {openedOrder.driverId
                                  ? t("orders:scheduled_orders.change_driver")
                                  : t("orders:scheduled_orders.add_driver")}
                              </p>
                            ),
                            onClick: () => {
                              setShowEditDriver(true);
                            },
                          },
                        ]
                      : []),
                    ...(openedOrder?.status === OrderStatus.InProgress
                      ? [
                          {
                            text: (
                              <p className="p-4 w-48">
                                {t("orders:ongoing_orders.edit_cargo")}
                              </p>
                            ),
                            onClick: () => {
                              setShowEditCargo(true);
                            },
                          },
                          {
                            text: (
                              <p className="p-4 w-48">
                                {t("orders:ongoing_orders.edit_route")}
                              </p>
                            ),
                            onClick: () => {
                              setShowEditRoute(true);
                            },
                          },
                          {
                            text: (
                              <p className="p-4 w-48 text-lgb-red-300 dark:text-lgb-red-200 hover:text-lgb-red-200 hover:dark:text-lgb-red-300">
                                {t("orders:ongoing_orders.cancel")}
                              </p>
                            ),
                            onClick: () => {
                              setShowCancelOrderDialog(true);
                            },
                          },
                        ]
                      : []),

                    ...(openedOrder?.status === OrderStatus.Completed
                      ? [
                          {
                            text: (
                              <p className="p-4 w-48">
                                {t("orders:completed_orders.download_pdf")}
                              </p>
                            ),
                            onClick: () => {
                              downloadPDF();
                            },
                          },
                          {
                            text: (
                              <p className="p-4 w-48">
                                {t(
                                  "orders:completed_orders.send_delivery_letter",
                                )}
                              </p>
                            ),
                            onClick: () => {
                              setShowDeliveryLetterDialog(true);
                            },
                          },
                        ]
                      : []),
                    ...(userSession?.user.isAdmin ||
                    openedOrder?.status === OrderStatus.InProgress
                      ? [
                          {
                            text: (
                              <p className="p-4 w-48">
                                {t("orders:ongoing_orders.update_status")}
                              </p>
                            ),
                            onClick: () => {
                              setShowUpdateStatus(true);
                            },
                          },
                        ]
                      : []),
                    ...(userSession?.user.isAdmin ||
                    [
                      OrderStatus.Draft,
                      OrderStatus.New,
                      OrderStatus.Scheduled,
                    ].includes(openedOrder?.status)
                      ? [
                          {
                            text: (
                              <p className="p-4 w-48 text-lgb-red-300 dark:text-lgb-red-200 hover:text-lgb-red-200 hover:dark:text-lgb-red-300">
                                {t("orders:all_orders.delete")}
                              </p>
                            ),
                            onClick: () => {
                              setShowDeleteOrderDialog(true);
                            },
                          },
                        ]
                      : []),
                  ]}
                />
              )}
          </div>
        }

      />
      {openedOrder && (
        <>
          {drivers.length && (
            <EditDriver
              order={openedOrder}
              drivers={drivers}
              isLoading={isLoading}
              show={showEditDriver}
              type="add"
              edit={(driverId) => {
                update({ driverId: driverId });
              }}
              close={() => setShowEditDriver(false)}
            ></EditDriver>
          )}

          <EditRoute
            route={[...openedOrder.route]}
            show={showEditRoute}
            edit={(route) => {
              update({ route: route });
            }}
            close={() => setShowEditRoute(false)}
          ></EditRoute>

          <EditCargo
            order={openedOrder}
            isLoading={isLoading}
            show={showEditCargo}
            edit={(cargo) => {
              update({ cargo: cargo });
            }}
            close={() => setShowEditCargo(false)}
          ></EditCargo>

          <UpdateStatus
            show={showUpdateStatus}
            isLoading={isLoading}
            currentStatus={openedOrder?.status}
            toggleModal={() => setShowUpdateStatus(!showUpdateStatus)}
            confirmButton={(newStatus: OrderStatus) => {
              update({ status: newStatus });
              setShowUpdateStatus(!showUpdateStatus);
            }}
          />
          <SendDeliveryLetter
            show={showDeliveryLetterDialog}
            isLoading={isLoading}
            toggleModal={() =>
              setShowDeliveryLetterDialog(!showDeliveryLetterDialog)
            }
            confirmButton={(email: string) => {
              sendDeliveryLetter(email);
              setShowDeliveryLetterDialog(!showDeliveryLetterDialog);
            }}
          />
          <DialogNotify
            show={showStartOrderDialog}
            isLoading={isLoading}
            icon={<StartOrderIcon />}
            buttonCloseText={t("common:close")}
            buttonConfirmColor="primaryPurple"
            buttonConfirmText={t("orders:scheduled_orders.start")}
            title={t("orders:scheduled_orders.start_title")}
            text={t("orders:scheduled_orders.start_description")}
            toggleModal={() => setShowStartOrderDialog(!showStartOrderDialog)}
            confirmButton={() => {
              const routeWithUpdatedDate = [...openedOrder.route];
              routeWithUpdatedDate[0].stopDate = Timestamp.now();
              update({
                status: OrderStatus.InProgress,
                route: routeWithUpdatedDate,
              });
              setShowStartOrderDialog(!showStartOrderDialog);
            }}
          />
          <DialogNotify
            show={showDeleteOrderDialog}
            isLoading={isLoading}
            icon={<DeleteOrderIcon />}
            buttonCloseText={t("common:close")}
            buttonConfirmColor="red300"
            buttonConfirmText={t("orders:all_orders.delete")}
            title={t("orders:all_orders.delete_title")}
            text={t("orders:all_orders.delete_description")}
            toggleModal={() => setShowDeleteOrderDialog(!showDeleteOrderDialog)}
            confirmButton={() => {
              deleteOrder();
              setShowDeleteOrderDialog(!showDeleteOrderDialog);
            }}
          />
          <DialogNotify
            show={showCancelOrderDialog}
            isLoading={isLoading}
            icon={<DeleteOrderIcon />}
            buttonCloseText={t("common:close")}
            buttonConfirmColor="red300"
            buttonConfirmText={t("orders:ongoing_orders.cancel")}
            title={t("orders:ongoing_orders.cancel_title")}
            text={t("orders:ongoing_orders.cancel_description")}
            toggleModal={() => setShowCancelOrderDialog(!showCancelOrderDialog)}
            confirmButton={() => {
              cancelOrder();
              setShowCancelOrderDialog(!showCancelOrderDialog);
            }}
          />
        </>
      )}
    </>
  );
};
