/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, type FC } from "react";
import { Invoice } from "../../../models";
import { SimpleList } from "../SimpleList";
import { useTranslation } from "react-i18next";
import { HiOutlineCalendar, HiOutlineArrowRight } from "react-icons/hi";
import { HiCheck } from "react-icons/hi";
import { Button, Tooltip } from "flowbite-react";
import { FaRegCopy } from "react-icons/fa";
import { Timestamp } from "../../../types";
import InfoCard from "../Cards/InfoCard";
import { IInvoiceStatus } from "../../../types/Invoice";

export interface InvoiceDetailsProps {
  invoice: Invoice | undefined;
  sendInvoice?: () => void;
}

export const InvoiceDetails: FC<InvoiceDetailsProps> = (props) => {
  const { t } = useTranslation(["common"]);
  const [hasCopied, setHasCopied] = useState(false);
  const isDraft = props.invoice?.status === IInvoiceStatus.Draft;

  const totalPrice = props.invoice?.items.reduce((accumulator, item) => {
    let itemTotal = item.quantity * item.price;

    // Apply discount if any
    if (item.discount) {
      itemTotal -= (itemTotal * item.discount) / 100;
    }

    // Apply VAT if any
    if (item.vat) {
      itemTotal += (itemTotal * item.vat) / 100;
    }

    return Math.ceil(accumulator + itemTotal);
  }, 0);

  return (
    <>
      <div className="xl:flex justify-between items-center gap-4 pt-4">
        <div className="flex items-center gap-4">
          <p className="text-2xl font-medium dark:text-lgb-grey-100">
            {t("invoice:details.invoice") + " "}
            {props.invoice?.invoiceId}
          </p>
          {!hasCopied ? (
            <Tooltip content={t("common:copy")}>
              <div
                className="bg-none rounded-md relative"
                style={{ width: "34px", height: "34px" }}
              >
                <FaRegCopy
                  className="w-6 h-6 dark:text-white cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      props.invoice?.invoiceId?.toString() ?? "",
                    );
                    setHasCopied(true);
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="flex items-center text-lgb-grey-400 dark:text-lgb-grey-100">
              <HiCheck className="mr-2 h-5 w-5" />
              <p>{t("common:copied")}</p>
            </div>
          )}
        </div>
        <div className="flex gap-2 items-center dark:text-lgb-grey-100 mt-2 xl:mt-0">
          <HiOutlineCalendar size={20} />
          <p>
            {Timestamp.toDisplayDate(
              props.invoice?.sentDate || Timestamp.now(),
            )}
          </p>
        </div>
      </div>

      <InfoCard
        icon={
          <div
            className={`bg-lgb-${isDraft ? "blue" : "green"}-400 rounded-xl relative`}
            style={{ width: "20px", height: "20px" }}
          >
            <HiCheck
              size={"18px"}
              className="text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        }
        title={t("orders:scheduled_orders.info_title")}
        description={t("orders:scheduled_orders.info_description")}
        titleClass={`text-lgb-${isDraft ? "blue" : "green"}-400`}
        descriptionClass={`text-lgb-${isDraft ? "blue" : "green"}-400`}
        containerClass={`my-6 bg-lgb-${isDraft ? "blue" : "green"}-100 dark:bg-lgb-${isDraft ? "blue" : "green"}-200`}
        children={
          props.invoice?.status === IInvoiceStatus.Draft ? (
            <Button
              size="sm"
              color="blue400"
              onClick={() => (props.sendInvoice ? props.sendInvoice() : null)}
            >
              {t("common:send")}
              <HiOutlineArrowRight className="ml-2 h-5 w-5" />
            </Button>
          ) : undefined
        }
      />
      <p className="text-2xl mb-4 font-medium dark:text-white">
        {t("common:general")}
      </p>
      <SimpleList
        items={[
          {
            title: t("invoice:details.related_order"),
            value: props.invoice?.relatedOrderId,
            redirectPath: "/orders",
          },
          {
            title: t("common:customer"),
            value: props.invoice?.customer.isCompany
              ? props.invoice.customer.name
              : props.invoice?.customer.firstName +
                " " +
                props.invoice?.customer.lastName,
          },
          {
            title: t("invoice:details.sent_to"),
            value: props.invoice?.invoiceEmail,
          },
          {
            title: t("invoice:pdf.sum_total"),
            value: "NOK " + totalPrice,
          },
        ]}
      />
    </>
  );
};
