import type { FC } from "react";
import { useDraggable } from "@dnd-kit/core";
import { HiInformationCircle, HiLocationMarker, HiKey, HiTag, HiClock, HiCalendar, HiPhone } from "react-icons/hi";
import { Tooltip } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { IOrder } from "../../../types";
import { OrderStatus } from "../../../types/order/IOrder";
import moment from "moment";

interface DraggableOrderProps {
  order: IOrder;
  isDraggable: boolean;
}

export const DraggableOrder: FC<DraggableOrderProps> = ({ order, isDraggable }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: order.id,
    disabled: !isDraggable
  });
  const { t } = useTranslation(["planner", "stats"]);

  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  } : undefined;

  // Format the first pickup and last delivery times/locations for the tooltip
  const firstStop = order.route?.[0];
  const lastStop = order.route?.[order.route.length - 1];
  
  // Format time for display
  const formatTime = (timestamp: any) => {
    if (!timestamp) return "";
    
    try {
      let date;
      if (timestamp.toDate) {
        date = timestamp.toDate();
      } else if (timestamp.seconds) {
        date = new Date(timestamp.seconds * 1000);
      } else {
        return "";
      }
      
      return moment(date).format('HH:mm');
    } catch (error) {
      return "";
    }
  };
  
  // Format date for display
  const formatDate = (timestamp: any) => {
    if (!timestamp) return "";
    
    try {
      let date;
      if (timestamp.toDate) {
        date = timestamp.toDate();
      } else if (timestamp.seconds) {
        date = new Date(timestamp.seconds * 1000);
      } else {
        return "";
      }
      
      return moment(date).format('DD. MMM');
    } catch (error) {
      return "";
    }
  };

  // Get status color
  const getStatusColor = () => {
    switch (order.status) {
      case OrderStatus.New:
        return "bg-lgb-blue-100 text-lgb-blue-800 dark:bg-lgb-blue-900/30 dark:text-lgb-blue-300";
      case OrderStatus.InProgress:
        return "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300";
      case OrderStatus.Completed:
        return "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300";
      case OrderStatus.Cancelled:
        return "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300";
      default:
        return "bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-300";
    }
  };

  const tooltipContent = (
    <div className="space-y-3 p-1 max-w-xs">
      <div className="flex items-center gap-2">
        <HiKey className="h-4 w-4 text-gray-400 flex-shrink-0" />
        <div className="truncate">
          <div className="text-xs opacity-75">{t("planner:order_details.order_id")}</div>
          <div className="truncate font-medium">{order.id || ''}</div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <HiLocationMarker className="h-4 w-4 text-gray-400 flex-shrink-0" />
        <div className="truncate">
          <div className="text-xs opacity-75">{t("planner:order_details.first_stop")}</div>
          <div className="truncate font-medium">{firstStop?.location?.addressLine || ''}</div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <HiLocationMarker className="h-4 w-4 text-gray-400 flex-shrink-0" />
        <div className="truncate">
          <div className="text-xs opacity-75">{t("planner:order_details.last_stop")}</div>
          <div className="truncate font-medium">{lastStop?.location?.addressLine || ''}</div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <HiPhone className="h-4 w-4 text-gray-400 flex-shrink-0" />
        <div className="truncate">
          <div className="text-xs opacity-75">{t("planner:order_details.contact")}</div>
          <div className="truncate font-medium">{order.customer.phoneNumber || ''}</div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <HiTag className="h-4 w-4 text-gray-400 flex-shrink-0" />
        <div className="truncate">
          <div className="text-xs opacity-75">{t("planner:order_details.status")}</div>
          <div className="truncate font-medium">{t(`stats:orderStatus.${order.status === 'InProgress' ? 'in_progress' : order.status.toLowerCase()}`)}</div>
        </div>
      </div>
    </div>
  );

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...(isDraggable ? listeners : {})}
      {...(isDraggable ? attributes : {})}
      className={`group relative bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors ${isDraggable ? 'cursor-move' : 'cursor-default'} border border-gray-200 dark:border-gray-700 rounded-lg shadow-sm p-2.5 touch-none select-none hover:shadow-md ${!isDraggable ? 'opacity-80' : ''}`}
    >
      <div className="flex flex-col gap-1.5 w-full">
        <div className="flex items-center justify-between gap-2 w-full">
          <div className="flex-1 min-w-0">
            <h5 className="text-sm font-medium tracking-tight text-gray-800 dark:text-white truncate">
              {order.customer.isCompany ? order.customer.name : `${order.customer.firstName} ${order.customer.lastName}`}
            </h5>
          </div>
          <span className={`text-xs px-1.5 py-0.5 rounded-full font-medium ${getStatusColor()}`}>
            {t(`stats:orderStatus.${order.status === 'InProgress' ? 'in_progress' : order.status.toLowerCase()}`)}
          </span>
        </div>
        
        <div className="flex items-center justify-between text-xs text-gray-600 dark:text-gray-400">
          <div className="flex items-center gap-1">
            <HiClock className="h-3 w-3" />
            <span>{formatTime(firstStop?.stopDate)}</span>
          </div>
          
          <div className="flex items-center gap-1">
            <HiLocationMarker className="h-3 w-3" />
            <span className="truncate max-w-[100px]">{firstStop?.location?.city || ''}</span>
          </div>
          
          <Tooltip content={tooltipContent} trigger="hover">
            <HiInformationCircle 
              className="h-4 w-4 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300 cursor-pointer"
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
}; 