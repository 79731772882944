import type { FC } from "react";
import Driver from "../../../models/Driver";

interface DriverCardProps {
  driver: Driver;
  onClick: () => void;
  isSelected?: boolean;
}

export const DriverCard: FC<DriverCardProps> = ({ driver, onClick, isSelected }) => {
  return (
    <div
      onClick={onClick}
      className={`group relative bg-white dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 
        transition-colors cursor-pointer border border-gray-200 dark:border-gray-700 rounded-lg 
        shadow-sm py-2 px-3 w-full hover:shadow-md ${isSelected ? 'ring-2 ring-lgb-green-300' : ''}`}
    >
      <h4 className="text-sm font-medium text-gray-900 dark:text-white truncate text-center">
        {`${driver.firstName} ${driver.lastName}`}
      </h4>
    </div>
  );
}; 