import firebase from "firebase/compat/app";
import { firestore } from "../firebaseConfig";
import { Timestamp, IVehicle } from "../types";
import { query } from "firebase/firestore";

type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;

class Vehicle implements IVehicle {
  id: string;
  workspaceId: string;
  companyId: string;
  name: string;
  registrationNumber: string;
  maxWeight: number;
  categoryCode?: string | null;
  createdAt?: Timestamp;

  static collectionName = "vehicles";

  static converter = {
    toFirestore(vehicle: Vehicle) {
      return vehicle.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as IVehicle;
      return new Vehicle({ ...data, id: snapshot.id });
    },
  };

  static createId() {
    return firestore.collection(Vehicle.collectionName).doc().id;
  }

  constructor({
    id,
    workspaceId,
    companyId,
    name,
    registrationNumber,
    maxWeight,
    categoryCode,
    createdAt,
  }: IVehicle) {
    this.id = id;
    this.workspaceId = workspaceId;
    this.companyId = companyId;
    this.name = name;
    this.registrationNumber = registrationNumber;
    this.maxWeight = maxWeight;
    this.categoryCode = categoryCode || null;
    this.createdAt = createdAt;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates: Partial<IVehicle>) {
    return Object.assign(this, updates);
  }

  data() {
    return {
      id: this.id,
      workspaceId: this.workspaceId,
      companyId: this.companyId,
      name: this.name,
      registrationNumber: this.registrationNumber || null,
      maxWeight: this.maxWeight || null,
      categoryCode: this.categoryCode || null,
      createdAt: this.createdAt || null,
    };
  }

  static default() {
    return new Vehicle({
      id: "",
      workspaceId: "",
      companyId: "",
      name : "",
      maxWeight: 0,
      registrationNumber: ""
    });
  }

  static isNew(data: IVehicle): boolean {
    return data.id.length === 0;
  }

  static create = async (workspaceId: string, data: Omit<IVehicle, "id" | "createdAt">) => {
    const newVehicle = new Vehicle({
      ...data,
      workspaceId: workspaceId,
      id: Vehicle.createId(),
      createdAt: Timestamp.now(),
    });
    try {
      await firestore
        .collection(Vehicle.collectionName)
        .doc(newVehicle.id)
        .withConverter(Vehicle.converter)
        .set(newVehicle, { merge: true });
    } catch (e) {
      console.warn("Create vehicle failed with error: ", e);
      return false;
    }
    return true;
  };

  static list = (workspaceId: string) => {
    return query(
      firestore
        .collection(Vehicle.collectionName)
        .where("workspaceId", "==", workspaceId)
        .withConverter(Vehicle.converter),
    );
  };

  static update = async (
    vehicle : Vehicle,
    updates: Partial<IVehicle>
  ) => {
    const updateType =
      (vehicle.clone && vehicle.clone()) || new Vehicle({ ...vehicle });

      updateType.setData({
      ...updates
    });

    await firestore
      .collection(Vehicle.collectionName)
      .doc(vehicle.id)
      .withConverter(Vehicle.converter)
      .set(updateType, { merge: true });

    return updateType;
  };

  static delete = async (vehicle : Vehicle) => {
    return await firestore
        .collection(Vehicle.collectionName)
        .doc(vehicle.id)
        .delete();
  };

}

export default Vehicle;
