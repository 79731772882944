import type { FC } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Badge } from "flowbite-react";

import { DialogForm, SplitTable } from "../..";
import { SplitTableRef } from "../../molecules/SplitTable/SplitTable";
import { DropdownButton } from "../../atoms/Button/DropdownButton";
import {
  HiOutlineChevronDown,
  HiTrash,
  HiPencilAlt,
} from "react-icons/hi";

import { Product, Parameter } from "../../../models";
import { useCache } from "../../../context/CacheContext";
import { ProductDetails } from "./ProductDetails";
import isSmallScreen from "../../../helpers/is-small-screen";
import { getProductTypeAsArray } from "../../../utils/productHelpers";

interface ProductsListProps {
  products: Product[];
  categories: Parameter[];
  isLoading: boolean;
  isOpen: (val: boolean) => void; // toggles the right pane
  createOrUpdate: (formData: Product) => void;
}

export const ProductsList: FC<ProductsListProps> = ({
  products,
  categories,
  isLoading,
  isOpen,
  createOrUpdate,
}) => {
  const { t } = useTranslation(["common", "products"]);
  const navigate = useNavigate();
  const { updateCacheKey } = useCache();

  const [openedProduct, setOpenedProduct] = useState<Product | undefined>();
  const [productData, setProductData] = useState<Product>(Product.default());
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [filteredProducts, setFilteredProducts] = useState<Product[]>(products);

  const splitTableRef = useRef<SplitTableRef>(null);

  // Update opened product
  const updateOpenedProduct = (prod: Product | undefined) => {
    setOpenedProduct(prod);

    if (prod) {
      isOpen(true);
      const productName = prod.descriptionShort || "unknown";
      const paramSafeName = productName.toLowerCase().replace(/\s+/g, "-");
      navigate(`/resources/products?product=${paramSafeName}`, {
        replace: true,
      });
      splitTableRef.current?.setSplit(true);
    } else {
      isOpen(false);
      navigate(`/resources/products`, { replace: true });
      splitTableRef.current?.setSplit(false);
    }
  };

  // Filter products based on search text
  useEffect(() => {
    if (searchText.trim().length > 0) {
      const lowerCaseSearchText = searchText.toLowerCase();
      setFilteredProducts(
        products.filter((prod) =>
          prod.descriptionShort.toLowerCase().includes(lowerCaseSearchText),
        ),
      );
    } else {
      setFilteredProducts(products);
    }
  }, [searchText, products]);

  // Table rows
  const tableRows = filteredProducts.map((prod) => {
    // Get product category
    const productTypeArray = getProductTypeAsArray(prod);
    let categoryDisplay;
    
    if (productTypeArray.length === 0) {
      categoryDisplay = (
        <span className="text-xs px-2 py-0.5 rounded bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 inline-block">
          {t("products:categories.uncategorized")}
        </span>
      );
    } else {
      const categoryId = productTypeArray[0];
      const category = categories.find(c => c.id === categoryId);
      categoryDisplay = category ? (
        <span className="text-xs px-2 py-0.5 rounded bg-purple-200 dark:bg-purple-700 text-purple-800 dark:text-purple-200 inline-block">
          {category.code}
        </span>
      ) : (
        <span className="text-xs px-2 py-0.5 rounded bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 inline-block">
          {t("products:categories.uncategorized")}
        </span>
      );
    }
    
    return {
      id: prod.id,
      isActive: prod.id === openedProduct?.id,
      onRowClickData: prod,
      cells: [
        {
          id: "descriptionShort",
          children: prod.descriptionShort || t("products:unknown_product"),
        },
        {
          id: "category",
          children: categoryDisplay,
          classOverride:
            "whitespace-normal p-4 text-base font-normal text-darkBlue dark:text-white overflow-hidden text-ellipsis" +
            (openedProduct !== undefined ? " hidden" : ""),
        },
      ],
      actions: !openedProduct
        ? [
            {
              id: "edit",
              icon: <HiPencilAlt />,
              onActionClick: (e: React.MouseEvent) => {
                e.stopPropagation();
                createOrUpdate(prod);
              },
            },
            {
              id: "delete",
              icon: <HiTrash />,
              color: "failure",
              onActionClick: (e: React.MouseEvent) => {
                e.stopPropagation();
                setProductData(prod);
                setDeleteModalOpen(true);
              },
            },
          ]
        : [],
    };
  });

  // Handle product deletion
  const handleDelete = async () => {
    try {
      await Product.delete(productData);
      setDeleteModalOpen(false);
      updateCacheKey();

      if (openedProduct?.id === productData.id) {
        updateOpenedProduct(undefined);
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  return (
    <>
      {/* SplitTable */}
      <div className="p-0">
        <SplitTable
          ref={splitTableRef}
          searchable
          hasActions
          isLoading={isLoading}
          tableRows={tableRows}
          tableHeaders={[
            { id: "displayName", children: t("common:product") },
            {
              id: "category",
              children: t("common:category"),
              hidden: openedProduct !== undefined,
            },
          ]}
          onRowClick={(prod: Product | undefined) => updateOpenedProduct(prod)}
          updateTable={(text) => setSearchText(text)}
          topRightContent={
            <div className="flex items-center">
              {openedProduct && (
                <DropdownButton
                  placement="bottom-end"
                  buttonText={t("products:actions.buttonText")}
                  buttonAppendIcon={<HiOutlineChevronDown className="ml-2" />}
                  items={[
                    {
                      text: (
                        <p className="p-4 w-48">{t("products:actions.edit")}</p>
                      ),
                      onClick: () => {
                        createOrUpdate(openedProduct);
                      },
                    },
                    {
                      text: (
                        <p className="p-4 w-48 text-lgb-red-300 dark:text-lgb-red-200 hover:text-lgb-red-200 hover:dark:text-lgb-red-300">
                          {t("products:actions.delete")}
                        </p>
                      ),
                      onClick: () => {
                        setProductData(openedProduct);
                        setDeleteModalOpen(true);
                      },
                    },
                  ]}
                />
              )}
            </div>
          }
          content={
            openedProduct && (
              <ProductDetails
                product={openedProduct}
                categories={categories}
              />
            )
          }
        />
      </div>

      {/* Delete Confirmation Dialog */}
      <DialogForm
        show={isDeleteModalOpen}
        title={t("products:delete.title")}
        buttonConfirmText={t("common:confirm")}
        buttonCloseText={t("common:cancel")}
        buttonConfirmColor="failure"
        toggleModal={() => setDeleteModalOpen(!isDeleteModalOpen)}
        confirmButton={handleDelete}
      />
    </>
  );
};
