import React from "react";
import { HiOutlineInformationCircle, HiOutlineExclamation, HiOutlineX, HiCheck } from "react-icons/hi";
import { HiExclamationTriangle } from "react-icons/hi2";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

export type InfoMessageVariant = "info" | "warning" | "error" | "neutral" | "success";

export interface InfoMessageProps {
  /**
   * Tittel på meldingen
   */
  title?: string;
  /**
   * Innhold i meldingen
   */
  children: React.ReactNode;
  /**
   * Variant av meldingen som bestemmer farge og ikon
   * @default "info"
   */
  variant?: InfoMessageVariant;
  /**
   * Om meldingen kan lukkes
   * @default false
   */
  dismissible?: boolean;
  /**
   * Funksjon som kalles når meldingen lukkes
   */
  onDismiss?: () => void;
  /**
   * Ekstra CSS-klasser
   */
  className?: string;
}

export const InfoMessage: React.FC<InfoMessageProps> = ({
  title,
  children,
  variant = "info",
  dismissible = false,
  onDismiss,
  className,
}) => {
  const { t } = useTranslation(["common"]);
  // Definer stiler basert på variant
  const variantStyles = {
    info: {
      container: "bg-lgb-blue-100/50 border-lgb-blue-200 dark:bg-lgb-blue-900/20 dark:border-lgb-blue-800",
      icon: "text-lgb-blue-500 dark:text-lgb-blue-400",
      title: "text-gray-800 dark:text-white",
      content: "text-gray-700 dark:text-gray-300",
      button: "text-lgb-blue-500 hover:text-lgb-blue-700 dark:text-lgb-blue-400 dark:hover:text-lgb-blue-300",
      IconComponent: HiOutlineInformationCircle,
    },
    warning: {
      container: "bg-yellow-100/50 border-yellow-200 dark:bg-yellow-900/20 dark:border-yellow-800",
      icon: "text-yellow-500 dark:text-yellow-400",
      title: "text-gray-800 dark:text-white",
      content: "text-gray-700 dark:text-gray-300",
      button: "text-yellow-500 hover:text-yellow-700 dark:text-yellow-400 dark:hover:text-yellow-300",
      IconComponent: HiExclamationTriangle,
    },
    error: {
      container: "bg-red-100/50 border-red-200 dark:bg-red-900/20 dark:border-red-800",
      icon: "text-red-500 dark:text-red-400",
      title: "text-gray-800 dark:text-white",
      content: "text-gray-700 dark:text-gray-300",
      button: "text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300",
      IconComponent: HiOutlineExclamation,
    },
    success: {
      container: "bg-green-100/50 border-green-200 dark:bg-green-900/20 dark:border-green-800",
      icon: "text-green-500 dark:text-green-400",
      title: "text-gray-800 dark:text-white",
      content: "text-gray-700 dark:text-gray-300",
      button: "text-green-500 hover:text-green-700 dark:text-green-400 dark:hover:text-green-300",
      IconComponent: HiCheck,
    },
    neutral: {
      container: "bg-gray-100/50 border-gray-200 dark:bg-gray-800/50 dark:border-gray-700",
      icon: "text-gray-500 dark:text-gray-400",
      title: "text-gray-800 dark:text-white",
      content: "text-gray-700 dark:text-gray-300",
      button: "text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-300",
      IconComponent: HiOutlineInformationCircle,
    },
  };

  const styles = variantStyles[variant];
  const IconComponent = styles.IconComponent;

  return (
    <div className={classNames(
      "p-4 border rounded-lg flex items-start",
      styles.container,
      className
    )}>
      <div className="flex-shrink-0">
        <IconComponent className={classNames("h-5 w-5", styles.icon)} />
      </div>
      <div className="ml-3 flex-grow">
        {title && (
          <h3 className={classNames("text-sm font-medium", styles.title)}>
            {title}
          </h3>
        )}
        <div className={classNames("mt-1 text-sm", styles.content)}>
          {children}
        </div>
      </div>
      {dismissible && onDismiss && (
        <button
          onClick={onDismiss}
          className={classNames("flex-shrink-0 ml-2 text-sm font-medium", styles.button)}
          aria-label={t("common:close")}
        >
          <HiOutlineX className="h-5 w-5" />
          <span className="sr-only">{t("common:close")}</span>
        </button>
      )}
    </div>
  );
};

export default InfoMessage; 