import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useWorkspace } from "../../../../context/WorkspaceContext";
import { useUserSession } from "../../../../context/UserContext";
import { getDriverRoutes } from "../../../../api/client";
import moment from "moment";
import { IRouteItem, Timestamp } from "../../../../types";
import { OrderStatus } from "../../../../types/order/IOrder";
import { MapRoute } from "./MapRoute";
import { IMapRoute } from "../types";
import { Map } from "./Map";
import { MapControls } from "./MapControls";
import Driver from "../../../../models/Driver";

interface EnhancedRouteItem extends IRouteItem {
  isAvailable: boolean;
  orderDisplayName?: string;
  stopDisplayName?: string;
  cargos?: {
    id: string;
    quantity: number;
    description?: string;
    itinerary: {
      pickupStopNumber?: number;
      deliveryStopNumber?: number;
    };
  }[];
}

interface DriverRoutesViewProps {
  drivers: Driver[];
  selectedDate?: Date;
}

export const DriverRoutesView: FC<DriverRoutesViewProps> = ({ 
  drivers, 
  selectedDate: initialDate,
}) => {
  const { t } = useTranslation(["map"]);
  const { activeWorkspace } = useWorkspace();
  const { userSession } = useUserSession();
  const [loading, setLoading] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState<string>();
  const [selectedRoute, setSelectedRoute] = useState<IMapRoute>();
  const [noRoutesMessage, setNoRoutesMessage] = useState<string>();
  const [selectedDate, setSelectedDate] = useState<Date>(initialDate || new Date());

  useEffect(() => {
    const fetchRoutes = async () => {
      if (!selectedDriver || !userSession?.token || !activeWorkspace?.workspaceId || !selectedDate) {
        setSelectedRoute(undefined);
        setNoRoutesMessage(undefined);
        return;
      }
      
      setSelectedRoute(undefined);
      setNoRoutesMessage(undefined);
      setLoading(true);
      try {
        const formattedDate = moment(selectedDate).format('YYYY-MM-DD');
        const response = await getDriverRoutes(
          userSession.token.accessToken,
          activeWorkspace.workspaceId,
          selectedDriver,
          formattedDate
        );
        
        if (response && response.length > 0) {
          const allStops = response
          .filter((item: any) => [
            OrderStatus.InProgress, 
            OrderStatus.Completed, 
            OrderStatus.Cancelled, 
            OrderStatus.Scheduled
          ].includes(item.status)).map((item: any) => ({
            routeId: item.id,
            orderId: item.orderId,
            location: {
              id: item.id,
              displayName: item.displayName,
              addressLine: item.stop.location?.addressLine,
              workspaceId: item.workspaceId,
              customerId: "",
              coordinate: item.stop.location?.coordinate
            },
            stopNumber: item.routeStopNumber || 0,
            stopDate: Timestamp.fromMoment(moment(item.routeDate)),
            timing: {
              earliest: false,
              latest: false
            },
            status: item.status,
            isPickup: item.isPickup,
            isAvailable: false,
            stopDisplayName: item.stop.location?.displayName,
            orderDisplayName: item.displayName,
            cargos: item.cargos?.map((cargo: any) => ({
              id: cargo.id,
              quantity: cargo.quantity,
              description: cargo.description,
              itinerary: {
                pickupStopNumber: cargo.itinerary?.pickupStopNumber,
                deliveryStopNumber: cargo.itinerary?.deliveryStopNumber
              }
            }))
          })) as EnhancedRouteItem[];

          if (allStops.length === 0) {
            showNoRoutesMessage();
            return;
          }

          const sortedStops = allStops.sort((a, b) => a.stopNumber - b.stopNumber);
          
          // Convert to IMapRoute format
          const driver = drivers.find(d => d.email === selectedDriver);
          const driverRoute: IMapRoute = {
            id: selectedDriver,
            customerName: driver ? `${driver.firstName} ${driver.lastName}`.trim() : "",
            type: "Driver Route",
            status: OrderStatus.InProgress,
            driver: driver,
            driverId: selectedDriver,
            customer: {
              id: driver?.id || "",
              name: driver ? `${driver.firstName} ${driver.lastName}`.trim() : "",
              isCompany: true,
              workspaceId: activeWorkspace?.workspaceId || ""
            },
            route: sortedStops
          };

          setSelectedRoute(driverRoute);
          // Add a small delay before clearing loading state to ensure route is processed
          setTimeout(() => setLoading(false), 100);
        } else {
          showNoRoutesMessage()
        }
      } catch (error) {
        console.error('Error fetching driver routes:', error);
        setNoRoutesMessage('An error occurred while fetching routes. Please try again.');
        setLoading(false);
      }
    };

    fetchRoutes();
  }, [selectedDriver, selectedDate, userSession?.token, activeWorkspace?.workspaceId, drivers, t]);

  // Auto-select first driver if none is selected
  useEffect(() => {
    if (drivers.length > 0 && !selectedDriver) {
      setSelectedDriver(drivers[0].email);
    }
  }, [drivers, selectedDriver]);

  const showNoRoutesMessage = () => {
    const driver = drivers.find(d => d.email === selectedDriver);
    const driverName = driver ? `${driver.firstName} ${driver.lastName}`.trim() : 'Selected driver';
    setNoRoutesMessage(t("map:drivers.no_routes_for_date", { driverName }));
    setLoading(false);
  }

  return (
    <div className="relative h-full">
      <Map 
        selectedRoute={selectedRoute} 
        isLoading={loading}
        message={selectedDriver ? noRoutesMessage : t("map:drivers.select_driver")}
        messageTitle={t("map:drivers.dialog_title")}
        hasListOverlay={true}
      >
        <MapRoute
          selectedRoute={selectedRoute}
        />
      </Map>

      {/* Driver Selection Overlay */}
      <div className="absolute inset-4 w-[280px] z-20">
        <div className="bg-white dark:bg-darkBlue rounded-lg shadow-sm h-full flex flex-col overflow-hidden border border-gray-200 dark:border-gray-700">
          <div className="bg-white dark:bg-darkBlue border-b border-gray-200 dark:border-gray-700 p-3">
            <h2 className="text-base font-medium text-gray-900 dark:text-white">{t('drivers.title')}</h2>
          </div>
          
          <div className="p-2 flex-shrink-0 border-b border-gray-200 dark:border-gray-700">
            <MapControls
              selectedDate={selectedDate}
              setSelectedDate={(date: Date | undefined) => date && setSelectedDate(date)}
              showStatusFilter={false}
              setSelectedTypes={() => {}}
            />
          </div>
          
          <div className="flex-1 overflow-y-auto">
            <div className="flex flex-col">
              {drivers?.length === 0 ? (
                <div className="text-gray-500 p-3 text-center text-sm">{t('drivers.no_drivers')}</div>
              ) : (
                drivers?.map(driver => (
                  <div 
                    key={driver.email}
                    onClick={() => setSelectedDriver(driver.email)}
                    className={`py-2 px-3 transition-colors duration-200 flex items-center gap-2 cursor-pointer border-l-2 ${
                      selectedDriver === driver.email 
                        ? 'bg-lgb-blue-100 dark:bg-lgb-blue-400/10 border-lgb-blue-300' 
                        : 'hover:bg-gray-50 dark:hover:bg-gray-800 border-transparent'
                    }`}
                  >
                    <div className="text-gray-700 dark:text-gray-300 w-7 h-7 flex items-center justify-center">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12" />
                      </svg>
                    </div>
                    <div className="flex-1 min-w-0">
                      <h3 className="font-medium text-gray-900 dark:text-white text-sm truncate">{`${driver.firstName} ${driver.lastName}`.trim()}</h3>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}; 