import type { FC } from "react";
import React, { useEffect, useState } from "react";
import { Table, Checkbox, Button } from "flowbite-react";
import { ITableData } from "../../../types";
import isSmallScreen from "../../../helpers/is-small-screen";

// Stil for å sikre at ikoner er 16x16 piksler
const iconStyle = `
  .table-action-icon svg {
    width: 16px !important;
    height: 16px !important;
  }
  
  @media (max-width: 640px) {
    .responsive-table th, .responsive-table td {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
    .responsive-table .action-cell {
      padding-left: 0.25rem !important;
      padding-right: 0.25rem !important;
    }
  }
`;

export const StandardTable: FC<ITableData> = function (props: ITableData) {
  const [isMobile, setIsMobile] = useState(false);
  
  useEffect(() => {
    // Sjekk om skjermen er mobil ved lasting
    setIsMobile(window.innerWidth < 640);
    
    // Legg til event listener for å oppdatere ved endring av skjermstørrelse
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };
    
    window.addEventListener('resize', handleResize);
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <style>{iconStyle}</style>
      <div className="overflow-x-auto">
        <Table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600 rounded-lg overflow-hidden responsive-table">
          <Table.Head>
            {props.canMultiSelect && !isSmallScreen() ? (
              <Table.HeadCell className="dark:bg-slate-700 bg-gray-200">
                <Checkbox
                  className="hover:cursor-pointer checked:bg-lgb-primary focus:ring-lgb-blue-300 dark:checked:bg-lgb-primary dark:focus:ring-lgb-blue-300"
                  id="select-all"
                  name="select-all"
                />
              </Table.HeadCell>
            ) : null}
            {props.header.map((header) => (
              <Table.HeadCell
                className="dark:bg-slate-700 bg-gray-200 text-darkBlue dark:text-slate-300 font-semibold text-sm sm:text-base"
                key={header.id}
                hidden={
                  header.hidden || (isSmallScreen() && !header.showOnSmallScreen)
                }
              >
                {header.children}
              </Table.HeadCell>
            ))}
            {props.hasActions ? (
              <Table.HeadCell className="dark:bg-slate-700 bg-gray-200 text-darkBlue dark:text-slate-300 action-cell"></Table.HeadCell>
            ) : null}
          </Table.Head>
          <Table.Body className="divide-y divide-gray-200 bg-white dark:divide-gray-700 dark:bg-darkBlue">
            {props.rows.map((row, index) => (
              <Table.Row
                key={row.id}
                className={`
                  ${index % 2 === 0 ? 'bg-white dark:bg-darkBlue' : 'bg-gray-50 dark:bg-slate-800/30'}
                  ${row.isActive
                    ? "bg-lgb-blue-100 dark:bg-lgb-blue-400 dark:bg-opacity-30 hover:cursor-pointer"
                    : props.onRowClick
                      ? "hover:bg-lgb-blue-100 dark:hover:bg-lgb-blue-400 dark:hover:bg-opacity-30 hover:cursor-pointer"
                      : ""
                  }
                `}
                onClick={() => props.onRowClick?.(row.onRowClickData)}
              >
                {props.canMultiSelect && !isSmallScreen() ? (
                  <Table.Cell className="w-4 py-2 px-3">
                    <div className="flex items-center">
                      <Checkbox
                        className="hover:cursor-pointer checked:bg-lgb-primary focus:ring-lgb-blue-300 dark:checked:bg-lgb-primary dark:focus:ring-lgb-blue-300"
                        aria-describedby="checkbox-1"
                        id={`checkbox-${row.id}`}
                      />
                    </div>
                  </Table.Cell>
                ) : null}
                {row.cells.map((cell) => (
                  <Table.Cell
                    key={cell.id}
                    hidden={
                      cell.hidden || (isSmallScreen() && !cell.showOnSmallScreen)
                    }
                    className={
                      cell.classOverride
                        ? cell.classOverride
                        : "whitespace-nowrap py-2 px-3 text-sm sm:text-base font-normal text-darkBlue dark:text-white"
                    }
                  >
                    {cell.children}
                  </Table.Cell>
                ))}
                {props.hasActions && row.actions ? (
                  <Table.Cell className="w-4 text-right py-2 px-3 text-sm sm:text-base font-medium text-gray-900 dark:text-white action-cell">
                    <div className="flex items-center justify-end">
                      {row.actions.map((action) =>
                        action.children ? (
                          action.children
                        ) : (
                          <Button
                            key={action.id}
                            className="w-8 h-8 sm:w-10 sm:h-10 mx-0.5 sm:mx-1 p-0 flex items-center justify-center bg-transparent hover:bg-transparent active:bg-transparent focus:bg-transparent focus:ring-0 border-0 shadow-none"
                            color="none"
                            onClick={(e: React.MouseEvent) => {
                              e.stopPropagation();
                              action.onActionClick && action.onActionClick(e);
                            }}
                          >
                            <span className="table-action-icon flex items-center justify-center text-lgb-grey-600 dark:text-white hover:text-lgb-blue-300 dark:hover:text-lgb-blue-200 hover:opacity-80 w-full h-full">
                              {action.icon}
                            </span>
                          </Button>
                        ),
                      )}
                    </div>
                  </Table.Cell>
                ) : null}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    </>
  );
};
