import { useTranslation } from "react-i18next";
import { EnhancedRouteItem } from "../types";

export const useStopValidation = (stop: EnhancedRouteItem, routeStops: EnhancedRouteItem[]) => {
  const { t } = useTranslation();

  const getErrorMessage = () => {
    if (!stop.isPickup) {
      // For delivery stops, check each cargo's pickup stop
      const missingPickups = stop.cargos?.filter(cargo => 
        !routeStops.some(s => s.isPickup && s.cargos?.some(c => c.id === cargo.id))
      );

      const wrongOrderPickups = stop.cargos?.filter(cargo => {
        const pickupStop = routeStops.find(s => 
          s.isPickup && 
          s.cargos?.some(c => c.id === cargo.id)
        );
        return pickupStop && pickupStop.stopNumber > stop.stopNumber;
      });

      if (missingPickups?.length) {
        return t('planner:route_planner.error_messages.missing_pickup_for_delivery', {
          count: missingPickups.length,
          stopNumber: stop.stopNumber
        });
      }

      if (wrongOrderPickups?.length) {
        const pickupStop = routeStops.find(s => 
          s.isPickup && 
          s.cargos?.some(c => c.id === wrongOrderPickups[0].id)
        );
        return t('planner:route_planner.error_messages.delivery_before_pickup', {
          deliveryStop: stop.stopNumber,
          pickupStop: pickupStop?.stopNumber
        });
      }
    } else {
      // For pickup stops, check each cargo's delivery stop
      const wrongOrderDeliveries = stop.cargos?.filter(cargo => {
        const deliveryStop = routeStops.find(s => 
          !s.isPickup && 
          s.cargos?.some(c => c.id === cargo.id)
        );
        return deliveryStop && deliveryStop.stopNumber <= stop.stopNumber;
      });

      if (wrongOrderDeliveries?.length) {
        const deliveryStop = routeStops.find(s => 
          !s.isPickup && 
          s.cargos?.some(c => c.id === wrongOrderDeliveries[0].id)
        );
        return t('planner:route_planner.error_messages.pickup_after_delivery', {
          pickupStop: stop.stopNumber,
          deliveryStop: deliveryStop?.stopNumber
        });
      }
    }
    return "";
  };

  return { getErrorMessage };
}; 