import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { GoogleMap } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import { useDarkMode } from "../../../../context/DarkModeContext";
import { containerStyle, initialCenter, darkModeStyles, lightModeStyles } from "../constants";
import { IMapRoute } from "../types";
import { HiInformationCircle } from "react-icons/hi";
import "./map-styles.css";

interface MapProps {
  children?: ReactNode;
  selectedRoute?: IMapRoute;
  isLoading?: boolean;
  message?: string;
  hasListOverlay?: boolean;
  messageTitle?: string;
}

export const Map: FC<MapProps> = ({
  children,
  selectedRoute,
  isLoading = false,
  message,
  hasListOverlay = false,
  messageTitle,
}) => {
  const { t } = useTranslation(["map"]);
  const { isDarkMode } = useDarkMode();
  const mapRef = useRef<google.maps.Map | null>(null);
  const pendingRouteRef = useRef<IMapRoute | undefined>(selectedRoute);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const handleLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    setIsMapLoaded(true);
  };

  // Store the route that needs to be processed
  useEffect(() => {
    pendingRouteRef.current = selectedRoute;
  }, [selectedRoute]);

  // Process the route when loading completes
  useEffect(() => {
    if (!isLoading && pendingRouteRef.current?.route.length && mapRef.current) {
      const coordinates = pendingRouteRef.current.route
        .filter(stop => stop.location.coordinate?.lat && stop.location.coordinate?.lon)
        .map(stop => ({
          lat: stop.location.coordinate?.lat ?? 0,
          lng: stop.location.coordinate?.lon ?? 0,
        }));

      if (coordinates.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        coordinates.forEach((coord) => bounds.extend(coord));
        
        // If we have a list overlay, extend the bounds to account for it
        if (hasListOverlay) {
          const sw = bounds.getSouthWest();
          const ne = bounds.getNorthEast();
          const offset = (ne.lng() - sw.lng()) * 0.2; // 20% offset for the list
          bounds.extend(new window.google.maps.LatLng(sw.lat(), sw.lng() - offset));
        }
        
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [isLoading, selectedRoute, hasListOverlay]);

  return (
    <div className="relative h-full map-container">
      <div className={`map-gradient-overlay ${isDarkMode ? 'dark' : 'light'}`}></div>
      
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={initialCenter}
        zoom={4}
        onLoad={handleLoad}
        options={{
          streetViewControl: true,
          mapTypeControl: true,
          fullscreenControl: true,
          mapTypeControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP,
            style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR
          },
          fullscreenControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP
          },
          styles: isDarkMode ? darkModeStyles : lightModeStyles,
          gestureHandling: "greedy",
        }}
      >
        {!isLoading && !message && children}
      </GoogleMap>
      
      {/* Map corner decorations */}
      <div className="map-corner-decoration top-left"></div>
      <div className="map-corner-decoration top-right"></div>
      <div className="map-corner-decoration bottom-left"></div>
      <div className="map-corner-decoration bottom-right"></div>
      
      {/* Loading overlay with improved animation */}
      {isLoading && (
        <div className="absolute inset-0 bg-black/20 dark:bg-black/40 flex items-center justify-center backdrop-blur-sm transition-all duration-300">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg flex flex-col items-center">
            <div className="loading-spinner">
              <div className="spinner-inner"></div>
            </div>
            <p className="mt-4 text-gray-700 dark:text-gray-300 font-medium">
              {t("common:loading")}
            </p>
          </div>
        </div>
      )}
      
      {/* Message overlay with improved styling */}
      {message && !isLoading && (
        <div className="absolute inset-0 bg-black/10 dark:bg-black/20 flex items-center justify-center backdrop-blur-sm transition-all duration-300">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg max-w-md mx-4 border-t-4 border-lgb-blue-300">
            <div className="flex items-start">
              <div className="flex-shrink-0 text-lgb-blue-300">
                <HiInformationCircle className="h-6 w-6" />
              </div>
              <div className="ml-3">
                {messageTitle && (
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
                    {messageTitle}
                  </h3>
                )}
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  {message}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* Map loaded animation */}
      {isMapLoaded && (
        <div className="map-loaded-animation"></div>
      )}
    </div>
  );
}; 