import type { FC } from "react";
import { HiOutlineChartBar, HiOutlineClipboardList, HiOutlineUsers, HiOutlineOfficeBuilding, HiOutlineCube, HiOutlineLocationMarker, HiOutlineDocumentText, HiOutlineCog, HiOutlineUserCircle } from "react-icons/hi";

export const NewMessageIcon: FC = function () {
  return (
    <svg
      className="h-3 w-3 text-white"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
      <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
    </svg>
  );
};

export const NewFollowIcon: FC = function () {
  return (
    <svg
      className="h-3 w-3 text-white"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"></path>
    </svg>
  );
};

export const NewLoveIcon: FC = function () {
  return (
    <svg
      className="h-3 w-3 text-white"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const NewMentionIcon: FC = function () {
  return (
    <svg
      className="h-3 w-3 text-white"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const NewVideoIcon: FC = function () {
  return (
    <svg
      className="h-3 w-3 text-white"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
    </svg>
  );
};

export const ClipboardIcon = (
  <svg
    className="w-[24px] h-[24px]"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="20"
    fill="none"
    viewBox="0 0 18 20"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="2"
      d="M12 2h4a1 1 0 0 1 1 1v15a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h4m6 0v3H6V2m6 0a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1M5 5h8m-5 5h5m-8 0h.01M5 14h.01M8 14h5"
    />
  </svg>
);

export const UserIcon = (
  <svg
    className="w-[24px] h-[24px]"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 14 18"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M7 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-2 3h4a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"
    />
  </svg>
);

export const MapPinIcon = (
  <svg
    className="w-[24px] h-[24px]"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 17 21"
  >
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path d="M8 12a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
      <path d="M13.8 12.938h-.01a7 7 0 1 0-11.465.144h-.016l.141.17c.1.128.2.252.3.372L8 20l5.13-6.248c.193-.209.373-.429.54-.66l.13-.154Z" />
    </g>
  </svg>
);

export const PhoneIcon = (
  <svg
    className="w-[24px] h-[24px]"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 18 18"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="m16.344 12.168-1.4-1.4a1.98 1.98 0 0 0-2.8 0l-.7.7a1.98 1.98 0 0 1-2.8 0l-2.1-2.1a1.98 1.98 0 0 1 0-2.8l.7-.7a1.981 1.981 0 0 0 0-2.8l-1.4-1.4a1.828 1.828 0 0 0-2.8 0C-.638 5.323 1.1 9.542 4.78 13.22c3.68 3.678 7.9 5.418 11.564 1.752a1.828 1.828 0 0 0 0-2.804Z"
    />
  </svg>
);

export const TruckIcon = (
  <svg
    className="w-[24px] h-[24px]"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 16"
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M15.5 10.25a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Zm0 0a2.225 2.225 0 0 0-1.666.75H12m3.5-.75a2.225 2.225 0 0 1 1.666.75H19V7m-7 4V3h5l2 4m-7 4H6.166a2.225 2.225 0 0 0-1.666-.75M12 11V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v9h1.834a2.225 2.225 0 0 1 1.666-.75M19 7h-6m-8.5 3.25a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z"
    />
  </svg>
);

export const UnderProgress: FC = function () {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1767_26498)">
        <path
          d="M18.1268 14.2695C16.4586 14.2695 15.1016 15.6266 15.1016 17.2947C15.1016 18.9629 16.4586 20.32 18.1268 20.32C19.7952 20.32 21.152 18.9629 21.152 17.2947C21.152 15.6266 19.7949 14.2695 18.1268 14.2695ZM18.1268 18.8074C17.2926 18.8074 16.6142 18.1289 16.6142 17.2947C16.6142 16.4606 17.2926 15.7821 18.1268 15.7821C18.961 15.7821 19.6394 16.4606 19.6394 17.2947C19.6394 18.129 18.961 18.8074 18.1268 18.8074Z"
          fill="#7214FF"
        />
        <path
          d="M7.79084 14.2695C6.1227 14.2695 4.76562 15.6266 4.76562 17.2947C4.76562 18.9629 6.1227 20.32 7.79084 20.32C9.45898 20.32 10.8161 18.9629 10.8161 17.2947C10.8161 15.6266 9.45898 14.2695 7.79084 14.2695ZM7.79084 18.8074C6.95666 18.8074 6.27823 18.1289 6.27823 17.2947C6.27823 16.4606 6.95666 15.7821 7.79084 15.7821C8.6248 15.7821 9.30345 16.4606 9.30345 17.2947C9.30345 18.129 8.62503 18.8074 7.79084 18.8074Z"
          fill="#7214FF"
        />
        <path
          d="M20.163 5.6118C20.0344 5.35642 19.773 5.19531 19.4871 5.19531H15.5039V6.70792H19.0207L21.0801 10.804L22.4319 10.1244L20.163 5.6118Z"
          fill="#7214FF"
        />
        <path
          d="M15.8555 16.5625H10.1328V18.0751H15.8555V16.5625Z"
          fill="#7214FF"
        />
        <path
          d="M5.52262 16.5625H2.90081C2.48306 16.5625 2.14453 16.9011 2.14453 17.3188C2.14453 17.7365 2.48311 18.0751 2.90081 18.0751H5.52267C5.94042 18.0751 6.27895 17.7365 6.27895 17.3188C6.27895 16.901 5.94038 16.5625 5.52262 16.5625Z"
          fill="#7214FF"
        />
        <path
          d="M23.8428 11.9385L22.3552 10.0225C22.2123 9.83798 21.9917 9.73008 21.758 9.73008H16.2622V4.43597C16.2622 4.01822 15.9236 3.67969 15.5059 3.67969H2.90081C2.48306 3.67969 2.14453 4.01827 2.14453 4.43597C2.14453 4.85367 2.48311 5.19225 2.90081 5.19225H14.7495V10.4864C14.7495 10.9041 15.0881 11.2426 15.5058 11.2426H21.3876L22.489 12.6615V16.562H20.3966C19.9788 16.562 19.6403 16.9005 19.6403 17.3182C19.6403 17.736 19.9789 18.0745 20.3966 18.0745H23.2453C23.6631 18.0745 24.0016 17.736 24.0016 17.3182V12.4024C24.0016 12.2345 23.9457 12.0711 23.8428 11.9385Z"
          fill="#7214FF"
        />
        <path
          d="M5.46967 12.7305H1.99066C1.57291 12.7305 1.23438 13.069 1.23438 13.4868C1.23438 13.9045 1.57295 14.243 1.99066 14.243H5.46962C5.88737 14.243 6.22591 13.9045 6.22591 13.4868C6.22595 13.069 5.88737 12.7305 5.46967 12.7305Z"
          fill="#7214FF"
        />
        <path
          d="M7.21008 9.75781H0.756281C0.338578 9.75781 0 10.0964 0 10.5141C0 10.9319 0.338578 11.2704 0.756281 11.2704H7.21008C7.62783 11.2704 7.96636 10.9318 7.96636 10.5141C7.96636 10.0964 7.62783 9.75781 7.21008 9.75781Z"
          fill="#7214FF"
        />
        <path
          d="M8.44445 6.78125H1.99066C1.57291 6.78125 1.23438 7.11983 1.23438 7.53753C1.23438 7.95528 1.57295 8.29381 1.99066 8.29381H8.44445C8.8622 8.29381 9.20073 7.95523 9.20073 7.53753C9.20078 7.11983 8.8622 6.78125 8.44445 6.78125Z"
          fill="#7214FF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1767_26498">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const WaitingOrders: FC = function () {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1767_26478)">
        <path
          d="M14.4242 21.857C14.2263 21.9063 14.0245 21.9501 13.8242 21.987C13.3007 22.0843 12.9546 22.5878 13.0514 23.1118C13.0992 23.3696 13.2456 23.5844 13.4444 23.7245C13.6493 23.8687 13.91 23.9338 14.1757 23.8844C14.4142 23.8402 14.6546 23.7881 14.8905 23.7292C15.4074 23.6006 15.7224 23.0768 15.5934 22.5601C15.4648 22.0429 14.9415 21.7282 14.4242 21.857Z"
          fill="#FCBD24"
        />
        <path
          d="M21.5573 8.92779C21.6249 9.13147 21.754 9.29775 21.9169 9.41264C22.1586 9.58284 22.4748 9.6401 22.7765 9.54032C23.2824 9.37235 23.5565 8.82683 23.3892 8.32118C23.3129 8.09068 23.2283 7.85945 23.1382 7.63431C22.9403 7.13955 22.3791 6.89867 21.8841 7.09658C21.3896 7.29438 21.1486 7.85573 21.3467 8.35064C21.4224 8.53988 21.4933 8.73412 21.5573 8.92779Z"
          fill="#FCBD24"
        />
        <path
          d="M17.5604 20.4803C17.3902 20.5927 17.2145 20.7014 17.0375 20.8033C16.5759 21.0697 16.4177 21.6598 16.6839 22.1213C16.7562 22.2468 16.8525 22.3494 16.9634 22.4278C17.261 22.6372 17.6652 22.6686 18.0017 22.4747C18.2121 22.3534 18.4213 22.2243 18.624 22.0903C19.0684 21.7967 19.1907 21.1981 18.897 20.7535C18.6033 20.3087 18.0049 20.1865 17.5604 20.4803Z"
          fill="#FCBD24"
        />
        <path
          d="M23.9914 11.6223C23.9704 11.0898 23.5218 10.6753 22.9892 10.6961C22.4572 10.7171 22.0424 11.1657 22.0633 11.6981C22.0713 11.9016 22.0733 12.1082 22.0686 12.3115C22.0611 12.6454 22.2245 12.9431 22.4783 13.122C22.6295 13.2285 22.813 13.2929 23.012 13.2975C23.5445 13.3093 23.9858 12.8869 23.9977 12.3541C24.003 12.1112 24.001 11.8651 23.9914 11.6223Z"
          fill="#FCBD24"
        />
        <path
          d="M21.402 17.9433C20.975 17.623 20.3709 17.7099 20.0511 18.1362C19.9286 18.2994 19.7996 18.4606 19.6675 18.6159C19.3224 19.0214 19.3712 19.6305 19.7767 19.9759C19.7998 19.9955 19.8231 20.0135 19.8474 20.0304C20.2505 20.3146 20.8108 20.2493 21.1367 19.8668C21.2945 19.6816 21.4482 19.489 21.5946 19.294C21.9144 18.8678 21.8279 18.2633 21.402 17.9433Z"
          fill="#FCBD24"
        />
        <path
          d="M22.8218 14.4546C22.3134 14.2952 21.7719 14.5782 21.6126 15.0866C21.5516 15.2809 21.484 15.476 21.4111 15.6672C21.2509 16.088 21.4044 16.5509 21.7562 16.799C21.8208 16.8444 21.8919 16.8828 21.9691 16.912C22.4669 17.102 23.0243 16.8522 23.2141 16.3542C23.3007 16.127 23.3811 15.8948 23.4538 15.6638C23.6129 15.1553 23.3301 14.614 22.8218 14.4546Z"
          fill="#FCBD24"
        />
        <path
          d="M10.2149 21.9973C9.35229 21.8424 8.52483 21.5786 7.74019 21.2108C7.7309 21.206 7.72259 21.2004 7.71284 21.196C7.52794 21.109 7.34335 21.0157 7.16443 20.9183C7.16381 20.9176 7.16268 20.9172 7.1617 20.9167C6.83343 20.736 6.51305 20.5359 6.2018 20.3168C1.66326 17.1198 0.572018 10.8263 3.7693 6.28783C4.46454 5.30131 5.30588 4.47829 6.24385 3.82597C6.25541 3.81792 6.26696 3.80993 6.27841 3.80183C9.58368 1.52441 14.0717 1.37093 17.5814 3.71118L16.8276 4.80031C16.618 5.10346 16.7469 5.32437 17.1138 5.29135L20.3881 4.99822C20.7554 4.9652 20.9751 4.64745 20.8763 4.29271L19.9971 1.12473C19.8986 0.769584 19.6466 0.727073 19.4368 1.03017L18.6813 2.12188C16.1057 0.39292 13.0165 -0.266358 9.95028 0.265336C9.64146 0.318784 9.33697 0.384201 9.03666 0.460349C9.03434 0.460762 9.03248 0.46102 9.03063 0.461432C9.01902 0.46427 9.00725 0.468036 8.99596 0.471183C6.35193 1.15017 4.04505 2.69227 2.39895 4.89251C2.38507 4.90896 2.37078 4.92506 2.35768 4.94296C2.30294 5.01669 2.24861 5.09211 2.19542 5.16754C2.10844 5.29115 2.0227 5.41786 1.94067 5.54456C1.9304 5.55983 1.92256 5.57536 1.91358 5.59079C0.5552 7.69574 -0.100002 10.1293 0.0123622 12.6068C0.0126202 12.6149 0.0121558 12.6231 0.0123622 12.6315C0.0232478 12.8735 0.0424396 13.1189 0.0684929 13.3604C0.0698858 13.376 0.0733424 13.3907 0.0759736 13.4063C0.102904 13.6492 0.136696 13.8927 0.179052 14.1361C0.609525 16.6195 1.78105 18.8543 3.53652 20.5932C3.5406 20.5972 3.54483 20.6017 3.54896 20.6059C3.5504 20.6075 3.552 20.6083 3.55339 20.6098C4.02504 21.075 4.53805 21.5053 5.09044 21.8943C6.53601 22.9129 8.14569 23.5862 9.87439 23.8965C10.3989 23.9907 10.9 23.6416 10.9941 23.1173C11.0881 22.5927 10.7393 22.0912 10.2149 21.9973Z"
          fill="#FCBD24"
        />
        <path
          d="M11.4098 4.28906C10.9784 4.28906 10.6289 4.63885 10.6289 5.06968V12.8468L17.7417 16.5237C17.8562 16.583 17.9788 16.6109 18.0994 16.6109C18.3819 16.6109 18.6548 16.4571 18.7935 16.1887C18.9913 15.8056 18.8416 15.3349 18.4585 15.137L12.1899 11.8962V5.06968C12.1898 4.63885 11.8408 4.28906 11.4098 4.28906Z"
          fill="#FCBD24"
        />
      </g>
      <defs>
        <clipPath id="clip0_1767_26478">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CompletedOrders: FC = function () {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0002 0C5.38324 0 0 5.38316 0 12C0 18.6168 5.38324 24 12.0002 24C18.617 24 24.0002 18.6168 24.0002 12C24.0002 5.38316 18.617 0 12.0002 0ZM12.0002 22.0328C6.46796 22.0328 1.96721 17.5322 1.96721 12C1.96721 6.46788 6.46796 1.96721 12.0002 1.96721C17.5323 1.96721 22.0329 6.46788 22.0329 12C22.0329 17.5322 17.5323 22.0328 12.0002 22.0328Z"
        fill="#0AAF87"
      />
      <path
        d="M17.1905 7.39352L10.0656 14.5185L6.80802 11.261C6.42386 10.8769 5.80112 10.8769 5.41697 11.261C5.03289 11.6451 5.03289 12.2679 5.41697 12.652L9.37004 16.6051C9.56212 16.7971 9.81384 16.8932 10.0656 16.8932C10.3173 16.8932 10.569 16.7971 10.7611 16.6051L18.5816 8.78465C18.9656 8.4005 18.9656 7.77775 18.5816 7.3936C18.1974 7.00944 17.5746 7.00944 17.1905 7.39352Z"
        fill="#0AAF87"
      />
    </svg>
  );
};

export const FailedOrders: FC = function () {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9961 24C10.4201 24.0029 8.85916 23.6943 7.40282 23.0921C5.94648 22.4898 4.62355 21.6056 3.50997 20.4905C-1.16999 15.8105 -1.16999 8.1962 3.50997 3.51625C4.62186 2.39811 5.94452 1.5116 7.40135 0.908044C8.85813 0.304482 10.4202 -0.00414032 11.9971 4.19436e-05C15.2031 4.19436e-05 18.2172 1.24829 20.4832 3.51625C22.7502 5.78327 23.9994 8.79727 23.9994 12.0034C23.9994 15.2095 22.7512 18.2235 20.4832 20.4905C19.3695 21.6056 18.0463 22.4898 16.5899 23.0921C15.1334 23.6943 13.5722 24.0029 11.9961 24ZM11.9971 1.92042C10.6724 1.91666 9.36022 2.17577 8.13643 2.68276C6.91264 3.18975 5.80159 3.93453 4.86768 4.87394C2.96362 6.77804 1.91509 9.31002 1.91509 12.0034C1.91509 14.6967 2.96362 17.2278 4.86768 19.1328C8.79868 23.0638 15.1955 23.0648 19.1256 19.1328C21.0296 17.2287 22.0791 14.6967 22.0791 12.0034C22.0791 9.31002 21.0305 6.77896 19.1256 4.87394C18.1916 3.9348 17.0807 3.19022 15.8571 2.68325C14.6335 2.17628 13.3216 1.917 11.9971 1.92042Z"
        fill="#FE2F67"
      />
      <path
        d="M7.92229 17.0361C7.73221 17.0366 7.54626 16.9805 7.38811 16.8751C7.22996 16.7697 7.10667 16.6196 7.03395 16.444C6.96118 16.2684 6.94231 16.0751 6.97957 15.8887C7.01687 15.7023 7.10873 15.5312 7.24345 15.3971L15.3906 7.24992C15.4798 7.16079 15.5856 7.09008 15.7021 7.04179C15.8185 6.99355 15.9434 6.96875 16.0695 6.96875C16.1955 6.96875 16.3204 6.99355 16.4369 7.04179C16.5534 7.09008 16.6592 7.16079 16.7483 7.24992C16.8375 7.33906 16.9082 7.4449 16.9565 7.56139C17.0047 7.67788 17.0296 7.8027 17.0296 7.92877C17.0296 8.05483 17.0047 8.1797 16.9565 8.2962C16.9082 8.41264 16.8375 8.51847 16.7483 8.60761L8.60113 16.7549C8.51221 16.8443 8.40643 16.9152 8.28988 16.9635C8.17339 17.0118 8.04841 17.0365 7.92229 17.0361Z"
        fill="#FE2F67"
      />
      <path
        d="M16.0721 17.0361C15.946 17.0363 15.8211 17.0115 15.7046 16.9632C15.5881 16.915 15.4823 16.8441 15.3933 16.7549L7.24602 8.60761C7.15688 8.51847 7.08618 8.41264 7.03794 8.2962C6.9897 8.1797 6.96484 8.05483 6.96484 7.92877C6.96484 7.8027 6.9897 7.67788 7.03794 7.56139C7.08618 7.4449 7.15688 7.33906 7.24602 7.24992C7.33521 7.16079 7.44105 7.09008 7.55749 7.04179C7.67398 6.99355 7.79885 6.96875 7.92491 6.96875C8.05098 6.96875 8.1758 6.99355 8.29229 7.04179C8.40878 7.09008 8.51462 7.16079 8.60376 7.24992L16.751 15.3971C16.8857 15.5312 16.9775 15.7023 17.0148 15.8887C17.0521 16.0751 17.0332 16.2684 16.9604 16.444C16.8877 16.6196 16.7644 16.7697 16.6063 16.8751C16.4481 16.9805 16.2622 17.0366 16.0721 17.0361Z"
        fill="#FE2F67"
      />
    </svg>
  );
};

export const FailedOrdersIcon: FC = function () {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9961 24C10.4201 24.0029 8.85916 23.6943 7.40282 23.0921C5.94648 22.4898 4.62355 21.6056 3.50997 20.4905C-1.16999 15.8105 -1.16999 8.1962 3.50997 3.51625C4.62186 2.39811 5.94452 1.5116 7.40135 0.908044C8.85813 0.304482 10.4202 -0.00414032 11.9971 4.19436e-05C15.2031 4.19436e-05 18.2172 1.24829 20.4832 3.51625C22.7502 5.78327 23.9994 8.79727 23.9994 12.0034C23.9994 15.2095 22.7512 18.2235 20.4832 20.4905C19.3695 21.6056 18.0463 22.4898 16.5899 23.0921C15.1334 23.6943 13.5722 24.0029 11.9961 24ZM11.9971 1.92042C10.6724 1.91666 9.36022 2.17577 8.13643 2.68276C6.91264 3.18975 5.80159 3.93453 4.86768 4.87394C2.96362 6.77804 1.91509 9.31002 1.91509 12.0034C1.91509 14.6967 2.96362 17.2278 4.86768 19.1328C8.79868 23.0638 15.1955 23.0648 19.1256 19.1328C21.0296 17.2287 22.0791 14.6967 22.0791 12.0034C22.0791 9.31002 21.0305 6.77896 19.1256 4.87394C18.1916 3.9348 17.0807 3.19022 15.8571 2.68325C14.6335 2.17628 13.3216 1.917 11.9971 1.92042Z"
        fill="#FE2F67"
      />
      <path
        d="M7.92229 17.0361C7.73221 17.0366 7.54626 16.9805 7.38811 16.8751C7.22996 16.7697 7.10667 16.6196 7.03395 16.444C6.96118 16.2684 6.94231 16.0751 6.97957 15.8887C7.01687 15.7023 7.10873 15.5312 7.24345 15.3971L15.3906 7.24992C15.4798 7.16079 15.5856 7.09008 15.7021 7.04179C15.8185 6.99355 15.9434 6.96875 16.0695 6.96875C16.1955 6.96875 16.3204 6.99355 16.4369 7.04179C16.5534 7.09008 16.6592 7.16079 16.7483 7.24992C16.8375 7.33906 16.9082 7.4449 16.9565 7.56139C17.0047 7.67788 17.0296 7.8027 17.0296 7.92877C17.0296 8.05483 17.0047 8.1797 16.9565 8.2962C16.9082 8.41264 16.8375 8.51847 16.7483 8.60761L8.60113 16.7549C8.51221 16.8443 8.40643 16.9152 8.28988 16.9635C8.17339 17.0118 8.04841 17.0365 7.92229 17.0361Z"
        fill="#FE2F67"
      />
      <path
        d="M16.0721 17.0361C15.946 17.0363 15.8211 17.0115 15.7046 16.9632C15.5881 16.915 15.4823 16.8441 15.3933 16.7549L7.24602 8.60761C7.15688 8.51847 7.08618 8.41264 7.03794 8.2962C6.9897 8.1797 6.96484 8.05483 6.96484 7.92877C6.96484 7.8027 6.9897 7.67788 7.03794 7.56139C7.08618 7.4449 7.15688 7.33906 7.24602 7.24992C7.33521 7.16079 7.44105 7.09008 7.55749 7.04179C7.67398 6.99355 7.79885 6.96875 7.92491 6.96875C8.05098 6.96875 8.1758 6.99355 8.29229 7.04179C8.40878 7.09008 8.51462 7.16079 8.60376 7.24992L16.751 15.3971C16.8857 15.5312 16.9775 15.7023 17.0148 15.8887C17.0521 16.0751 17.0332 16.2684 16.9604 16.444C16.8877 16.6196 16.7644 16.7697 16.6063 16.8751C16.4481 16.9805 16.2622 17.0366 16.0721 17.0361Z"
        fill="#FE2F67"
      />
    </svg>
  );
};

export const WorkSpaceIcon: FC = function () {
  return <HiOutlineOfficeBuilding className="w-6 h-6" />;
};

export const WorkSpaceIconCircle: FC = function () {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#7214FF" />
      <g clipPath="url(#clip0_6057_50)">
        <path
          d="M15.7647 9.58582H11.4353L10.5741 8.56229C10.5451 8.52747 10.5017 8.50794 10.4565 8.50935H6.96353C6.43024 8.51323 6 8.94664 6 9.47994V15.7752C6.00065 16.3103 6.43429 16.744 6.96941 16.7446H15.7647C16.2998 16.744 16.7335 16.3103 16.7341 15.7752V10.5552C16.7335 10.0201 16.2998 9.58647 15.7647 9.58582Z"
          fill="white"
        />
        <path
          d="M17.0373 8.33191H12.7068L11.8456 7.30838C11.8166 7.27356 11.7732 7.25403 11.7279 7.25544H8.23617C7.75228 7.25624 7.34311 7.61371 7.27734 8.09309H10.462C10.629 8.09268 10.7876 8.16638 10.895 8.29427L11.6303 9.16838H15.7656C16.5304 9.16968 17.1502 9.78941 17.1515 10.5543V15.4813C17.6364 15.4208 18.0005 15.0089 18.0009 14.5201V9.30015C17.9996 8.76777 17.5697 8.3358 17.0373 8.33191Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6057_50">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const UserProfile: FC = function () {
  return <HiOutlineUserCircle className="w-6 h-6" />;
};

export const CopyIcon: React.FC<
  React.SVGProps<SVGSVGElement> & { onClick?: () => void }
> = ({ onClick, ...props }) => {
  return (
    <svg
      height="512pt"
      viewBox="-40 0 512 512"
      width="512pt"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick} // Add onClick event
      {...props} // Spread other SVG props
    >
      <path d="m271 512h-191c-44.113281 0-80-35.886719-80-80v-271c0-44.113281 35.886719-80 80-80h191c44.113281 0 80 35.886719 80 80v271c0 44.113281-35.886719 80-80 80zm-191-391c-22.054688 0-40 17.945312-40 40v271c0 22.054688 17.945312 40 40 40h191c22.054688 0 40-17.945312 40-40v-271c0-22.054688-17.945312-40-40-40zm351 261v-302c0-44.113281-35.886719-80-80-80h-222c-11.046875 0-20 8.953125-20 20s8.953125 20 20 20h222c22.054688 0 40 17.945312 40 40v302c0 11.046875 8.953125 20 20 20s20-8.953125 20-20zm0 0" />
    </svg>
  );
};

export const PenIcon: React.FC<
  React.SVGProps<SVGSVGElement> & { className?: string }
> = ({ className, ...props }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.51176 3.00943C9.64091 2.87572 9.79539 2.76907 9.9662 2.69569C10.137 2.62232 10.3207 2.5837 10.5066 2.58208C10.6925 2.58047 10.8768 2.61589 11.0489 2.68628C11.221 2.75668 11.3773 2.86063 11.5087 2.99208C11.6402 3.12353 11.7441 3.27984 11.8145 3.4519C11.8849 3.62395 11.9203 3.80831 11.9187 3.9942C11.9171 4.18009 11.8785 4.3638 11.8051 4.5346C11.7317 4.70541 11.6251 4.85989 11.4914 4.98903L10.9363 5.54413L8.95666 3.56453L9.51176 3.00943V3.00943ZM7.96686 4.55433L2.10156 10.4196V12.3992H4.08116L9.94716 6.53393L7.96616 4.55433H7.96686Z"
        fill="white"
      />
    </svg>
  );
};

export const UserAvatar: React.FC<
  React.SVGProps<SVGSVGElement> & { className?: string }
> = ({ className, ...props }) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className} // Add the className prop
      {...props} // Spread other SVG props
    >
      <path
        d="M40 78.75C61.401 78.75 78.75 61.401 78.75 40C78.75 18.599 61.401 1.25 40 1.25C18.599 1.25 1.25 18.599 1.25 40C1.25 61.401 18.599 78.75 40 78.75Z"
        fill="#E5D4FF"
      />
      <path
        d="M71.097 63.0935C67.483 58.2551 62.7903 54.3263 57.3918 51.6193C51.9933 48.9124 46.0378 47.5019 39.9987 47.5C33.9595 47.4981 28.0032 48.9048 22.603 51.6084C17.2028 54.312 12.5076 58.2378 8.89062 63.074C12.4892 67.9347 17.1767 71.8846 22.5773 74.6069C27.9778 77.3292 33.941 78.7481 39.9889 78.75C46.0368 78.7519 52.0009 77.3367 57.4031 74.6178C62.8054 71.8989 67.4953 67.952 71.097 63.0935Z"
        fill="#7214FF"
      />
      <path
        d="M40 42.5C48.2843 42.5 55 35.7843 55 27.5C55 19.2157 48.2843 12.5 40 12.5C31.7157 12.5 25 19.2157 25 27.5C25 35.7843 31.7157 42.5 40 42.5Z"
        fill="#7214FF"
      />
    </svg>
  );
};

export const VerifiedBadge: FC = function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1254 6.67117C13.9088 6.5453 13.7143 6.38497 13.5494 6.1965C13.566 5.93411 13.6285 5.67667 13.734 5.43584C13.928 4.8885 14.1474 4.2685 13.7954 3.7865C13.4434 3.3045 12.7787 3.31984 12.1954 3.33317C11.9376 3.35969 11.6772 3.34211 11.4254 3.28117C11.2912 3.06284 11.1954 2.82316 11.142 2.5725C10.9767 2.00917 10.788 1.3725 10.2087 1.18184C9.65003 1.00184 9.1327 1.39784 8.67537 1.74584C8.47803 1.92605 8.24937 2.06862 8.0007 2.1665C7.74943 2.06942 7.51823 1.92681 7.3187 1.74584C6.8627 1.39984 6.34737 0.999837 5.78603 1.1825C5.20803 1.3705 5.01936 2.00917 4.8527 2.5725C4.79945 2.82235 4.70457 3.06146 4.57203 3.27984C4.31971 3.34061 4.05898 3.35864 3.8007 3.33317C3.21536 3.31717 2.55603 3.29984 2.2007 3.7865C1.84536 4.27317 2.06736 4.8885 2.26203 5.43517C2.36899 5.67564 2.43242 5.93319 2.44936 6.19584C2.28475 6.38455 2.09041 6.54511 1.87403 6.67117C1.38603 7.0045 0.832031 7.38384 0.832031 7.99984C0.832031 8.61584 1.38603 8.99384 1.87403 9.3285C2.09036 9.45436 2.2847 9.61469 2.44936 9.80317C2.4342 10.0657 2.37258 10.3235 2.26736 10.5645C2.07403 11.1112 1.85536 11.7312 2.2067 12.2132C2.55803 12.6952 3.2207 12.6798 3.8067 12.6665C4.06468 12.64 4.32529 12.6575 4.57736 12.7185C4.71095 12.9371 4.80653 13.1767 4.86003 13.4272C5.02536 13.9905 5.21403 14.6272 5.79336 14.8178C5.88625 14.8476 5.98316 14.8629 6.0807 14.8632C6.5495 14.7959 6.98522 14.5827 7.32603 14.2538C7.52337 14.0736 7.75203 13.9311 8.0007 13.8332C8.25197 13.9303 8.48317 14.0729 8.6827 14.2538C9.13936 14.6025 9.6567 15.0005 10.216 14.8172C10.794 14.6292 10.9827 13.9905 11.1494 13.4278C11.2028 13.1775 11.2984 12.9381 11.432 12.7198C11.6834 12.6586 11.9433 12.6406 12.2007 12.6665C12.786 12.6805 13.4454 12.6998 13.8007 12.2132C14.156 11.7265 13.934 11.1112 13.7394 10.5638C13.6331 10.3236 13.5697 10.0666 13.552 9.8045C13.7167 9.61562 13.9113 9.45504 14.128 9.32917C14.616 8.99584 15.17 8.61584 15.17 7.99984C15.17 7.38384 14.614 7.00517 14.1254 6.67117Z"
        fill="#7214FF"
      />
      <path
        d="M7.33258 9.83203C7.26691 9.83216 7.20187 9.81926 7.14123 9.79408C7.08058 9.76891 7.02552 9.73196 6.97925 9.68537L5.64591 8.35203C5.55759 8.25725 5.50951 8.13189 5.5118 8.00235C5.51408 7.87282 5.56656 7.74923 5.65817 7.65762C5.74977 7.56601 5.87336 7.51354 6.0029 7.51125C6.13243 7.50896 6.2578 7.55705 6.35258 7.64537L7.37925 8.67203L9.69925 6.93203C9.80533 6.85247 9.93868 6.8183 10.07 6.83706C10.2012 6.85581 10.3197 6.92595 10.3992 7.03203C10.4788 7.13812 10.513 7.27147 10.4942 7.40274C10.4755 7.53402 10.4053 7.65247 10.2992 7.73203L7.63258 9.73203C7.54601 9.7969 7.44076 9.83199 7.33258 9.83203Z"
        fill="white"
      />
    </svg>
  );
};

export const NotVerifiedBadge: FC = function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1849_26645)">
        <path
          d="M15.5897 12.9806L3.05051 0.441403C2.50364 -0.105468 1.61703 -0.105468 1.07101 0.441403L0.410153 1.10124C-0.136718 1.64828 -0.136718 2.53489 0.410153 3.08091L12.9494 15.6201C13.4964 16.167 14.383 16.167 14.929 15.6201L15.5889 14.9603C16.1368 14.4143 16.1368 13.5275 15.5897 12.9806Z"
          fill="#FE2F67"
        />
        <path
          d="M12.9494 0.441275L0.410153 12.9805C-0.136718 13.5274 -0.136718 14.4142 0.410153 14.9602L1.06999 15.62C1.61703 16.1669 2.50364 16.1669 3.04966 15.62L15.5897 3.08163C16.1368 2.53476 16.1368 1.64815 15.5897 1.10213L14.9299 0.4423C14.383 -0.105596 13.4964 -0.105596 12.9494 0.441275Z"
          fill="#FE2F67"
        />
      </g>
      <defs>
        <clipPath id="clip0_1849_26645">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const dashboardIcon: FC = function () {
  return <HiOutlineChartBar className="w-6 h-6" />;
};

export const orderIcon: FC = function () {
  return <HiOutlineClipboardList className="w-6 h-6" />;
};

export const administrationIcon: FC = function () {
  return <HiOutlineCog className="w-6 h-6" />;
};

export const inventoryIcon: FC = function () {
  return <HiOutlineCube className="w-6 h-6" />;
};

export const mapIcon: FC = function () {
  return <HiOutlineLocationMarker className="w-6 h-6" />;
};

export const customersIcon: FC = function () {
  return <HiOutlineUsers className="w-6 h-6" />;
};

export const PickUpArrow: FC = function () {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12.5" r="12" fill="#F1E8FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0093 6.125L7.06013 10.9736C6.64662 11.3787 6.64662 12.0415 7.06013 12.4466C7.47364 12.8517 8.15017 12.8517 8.56368 12.4466L13.5128 7.59799L12.0093 6.125Z"
        fill="#4629D1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0114 6.125L16.9605 10.9736C17.374 11.3787 17.374 12.0415 16.9605 12.4466C16.547 12.8517 15.8705 12.8517 15.4569 12.4466L10.5078 7.59799L12.0114 6.125Z"
        fill="#4629D1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9453 8.21484V17.0831C10.9453 17.656 11.4237 18.1247 12.0085 18.1247C12.5933 18.1247 13.0716 17.656 13.0716 17.0831V8.21484H10.9453Z"
        fill="#4629D1"
      />
    </svg>
  );
};

export const DeliveryArrow: FC = function () {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12.5" r="12" fill="#E7F7F3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0103 18.125L16.9594 13.2764C17.3729 12.8713 17.3729 12.2085 16.9594 11.8034C16.5459 11.3983 15.8694 11.3983 15.4559 11.8034L10.5067 16.652L12.0103 18.125Z"
        fill="#0AAF87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0082 18.125L7.05905 13.2764C6.64554 12.8713 6.64554 12.2085 7.05905 11.8034C7.47256 11.3983 8.14908 11.3983 8.56259 11.8034L13.5117 16.652L12.0082 18.125Z"
        fill="#0AAF87"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0742 16.0352V7.16687C13.0742 6.59396 12.5958 6.12531 12.0111 6.12531C11.4263 6.12531 10.9479 6.59396 10.9479 7.16687V16.0352H13.0742Z"
        fill="#0AAF87"
      />
    </svg>
  );
};

export const DeliveryCompleted: FC = function () {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5002 0.5C5.88324 0.5 0.5 5.88316 0.5 12.5C0.5 19.1168 5.88324 24.5 12.5002 24.5C19.117 24.5 24.5002 19.1168 24.5002 12.5C24.5002 5.88316 19.117 0.5 12.5002 0.5ZM12.5002 22.5328C6.96796 22.5328 2.46721 18.0322 2.46721 12.5C2.46721 6.96788 6.46796 2.46721 12.0002 2.46721C17.5323 2.46721 22.0329 6.96788 22.0329 12.5C22.0329 18.0322 17.5323 22.5328 12.5002 22.5328Z"
        fill="#0AAF87"
      />
      <path
        d="M17.6905 7.89352L10.5656 15.0185L7.30802 11.261C6.92386 10.8769 6.30112 10.8769 5.91697 11.261C5.53289 11.6451 5.53289 12.2679 5.91697 12.652L9.37004 16.6051C9.56212 16.7971 9.81384 16.8932 10.0656 16.8932C10.3173 16.8932 10.569 16.7971 10.7611 16.6051L18.5816 8.78465C18.9656 8.4005 18.9656 7.77775 18.5816 7.3936C18.1974 7.00944 17.5746 7.00944 17.1905 7.39352Z"
        fill="#0AAF87"
      />
    </svg>
  );
};

export const StartOrderIcon: FC = function () {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4089_21331)">
        <path
          d="M19.98 27.06C18.72 29.44 18 32.14 18 35C18 36.46 18.18 37.88 18.54 39.22L0 29.18V9.71997L19.98 19.46V27.06Z"
          fill="#FFCC80"
        />
        <path
          d="M39.9995 9.71997V18.74C38.4195 18.26 36.7395 18 34.9995 18C28.5195 18 22.8795 21.64 20.0195 26.98V19.46L39.9995 9.71997Z"
          fill="#FFB74D"
        />
        <path
          d="M40 9.72L20.02 19.46L12.24 15.68L9 14.1L0 9.72L19.98 0L28.5 4.14L31.9 5.78L40 9.72Z"
          fill="#FFA726"
        />
        <path
          d="M31.4625 5.59203L11.6985 15.44V25.388L8.56445 23.792V13.912L28.0645 3.95203L31.4625 5.59203Z"
          fill="#795548"
        />
        <path
          d="M0 9.71997V29.18L18.54 39.22C18.18 37.88 18 36.46 18 35C18 32.14 18.72 29.44 19.98 27.06V19.46L11.716 15.432L11.698 15.44V25.388L8.566 23.792V13.912L8.584 13.904L0 9.71997Z"
          fill="#DEB26F"
        />
        <path
          d="M20 11.304L11.724 15.428L12.24 15.68L20 19.45V11.304ZM19.98 0L0 9.72L8.588 13.9L20 8.072V0.01L19.98 0Z"
          fill="#DE9121"
        />
        <path
          d="M20.0005 8.07202L8.58845 13.9L8.58245 13.904L8.56445 13.912V23.792L11.6985 25.388V15.44L11.7165 15.432L11.7245 15.428L20.0005 11.304V8.07202Z"
          fill="#694A3F"
        />
        <path
          d="M35 48C27.832 48 22 42.168 22 35C22 27.832 27.832 22 35 22C42.168 22 48 27.832 48 35C48 42.168 42.168 48 35 48Z"
          fill="#4CAF50"
        />
        <path
          d="M35.0005 40C34.6385 40 34.2785 39.87 33.9905 39.61L28.4905 34.61C27.8785 34.052 27.8325 33.104 28.3905 32.49C28.9445 31.876 29.8945 31.832 30.5105 32.388L35.0005 36.472L39.4905 32.388C40.1065 31.832 41.0525 31.876 41.6105 32.49C42.1685 33.102 42.1225 34.052 41.5105 34.61L36.0105 39.61C35.7225 39.87 35.3625 40 35.0005 40Z"
          fill="white"
        />
        <path
          d="M35 22C27.832 22 22 27.832 22 35C22 42.168 27.832 48 35 48V40C34.638 40 34.278 39.87 33.99 39.61L28.49 34.61C27.878 34.052 27.832 33.104 28.39 32.49C28.686 32.166 29.09 32 29.498 32C29.858 32 30.22 32.128 30.51 32.39L35 36.474V22Z"
          fill="#429846"
        />
        <path
          d="M29.4985 32C29.0905 32 28.6845 32.166 28.3905 32.492C27.8325 33.104 27.8785 34.054 28.4905 34.612L33.9905 39.612C34.2785 39.87 34.6385 40 35.0005 40V36.472L30.5105 32.388C30.2225 32.128 29.8605 32 29.4985 32Z"
          fill="#DEDEDE"
        />
      </g>
      <defs>
        <clipPath id="clip0_4089_21331">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DeleteOrderIcon: FC = function () {
  return (
    <svg
      width="46"
      height="48"
      viewBox="0 0 46 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.2129 0H14.9588L0.606445 14.3525V45.0137C0.606445 46.6584 1.94791 48 3.59548 48H34.3114C35.9561 48 37.2977 46.6584 37.2977 45.0137V6.08747C37.2977 2.74041 34.56 0 31.2129 0Z"
        fill="#515A7A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9588 0L0.606445 14.3525H13.4097C14.2622 14.3525 14.9588 13.6557 14.9588 12.8032V0Z"
        fill="#767D9D"
      />
      <path
        d="M23.3281 41.806H8.44825C8.07559 41.806 7.77344 41.5039 7.77344 41.1312C7.77344 40.7586 8.07559 40.4564 8.44825 40.4564H23.3281C23.7007 40.4564 24.0029 40.7586 24.0029 41.1312C24.0029 41.5039 23.7008 41.806 23.3281 41.806Z"
        fill="#EAF2FF"
      />
      <path
        d="M23.3281 36.8279H8.44825C8.07559 36.8279 7.77344 36.5257 7.77344 36.1531C7.77344 35.7804 8.07559 35.4783 8.44825 35.4783H23.3281C23.7007 35.4783 24.0029 35.7804 24.0029 36.1531C24.0029 36.5257 23.7008 36.8279 23.3281 36.8279Z"
        fill="#EAF2FF"
      />
      <path
        d="M23.3281 31.8524H8.44825C8.07559 31.8524 7.77344 31.5503 7.77344 31.1776C7.77344 30.8049 8.07559 30.5028 8.44825 30.5028H23.3281C23.7007 30.5028 24.0029 30.805 24.0029 31.1776C24.003 31.5504 23.7008 31.8524 23.3281 31.8524Z"
        fill="#EAF2FF"
      />
      <path
        d="M23.3281 26.8743H8.44825C8.07559 26.8743 7.77344 26.5721 7.77344 26.1995C7.77344 25.8267 8.07559 25.5247 8.44825 25.5247H23.3281C23.7007 25.5247 24.0029 25.8268 24.0029 26.1995C24.003 26.5722 23.7008 26.8743 23.3281 26.8743Z"
        fill="#EAF2FF"
      />
      <path
        d="M23.3281 21.8961H8.44825C8.07559 21.8961 7.77344 21.594 7.77344 21.2213C7.77344 20.8487 8.07559 20.5465 8.44825 20.5465H23.3281C23.7007 20.5465 24.0029 20.8487 24.0029 21.2213C24.0029 21.594 23.7008 21.8961 23.3281 21.8961Z"
        fill="#EAF2FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.606445 22.9454L14.0682 14.205C13.8688 14.2979 13.6447 14.3525 13.4098 14.3525H0.606445V22.9454Z"
        fill="#2C3353"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.4068 6.08747V45.0137C34.4068 46.6585 33.0652 48 31.4178 48H34.3113C35.956 48 37.2976 46.6584 37.2976 45.0137V6.08747C37.2975 2.74041 34.5598 0 31.2127 0H28.3193C31.6691 0 34.4068 2.74041 34.4068 6.08747Z"
        fill="#2C3353"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5357 24.9426H44.0602C44.7924 24.9426 45.3935 25.541 45.3935 26.2732V39.8005C45.3935 40.5328 44.7924 41.1311 44.0602 41.1311H30.5357C29.8034 41.1311 29.2051 40.5327 29.2051 39.8005V26.2732C29.2051 25.5409 29.8034 24.9426 30.5357 24.9426Z"
        fill="#DE0028"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.1203 35.2514L39.5138 36.8606L37.298 34.6448L35.0848 36.8606L33.4756 35.2514L35.6914 33.0355L33.4756 30.8224L35.0848 29.2131L37.298 31.4289L39.5138 29.2131L41.1203 30.8224L38.9073 33.0355L41.1203 35.2514Z"
        fill="#EAF2FF"
      />
    </svg>
  );
};

export const LeafIcon: FC = function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_351_203)">
        <path
          d="M15.9724 0.224727C15.9164 0.0887387 15.7833 0 15.6364 0C1.52366 0 0 5.84874 0 8.36363C0 9.26837 0.200727 10.1135 0.597103 10.8749C0.658194 10.9927 0.77891 11.0676 0.911285 11.0706C1.03636 11.0669 1.16802 11.0044 1.23419 10.8887C2.18693 9.24219 4.88509 5.17817 9.34112 3.01892C9.52368 2.93165 9.74186 3.00655 9.82838 3.18621C9.91566 3.3673 9.84001 3.58548 9.65967 3.67275C9.32367 3.83567 9.00076 4.01312 8.68439 4.1971C8.56368 4.26764 8.44657 4.34257 8.32804 4.416C8.14985 4.52656 7.97387 4.63926 7.80222 4.75565C7.63132 4.87057 7.46257 4.98839 7.29675 5.10911C7.24511 5.14691 7.19568 5.18547 7.14474 5.22403C2.70982 8.52873 0 13.8007 0 15.6364C0 15.8371 0.162921 16 0.363648 16C0.564375 16 0.727296 15.8371 0.727296 15.6364C0.727296 15.0502 1.12657 13.8619 1.88219 12.4444C2.97893 13.3411 4.45456 13.8182 6.18185 13.8182C10.8517 13.8182 11.8299 9.37456 12.3549 6.98694C13.0618 3.77383 14.6175 1.89675 15.8931 0.620387C15.9979 0.516375 16.0284 0.360716 15.9724 0.224727Z"
          fill="#06765F"
        />
      </g>
      <defs>
        <clipPath id="clip0_351_203">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const InvoiceIcon: FC = function () {
  return <HiOutlineDocumentText className="w-6 h-6" />;
};

export const checkCirlce = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#E7F7F3" />
    <g clip-path="url(#clip0_478_553)">
      <path
        d="M17.7212 7.96133C17.3499 7.58955 16.747 7.58979 16.3752 7.96133L10.3174 14.0193L7.62503 11.3269C7.25325 10.9552 6.65061 10.9552 6.27883 11.3269C5.90706 11.6987 5.90706 12.3014 6.27883 12.6731L9.6442 16.0385C9.82998 16.2243 10.0736 16.3174 10.3172 16.3174C10.5608 16.3174 10.8046 16.2245 10.9904 16.0385L17.7212 9.3075C18.0929 8.93598 18.0929 8.33308 17.7212 7.96133Z"
        fill="#0AAF87"
      />
    </g>
    <defs>
      <clipPath id="clip0_478_553">
        <rect width="12" height="12" fill="white" transform="translate(6 6)" />
      </clipPath>
    </defs>
  </svg>
);

export const waitCircle = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#FFF9EA" />
    <path
      d="M13.2648 16.9659C13.1651 16.9907 13.0635 17.0128 12.9626 17.0314C12.6989 17.0804 12.5246 17.334 12.5734 17.5979C12.5975 17.7277 12.6712 17.8359 12.7713 17.9065C12.8745 17.9791 13.0058 18.0119 13.1396 17.987C13.2598 17.9647 13.3808 17.9385 13.4996 17.9089C13.76 17.8441 13.9186 17.5803 13.8537 17.32C13.7889 17.0595 13.5253 16.901 13.2648 16.9659Z"
      fill="#FCBD24"
    />
    <path
      d="M16.8583 10.4526C16.8923 10.5552 16.9573 10.6389 17.0394 10.6968C17.1611 10.7825 17.3204 10.8113 17.4724 10.7611C17.7272 10.6765 17.8652 10.4017 17.7809 10.1471C17.7425 10.031 17.6999 9.91452 17.6545 9.80112C17.5548 9.55194 17.2722 9.43062 17.0229 9.53029C16.7738 9.62991 16.6524 9.91264 16.7522 10.1619C16.7903 10.2572 16.8261 10.355 16.8583 10.4526Z"
      fill="#FCBD24"
    />
    <path
      d="M14.8439 16.2721C14.7582 16.3287 14.6697 16.3834 14.5806 16.4347C14.3481 16.5689 14.2684 16.8661 14.4025 17.0985C14.4389 17.1617 14.4874 17.2134 14.5432 17.2529C14.6931 17.3584 14.8967 17.3742 15.0662 17.2765C15.1722 17.2154 15.2775 17.1504 15.3796 17.0829C15.6035 16.935 15.6651 16.6335 15.5171 16.4096C15.3692 16.1856 15.0678 16.1241 14.8439 16.2721Z"
      fill="#FCBD24"
    />
    <path
      d="M18.083 11.8098C18.0725 11.5416 17.8466 11.3328 17.5783 11.3433C17.3103 11.3538 17.1014 11.5798 17.112 11.8479C17.116 11.9504 17.117 12.0545 17.1147 12.1569C17.1109 12.3251 17.1932 12.475 17.321 12.5651C17.3972 12.6187 17.4896 12.6512 17.5898 12.6535C17.858 12.6594 18.0803 12.4467 18.0862 12.1784C18.0889 12.056 18.0879 11.932 18.083 11.8098Z"
      fill="#FCBD24"
    />
    <path
      d="M16.779 14.9937C16.5639 14.8324 16.2597 14.8762 16.0986 15.0909C16.0369 15.1731 15.9719 15.2543 15.9054 15.3325C15.7316 15.5367 15.7561 15.8435 15.9604 16.0174C15.972 16.0273 15.9838 16.0364 15.996 16.0449C16.199 16.188 16.4812 16.1551 16.6454 15.9625C16.7248 15.8692 16.8022 15.7722 16.876 15.674C17.0371 15.4593 16.9935 15.1549 16.779 14.9937Z"
      fill="#FCBD24"
    />
    <path
      d="M17.4932 13.2372C17.2371 13.1569 16.9644 13.2994 16.8842 13.5555C16.8535 13.6533 16.8194 13.7516 16.7827 13.8479C16.702 14.0598 16.7793 14.293 16.9565 14.4179C16.989 14.4408 17.0248 14.4601 17.0637 14.4749C17.3144 14.5705 17.5952 14.4447 17.6908 14.1939C17.7344 14.0795 17.7749 13.9625 17.8115 13.8462C17.8916 13.5901 17.7492 13.3175 17.4932 13.2372Z"
      fill="#FCBD24"
    />
    <path
      d="M11.1448 17.0357C10.7103 16.9577 10.2936 16.8249 9.89837 16.6396C9.8937 16.6372 9.88951 16.6344 9.8846 16.6322C9.79148 16.5883 9.69851 16.5414 9.60839 16.4923C9.60808 16.492 9.60751 16.4917 9.60702 16.4915C9.44168 16.4005 9.28032 16.2997 9.12356 16.1894C6.83771 14.5792 6.2881 11.4094 7.89842 9.12361C8.24858 8.62674 8.67233 8.21222 9.14474 7.88368C9.15056 7.87963 9.15638 7.8756 9.16215 7.87152C10.8269 6.72449 13.0873 6.64719 14.8549 7.82587L14.4753 8.37441C14.3697 8.5271 14.4347 8.63836 14.6194 8.62173L16.2686 8.47409C16.4536 8.45746 16.5642 8.29742 16.5144 8.11876L16.0716 6.52319C16.022 6.34432 15.8951 6.32291 15.7894 6.47557L15.4089 7.02541C14.1117 6.15461 12.5558 5.82257 11.0115 6.09035C10.856 6.11727 10.7026 6.15022 10.5513 6.18857C10.5502 6.18878 10.5492 6.18891 10.5483 6.18912C10.5425 6.19055 10.5365 6.19245 10.5308 6.19403C9.19917 6.536 8.03731 7.31269 7.20824 8.42085C7.20125 8.42914 7.19405 8.43724 7.18745 8.44626C7.15988 8.48339 7.13252 8.52138 7.10573 8.55937C7.06192 8.62162 7.01874 8.68544 6.97742 8.74926C6.97225 8.75695 6.9683 8.76477 6.96378 8.77254C6.27963 9.83271 5.94963 11.0584 6.00623 12.3062C6.00636 12.3103 6.00612 12.3144 6.00623 12.3186C6.01171 12.4405 6.02137 12.5641 6.0345 12.6857C6.0352 12.6936 6.03694 12.701 6.03826 12.7089C6.05183 12.8312 6.06885 12.9538 6.09018 13.0764C6.30699 14.3272 6.89703 15.4528 7.78118 16.3285C7.78324 16.3306 7.78537 16.3328 7.78745 16.335C7.78817 16.3358 7.78898 16.3362 7.78968 16.3369C8.02722 16.5712 8.28561 16.7879 8.56382 16.9839C9.29188 17.4969 10.1026 17.836 10.9733 17.9923C11.2375 18.0397 11.4898 17.8639 11.5372 17.5998C11.5846 17.3356 11.4089 17.083 11.1448 17.0357Z"
      fill="#FCBD24"
    />
    <path
      d="M11.7456 8.11795C11.5283 8.11795 11.3523 8.29412 11.3523 8.51111V12.4281L14.9347 14.28C14.9923 14.3099 15.0541 14.3239 15.1148 14.3239C15.2571 14.3239 15.3946 14.2464 15.4644 14.1112C15.5641 13.9183 15.4887 13.6812 15.2957 13.5816L12.1385 11.9493V8.51111C12.1385 8.29412 11.9627 8.11795 11.7456 8.11795Z"
      fill="#FCBD24"
    />
  </svg>
);

export const reportsIcon = HiOutlineChartBar;
