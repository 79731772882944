/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { PageLayout } from "../components";
import { useUserSession } from "../context/UserContext";
import { useTranslation } from "react-i18next";
import { Button, Card, Avatar, Badge, Tooltip, Toast } from "flowbite-react";
import { DefaultSkeleton } from "../components";
import { 
  HiOutlineBadgeCheck, 
  HiOutlineClipboardCopy, 
  HiOutlineUser, 
  HiOutlineClock, 
  HiOutlineShieldCheck,
  HiOutlineCog,
  HiCheck
} from "react-icons/hi";
import { Timestamp } from "../types";
import { useState, useEffect } from "react";

const ProfilePage: FC = function () {
  const { userSession } = useUserSession();
  const { t } = useTranslation(["common", "user"]);
  const [showCopyToast, setShowCopyToast] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Sjekk om dark mode er aktivert
    const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const htmlHasDarkClass = document.documentElement.classList.contains('dark');
    setIsDarkMode(darkModeMediaQuery.matches || htmlHasDarkClass);

    // Lytt til endringer i dark mode
    const handleChange = (e: MediaQueryListEvent) => {
      setIsDarkMode(e.matches || document.documentElement.classList.contains('dark'));
    };
    
    darkModeMediaQuery.addEventListener('change', handleChange);
    return () => darkModeMediaQuery.removeEventListener('change', handleChange);
  }, []);

  if (userSession == null)
    return (
      <PageLayout>
        <DefaultSkeleton />
      </PageLayout>
    );

  // Formater dato fra Timestamp eller bruk dagens dato som fallback
  const formatDate = (timestamp?: Timestamp) => {
    if (!timestamp) return new Date().toLocaleDateString();
    
    // Bruk Timestamp.toDisplayDate hvis tilgjengelig, ellers formater manuelt
    if (typeof Timestamp.toDisplayDate === 'function') {
      return Timestamp.toDisplayDate(timestamp);
    } else {
      // Fallback til manuell formatering hvis Timestamp.toDisplayDate ikke er tilgjengelig
      const date = timestamp instanceof Date ? timestamp : new Date(timestamp.seconds * 1000);
      return date.toLocaleDateString();
    }
  };

  // Kopier token til utklippstavlen og vis toast
  const copyTokenToClipboard = () => {
    navigator.clipboard.writeText(userSession?.token.accessToken ?? "");
    setShowCopyToast(true);
    setTimeout(() => setShowCopyToast(false), 3000);
  };

  // Definer stripefarge basert på dark mode
  const stripeColor = isDarkMode ? 'rgba(100,100,100,0.5)' : 'rgba(180,180,180,0.35)';

  return (
    <PageLayout>
      <div className="relative pb-20">
        {/* Bakgrunn med solid farge */}
        <div 
          className="absolute bg-gray-100 dark:bg-lgb-dark-background" 
          style={{
            top: '-85px',
            left: 'calc(-50vw + 50%)',
            width: '100vw',
            height: '309px', // 224px + 85px for å beholde samme synlige høyde
            zIndex: 0
          }}
        ></div>
        
        {/* Svært tydelige skrå striper */}
        <div 
          className="absolute dark:after:content-[''] dark:after:absolute dark:after:inset-0 dark:after:bg-gradient-to-r dark:after:from-transparent dark:after:to-transparent dark:after:opacity-10" 
          style={{
            top: '-85px',
            left: 'calc(-50vw + 50%)',
            width: '100vw',
            height: '309px', // 224px + 85px for å beholde samme synlige høyde
            zIndex: 1,
            backgroundImage: `repeating-linear-gradient(135deg, 
              transparent, 
              transparent 25px, 
              ${stripeColor} 25px, 
              ${stripeColor} 50px
            )`,
            backgroundSize: '70px 70px'
          }}
        ></div>
        
        <div className="relative px-2 sm:px-4 pt-4 sm:pt-6 z-10">
          <div className="max-w-4xl mx-auto">
            {/* Toast-melding for kopiering */}
            {showCopyToast && (
              <div className="fixed bottom-4 right-4 z-50">
                <Toast>
                  <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
                    <HiCheck className="h-5 w-5" />
                  </div>
                  <div className="ml-3 text-sm font-normal">
                    {t("common:copied_to_clipboard")}
                  </div>
                  <button
                    type="button"
                    className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
                    onClick={() => setShowCopyToast(false)}
                    aria-label="Lukk"
                  >
                    <span className="sr-only">Lukk</span>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>
                    </svg>
                  </button>
                </Toast>
              </div>
            )}
            
            {/* Hovedprofilkort */}
            <Card className="mb-6 border-0 shadow-xl overflow-hidden bg-white dark:bg-darkBlue dark:border dark:border-lgb-grey-600">
              {/* Profilheader */}
              <div className="flex flex-col items-center px-3 sm:px-6 pt-4 sm:pt-6 pb-3 sm:pb-4 border-b border-gray-100 dark:border-lgb-grey-600">
                <div className="relative mb-4 sm:mb-5">
                  <Avatar 
                    size="xl" 
                    rounded 
                    alt={userSession?.user.email || "User"}
                    className="w-24 h-24 sm:w-32 sm:h-32 !border-0"
                    style={{ border: 'none', boxShadow: 'none' }}
                  />
                  {userSession?.user.emailVerified && (
                    <Tooltip content={t("user:profile.verified")}>
                      <div className="absolute -bottom-1 -right-1 bg-green-100 dark:bg-green-900 p-1.5 rounded-full border-2 border-white dark:border-lgb-grey-600 shadow-sm">
                        <HiOutlineBadgeCheck className="w-5 h-5 sm:w-6 sm:h-6 text-green-600 dark:text-green-300" />
                      </div>
                    </Tooltip>
                  )}
                </div>
                
                <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-white mb-2 sm:mb-3 text-center">
                  {userSession?.user.displayName || userSession?.user.email || t("user:profile.unnamed_user")}
                </h1>
                
                {/* Viser kun admin-badge her, fjernet duplisert verifiseringsindikator */}
                {userSession?.user.isAdmin && (
                  <Badge color="purple" size="sm" className="px-2 sm:px-3 py-1 sm:py-1.5 mb-2 sm:mb-3">
                    <div className="flex items-center gap-1 sm:gap-1.5">
                      <HiOutlineShieldCheck className="w-3 h-3 sm:w-3.5 sm:h-3.5" />
                      <span>{t("user:profile.admin")}</span>
                    </div>
                  </Badge>
                )}
              </div>
              
              {/* Profilinnhold */}
              <div className="p-3 sm:p-6">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
                  {/* Brukerdetaljer */}
                  <div>
                    <div className="flex items-center gap-1.5 sm:gap-2 mb-3 sm:mb-4">
                      <HiOutlineUser className="w-4 h-4 sm:w-5 sm:h-5 text-lgb-blue-300 dark:text-lgb-blue-200" />
                      <h3 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white">
                        {t("user:profile.user_details")}
                      </h3>
                    </div>
                    
                    <div className="space-y-3 sm:space-y-4">
                      {userSession?.user.displayName && (
                        <div className="p-3 sm:p-4 bg-gray-50 dark:bg-lgb-on-dark-background rounded-lg border border-gray-100 dark:border-lgb-grey-600 transition-all hover:shadow-md">
                          <div className="flex justify-between items-center">
                            <div>
                              <p className="text-xs sm:text-sm font-medium text-gray-700 dark:text-lgb-grey-300 mb-1">
                                {t("user:users.displayName")}
                              </p>
                              <p className="text-sm sm:text-base text-gray-600 dark:text-lgb-grey-400">
                                {userSession?.user.displayName}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      
                      <div className="p-3 sm:p-4 bg-gray-50 dark:bg-lgb-on-dark-background rounded-lg border border-gray-100 dark:border-lgb-grey-600 transition-all hover:shadow-md">
                        <div className="flex justify-between items-center">
                          <div>
                            <p className="text-xs sm:text-sm font-medium text-gray-700 dark:text-lgb-grey-300 mb-1">
                              {t("user:users.email")}
                            </p>
                            <p className="text-sm sm:text-base text-gray-600 dark:text-lgb-grey-400 break-all">
                              {userSession?.user.email}
                            </p>
                          </div>
                          <Button
                            size="xs"
                            color="light"
                            className="transition-transform duration-200 ease-in-out active:scale-95 ml-2 flex-shrink-0"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                userSession?.user.email ?? "",
                              );
                              setShowCopyToast(true);
                              setTimeout(() => setShowCopyToast(false), 3000);
                            }}
                          >
                            <HiOutlineClipboardCopy className="w-3.5 h-3.5 sm:w-4 sm:h-4" />
                          </Button>
                        </div>
                      </div>
                      
                      <div className="p-3 sm:p-4 bg-gray-50 dark:bg-lgb-on-dark-background rounded-lg border border-gray-100 dark:border-lgb-grey-600 transition-all hover:shadow-md">
                        <div className="flex justify-between items-center">
                          <div>
                            <p className="text-xs sm:text-sm font-medium text-gray-700 dark:text-lgb-grey-300 mb-1">
                              {t("user:users.createdAt")}
                            </p>
                            <p className="text-sm sm:text-base text-gray-600 dark:text-lgb-grey-400">
                              {formatDate(userSession?.user.createdAt)}
                            </p>
                          </div>
                          <div className="w-7 h-7 sm:w-8 sm:h-8 flex items-center justify-center text-gray-400 dark:text-lgb-grey-400 flex-shrink-0">
                            <HiOutlineClock className="w-4 h-4 sm:w-5 sm:h-5" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  {/* Kontoinnstillinger */}
                  <div>
                    <div className="flex items-center gap-1.5 sm:gap-2 mb-3 sm:mb-4">
                      <HiOutlineCog className="w-4 h-4 sm:w-5 sm:h-5 text-lgb-blue-300 dark:text-lgb-blue-200" />
                      <h3 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white">
                        {t("user:profile.account_settings")}
                      </h3>
                    </div>
                    
                    <div className="space-y-3 sm:space-y-4">
                      {userSession?.user.isAdmin && (
                        <div className="p-3 sm:p-4 bg-gray-50 dark:bg-lgb-on-dark-background rounded-lg border border-gray-100 dark:border-lgb-grey-600 transition-all hover:shadow-md">
                          <div className="flex flex-col">
                            <p className="text-xs sm:text-sm font-medium text-gray-700 dark:text-lgb-grey-300 mb-1">
                              {t("common:access_token")}
                            </p>
                            <div className="flex items-center justify-between mt-2">
                              <Button
                                size="xs"
                                color="light"
                                className="w-full transition-transform duration-200 ease-in-out active:scale-95"
                                onClick={copyTokenToClipboard}
                              >
                                <HiOutlineClipboardCopy className="mr-1 sm:mr-2 h-3.5 w-3.5 sm:h-4 sm:w-4" />
                                <span className="text-xs sm:text-sm">{t("common:copy_access_token")}</span>
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                      
                      <div className="p-3 sm:p-4 bg-lgb-blue-50 dark:bg-lgb-blue-900/10 rounded-lg border border-lgb-blue-100 dark:border-lgb-grey-600 transition-all hover:shadow-md">
                        <div className="flex items-start gap-2 sm:gap-3">
                          <div className="p-1.5 sm:p-2 bg-lgb-blue-100 dark:bg-lgb-blue-800 rounded-full text-lgb-blue-600 dark:text-lgb-blue-300 mt-1">
                            <HiOutlineShieldCheck className="w-4 h-4 sm:w-5 sm:h-5" />
                          </div>
                          <div>
                            <p className="text-sm sm:text-base font-medium text-gray-900 dark:text-white mb-1">
                              {t("user:profile.account_security")}
                            </p>
                            <p className="text-xs sm:text-sm text-gray-600 dark:text-lgb-grey-400 mb-2 sm:mb-3">
                              {userSession?.user.emailVerified 
                                ? t("user:profile.security_good")
                                : t("user:profile.security_needs_verification")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default ProfilePage;
