/* eslint-disable jsx-a11y/anchor-is-valid */
import { type FC } from "react";
import RouteStopCard from "../Cards/RouteStopCard";
import { IOrderProduct, IRouteItem } from "../../../types";
import { HiArrowNarrowRight, HiArrowNarrowDown } from "react-icons/hi";

export interface OrderDetailsProps {
  route: IRouteItem[];
  cargo: IOrderProduct[];
}

export const RouteTimeline: FC<OrderDetailsProps> = (props) => {
  const stopType = (
    stopNumber: number,
  ): "Pickup" | "Delivery" | "DeliveryAndPickup" => {
    const deliveryStops = props.cargo.map(
      (x) => x.itinerary?.deliveryStopNumber,
    );
    const pickupStops = props.cargo.map((x) => x.itinerary?.pickupStopNumber);

    switch (true) {
      case pickupStops.includes(stopNumber) &&
        deliveryStops.includes(stopNumber):
        return "DeliveryAndPickup";
      case pickupStops.includes(stopNumber):
        return "Pickup";
      case deliveryStops.includes(stopNumber):
        return "Delivery";
      default:
        return "Pickup";
    }
  };

  return (
    <div className="flex flex-col gap-4 my-6 sm:my-8">
      {props.route.map((routeItem, index) => {
        const isLastItem = routeItem.stopNumber === props.route[props.route.length - 1].stopNumber;
        return (
          <div
            key={routeItem.stopNumber}
            className="flex flex-col items-center"
          >
            <RouteStopCard
              className="w-full"
              stopType={stopType(routeItem.stopNumber)}
              routeStop={routeItem}
              hasImages={index % 2 === 0} //TODO
              hasSignature={index === 0} //TODO
            />
            {!isLastItem && (
              <div className="flex justify-center w-full my-2">
                <HiArrowNarrowDown size={24} className="text-lgb-grey-200 dark:text-lgb-grey-100" />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
