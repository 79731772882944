import { v4 as uuidv4 } from "uuid";
import firebase from "firebase/compat/app";
import { firestore } from "../firebaseConfig";
import { Timestamp, ILocation } from "../types";
import { query, doc } from "firebase/firestore";
import { ILocationPropertyValidated } from "../types";
import {
  validatePostCode,
  validateStringLength,
} from "../helpers/validationHelper";

type DocumentSnapshot = firebase.firestore.DocumentSnapshot;
type SnapshotOptions = firebase.firestore.SnapshotOptions;

class Location implements ILocation {
  id: string;
  displayName: string;
  description?: string;
  addressLine: string;
  city: string;
  imgUrl?: string;
  postCode: string;
  countryCode: string;
  customerId: string;
  workspaceId: string;
  createdAt: Timestamp | null;

  static collectionName = "locations";

  static converter = {
    toFirestore(Location: Location) {
      return Location.data();
    },
    fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions) {
      const data = snapshot.data(options) as ILocation;
      return new Location({ ...data, id: snapshot.id });
    },
  };

  static createId() {
    return firestore.collection(Location.collectionName).doc().id;
  }

  constructor({
    id,
    displayName,
    description,
    addressLine,
    city,
    imgUrl,
    postCode,
    countryCode,
    customerId,
    workspaceId,
    createdAt,
  }: ILocation) {
    this.id = id || "";
    this.displayName = displayName || "";
    this.description = description || "";
    this.addressLine = addressLine || "";
    this.city = city || "";
    this.imgUrl = imgUrl || "";
    this.postCode = postCode || "";
    this.countryCode = countryCode || "";
    this.customerId = customerId || "";
    this.workspaceId = workspaceId || "";
    this.createdAt = createdAt || null;
  }

  clone() {
    return Object.assign(Object.create(Object.getPrototypeOf(this)), this);
  }

  setData(updates: Partial<ILocation>) {
    return Object.assign(this, updates);
  }

  data() {
    return {
      id: this.id || "",
      displayName: this.displayName || "",
      description: this.description || "",
      addressLine: this.addressLine || "",
      city: this.city || "",
      imgUrl: this.imgUrl || "",
      postCode: this.postCode || "",
      countryCode: this.countryCode || "",
      customerId: this.customerId || "",
      workspaceId: this.workspaceId || "",
      createdAt: this.createdAt || null,
    };
  }

  static defaultLocation = (): Location => {
    return new Location({
      id: "",
      displayName: "",
      description: "",
      addressLine: "",
      city: "",
      imgUrl: "",
      postCode: "",
      countryCode: "NO",
      customerId: "",
      workspaceId: "",
      createdAt: null,
    });
  };

  static get = (id: string) => {
    return doc(
      firestore
        .collection(Location.collectionName)
        .withConverter(Location.converter),
      id,
    );
  };

  static list = (
    workspaceId: string,
    additionalWhere: [string, firebase.firestore.WhereFilterOp, string][] = []
  ) => {
    let request = firestore
      .collection(Location.collectionName)
      .where("workspaceId", "==", workspaceId);

    // Legg til eventuelle ekstra filtreringsbetingelser
    additionalWhere.forEach(([field, operator, value]) => {
      request = request.where(field, operator, value);
    });

    return query(request.withConverter(Location.converter));
  };

  // Metode for å hente lokasjoner basert på søketekst (client-side filtrering)
  static filterBySearchText = (locations: Location[], searchText: string): Location[] => {
    if (!searchText || searchText.trim() === "") {
      return locations;
    }
    
    const lowerCaseSearchText = searchText.toLowerCase().trim();
    
    // Optimalisert søk - sjekk først displayName og postCode som er mest brukt
    return locations.filter(location => {
      const displayName = (location.displayName || "").toLowerCase();
      const postCode = (location.postCode || "").toLowerCase();
      
      // Sjekk først de mest brukte feltene
      if (displayName.includes(lowerCaseSearchText) || postCode.includes(lowerCaseSearchText)) {
        return true;
      }
      
      // Sjekk deretter de andre feltene
      const addressLine = (location.addressLine || "").toLowerCase();
      const city = (location.city || "").toLowerCase();
      const description = (location.description || "").toLowerCase();
      
      return addressLine.includes(lowerCaseSearchText) || 
             city.includes(lowerCaseSearchText) || 
             description.includes(lowerCaseSearchText);
    });
  };

  static isNew(data: ILocation): boolean {
    return data.id.length === 0;
  }

  static validateLocation(location: ILocation) {
    let validationValues = {
      addressLine: validateStringLength(location.addressLine || "", 5),
      displayName: validateStringLength(location.displayName, 3),
      city: validateStringLength(location.city, 2),
      postCode: validatePostCode(location.postCode),
    } as ILocationPropertyValidated;

    let invalid = Object.entries(validationValues);
    return {
      isValidLocation: invalid.filter(([key, value]) => !value).length === 0,
      invalidFields: Object.fromEntries(invalid),
    };
  }

  static create = async (data: Omit<ILocation, "id" | "createdAt">) => {
    const newLocation = new Location({
      ...data,
      id: uuidv4().toString(),
      createdAt: Timestamp.now(),
    });
    try {
      await firestore
        .collection(Location.collectionName)
        .doc(newLocation.id)
        .withConverter(Location.converter)
        .set(newLocation, { merge: true });
    } catch (e) {
      console.warn("Create location failed with error: ", e);
      return null;
    }
    return newLocation;
  };

  static update = async (location: Location, updates: Partial<ILocation>) => {
    const updateType =
      (location.clone && location.clone()) || new Location({ ...location });

    updateType.setData({
      ...updates,
    });

    await firestore
      .collection(Location.collectionName)
      .doc(location.id)
      .withConverter(Location.converter)
      .set(updateType, { merge: true });

    return updateType;
  };

  static delete = async (location: Location) => {
    return await firestore
      .collection(Location.collectionName)
      .doc(location.id)
      .delete();
  };
}

export default Location;
