// src/components/molecules/CustomerDetails/CustomerDetails.tsx

import { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Customer from "../../../models/Customer"; // Corrected default import
import { Tooltip } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import { FaRegCopy } from "react-icons/fa";
import { SimpleList } from "../SimpleList";
import { OrderKPIs, RelatedOrders } from "../RelatedOrders";
import { useWorkspace } from "../../../context/WorkspaceContext";
import { useOrders } from "../../../hooks";
import { Order } from "../../../models";

interface CustomerDetailsProps {
  customer: Customer | undefined;
  edit: () => void;
  delete: () => void;
}

const CustomerDetails: FC<CustomerDetailsProps> = ({
  customer,
  edit,
  delete: deleteCustomer,
}) => {
  const { t } = useTranslation(["common", "customer"]);
  const [hasCopied, setHasCopied] = useState(false);
  const { activeWorkspace } = useWorkspace();

  const query = useOrders(
    activeWorkspace?.workspaceId ?? "",
    [],
    [["customerId", "==", customer?.id ?? ""]], // Extra where clause to query so we can show only related orders and KPIs
  );
  const { refetch } = query;
  const orders = useMemo(() => query.data ?? [], [query]) as Order[];

  const isQueryLoading = useMemo(
    () => query.isLoading || query.isRefetching,
    [query],
  );

  /**
   * Refetch related orders when customer changes
   */
  useEffect(() => {
    if (customer?.id) {
      refetch(); // Trigger a refetch
    }
  }, [customer?.id, refetch]);

  if (!customer) {
    return (
      <div className="p-4 text-gray-600 dark:text-gray-300">
        {t("customer:split.no_customer_selected")}
      </div>
    );
  }

  return (
    <>
      {/* Customer Header */}
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2 sm:gap-4 pt-2 sm:pt-4 mb-2 sm:mb-4">
        <div className="flex items-center gap-2 sm:gap-4">
          <p className="text-xl sm:text-2xl font-medium dark:text-lgb-grey-100 break-words">
            {Customer.getCustomerName(customer)}
          </p>

          {!hasCopied ? (
            <Tooltip content={t("common:copy")}>
              <div
                className="bg-none rounded-md relative"
                style={{ width: "30px", height: "30px" }}
              >
                <FaRegCopy
                  className="w-5 h-5 sm:w-6 sm:h-6 dark:text-white cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      Customer.getCustomerName(customer) ?? "",
                    );
                    setHasCopied(true);
                    setTimeout(() => setHasCopied(false), 2000); // Reset after 2 seconds
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="flex items-center text-lgb-grey-400 dark:text-lgb-grey-100">
              <HiCheck className="mr-1 sm:mr-2 h-4 w-4 sm:h-5 sm:w-5" />
              <p className="text-sm sm:text-base">{t("common:copied")}</p>
            </div>
          )}
        </div>
      </div>

      <div className="space-y-4 sm:space-y-6">
        <OrderKPIs orders={orders} loading={isQueryLoading} />

        {/* Basic Info */}
        <SimpleList
          items={[
            {
              title: t("customer:details.email"),
              value: customer.contactEmail || "-",
            },
            {
              title: t("customer:details.phoneNumber"),
              value: customer.phoneNumber || "-",
            },
            {
              title: t(
                customer.organizationNumber
                  ? "customer:details.organization_number"
                  : "customer:details.social_security_number",
              ),
              value:
                customer.organizationNumber ||
                customer.socialSecurityNumber ||
                "-",
            },
            {
              title: t("customer:details.address"),
              value: customer.primaryAddress?.addressLine || "-",
            },
            {
              title: t("customer:details.city"),
              value: customer.primaryAddress?.city || "-",
            },
            {
              title: t("customer:details.postCode"),
              value: customer.primaryAddress?.postCode || "-",
            },
          ]}
        />

        <RelatedOrders orders={orders} loading={isQueryLoading} />
      </div>
    </>
  );
};

export default CustomerDetails;
