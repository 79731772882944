import React, { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HiCheckCircle, HiOutlineClipboardList, HiOutlinePlusCircle, HiOutlineDocumentText } from "react-icons/hi";
import { StyledButton } from "../atoms/Button/StyledButton";
import { useNavigate } from "react-router-dom";

interface OrderSuccessPopupProps {
  onClose: () => void;
  onPlanStops: () => void;
  onCreateNewOrder: () => void;
  orderId?: string;
}

const OrderSuccessPopup: React.FC<OrderSuccessPopupProps> = ({ onClose, onPlanStops, onCreateNewOrder, orderId }) => {
  const { t } = useTranslation(["orders"]);
  const modalRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Log orderId for debugging
    console.log("OrderSuccessPopup received orderId:", orderId);
  }, [orderId]);

  // Håndter klikk utenfor modalen
  const handleOutsideClick = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  // Legg til og fjern event listener for klikk
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [onClose]);

  // Håndter navigering til ordredetaljer
  const handleViewOrder = () => {
    if (orderId) {
      // Først lukk popupen
      onClose();
      
      // Så naviger til ordre-detaljesiden
      console.log("Navigating to order details for ID:", orderId);
      
      // Bruk en timeout for å sikre at navigasjonen skjer etter at popupen er lukket
      setTimeout(() => {
        navigate(`/orders?id=${orderId}`);
      }, 100);
    } else {
      console.warn("Cannot view order: No order ID provided");
      // We'll still close the modal even if there's no ID
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[9999]">
      <div 
        ref={modalRef}
        className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-xl max-w-md w-full transform transition-all animate-fadeIn"
      >
        <div className="flex flex-col items-center">
          <div className="bg-green-100 dark:bg-green-900 p-4 rounded-full mb-6">
            <HiCheckCircle className="text-green-500 dark:text-green-400 h-16 w-16" />
          </div>
          <h2 className="text-2xl font-bold mb-3 text-center dark:text-white">{t("orders:success.title")}</h2>
          <p className="text-gray-600 dark:text-gray-300 mb-8 text-center">{t("orders:success.message")}</p>
          <div className="flex flex-col gap-4 w-full">
            <StyledButton
              variant="success"
              onClick={onPlanStops}
              icon={<HiOutlineClipboardList className="mr-2 h-5 w-5" />}
              className="w-full py-3 sm:py-2.5"
            >
              {t("orders:success.plan_stops")}
            </StyledButton>
            
            <div className="flex flex-row gap-4">
              <StyledButton
                variant="secondary"
                onClick={handleViewOrder}
                icon={<HiOutlineDocumentText className="mr-2 h-5 w-5" />}
                className="w-1/2 py-3 sm:py-2.5"
                data-testid="view-order-button"
              >
                {t("orders:success.view_order", "View order")}
              </StyledButton>
              
              <StyledButton
                variant="primary"
                onClick={onCreateNewOrder}
                icon={<HiOutlinePlusCircle className="mr-2 h-5 w-5" />}
                className="w-1/2 py-3 sm:py-2.5"
              >
                {t("orders:success.create_new_order")}
              </StyledButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Legg til en CSS-animasjon for fadeIn-effekten
const style = document.createElement('style');
style.innerHTML = `
  @keyframes fadeIn {
    from { opacity: 0; transform: scale(0.95); }
    to { opacity: 1; transform: scale(1); }
  }
  .animate-fadeIn {
    animation: fadeIn 0.3s ease-out forwards;
  }
`;
document.head.appendChild(style);

export default OrderSuccessPopup; 