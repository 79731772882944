/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useMemo, useState, type FC } from "react";
import { Driver, Order, Vehicle } from "../../../models";
import { SimpleList } from "../SimpleList";
import { useTranslation } from "react-i18next";
import { IOrderProduct } from "../../../types";
import { useWorkspace } from "../../../context/WorkspaceContext";
import { useVehicles } from "../../../hooks";
import { HiOutlineCalendar } from "react-icons/hi";
import { RouteTimeline } from "./RouteTimeline";
import { GoodsOverview } from "../GoodsOverview";
import { ImageList } from "../ImageList";
import { OrderStatus } from "../../../types/order/IOrder";
import { HiCheck } from "react-icons/hi";
import { Tooltip } from "flowbite-react";
import { FaRegCopy } from "react-icons/fa";
import { Timestamp } from "../../../types";
import { Link } from "react-router-dom";
import { IPriceModel } from "../../../types/Product";
import { InfoMessage } from "../../atoms/InfoMessage";

export interface OrderDetailsProps {
  order: Order | undefined;
  drivers: Driver[];
  editCargo: () => void;
}

export const OrderDetails: FC<OrderDetailsProps> = (props) => {
  const { t } = useTranslation(["common", "orders"]);
  const { activeWorkspace } = useWorkspace();
  const [driver, setDriver] = useState<Driver | undefined>();
  const [vehicle, setVehicle] = useState<Vehicle | undefined>();
  const [hasCopied, setHasCopied] = useState(false);
  const vehiclesQuery = useVehicles(activeWorkspace?.workspaceId ?? "");
  const isQueryLoading = useMemo(
    () => vehiclesQuery.isLoading || vehiclesQuery.isRefetching,
    [vehiclesQuery],
  );
  const vehicles = useMemo(() => vehiclesQuery.data ?? [], [vehiclesQuery]);

  const calculateTotalVolume = (products: IOrderProduct[]) => {
    const total = products.reduce(
      (partialSum, item) =>
        partialSum + item.quantity > 0
          ? (item.length / 100) *
            (item.height / 100) *
            (item.width / 100) *
            item.quantity
          : 0,
      0,
    );
    return total > 0 && typeof total === "number" ? total.toFixed(3) : 0;
  };

  const calculateTotalWeight = (products: IOrderProduct[]) => {
    const total = products.reduce(
      (partialSum, item) =>
        partialSum + item.quantity > 0 ? item.weight * item.quantity : 0,
      0,
    );
    return total > 0 && typeof total === "number" ? total : 0;
  };

  const calculateTotalPrice = () => {
    // Beregn pris basert på cargo-elementer
    if (props.order?.cargo && props.order.cargo.length > 0) {
      // Summer prisen for hvert cargo-element
      const cargoTotal = props.order.cargo.reduce((sum, item) => {
        // Hvis item har en pricing med Fixed price
        if (item.pricing && item.pricing.priceModel) {
          // Sjekk om priceModel er en array og inneholder Fixed
          if (Array.isArray(item.pricing.priceModel) && 
              item.pricing.priceModel.includes(IPriceModel.FIXED)) {
            // Bruk bare Fixed price-verdien
            const fixedPrice = item.pricing.pricePerCargoUnit || 0;
            return sum + (fixedPrice * (item.quantity || 1));
          }
          // Sjekk om priceModel er en enkelt verdi og er Fixed
          else if (!Array.isArray(item.pricing.priceModel) && 
                   item.pricing.priceModel === IPriceModel.FIXED) {
            // Bruk bare Fixed price-verdien
            const fixedPrice = item.pricing.pricePerCargoUnit || 0;
            return sum + (fixedPrice * (item.quantity || 1));
          }
        }
        
        // Hvis item har en direkte pris
        if (item.price && typeof item.price === 'number' && item.price > 0) {
          return sum + (item.price * (item.quantity || 1));
        }
        
        return sum;
      }, 0);
      
      if (cargoTotal > 0) {
        return cargoTotal;
      }
    }
    
    // Ingen pris funnet
    return 0;
  };

  useEffect(() => {
    if (!isQueryLoading) {
      if (props.drivers) {
        setDriver(props.drivers.find((x) => x.email === props.order?.driverId));
      }
      if (vehicles && driver) {
        setVehicle(vehicles.find((x) => x.id === driver.vehicleId));
      }
    }
    setHasCopied(false);
  }, [isQueryLoading, props.drivers, vehicles, driver, props.order]);

  return (
    <>
      <div className="xl:flex justify-between items-center gap-4 pt-4">
        <div className="flex items-center gap-4">
          <p className="text-2xl font-medium dark:text-lgb-grey-100">
            {props.order?.id}
          </p>
          {!hasCopied ? (
            <Tooltip content={t("common:copy")}>
              <div
                className="bg-none rounded-md relative"
                style={{ width: "34px", height: "34px" }}
              >
                <FaRegCopy
                  className="w-6 h-6 dark:text-white cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  onClick={() => {
                    navigator.clipboard.writeText(props.order?.id ?? "");
                    setHasCopied(true);
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <div className="flex items-center text-lgb-grey-400 dark:text-lgb-grey-100">
              <HiCheck className="mr-2 h-5 w-5" />
              <p>{t("common:copied")}</p>
            </div>
          )}
        </div>
        <div className="flex gap-2 items-center dark:text-lgb-grey-100 mt-2 xl:mt-0">
          <HiOutlineCalendar size={20} />
          <p>{Timestamp.toDisplayDate(props.order?.route.at(0)?.stopDate)}</p>
        </div>
      </div>

      {/* Vis informasjonsmelding basert på ordrestatus */}
      {props.order?.status === OrderStatus.New && (
        <div className="my-4">
          <InfoMessage 
            variant="warning" 
            title={t("orders:new_orders.info_title")}
          >
            <p>{t("orders:new_orders.info_description")}</p>
          </InfoMessage>
        </div>
      )}
      
      {props.order?.status === OrderStatus.Scheduled && (
        <div className="my-4">
          <InfoMessage 
            variant="warning" 
            title={t("orders:scheduled_orders.info_title")}
          >
            <p>{t("orders:scheduled_orders.info_description")}</p>
          </InfoMessage>
        </div>
      )}
      
      {props.order?.status === OrderStatus.InProgress && (
        <div className="my-4">
          <InfoMessage 
            variant="info" 
            title={t("orders:ongoing_orders.info_title")}
          >
            <p>{t("orders:ongoing_orders.info_description")}</p>
          </InfoMessage>
        </div>
      )}
      
      {props.order?.status === OrderStatus.Cancelled && (
        <div className="my-4">
          <InfoMessage 
            variant="error" 
            title={t("orders:cancelled_orders.info_title")}
          >
            <p>{t("orders:cancelled_orders.info_description")}</p>
          </InfoMessage>
        </div>
      )}
      
      {props.order?.status === OrderStatus.Completed && (
        <div className="my-4">
          <InfoMessage 
            variant="success" 
            title={t("orders:completed_orders.info_title")}
          >
            <p>{t("orders:completed_orders.info_description")}</p>
          </InfoMessage>
        </div>
      )}

      <RouteTimeline
        route={props.order?.route ?? []}
        cargo={props.order?.cargo ?? []}
      />

      {/* TODO */}
      {/* <img alt="Order" /> */}

      <p className="text-2xl mb-4 font-medium dark:text-white">
        {t("common:general")}
      </p>
      <SimpleList
        items={[
          ...(props.order?.status !== OrderStatus.Cancelled
            ? [{
              title: t("common:customer"),
              value: props.order?.customer && typeof props.order.customer === "object" && "name" in props.order.customer
                ? (
                  <Link
                  to={`/customers?customer=${
                    props.order?.customer
                      ? encodeURIComponent(
                          (typeof props.order.customer === "string"
                            ? props.order.customer
                            : props.order.customer.name || "" // ✅ Directly use the name property
                          )
                            .toLowerCase()
                            .replace(/\s+/g, "-")
                        )
                      : ""
                  }`}
                  className="text-lgb-blue-300 hover:text-lgb-blue-400 dark:text-lgb-blue-200 underline"
                >
                  {typeof props.order.customer === "string"
                    ? props.order.customer
                    : props.order.customer.name || "-"} 
                </Link>
                
                )
                : "-",
            },
            
                {
                  title: t("common:route"),
                  value: t("common:open_map"),
                  redirectPath: `/map?orderId=${props.order?.id}`,
                },
              ]
            : []),

          {
            title: t("common:from"),
            value: (
              <Link
                to={`/resources/locations?location=${props.order?.route[0].location.addressLine
                  ?.toLowerCase()
                  .replace(
                    /\s+/g,
                    "-",
                  )}-${props.order?.route[0].location.postCode}-${props.order?.route[0].location.city
                  ?.toLowerCase()
                  .replace(/\s+/g, "-")}`}
                className="text-lgb-blue-300 hover:text-lgb-blue-400 dark:text-lgb-blue-200"
              >
                {props.order?.route[0].location.addressLine || ""}
              </Link>
            ),
            redirectPath: "/resources/locations",
          },
          {
            title: t("common:to"),
            value: (
              <Link
                to={`/resources/locations?location=${props.order?.route[
                  props.order.route.length - 1
                ].location.addressLine
                  ?.toLowerCase()
                  .replace(/\s+/g, "-")}-${
                  props.order?.route[props.order.route.length - 1].location
                    .postCode
                }-${props.order?.route[
                  props.order.route.length - 1
                ].location.city
                  ?.toLowerCase()
                  .replace(/\s+/g, "-")}`}
                className="text-lgb-blue-300 hover:text-lgb-blue-400 dark:text-lgb-blue-200"
              >
                {props.order?.route[props.order.route.length - 1].location
                  .addressLine || ""}
              </Link>
            ),

            redirectPath: "/resources/locations",
          },

          ...(props.order?.comment.length
            ? [
                {
                  title: t("common:comment"),
                  value: props.order?.comment ?? "",
                },
              ]
            : []),

          ...(driver !== undefined
            ? [
                {
                  title: t("common:driver"),
                  value: (
                    <Link
                      to={`/resources/drivers?driver=${encodeURIComponent(driver?.email || "")}`}
                      className="text-lgb-blue-300 hover:text-lgb-blue-400 dark:text-lgb-blue-200 underline"
                    >
                      {driver?.firstName + " " + driver?.lastName || ""}
                    </Link>
                  ),
                },
              ]
            : []),

          ...(vehicle !== undefined
            ? [
                {
                  title: t("common:vehicle"),
                  value: vehicle.registrationNumber ?? "",
                  redirectPath: "/resources/vehicles",
                },
              ]
            : []),
          // {
          //   title: t("common:picked_up"),
          //   value: "11:22", //TODO
          // },
          // {
          //   title: t("common:delivered"),
          //   value: "16:50", //TODO
          // },
          {
            title: t("common:amount_of_goods"),
            value: props.order?.cargo.length ?? 0,
          },
          {
            title: t("common:total_weight"),
            value:
              (props.order?.cargo
                ? calculateTotalWeight(props.order?.cargo)
                : 0) + " kg",
          },
          {
            title: t("common:total_volume"),
            value: (
              <p>
                {props.order?.cargo
                  ? calculateTotalVolume(props.order?.cargo)
                  : 0}{" "}
                m<sup>3</sup>
              </p>
            ),
          },
          {
            title: t("common:total_price"),
            value: `${calculateTotalPrice().toLocaleString()} NOK`,
          },
          // {
          //   title: t("common:wait_time"),
          //   value: "23 minutes", //TODO
          // },
          // {
          //   title: t("common:extra_services"),
          //   value: "No", //TODO
          // },
          // {
          //   title: t("common:delivery_documents_sent_to"),
          //   value: "post@tskuteservice.no", //TODO
          // },
        ]}
      />

      <div className="my-8">
        <div className="flex justify-between items-center">
          <p className="text-2xl mb-4 font-medium dark:text-white">
            {t("common:cargo")}
          </p>
          {props.order?.status !== OrderStatus.Completed && (
            <p
              onClick={() => props.editCargo()}
              className="underline cursor-pointer text-lgb-blue-300 hover:text-lgb-blue-400 dark:text-white dark:hover:text-lgb-blue-200 dark:visited:text-purple-200"
            >
              {t("common:cargo_edit")}
            </p>
          )}
        </div>

        <GoodsOverview cargo={props.order?.cargo ?? []} />
      </div>

      <div className="my-8">
        <div className="flex justify-between items-center">
          <p className="text-2xl mb-4 font-medium dark:text-white">
            {t("common:images")}
          </p>
          {/* {props.order?.status !== OrderStatus.Completed && (
            <p
              onClick={() => console.log("Edit images")}
              className="underline cursor-pointer text-lgb-blue-300 hover:text-lgb-blue-400 visited:text-purple-600 dark:text-lgb-blue-300 dark:hover:text-lgb-blue-200 dark:visited:text-purple-200"
            >
              {t("common:images_edit")}
            </p>
          )} */}
        </div>
        <ImageList
          imgUrls={[
            //TEMP
            "https://picsum.photos/720/1080",
            "https://picsum.photos/1080/1080",
          ]}
        />
      </div>
    </>
  );
};
