import { ICustomer, IRouteItem } from "../../../types";
import { OrderStatus } from "../../../types/order/IOrder";
import Driver from "../../../models/Driver";
import { EnhancedRouteItem } from "../../../components/molecules/Planner/types";

export type { IRouteItem };

export interface IMapRoute {
  customerName: string;
  customer: ICustomer;
  id: string;
  type: string;
  status: OrderStatus;
  driver?: Driver;
  driverId?: string;
  route: EnhancedRouteItem[];
}

export interface MapPinProps {
  isLoaded: boolean;
}

export interface MapControlsProps {
  setSelectedTypes: (types: string[]) => void;
  selectedDate: Date | undefined;
  setSelectedDate: (date: Date | undefined) => void;
  showStatusFilter?: boolean;
}

export interface MapInfoCardsProps {
  selectedRoute?: IMapRoute;
  vehicles: any[];
}

export interface MapMarkersProps {
  selectedRoute?: IMapRoute;
  driverPosition?: google.maps.LatLngLiteral;
  startPin?: google.maps.Icon;
  waypointPin?: google.maps.Icon;
  driverPin?: google.maps.Icon;
  onStopSelect: (stop?: IRouteItem) => void;
}

export interface MapPolylineProps {
  selectedRoute?: IMapRoute;
}

export interface MapStopOverlayProps {
  selectedRoute?: IMapRoute;
  selectedStop?: IRouteItem;
  onClose: () => void;
}

export const libraries: ("marker" | "places" | "geometry")[] = [
  "marker",
  "places",
  "geometry",
];

export const customStyles: google.maps.MapTypeStyle[] = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#212121",
      },
    ],
  },
];

export const containerStyle = {
  width: "100%",
  height: "680px",
};

export const initialCenter = {
  lat: 62.75769482961424,
  lng: 16.89871699772572,
}; 