import type { FC } from "react";
import { useState } from "react";
import { HiLocationMarker, HiExclamation, HiOfficeBuilding } from "react-icons/hi";
import { StatusBadge } from "../../atoms/Badges/StatusBadge";
import { useTranslation } from "react-i18next";
import { EnhancedRouteItem, STYLE_CLASSES } from "./types";
import { useStopValidation } from "./hooks/useStopValidation";
import { StopDetailModal } from "./StopDetailModal";

export interface StopCardProps {
  stop: EnhancedRouteItem;
  onMoveToRoute?: () => void;
  onMoveToAvailable?: () => void;
  isInvalid?: boolean;
  routeStops: EnhancedRouteItem[];
  isDragging?: boolean;
}

export const StopCardContent: FC<StopCardProps> = ({ stop, isInvalid, routeStops }) => {
  const { t } = useTranslation();
  const [showErrorTooltip, setShowErrorTooltip] = useState(false);
  const { getErrorMessage } = useStopValidation(stop, routeStops);

  const errorTooltipContent = (
    <div className={`${STYLE_CLASSES.TOOLTIP} ${showErrorTooltip ? 'block' : 'hidden'}`}>
      {getErrorMessage()}
    </div>
  );

  return (
    <div className="flex items-center justify-between gap-2 w-full h-full">
      <div className="flex items-start gap-2">
        {!stop.isAvailable && (
          <div className="flex flex-col items-center">
            <div className={`flex items-center justify-center w-6 h-6 rounded-full 
              ${stop.isPickup ? 'bg-lgb-blue-100 text-lgb-blue-500' : 'bg-lgb-green-100 text-lgb-green-500'} 
              font-medium text-sm`}>
              {stop.stopNumber}
            </div>
            {stop.stopNumber < routeStops.filter(s => !s.isAvailable).length && (
              <div className="w-0.5 h-3 bg-gray-300 dark:bg-gray-600 mt-0.5"></div>
            )}
          </div>
        )}
        
        <div className="flex-1 min-w-0">
          <div className="flex items-center gap-1.5 mb-0.5">
            <HiLocationMarker className="h-3.5 w-3.5 text-gray-500 dark:text-gray-400 shrink-0" />
            <p className={`text-sm font-medium truncate ${isInvalid ? 'text-red-500 dark:text-red-400' : 'text-gray-800 dark:text-white'}`}>
              {stop.location.addressLine}
            </p>
          </div>
          
          <div className="flex items-center gap-1.5">
            <HiOfficeBuilding className="h-3 w-3 text-gray-400 dark:text-gray-500 shrink-0" />
            <p className="text-xs text-gray-500 dark:text-gray-400 truncate">
              {stop.stopDisplayName}
            </p>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-2">
        <StatusBadge 
          status={stop.isPickup ? t('planner:route_planner.pickup') : t('planner:route_planner.delivery')}
          bgStyle={stop.isPickup ? 'bg-lgb-blue-100 text-lgb-blue-300' : 'bg-lgb-green-100 text-lgb-green-300'}
          rounded
        />
        {isInvalid && (
          <div className="relative">
            <div
              onMouseEnter={() => setShowErrorTooltip(true)}
              onMouseLeave={() => setShowErrorTooltip(false)}
            >
              <HiExclamation className="h-4 w-4 text-red-500 cursor-pointer" />
            </div>
            {errorTooltipContent}
          </div>
        )}
      </div>
    </div>
  );
};

export const StopCardWrapper: FC<StopCardProps> = ({ stop, isDragging, onMoveToRoute, onMoveToAvailable, isInvalid, routeStops }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCardClick = (e: React.MouseEvent) => {
    if (!isDragging) {
      setIsModalOpen(true);
    }
  };

  return (
    <>
      <div
        className={`${STYLE_CLASSES.CARD} p-2.5 h-[54px] ${isDragging ? 'opacity-70 scale-105 shadow-lg' : ''} 
        ${isInvalid ? 'border-red-200 dark:border-red-800' : ''}
        ${!stop.isAvailable ? `border-l-4 ${stop.isPickup ? 'border-l-lgb-blue-300' : 'border-l-lgb-green-300'}` : ''}
        transition-all duration-150 ease-in-out
        hover:border-gray-300 dark:hover:border-gray-600
        active:scale-[0.98]`}
        onClick={handleCardClick}
      >
        <StopCardContent stop={stop} onMoveToRoute={onMoveToRoute} onMoveToAvailable={onMoveToAvailable} isInvalid={isInvalid} routeStops={routeStops} />
      </div>

      <StopDetailModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        stop={stop} 
      />
    </>
  );
}; 