/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  ForwardedRef,
  useRef,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DropdownSearch,
  DropdownSearchListItem,
  SavedLocations,
  LGBDatepicker,
  ValidationToast,
} from "../../../../components";
import { Button, Badge, Label } from "flowbite-react";
import { StyledButton } from "../../../../components/atoms/Button";
import { 
  HiOutlinePlus, 
  HiOutlineLocationMarker, 
  HiOutlinePencil, 
  HiOutlineX,
  HiOutlineChevronUp,
  HiOutlineCalendar,
  HiOutlineTrash,
  HiOutlinePhone,
  HiOutlineUser,
  HiOutlineArrowUp,
  HiOutlineArrowDown,
} from "react-icons/hi";
import {
  ILocation,
  IRouteItem,
  IRouteTiming,
  Timestamp,
} from "../../../../types";
import { useLocations } from "../../../../hooks";
import { useWorkspace } from "../../../../context/WorkspaceContext";
import { TableSkeletonsByAmount } from "../../../../components/atoms/Skeleton/TableSkeleton";
import { LocationModal } from "../../../../components";
import { Location } from "../../../../models";

export interface RouteSelectionProps {
  route: IRouteItem[];
  validationFailed: boolean;
  hideSearch?: boolean;
  update: (routes: IRouteItem[]) => void;
}
export interface RouteSelectionRef {
  updateOrderRoute: () => void;
}

// Legg til denne nye komponenten rett før RouteSelection-komponenten
interface ContactDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (name: string, phone: string) => void;
  t: any; // Translation function
}

// Kontaktperson form-komponent
const ContactPersonForm: React.FC<ContactDialogProps> = (props) => {
  const { t } = props;
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  
  // Reset feltene når dialogen lukkes
  useEffect(() => {
    if (!props.isOpen) {
      setName("");
      setPhone("");
    }
  }, [props.isOpen]);
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (name && phone) {
      props.onAdd(name, phone);
    }
  };
  
  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <Label htmlFor="contact-name" value={t("create.contact_info.name")} />
        <input
          id="contact-name"
          name="contact-name"
          placeholder={t("create.contact_info.name")}
          required
          type="text"
          className="w-full border p-2.5 text-sm rounded-lg border-gray-300 dark:border-[#2c3353] dark:bg-lgb-dark-background dark:text-white mt-2"
          onChange={(e) => setName(e.target.value)}
          value={name}
          autoComplete="off"
          autoFocus
        />
      </div>
      
      <div>
        <Label htmlFor="contact-phone" value={t("create.contact_info.phone_number")} />
        <input
          id="contact-phone"
          name="contact-phone"
          placeholder={t("create.contact_info.enter_phone_number")}
          required
          type="text"
          className="w-full border p-2.5 text-sm rounded-lg border-gray-300 dark:border-[#2c3353] dark:bg-lgb-dark-background dark:text-white mt-2"
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
          autoComplete="off"
        />
      </div>
      
      <div className="pt-4 flex justify-end gap-4">
        <Button color="gray" onClick={props.onClose}>
          {t("common:close")}
        </Button>
        <Button 
          type="submit" 
          color="primary" 
          disabled={!name || !phone}
        >
          <HiOutlinePlus className="mr-2 h-5 w-5 dark:text-white" />
          {t("create.contact_info.title")}
        </Button>
      </div>
    </form>
  );
};

// Hovedkomponenten for kontaktperson-dialogen
const ContactPersonDialog: React.FC<ContactDialogProps> = (props) => {
  return (
    <Dialog
      title={props.t("create.contact_info.title")}
      show={props.isOpen}
      toggleModal={props.onClose}
      hideButtons={true}
      content={<ContactPersonForm {...props} />}
    />
  );
};

// Legg til denne nye komponenten for "Legg til tomt stopp" dialogen
interface AddEmptyStopDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onAdd: (
    name: string,
    address: string,
    postCode: string,
    city: string,
    country: string,
    description: string,
    isPickup: boolean,
    date: Date,
    timing: string,
    shouldSave: boolean
  ) => void;
  t: any; // Translation function
  generateTimeOptions: () => string[];
  defaultIsPickup?: boolean;
  shouldSaveLocation?: boolean;
  onSaveToggleChange?: (shouldSave: boolean) => void;
}

// Legg til denne nye komponenten for en toggle-knapp som ikke forårsaker re-rendering
interface StableToggleProps {
  id: string;
  defaultChecked: boolean;
  labelOn: string;
  labelOff: string;
  onChange: (isChecked: boolean) => void;
}

const StableToggle: React.FC<StableToggleProps> = ({ id, defaultChecked, labelOn, labelOff, onChange }) => {
  const toggleRef = useRef<HTMLInputElement>(null);
  const labelRef = useRef<HTMLSpanElement>(null);
  
  // Initialiser toggle-tilstanden
  useEffect(() => {
    if (toggleRef.current) {
      toggleRef.current.checked = defaultChecked;
    }
    if (labelRef.current) {
      labelRef.current.textContent = defaultChecked ? labelOn : labelOff;
    }
  }, [defaultChecked, labelOn, labelOff]);
  
  // Håndter endringer uten å bruke React state
  const handleChange = () => {
    const isChecked = toggleRef.current?.checked ?? defaultChecked;
    
    // Oppdater label-teksten direkte
    if (labelRef.current) {
      labelRef.current.textContent = isChecked ? labelOn : labelOff;
    }
    
    // Kall onChange-callback
    onChange(isChecked);
  };
  
  return (
    <div className="flex items-center py-3">
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          ref={toggleRef}
          id={id}
          type="checkbox"
          defaultChecked={defaultChecked}
          className="sr-only peer"
          onChange={handleChange}
        />
        <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all dark:border-gray-600 peer-checked:bg-gradient-to-r peer-checked:from-blue-400 peer-checked:to-lgb-blue-300"></div>
        <span ref={labelRef} className="ml-4 text-sm font-medium text-gray-900 dark:text-gray-300">
          {defaultChecked ? labelOn : labelOff}
        </span>
      </label>
    </div>
  );
};

// Form-komponent for "Legg til tomt stopp"
const AddEmptyStopForm: React.FC<AddEmptyStopDialogProps> = (props) => {
  const { t } = props;
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [postCode, setPostCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("NO");
  const [description, setDescription] = useState("");
  const [isPickup, setIsPickup] = useState(props.defaultIsPickup ?? false);
  const [date, setDate] = useState(new Date());
  const [timing, setTiming] = useState("4"); // 4 = earliest
  
  // Bruk en ref for å holde toggle-tilstanden i stedet for state
  const saveLocationRef = useRef<boolean>(props.shouldSaveLocation ?? true);
  
  // Reset feltene når dialogen lukkes
  useEffect(() => {
    if (!props.isOpen) {
      setName("");
      setAddress("");
      setPostCode("");
      setCity("");
      setCountry("NO");
      setDescription("");
      setIsPickup(props.defaultIsPickup ?? false);
      setDate(new Date());
      setTiming("4");
    } else {
      // Sett standard verdier når dialogen åpnes
      setIsPickup(props.defaultIsPickup ?? false);
      saveLocationRef.current = props.shouldSaveLocation ?? true;
    }
  }, [props.isOpen, props.defaultIsPickup, props.shouldSaveLocation]);
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (postCode && city) {
      // Hent toggle-status direkte fra ref
      const shouldSave = saveLocationRef.current;
      props.onAdd(name, address, postCode, city, country, description, isPickup, date, timing, shouldSave);
    }
  };
  
  // Funksjon for å håndtere toggle-endringer
  const handleToggleChange = (isChecked: boolean) => {
    saveLocationRef.current = isChecked;
    
    // Informer parent-komponenten om endringen
    if (props.onSaveToggleChange) {
      props.onSaveToggleChange(isChecked);
    }
  };
  
  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {/* Lokasjonsinformasjon */}
      <div>
        <Label htmlFor="newLocationName" value={t("common:name")} />
        <input
          id="newLocationName"
          type="text"
          className="w-full border p-2.5 text-sm rounded-lg border-gray-300 dark:border-[#2c3353] dark:bg-lgb-dark-background dark:text-white mt-2"
          placeholder={t("create.route_selection.display_name_ph")}
          value={name}
          onChange={(e) => setName(e.target.value)}
          autoComplete="off"
          autoFocus
        />
      </div>
      
      <div>
        <Label htmlFor="newLocationAddress" value={t("common:address_line")} />
        <input
          id="newLocationAddress"
          type="text"
          className="w-full border p-2.5 text-sm rounded-lg border-gray-300 dark:border-[#2c3353] dark:bg-lgb-dark-background dark:text-white mt-2"
          placeholder={t("common:placeholder.address_line")}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          autoComplete="off"
        />
      </div>
      
      <div className="grid grid-cols-2 gap-4">
        <div>
          <Label htmlFor="newLocationPostCode" value={t("common:postcode")} />
          <input
            id="newLocationPostCode"
            type="text"
            className="w-full border p-2.5 text-sm rounded-lg border-gray-300 dark:border-[#2c3353] dark:bg-lgb-dark-background dark:text-white mt-2"
            placeholder={t("common:placeholder.postcode")}
            value={postCode}
            onChange={(e) => setPostCode(e.target.value)}
            autoComplete="off"
            required
          />
        </div>
        
        <div>
          <Label htmlFor="newLocationCity" value={t("common:city")} />
          <input
            id="newLocationCity"
            type="text"
            className="w-full border p-2.5 text-sm rounded-lg border-gray-300 dark:border-[#2c3353] dark:bg-lgb-dark-background dark:text-white mt-2"
            placeholder={t("common:placeholder.city")}
            value={city}
            onChange={(e) => setCity(e.target.value)}
            autoComplete="off"
            required
          />
        </div>
      </div>
      
      <div>
        <Label htmlFor="newLocationCountry" value={t("common:country")} />
        <select
          id="newLocationCountry"
          className="w-full border p-2.5 text-sm rounded-lg border-gray-300 dark:border-[#2c3353] dark:bg-lgb-dark-background dark:text-white mt-2"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
        >
          <option value="NO">Norge</option>
          <option value="SE">Sverige</option>
          <option value="DK">Danmark</option>
          <option value="FI">Finland</option>
        </select>
      </div>
      
      <div>
        <Label htmlFor="newLocationDescription" value={t("common:description")} />
        <textarea
          id="newLocationDescription"
          rows={2}
          className="w-full border p-2.5 text-sm rounded-lg border-gray-300 dark:border-[#2c3353] dark:bg-lgb-dark-background dark:text-white mt-2"
          placeholder={t("common:placeholder.description")}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      
      {/* Pickup/Delivery toggle */}
      <div className="pt-4 border-t border-lgb-grey-200 dark:border-[#2c3353]">
        <Label htmlFor="newStopType" value={t("create.route_selection.stop_type")} />
        <div className="flex space-x-4 mt-2">
          <div className="flex items-center">
            <input
              id="newPickup"
              type="radio"
              name="newStopType"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-[#2c3353]"
              checked={isPickup === true}
              onChange={() => setIsPickup(true)}
            />
            <label
              htmlFor="newPickup"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              {t("pickup")}
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="newDelivery"
              type="radio"
              name="newStopType"
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-[#2c3353]"
              checked={isPickup === false}
              onChange={() => setIsPickup(false)}
            />
            <label
              htmlFor="newDelivery"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              {t("delivery")}
            </label>
          </div>
        </div>
      </div>
      
      {/* Date picker */}
      <div>
        <Label htmlFor="newDate" value={t("create.route_selection.date")} />
        <LGBDatepicker
          inputId="newDate"
          selectedDate={date}
          select={(newDate) => setDate(newDate)}
        />
      </div>
      
      {/* Timing selector */}
      <div>
        <Label htmlFor="newTiming" value={t("create.route_selection.timing")} />
        <select
          id="newTiming"
          className="w-full border p-2.5 text-sm rounded-lg border-gray-300 dark:border-[#2c3353] dark:bg-lgb-dark-background dark:text-white mt-2"
          value={timing}
          onChange={(e) => setTiming(e.target.value)}
        >
          <option value="1">{t("create.route_selection.before")}</option>
          <option value="2">{t("create.route_selection.between")}</option>
          <option value="3">{t("create.route_selection.after")}</option>
          <option value="4">{t("create.route_selection.earliest_possible")}</option>
          <option value="5">{t("create.route_selection.latest_possible")}</option>
        </select>
      </div>
      
      {/* Legg til toggle for å lagre lokasjonen */}
      <div className="border-t border-gray-200 dark:border-gray-700 pt-4 mt-4">
        <StableToggle
          id="save-location-toggle"
          defaultChecked={props.shouldSaveLocation ?? true}
          labelOn={t("create.route_selection.saved")}
          labelOff={t("create.route_selection.not_saved")}
          onChange={handleToggleChange}
        />
      </div>
      
      {/* Footer */}
      <div className="pt-4 flex justify-end gap-4">
        <Button color="gray" onClick={props.onClose}>
          {t("common:cancel")}
        </Button>
        <Button 
          type="submit" 
          color="primary" 
          disabled={!postCode || !city}
        >
          <HiOutlinePlus className="mr-2 h-5 w-5 dark:text-white" />
          {t("create.route_selection.add_stop")}
        </Button>
      </div>
    </form>
  );
};

// Hovedkomponenten for "Legg til tomt stopp" dialogen
const AddEmptyStopDialog: React.FC<AddEmptyStopDialogProps> = (props) => {
  return (
    <Dialog
      title={props.t("create.route_selection.add_stop")}
      show={props.isOpen}
      toggleModal={props.onClose}
      hideButtons={true}
      content={<AddEmptyStopForm {...props} />}
    />
  );
};

const RouteSelection = forwardRef(
  (props: RouteSelectionProps, ref: ForwardedRef<RouteSelectionRef>) => {
    const { t } = useTranslation("orders");
    const { activeWorkspace } = useWorkspace();
    const [showAllLocations, setShowAllLocations] = useState(false);
    const [currentRoute, setCurrentRoute] = useState(props.route);
    const [searchText, setSearchText] = useState<string>("");
    const [searchResults, setSearchResults] = useState([] as ILocation[]);
    const [isInsertModalOpen, openInsertModal] = useState(false);
    const [locationData, setLocationData] = useState(Location.defaultLocation());
    const [inputFocused, setInputFocused] = useState(false);
    const [expandedRouteItems, setExpandedRouteItems] = useState<number[]>([]);
    const [showValidationToast, setShowValidationToast] = useState(false);
    const [validationMessage, setValidationMessage] = useState("");
    
    // Bruk en ref i stedet for state for å holde toggle-tilstanden
    const shouldSaveLocationRef = useRef<boolean>(true);

    const query = useLocations(activeWorkspace?.workspaceId ?? "");
    const locations = query.data;

    // Kontaktperson dialog
    const [showContactDialog, setShowContactDialog] = useState(false);
    const [contactDialogIndex, setContactDialogIndex] = useState<number | null>(null);
    
    // Dialog for å legge til ny lokasjon
    const [showAddLocationDialog, setShowAddLocationDialog] = useState(false);
    
    // Sjekk om enheten er mobil ved å bruke en CSS-mediaquery variabel
    const [isMobile, setIsMobile] = useState(false);
    
    // Oppdater isMobile state når vindusstørrelsen endres
    useEffect(() => {
      const checkIfMobile = () => {
        setIsMobile(window.innerWidth < 768);
      };
      
      // Kjør sjekken på oppstart
      checkIfMobile();
      
      // Legg til lytter for vindusendringer
      window.addEventListener('resize', checkIfMobile);
      
      // Fjern lytteren når komponenten avmonteres
      return () => {
        window.removeEventListener('resize', checkIfMobile);
      };
    }, []);

    // Funksjon for å generere tidsalternativer for hver 15. minutt
    const generateTimeOptions = (): string[] => {
      const times: string[] = [];
      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
          const formattedHour = hour.toString().padStart(2, '0');
          const formattedMinute = minute.toString().padStart(2, '0');
          times.push(`${formattedHour}:${formattedMinute}`);
        }
      }
      return times;
    };

    // When props.route changes, update the current route.
    useEffect(() => {
      setCurrentRoute(props.route);
    }, [props.route]);

    // Update search results based on searchText and input focus.
    useEffect(() => {
      if (searchText.length) {
        // When something is typed, filter the available locations.
        const res =
          locations?.filter(
            (x) =>
              !currentRoute.map((r) => r.location.id).includes(x.id) &&
              (x.addressLine + " " + x.displayName)
                .toLowerCase()
                .includes(searchText.toLowerCase())
          ) || [];
        setSearchResults(res.slice(0, 3));
      } else if (inputFocused) {
        // When input is focused and empty, show all available locations.
        const availableLocations =
          locations?.filter(
            (x) => !currentRoute.map((r) => r.location.id).includes(x.id)
          ) || [];
        setSearchResults(availableLocations.slice(0, 3));
      } else {
        setSearchResults([]);
      }
    }, [searchText, locations, currentRoute, inputFocused, props.hideSearch]);

    function toggleShowAllDialog() {
      setSearchText("");
      setShowAllLocations(!showAllLocations);
    }

    function handleSearch(val: string) {
      setSearchText(val);
    }

    // When the dropdown input is focused, set inputFocused to true.
    function handleFocus() {
      setInputFocused(true);
    }

    // Delay hiding the dropdown on blur to allow click events to process.
    function handleBlur() {
      setTimeout(() => {
        setInputFocused(false);
      }, 150); // Adjust the delay as needed.
    }

    /**
     * Exposed to the parent so it can be called through ref.
     */
    useImperativeHandle(ref, () => ({
      updateOrderRoute,
    }));

    const updateOrderRoute = () => {
      // Bruk currentRoute direkte i stedet for å gå via routeRefs
      // Dette sikrer at vi alltid har oppdaterte data, selv om kortene ikke er utvidet
      props.update([...currentRoute]);
    };

    const removeRouteItem = (routeIdx: number) => {
      const stopToRemove = currentRoute.at(routeIdx);
      const route = currentRoute.filter(
        (x) => x.stopNumber !== stopToRemove?.stopNumber
      );
      // Re-number the stops.
      for (let i = 0; i < route.length; i++) {
        route[i].stopNumber = i;
      }
      setCurrentRoute(route);
      // Remove from expanded items if it was expanded
      setExpandedRouteItems(expandedRouteItems.filter(idx => idx !== routeIdx));
      // Update parent component
      props.update(route);
    };

    // Validerer at ruten har riktig rekkefølge av henting og levering
    const validateRouteOrder = (route: IRouteItem[]): boolean => {
      if (route.length === 0) return true;
      
      // Første stopp må være en henting
      if (!route[0].isPickup) {
        setValidationMessage(t("create.route_selection.first_stop_must_be_pickup"));
        setShowValidationToast(true);
        return false;
      }
      
      // Sjekk at vi har minst én henting før en levering
      let hasPickup = false;
      
      for (let i = 0; i < route.length; i++) {
        if (route[i].isPickup) {
          hasPickup = true;
        } else if (!hasPickup) {
          // Hvis vi har en levering uten en tidligere henting
          setValidationMessage(t("create.route_selection.delivery_before_pickup"));
          setShowValidationToast(true);
          return false;
        }
      }
      
      return true;
    };

    // Oppdatert togglePickupDelivery funksjon med validering
    const togglePickupDelivery = (index: number, isPickup: boolean | undefined) => {
      const updatedRoute = [...currentRoute];
      // Sikre at isPickup alltid er en boolean
      const pickupValue = isPickup === undefined ? false : isPickup;
      const updatedRouteItem = { ...updatedRoute[index], isPickup: pickupValue };
      
      // Lag en kopi av ruten med den oppdaterte verdien for validering
      const routeForValidation = [...updatedRoute];
      routeForValidation[index] = updatedRouteItem;
      
      // Valider den nye ruten
      if (!validateRouteOrder(routeForValidation)) {
        return; // Ikke oppdater hvis valideringen feiler
      }
      
      // Oppdater ruten hvis valideringen er OK
      updatedRoute[index] = updatedRouteItem;
      setCurrentRoute(updatedRoute);
      // Oppdater parent-komponenten
      props.update(updatedRoute);
    };

    // Oppdater updateRouteItem for å inkludere validering
    const updateRouteItem = (routeIdx: number, val: IRouteItem) => {
      // Hvis isPickup har endret seg, bruk togglePickupDelivery i stedet
      if (val.isPickup !== currentRoute[routeIdx].isPickup) {
        togglePickupDelivery(routeIdx, val.isPickup);
        return;
      }
      
      const route = [...currentRoute];
      route[routeIdx] = val;
      setCurrentRoute(route);
      // Oppdater parent-komponenten
      props.update(route);
    };

    // Funksjon for å flytte et stopp opp i rekkefølgen
    const moveStopUp = (index: number) => {
      if (index <= 0) return; // Kan ikke flytte første element opp
      
      const updatedRoute = [...currentRoute];
      // Bytt stopNumber mellom dette og forrige element
      const currentStopNumber = updatedRoute[index].stopNumber;
      updatedRoute[index].stopNumber = updatedRoute[index - 1].stopNumber;
      updatedRoute[index - 1].stopNumber = currentStopNumber;
      
      // Bytt posisjon i arrayet
      [updatedRoute[index], updatedRoute[index - 1]] = [updatedRoute[index - 1], updatedRoute[index]];
      
      // Valider den nye ruten
      if (!validateRouteOrder(updatedRoute)) {
        return; // Ikke oppdater hvis valideringen feiler
      }
      
      setCurrentRoute(updatedRoute);
      // Oppdater parent-komponenten
      props.update(updatedRoute);
    };
    
    // Funksjon for å flytte et stopp ned i rekkefølgen
    const moveStopDown = (index: number) => {
      if (index >= currentRoute.length - 1) return; // Kan ikke flytte siste element ned
      
      const updatedRoute = [...currentRoute];
      // Bytt stopNumber mellom dette og neste element
      const currentStopNumber = updatedRoute[index].stopNumber;
      updatedRoute[index].stopNumber = updatedRoute[index + 1].stopNumber;
      updatedRoute[index + 1].stopNumber = currentStopNumber;
      
      // Bytt posisjon i arrayet
      [updatedRoute[index], updatedRoute[index + 1]] = [updatedRoute[index + 1], updatedRoute[index]];
      
      // Valider den nye ruten
      if (!validateRouteOrder(updatedRoute)) {
        return; // Ikke oppdater hvis valideringen feiler
      }
      
      setCurrentRoute(updatedRoute);
      // Oppdater parent-komponenten
      props.update(updatedRoute);
    };

    // Handle creation/updating of a location.
    const handleCreateOrUpdate = (location: ILocation) => {
      addSavedLocation(location.id);
      openInsertModal(false);
    };

    // Finn CreateDialog-komponenten og oppdater den
    const CreateDialog = (
      <LocationModal
        data={locationData}
        isShowing={isInsertModalOpen}
        showSaveToggle={true}
        shouldSaveLocation={shouldSaveLocationRef.current}
        onSaveToggleChange={(shouldSave) => {
          shouldSaveLocationRef.current = shouldSave;
        }}
        onConfirm={(location: ILocation) => handleCreateOrUpdate(location)}
        onCancel={() => openInsertModal(false)}
      />
    );

    const showAllLocationsDialog = (
      <Dialog
        title={t("create.route_selection.add_saved_location_title")}
        show={showAllLocations}
        dismissible
        toggleModal={() => toggleShowAllDialog()}
        hideButtons
        content={
          <SavedLocations
            locations={
              locations?.filter(
                (x) => !currentRoute.map((r) => r.location.id).includes(x.id)
              ) || []
            }
            currentRoute={currentRoute}
            addLocation={addSavedLocation}
            close={toggleShowAllDialog}
          />
        }
      />
    );

    // Oppdater addSavedLocation for å inkludere validering
    function addSavedLocation(id: string) {
      const location = locations?.find((x) => x.id === id);
      let newRoute = [...currentRoute];
      
      if (!location) {
        console.error("Kunne ikke finne lokasjon med ID:", id);
        return;
      }
      
      // Marker lokasjonen som lagret
      (location as any).isSaved = true;
      
      // Bestem om dette skal være en henting eller levering
      const shouldBePickup = currentRoute.length === 0 || !currentRoute.some(item => item.isPickup);
      
      if (currentRoute.length === 1 && !currentRoute[0].isPickup) {
        newRoute = [{
          location: location as ILocation,
          stopNumber: currentRoute.length,
          stopDate: Timestamp.now(),
          timing: { earliest: true } as IRouteTiming,
          isPickup: true // Første stopp eller hvis ingen henting finnes ennå
        }, ...currentRoute]
      } else {
        newRoute = newRoute.concat([
          {
            location: location as ILocation,
            stopNumber: currentRoute.length,
            stopDate: Timestamp.now(),
            timing: { earliest: true } as IRouteTiming,
            isPickup: shouldBePickup // Første stopp eller hvis ingen henting finnes ennå
          },
        ]);
      }
      
    
      
      setSearchText("");
      if (showAllLocations) {
        toggleShowAllDialog();
      }
      setCurrentRoute(newRoute);
      // Oppdater parent-komponenten
      props.update(newRoute);
    }

    function addRow() {
      // Åpne dialogen med riktig defaultIsPickup verdi
      setShowAddLocationDialog(true);
    }
    
    // Oppdater addNewLocationFromDialog for å inkludere validering
    function addNewLocationFromDialog(
      name: string,
      address: string,
      postCode: string,
      city: string,
      country: string,
      description: string,
      isPickup: boolean,
      date: Date,
      timingOption: string,
      shouldSave: boolean
    ) {
      
      // Tving første stopp til å være henting
      if (currentRoute.length === 0 && !isPickup) {
        isPickup = true; // Overstyr brukerens valg for første stopp
      }
      
      // Hvis dette ikke er en henting og vi ikke har noen henting ennå, vis feilmelding
      if (!isPickup && !currentRoute.some(item => item.isPickup)) {
        setValidationMessage(t("create.route_selection.need_pickup_first"));
        setShowValidationToast(true);
        return;
      }
      
      let timing: IRouteTiming;
      
      switch (timingOption) {
        case "1": timing = { earliest: false, latest: false, before: "17:00" }; break;
        case "2": timing = { earliest: false, latest: false, before: "17:00", after: "08:00" }; break;
        case "3": timing = { earliest: false, latest: false, after: "08:00" }; break;
        case "4": timing = { earliest: true, latest: false }; break;
        case "5": timing = { earliest: false, latest: true }; break;
        default: timing = { earliest: true, latest: false };
      }
      
      // Opprett et nytt lokasjonsobjekt med all informasjon
      const location: ILocation = {
        id: '', // Midlertidig ID
        displayName: name || t("create.route_selection.unnamed_location"),
        addressLine: address,
        postCode: postCode,
        city: city,
        countryCode: country,
        description: description,
        workspaceId: activeWorkspace?.workspaceId || "",
        customerId: "",
        createdAt: Timestamp.now()
      };
      
      // Hvis lokasjonen skal lagres, gjør det først
      if (shouldSave) {
        // Opprett et Location-objekt for å lagre
        const locationToSave = new Location({...location});
        
        // Vis laster-indikator
        // TODO: Legg til en laster-indikator her
        
        // Lagre lokasjonen i databasen
        Location.create(locationToSave)
          .then((res: ILocation | null) => {
            
            if (res) {
              // Marker lokasjonen som lagret
              (res as any).isSaved = true;
                            
              // Oppdater query for å tvinge en oppdatering av lokasjonslisten
              if (query && query.refetch) {
                setTimeout(() => {
                  query.refetch();
                }, 500);
              }
              // Legg til stoppet i ruten
              addStopToRoute(res, isPickup, date, timing);
            } else {
              console.error("Kunne ikke lagre lokasjon i databasen");
              // Marker lokasjonen som ikke lagret
              (location as any).isSaved = false;
              addStopToRoute(location, isPickup, date, timing);
            }
          })
          .catch(error => {
            console.error("Feil ved lagring av lokasjon:", error);
            // Marker lokasjonen som ikke lagret
            (location as any).isSaved = false;
            // Legg til stoppet i ruten selv om lagringen feilet
            addStopToRoute(location, isPickup, date, timing);
          });
      } else {
        // Marker lokasjonen som ikke lagret
        (location as any).isSaved = false;
        
        // Legg til stoppet i ruten uten å lagre lokasjonen
        addStopToRoute(location, isPickup, date, timing);
      }
      
      // Lukk dialogen
      setShowAddLocationDialog(false);
    }

    // Hjelpefunksjon for å legge til et stopp i ruten
    function addStopToRoute(location: ILocation, isPickup: boolean, date: Date, timing: IRouteTiming) {
      const newLocation: IRouteItem = {
        location: location,
        stopNumber: currentRoute.length,
        stopDate: Timestamp.fromDate(date),
        timing: timing,
        isPickup: isPickup
      };
      
      const updatedRoute = [...currentRoute, newLocation];
      
      // Valider den nye ruten
      if (!validateRouteOrder(updatedRoute)) {
        return; // Ikke oppdater hvis valideringen feiler
      }
      setCurrentRoute(updatedRoute);
      // Oppdater parent-komponenten
      props.update(updatedRoute);
    }

    // Toggle expanded state for a route item
    const toggleExpandRouteItem = (index: number) => {
      if (expandedRouteItems.includes(index)) {
        setExpandedRouteItems(expandedRouteItems.filter(idx => idx !== index));
      } else {
        setExpandedRouteItems([...expandedRouteItems, index]);
      }
    };

    // Format date for display
    const formatDate = (timestamp: Timestamp) => {
      const date = timestamp.toDate();
      return date.toLocaleDateString();
    };

    // Get timing display text
    const getTimingText = (timing: IRouteTiming) => {
      if (timing.earliest) return t("create.route_selection.earliest_possible");
      if (timing.latest) return t("create.route_selection.latest_possible");
      if (timing.before) return `${t("create.route_selection.before")} ${timing.before}`;
      if (timing.after) return `${t("create.route_selection.after")} ${timing.after}`;
      if (timing.before && timing.after) 
        return `${t("create.route_selection.between")} ${timing.after} - ${timing.before}`;
      return "";
    };

    // Render compact location card
    const renderCompactLocationCard = (routeItem: IRouteItem, index: number) => {
      const isExpanded = expandedRouteItems.includes(index);
      
      // Sjekk om lokasjonen er lagret i databasen
      // Sjekk først om isSaved-egenskapen er satt, deretter sjekk ID-formatet
      const isSavedLocation = (routeItem.location as any)?.isSaved === true || 
                             (routeItem.location.id && 
                              !routeItem.location.id.startsWith('temp-') && 
                              routeItem.location.id.length > 10);
            
      // Sjekk om lokasjonen har gyldig adresse
      const hasValidAddress = routeItem.location.addressLine && 
                             routeItem.location.postCode && 
                             routeItem.location.city;
      
      // Funksjon for å endre om et stopp er henting eller levering
      const handleTogglePickupDelivery = (isPickup: boolean) => {
        togglePickupDelivery(index, isPickup);
      };
      
      // Bestem bakgrunnsfarge og border basert på om det er henting eller levering og om lokasjonen er lagret
      const getCardStyle = () => {
        // Hvis lokasjonen er lagret, bruk blå/grønn styling
        if (isSavedLocation) {
          if (routeItem.isPickup) {
            return "bg-gradient-to-r from-lgb-blue-100 via-lgb-blue-50 to-white dark:from-[#051a3e]/80 dark:via-[#051a3e]/40 dark:to-lgb-dark-background border-l-4 border-lgb-blue-300 dark:border-lgb-blue-300 text-gray-900 dark:text-white shadow-sm";
          } else {
            return "bg-gradient-to-r from-lgb-green-100 via-lgb-green-50 to-white dark:from-[#053e14]/80 dark:via-[#053e14]/40 dark:to-lgb-dark-background border-l-4 border-lgb-green-300 dark:border-lgb-green-300 text-gray-900 dark:text-white shadow-sm";
          }
        } 
        // Hvis lokasjonen ikke er lagret, bruk gul styling uavhengig av om det er henting eller levering
        else {
          return "bg-gradient-to-r from-lgb-yellow-100 via-lgb-yellow-50 to-white dark:from-[#3e3005]/80 dark:via-[#3e3005]/40 dark:to-lgb-dark-background border-l-4 border-lgb-yellow-300 dark:border-lgb-yellow-300 text-gray-900 dark:text-white shadow-sm";
        }
      };
      
      // Bestem farge for nummer-indikatoren
      const getNumberStyle = () => {
        return routeItem.isPickup 
          ? "bg-lgb-blue-300 dark:bg-lgb-blue-300 text-white shadow-lg" 
          : "bg-lgb-green-300 dark:bg-lgb-green-500 text-white shadow-lg";
      };
      
      // Bestem tekst for stopp-nummer
      const getStopLabel = () => {
        return routeItem.isPickup 
          ? t("pickup") 
          : t("delivery");
      };
      
      return (
        <div 
          key={`route-item-${index}`}
          className={`rounded-lg p-3 mb-3 transition-all duration-200 relative shadow-md hover:shadow-lg ${getCardStyle()} ${props.validationFailed && !hasValidAddress ? "border-red-500" : ""}`}
        >
          {/* Stopp-nummer indikator */}
          <div className="absolute -left-6 top-1/2 -translate-y-1/2 flex flex-col items-center">
            <div className={`w-8 h-8 rounded-full flex items-center justify-center font-bold shadow-md ${getNumberStyle()}`}>
              {index + 1}
            </div>
          </div>
          
          {!isExpanded && (
            <div className={`${isMobile ? 'grid grid-cols-1 gap-2' : 'grid grid-cols-12 gap-2'} items-start`}>
              {/* Info-seksjon */}
              <div className={`${isMobile ? 'mb-3' : 'col-span-5'}`}>
                <div className="flex items-center mb-1">
                  <Badge 
                    color={routeItem.isPickup ? "info" : "success"} 
                    className="mr-2"
                  >
                    {getStopLabel()}
                  </Badge>
                  {isSavedLocation ? (
                    <span className="text-xs text-lgb-green-600 dark:text-lgb-green-400">
                      ({t("create.route_selection.saved")})
                    </span>
                  ) : (
                    <span className="text-xs text-lgb-yellow-600 dark:text-lgb-yellow-400">
                      ({t("create.route_selection.not_saved")})
                    </span>
                  )}
                </div>
                <h3 className="text-md font-medium text-gray-900 dark:text-white truncate">
                  {routeItem.location.displayName || t("create.route_selection.unnamed_location")}
                </h3>
                <div className="flex items-center text-sm text-lgb-grey-600 dark:text-lgb-grey-300">
                  <HiOutlineLocationMarker className="h-3.5 w-3.5 mr-1 flex-shrink-0 dark:text-white" />
                  <span className="truncate">{routeItem.location.addressLine || t("create.route_selection.no_address")}</span>
                </div>
              </div>
              
              {/* Dato og timing */}
              <div className={`${isMobile ? 'mb-3' : 'col-span-4'}`}>
                <div className="flex items-center text-sm text-lgb-grey-600 dark:text-lgb-grey-300 mb-1">
                  <HiOutlineCalendar className="h-3.5 w-3.5 mr-1 flex-shrink-0 dark:text-white" />
                  <span>{formatDate(routeItem.stopDate)}</span>
                </div>
                <div className="text-sm text-lgb-grey-600 dark:text-lgb-grey-300">
                  {getTimingText(routeItem.timing)}
                </div>
                
                {/* Pickup/Delivery toggler */}
                <div className={`flex ${isMobile ? 'mt-3' : 'mt-2'} space-x-4`}>
                  <div className="flex items-center">
                    <input
                      id={`pickup-${index}`}
                      type="radio"
                      name={`stopType-${index}`}
                      className="w-4 h-4 text-lgb-blue-300 bg-gray-100 border-gray-300 focus:ring-lgb-blue-300 dark:focus:ring-lgb-blue-300 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-[#2c3353]"
                      checked={routeItem.isPickup === true}
                      onChange={() => handleTogglePickupDelivery(true)}
                    />
                    <label
                      htmlFor={`pickup-${index}`}
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      {t("pickup")}
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id={`delivery-${index}`}
                      type="radio"
                      name={`stopType-${index}`}
                      className="w-4 h-4 text-lgb-blue-300 bg-gray-100 border-gray-300 focus:ring-lgb-blue-300 dark:focus:ring-lgb-blue-300 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-[#2c3353]"
                      checked={routeItem.isPickup === false}
                      onChange={() => handleTogglePickupDelivery(false)}
                    />
                    <label
                      htmlFor={`delivery-${index}`}
                      className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      {t("delivery")}
                    </label>
                  </div>
                </div>
              </div>
              
              {/* Handlingsknapper */}
              <div className={`${isMobile ? 'flex items-center justify-between' : 'col-span-3 flex justify-end items-center'} space-x-1`}>
                {/* Rekkefølge-knapper - bare på desktop */}
                {!isMobile && (
                  <div className="flex flex-col mr-2">
                    <Button
                      size="xs"
                      color="light"
                      onClick={() => moveStopUp(index)}
                      disabled={index === 0}
                      className="p-1 mb-1 hover:bg-lgb-grey-100 dark:hover:bg-lgb-grey-800 disabled:opacity-50"
                    >
                      <HiOutlineArrowUp className="h-3.5 w-3.5 dark:text-white" />
                    </Button>
                    <Button
                      size="xs"
                      color="light"
                      onClick={() => moveStopDown(index)}
                      disabled={index === currentRoute.length - 1}
                      className="p-1 hover:bg-lgb-grey-100 dark:hover:bg-lgb-grey-800 disabled:opacity-50"
                    >
                      <HiOutlineArrowDown className="h-3.5 w-3.5 dark:text-white" />
                    </Button>
                  </div>
                )}
                
                {/* På mobil, bruk ikonknapper i stedet for tekst for å spare plass */}
                <Button 
                  size="xs" 
                  color="light"
                  onClick={() => toggleExpandRouteItem(index)}
                  className={`p-1 hover:bg-lgb-grey-100 dark:hover:bg-lgb-grey-800 ${isMobile ? 'p-2 w-auto h-auto flex items-center' : ''}`}
                >
                  {isMobile ? (
                    <>
                      <HiOutlinePencil className="h-4 w-4 dark:text-white mr-1" />
                      <span className="text-xs">Rediger</span>
                    </>
                  ) : (
                    <>
                      <HiOutlinePencil className="mr-1 h-3.5 w-3.5 dark:text-white" />
                      {t("common:edit")}
                    </>
                  )}
                </Button>
                
                {/* Opp/ned knapper på mobil */}
                {isMobile && (
                  <>
                    <Button
                      size="xs"
                      color="light"
                      onClick={() => moveStopUp(index)}
                      disabled={index === 0}
                      className="p-2 hover:bg-lgb-grey-100 dark:hover:bg-lgb-grey-800 disabled:opacity-50 w-10 h-10"
                    >
                      <HiOutlineArrowUp className="h-4 w-4 dark:text-white" />
                    </Button>
                    <Button
                      size="xs"
                      color="light"
                      onClick={() => moveStopDown(index)}
                      disabled={index === currentRoute.length - 1}
                      className="p-2 hover:bg-lgb-grey-100 dark:hover:bg-lgb-grey-800 disabled:opacity-50 w-10 h-10"
                    >
                      <HiOutlineArrowDown className="h-4 w-4 dark:text-white" />
                    </Button>
                  </>
                )}
                
                <Button 
                  size="xs" 
                  color="light"
                  onClick={() => removeRouteItem(index)}
                  className={`p-1 hover:bg-lgb-grey-100 dark:hover:bg-lgb-grey-800 ${isMobile ? 'p-2 w-10 h-10' : ''}`}
                >
                  {isMobile ? (
                    <HiOutlineTrash className="h-4 w-4 dark:text-white" />
                  ) : (
                    <HiOutlineTrash className="h-3.5 w-3.5 dark:text-white" />
                  )}
                </Button>
              </div>
            </div>
          )}

          {/* Expanded view with full RouteCard */}
          {isExpanded && (
            <div>
              <div className="flex justify-between items-center mb-2">
                <div className="flex items-center">
                  <Badge 
                    color={routeItem.isPickup ? "info" : "success"} 
                    className="mr-2"
                  >
                    {getStopLabel()}
                  </Badge>
                  {isSavedLocation ? (
                    <span className="text-xs text-lgb-green-600 dark:text-lgb-green-400">
                      ({t("create.route_selection.saved")})
                    </span>
                  ) : (
                    <span className="text-xs text-lgb-yellow-600 dark:text-lgb-yellow-400">
                      ({t("create.route_selection.not_saved")})
                    </span>
                  )}
                </div>
                <div className="flex items-center space-x-2">
                  {/* Rekkefølge-knapper */}
                  <div className="flex space-x-1 mr-2">
                    <Button 
                      size="xs" 
                      color="light"
                      onClick={() => moveStopUp(index)}
                      disabled={index === 0}
                      className="p-1 hover:bg-lgb-grey-100 dark:hover:bg-lgb-grey-800 disabled:opacity-50"
                    >
                      <HiOutlineArrowUp className="h-3.5 w-3.5 dark:text-white" />
                    </Button>
                    <Button 
                      size="xs" 
                      color="light"
                      onClick={() => moveStopDown(index)}
                      disabled={index === currentRoute.length - 1}
                      className="p-1 hover:bg-lgb-grey-100 dark:hover:bg-lgb-grey-800 disabled:opacity-50"
                    >
                      <HiOutlineArrowDown className="h-3.5 w-3.5 dark:text-white" />
                    </Button>
                  </div>
                  <button
                    onClick={() => toggleExpandRouteItem(index)}
                    className="p-1 text-lgb-grey-500 hover:text-lgb-primary dark:text-lgb-grey-400 dark:hover:text-white"
                    aria-label="Collapse"
                  >
                    <HiOutlineChevronUp className="h-5 w-5 dark:text-white" />
                  </button>
                </div>
              </div>
              
              <div className="mt-2 pt-2 border-t border-lgb-grey-200 dark:border-[#2c3353]">
                <div className="bg-transparent dark:bg-transparent rounded-lg p-4 shadow-sm">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <Label
                      className="dark:text-gray-300 mb-2"
                      htmlFor={`date-${index}`}
                      value={t("create.route_selection.date")}
                    />
                    <LGBDatepicker
                      inputId={`date-${index}`}
                      selectedDate={routeItem.stopDate ? routeItem.stopDate.toDate() : undefined}
                      select={(newDate) => {
                        const updatedRouteItem = { 
                          ...routeItem, 
                          stopDate: Timestamp.fromDate(newDate) 
                        };
                        updateRouteItem(index, updatedRouteItem);
                      }}
                    />
                  </div>
                  
                  <div>
                    <Label
                      className="dark:text-gray-300 mb-2"
                      htmlFor={`timing-select-${index}`}
                      value={t("create.route_selection.timing")}
                    />
                    <select
                      id={`timing-select-${index}`}
                        className="w-full border p-2.5 text-sm rounded-lg border-gray-300 dark:border-[#2c3353] dark:bg-lgb-dark-background dark:text-white"
                      value={
                        routeItem.timing.earliest ? "4" :
                        routeItem.timing.latest ? "5" :
                        routeItem.timing.before && routeItem.timing.after ? "2" :
                        routeItem.timing.before ? "1" : 
                        routeItem.timing.after ? "3" : "4"
                      }
                      onChange={(e) => {
                        let newTiming: IRouteTiming = { earliest: false, latest: false };
                        switch (e.target.value) {
                            case "1": newTiming = { earliest: false, latest: false, before: routeItem.timing.before || "17:00" }; break;
                            case "2": newTiming = { earliest: false, latest: false, before: routeItem.timing.before || "17:00", after: routeItem.timing.after || "08:00" }; break;
                            case "3": newTiming = { earliest: false, latest: false, after: routeItem.timing.after || "08:00" }; break;
                          case "4": newTiming = { earliest: true, latest: false }; break;
                          case "5": newTiming = { earliest: false, latest: true }; break;
                        }
                        const updatedRouteItem = { ...routeItem, timing: newTiming };
                        updateRouteItem(index, updatedRouteItem);
                      }}
                    >
                      <option value="1">{t("create.route_selection.before")}</option>
                      <option value="2">{t("create.route_selection.between")}</option>
                      <option value="3">{t("create.route_selection.after")}</option>
                      <option value="4">{t("create.route_selection.earliest_possible")}</option>
                      <option value="5">{t("create.route_selection.latest_possible")}</option>
                      </select>
                      
                      
                      {/* Tidsvelgere for før/etter/før og etter */}
                      {(routeItem.timing.before || routeItem.timing.after) && (
                        <div className="mt-3 grid grid-cols-1 gap-3">
                          {routeItem.timing.before && (
                            <div>
                              <Label
                                className="dark:text-gray-300 mb-2 text-sm"
                                htmlFor={`before-time-${index}`}
                                value={t("create.route_selection.before")}
                              />
                              <select
                                id={`before-time-${index}`}
                                className="w-full border p-2.5 text-sm rounded-lg border-gray-300 dark:border-[#2c3353] dark:bg-lgb-dark-background dark:text-white"
                                value={routeItem.timing.before}
                                onChange={(e) => {
                                  const newTiming = { ...routeItem.timing, before: e.target.value };
                                  const updatedRouteItem = { ...routeItem, timing: newTiming };
                                  updateRouteItem(index, updatedRouteItem);
                                }}
                              >
                                {generateTimeOptions().map(time => (
                                  <option key={`before-${time}`} value={time}>{time}</option>
                                ))}
                    </select>
                            </div>
                          )}
                          
                          {routeItem.timing.after && (
                            <div>
                              <Label
                                className="dark:text-gray-300 mb-2 text-sm"
                                htmlFor={`after-time-${index}`}
                                value={t("create.route_selection.after")}
                              />
                              <select
                                id={`after-time-${index}`}
                                className="w-full border p-2.5 text-sm rounded-lg border-gray-300 dark:border-[#2c3353] dark:bg-lgb-dark-background dark:text-white"
                                value={routeItem.timing.after}
                                onChange={(e) => {
                                  const newTiming = { ...routeItem.timing, after: e.target.value };
                                  const updatedRouteItem = { ...routeItem, timing: newTiming };
                                  updateRouteItem(index, updatedRouteItem);
                                }}
                              >
                                {generateTimeOptions().map(time => (
                                  <option key={`after-${time}`} value={time}>{time}</option>
                                ))}
                              </select>
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                </div>
                  
                  {/* Lokasjonsinformasjon - kun redigerbar for ikke-lagrede lokasjoner */}
                  {!hasValidAddress && (
                    <div className="mb-4 border-t border-lgb-grey-200 dark:border-[#2c3353] pt-4">
                      <h4 className="text-md font-medium mb-3 dark:text-white">
                        {t("create.route_selection.location")}
                      </h4>
                      
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                        <div>
                          <Label
                            className="dark:text-gray-300 mb-2"
                            htmlFor={`location-name-${index}`}
                            value={t("create.route_selection.display_name")}
                          />
                          <input
                            type="text"
                            id={`location-name-${index}`}
                            className="w-full border p-2.5 text-sm rounded-lg border-gray-300 dark:border-[#2c3353] dark:bg-lgb-dark-background dark:text-white"
                            placeholder={t("create.route_selection.display_name_ph")}
                            value={routeItem.location.displayName || ""}
                            onChange={(e) => {
                              const updatedLocation = { ...routeItem.location, displayName: e.target.value };
                              const updatedRouteItem = { ...routeItem, location: updatedLocation };
                              updateRouteItem(index, updatedRouteItem);
                            }}
                          />
                        </div>
                        
                        <div>
                          <Label
                            className="dark:text-gray-300 mb-2"
                            htmlFor={`location-address-${index}`}
                            value={t("create.route_selection.address")}
                          />
                          <input
                            type="text"
                            id={`location-address-${index}`}
                            className="w-full border p-2.5 text-sm rounded-lg border-gray-300 dark:border-[#2c3353] dark:bg-lgb-dark-background dark:text-white"
                            placeholder={t("create.route_selection.address_ph")}
                            value={routeItem.location.addressLine || ""}
                            onChange={(e) => {
                              const updatedLocation = { ...routeItem.location, addressLine: e.target.value };
                              const updatedRouteItem = { ...routeItem, location: updatedLocation };
                              updateRouteItem(index, updatedRouteItem);
                            }}
                          />
                        </div>
                      </div>
                      
                      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-4">
                        <div>
                          <Label
                            className="dark:text-gray-300 mb-2"
                            htmlFor={`location-postcode-${index}`}
                            value={t("create.route_selection.postal_code")}
                          />
                          <input
                            type="text"
                            id={`location-postcode-${index}`}
                            className="w-full border p-2.5 text-sm rounded-lg border-gray-300 dark:border-[#2c3353] dark:bg-lgb-dark-background dark:text-white"
                            placeholder={t("create.route_selection.postal_code_ph")}
                            value={routeItem.location.postCode || ""}
                            onChange={(e) => {
                              const updatedLocation = { ...routeItem.location, postCode: e.target.value };
                              const updatedRouteItem = { ...routeItem, location: updatedLocation };
                              updateRouteItem(index, updatedRouteItem);
                            }}
                          />
                        </div>
                        
                        <div>
                          <Label
                            className="dark:text-gray-300 mb-2"
                            htmlFor={`location-city-${index}`}
                            value={t("create.route_selection.city")}
                          />
                          <input
                            type="text"
                            id={`location-city-${index}`}
                            className="w-full border p-2.5 text-sm rounded-lg border-gray-300 dark:border-[#2c3353] dark:bg-lgb-dark-background dark:text-white"
                            placeholder={t("create.route_selection.city_ph")}
                            value={routeItem.location.city || ""}
                            onChange={(e) => {
                              const updatedLocation = { ...routeItem.location, city: e.target.value };
                              const updatedRouteItem = { ...routeItem, location: updatedLocation };
                              updateRouteItem(index, updatedRouteItem);
                            }}
                          />
                        </div>
                        
                        <div>
                          <Label
                            className="dark:text-gray-300 mb-2"
                            htmlFor={`location-country-${index}`}
                            value={t("create.route_selection.country")}
                          />
                          <select
                            id={`location-country-${index}`}
                            className="w-full border p-2.5 text-sm rounded-lg border-gray-300 dark:border-[#2c3353] dark:bg-lgb-dark-background dark:text-white mt-2"
                            value={routeItem.location.countryCode || "NO"}
                            onChange={(e) => {
                              const updatedLocation = { ...routeItem.location, countryCode: e.target.value };
                              const updatedRouteItem = { ...routeItem, location: updatedLocation };
                              updateRouteItem(index, updatedRouteItem);
                            }}
                          >
                            <option value="NO">Norge</option>
                            <option value="SE">Sverige</option>
                            <option value="DK">Danmark</option>
                            <option value="FI">Finland</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                
                <div className="mb-4">
                  <Label
                    className="dark:text-gray-300 mb-2"
                    htmlFor={`notes-${index}`}
                    value={t("common:comment")}
                  />
                  <textarea
                    id={`notes-${index}`}
                    rows={3}
                      className="w-full border p-2.5 text-sm rounded-lg border-gray-300 dark:border-[#2c3353] dark:bg-lgb-dark-background dark:text-white"
                    placeholder={t("common:placeholder.description")}
                    value={routeItem.location.description || ""}
                    onChange={(e) => {
                      const updatedLocation = { ...routeItem.location, description: e.target.value };
                      const updatedRouteItem = { ...routeItem, location: updatedLocation };
                      updateRouteItem(index, updatedRouteItem);
                    }}
                  />
                </div>
                
                {/* Kontaktpersoner */}
                <div className="mb-4">
                  <div className="flex justify-between items-center mb-2">
                    <Label
                      className="dark:text-gray-300"
                      htmlFor={`contacts-${index}`}
                      value={t("create.contact_info.title")}
                    />
                    <Button 
                      size="xs" 
                      color="light"
                      onClick={() => openContactDialog(index)}
                      className="p-1 hover:bg-lgb-grey-100 dark:hover:bg-lgb-grey-800 flex items-center"
                    >
                      <HiOutlineUser className="mr-1 h-3.5 w-3.5 dark:text-white" />
                      {isMobile ? "Legg til" : t("create.contact_info.title")}
                    </Button>
                  </div>
                  
                  {/* Liste over kontaktpersoner */}
                  {routeItem.contact && routeItem.contact.length > 0 ? (
                    <div className="space-y-2 mt-2">
                      {routeItem.contact.map((person, contactIdx) => (
                        <div 
                          key={contactIdx} 
                            className="flex justify-between items-center p-3 bg-gray-50 dark:bg-gray-800/80 rounded-lg border border-transparent dark:border-[#2c3353]"
                        >
                          <div className="flex items-center">
                              <HiOutlineUser className="h-4 w-4 mr-2 text-lgb-grey-600 dark:text-white" />
                              <span className="text-sm font-medium text-gray-900 dark:text-white">{person.name}</span>
                          </div>
                          <div className="flex items-center">
                              <HiOutlinePhone className="h-4 w-4 mr-1 text-lgb-grey-600 dark:text-white" />
                              <span className="text-sm mr-3 text-gray-900 dark:text-white">{person.phoneNumber}</span>
                            <button
                              onClick={() => {
                                // Fjern kontaktperson
                                const updatedContacts = routeItem.contact?.filter((_, idx) => idx !== contactIdx) || [];
                                const updatedRouteItem = { ...routeItem, contact: updatedContacts };
                                updateRouteItem(index, updatedRouteItem);
                              }}
                              className="text-lgb-grey-600 dark:text-lgb-grey-400 hover:text-lgb-red-500 dark:hover:text-lgb-red-400"
                            >
                                <HiOutlineX className="h-4 w-4 dark:text-white" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                      <div className="text-sm text-lgb-grey-600 dark:text-lgb-grey-300 italic mt-2">
                      {t("create.contact_info.no_contacts")}
                    </div>
                  )}
                </div>
                
                <div className="flex justify-end mt-4">
                  <button
                    onClick={() => toggleExpandRouteItem(index)}
                      className="text-sm px-4 py-2 text-lgb-grey-700 dark:text-white hover:text-lgb-primary dark:hover:text-lgb-blue-300 border border-lgb-grey-300 dark:border-[#2c3353] rounded-lg flex items-center transition-colors duration-200 bg-transparent dark:bg-lgb-blue-900/20 hover:bg-lgb-grey-100 dark:hover:bg-lgb-blue-900/40"
                  >
                    {t("common:close")}
                  </button>
                </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    };

    // Check if we have at least one pickup and one delivery location
    const hasRequiredLocations = currentRoute.length >= 2;

    // Funksjon for å åpne kontaktperson-dialogen
    const openContactDialog = useCallback((index: number) => {
      setContactDialogIndex(index);
      setShowContactDialog(true);
    }, []);
    
    // Funksjon for å legge til kontaktperson
    const handleAddContact = useCallback((name: string, phone: string) => {
      if (contactDialogIndex !== null) {
        const routeItem = currentRoute[contactDialogIndex];
        const newContact = {
          name: name,
          phoneNumber: phone,
          location: routeItem.location.displayName || ""
        };
        
        const updatedContacts = routeItem.contact 
          ? [...routeItem.contact, newContact] 
          : [newContact];
          
        const updatedRouteItem = { ...routeItem, contact: updatedContacts };
        updateRouteItem(contactDialogIndex, updatedRouteItem);
        
        // Lukk dialogen
        setShowContactDialog(false);
      }
    }, [contactDialogIndex, currentRoute, updateRouteItem]);

    return (
      <div>
        {/* Toast notification for validation errors */}
        <ValidationToast
          show={showValidationToast}
          title={t("create.route_selection.validation_error")}
          message={validationMessage}
          onClose={() => setShowValidationToast(false)}
          position="top-right"
        />
        
        {/* Initial view with search and add buttons */}
        <div className="mb-6 bg-white dark:bg-lgb-dark-background rounded-lg p-3 md:p-6 border border-lgb-grey-200 dark:border-[#2c3353]">
          <h2 className="text-xl font-medium mb-2 text-gray-900 dark:text-white">
            {t("create.route_selection.title")}
          </h2>
          
          <p className="text-lgb-grey-600 dark:text-lgb-grey-300 mb-4">
            {t("create.route_selection.description")}
          </p>
          
          {!props.hideSearch && (
            <div className="mb-4">
              <DropdownSearch
                value={searchText}
                placeholder={t("create.route_selection.search_ph")}
                inputChanged={handleSearch}
                showResults={searchResults.length > 0}
                onFocus={handleFocus}
                onBlur={handleBlur}
                childrenAbsolute
              >
                {query.isLoading && <TableSkeletonsByAmount quantity={3} />}
                {!query.isLoading &&
                  searchResults.length > 0 && (
                    <div>
                      {searchResults.map((location: ILocation) => (
                        <DropdownSearchListItem
                          key={location.id}
                          id={location.id}
                          title={location.displayName}
                          subtitle={location.addressLine || ""}
                          buttonClickEvent={addSavedLocation}
                          inlineSubtitle
                          iconType="location"
                        />
                      ))}
                      {searchResults.length === 3 && (
                        <p
                          className="pt-4 text-md underline font-normal cursor-pointer hover:text-lgb-primary"
                          onClick={() => toggleShowAllDialog()}
                        >
                          {t("create.route_selection.see_all")}
                        </p>
                      )}
                    </div>
                  )}
              </DropdownSearch>
            </div>
          )}
          
          {/* Add location buttons */}
          <div className="flex flex-wrap gap-2">
            <StyledButton
              variant="secondary"
              onClick={() => toggleShowAllDialog()}
              className="flex items-center border-lgb-grey-200 dark:border dark:border-gray-500"
              icon={<HiOutlineLocationMarker className="dark:text-white" />}
            >
              {isMobile ? "Lagrede steder" : t("create.route_selection.add_saved_location_button")}
            </StyledButton>
            
            <StyledButton
              variant="secondary"
              onClick={() => addRow()}
              className="flex items-center border-lgb-grey-200 dark:border dark:border-gray-500"
              icon={<HiOutlinePlus className="dark:text-white" />}
            >
              {isMobile ? "Legg til" : t("create.route_selection.add_stop")}
            </StyledButton>
          </div>
        </div>

        {/* Selected locations section */}
        {currentRoute.length > 0 && (
          <div className="mb-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-medium dark:text-white">
                {t("create.route_selection.selected_locations")} ({currentRoute.length})
              </h3>
              
              {!hasRequiredLocations && (
                <Badge color="warning">
                  {t("create.route_selection.need_pickup_delivery")}
                </Badge>
              )}
            </div>
            
            <div className="flex flex-col gap-3 md:gap-6">
              {currentRoute.map((routeItem, index) => 
                renderCompactLocationCard(routeItem, index)
              )}
            </div>
          </div>
        )}

        {/* Empty state */}
        {currentRoute.length === 0 && (
          <div className="text-center p-3 md:p-8 bg-white dark:bg-lgb-dark-background rounded-lg border border-lgb-grey-200 dark:border-[#2c3353] shadow-md">
            <HiOutlineLocationMarker className="mx-auto h-12 w-12 text-lgb-grey-400 dark:text-white" />
            <h3 className="mt-2 text-lg font-medium text-gray-900 dark:text-white">
              {t("create.route_selection.no_locations_yet")}
            </h3>
            <p className="mt-1 text-lgb-grey-600 dark:text-white">
              {t("create.route_selection.start_by_adding_location")}
            </p>
          </div>
        )}
        
        {CreateDialog}
        {showAllLocationsDialog}
        
        {/* Dialog for å legge til ny lokasjon */}
        <AddEmptyStopDialog
          isOpen={showAddLocationDialog}
          onClose={() => setShowAddLocationDialog(false)}
          onAdd={addNewLocationFromDialog}
          t={t}
          generateTimeOptions={generateTimeOptions}
          defaultIsPickup={currentRoute.length === 0}
          shouldSaveLocation={shouldSaveLocationRef.current}
          onSaveToggleChange={(shouldSave) => {
            shouldSaveLocationRef.current = shouldSave;
          }}
        />
        
        {/* Erstatt den gamle kontaktperson-dialogen med den nye komponenten */}
        <ContactPersonDialog
          isOpen={showContactDialog}
          onClose={() => setShowContactDialog(false)}
          onAdd={handleAddContact}
          t={t}
        />
      </div>
    );
  }
);

export default RouteSelection;
