import { FC, useState, useEffect } from "react";
import { MapStopOverlayProps } from "../types";
import { EnhancedRouteItem } from "../../../../components/molecules/Planner/types";
import { HiX, HiLocationMarker, HiClock, HiInformationCircle, HiPhone, HiMail, HiUser } from "react-icons/hi";
import { HiOutlineLocationMarker, HiOutlineClock, HiOutlineInformationCircle, HiOutlinePhone, HiOutlineMail, HiOutlineUser, HiOutlineExternalLink } from "react-icons/hi";
import { Timestamp } from "../../../../types";
import moment from "moment";
import { StyledButton } from "../../../../components/atoms/Button";

export const MapStopOverlay: FC<MapStopOverlayProps> = ({
  selectedRoute,
  selectedStop,
  onClose,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  
  useEffect(() => {
    if (selectedStop) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [selectedStop]);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
    }, 300); // Match transition duration
  };

  if (!selectedRoute || !selectedStop) return null;

  // Find the matching stop from the route to get the cargo data
  const stopWithCargo = selectedRoute.route.find(
    stop => stop.location.id === selectedStop.location.id
  ) as EnhancedRouteItem | undefined;

  const enhancedStop: EnhancedRouteItem = {
    ...selectedStop,
    isAvailable: false,
    cargos: stopWithCargo?.cargos || [],
    orderDisplayName: stopWithCargo?.orderDisplayName || '',
    stopDisplayName: stopWithCargo?.stopDisplayName || ''
  };

  // Format time for display
  const formatTime = (timestamp: any) => {
    if (!timestamp) return "";
    
    try {
      let date;
      if (timestamp.toDate) {
        date = timestamp.toDate();
      } else if (timestamp.seconds) {
        date = new Date(timestamp.seconds * 1000);
      } else if (timestamp instanceof Timestamp) {
        date = Timestamp.toMoment(timestamp).toDate();
      } else {
        return "";
      }
      
      return moment(date).format('HH:mm');
    } catch (error) {
      return "";
    }
  };

  // Format date and time for display
  const formatDateTime = (timestamp: any) => {
    if (!timestamp) return "";
    
    try {
      let date;
      if (timestamp.toDate) {
        date = timestamp.toDate();
      } else if (timestamp.seconds) {
        date = new Date(timestamp.seconds * 1000);
      } else if (timestamp instanceof Timestamp) {
        date = Timestamp.toMoment(timestamp).toDate();
      } else {
        return "";
      }
      
      return moment(date).format('DD.MM.YYYY HH:mm');
    } catch (error) {
      return "";
    }
  };

  // Format location address for URL
  const formatLocationForUrl = (): string => {    
    if (!enhancedStop.location) return '';
    
    // Ensure we have the required fields
    const addressLine = enhancedStop.location.addressLine || '';
    const postCode = enhancedStop.location.postCode || '';
    const city = enhancedStop.location.city || '';
        
    if (!addressLine || !postCode || !city) return '';
    
    // Format exactly like: idrettsvegen-155-5353-straume
    // Remove special characters, convert to lowercase, replace spaces with hyphens
    const formattedAddress = addressLine.toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]/g, '');
    
    const formattedCity = city.toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]/g, '');
    
    // Debug: Log the formatted result
    const result = `${formattedAddress}-${postCode}-${formattedCity}`;
    
    // Hard-code the format to match the example exactly
    return result;
  };

  return (
    <div 
      className={`fixed inset-0 z-50 flex items-center justify-center p-4 transition-opacity duration-300 ${
        isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
    >
      <div 
        className={`bg-white dark:bg-backgroundBlue rounded-lg shadow-xl max-w-md w-full overflow-hidden transition-all duration-300 transform ${
          isVisible ? 'scale-100' : 'scale-95'
        }`}
      >
        {/* Header med gradient bakgrunn */}
        <div className="bg-gradient-to-r from-lgb-blue-300 to-lgb-blue-200 p-4 flex justify-between items-center">
          <div className="flex items-center">
            <HiOutlineLocationMarker className="h-6 w-6 text-white mr-3" />
            <h3 className="text-lg font-semibold text-white">
              {enhancedStop.isPickup ? 'Hentested' : 'Leveringssted'}
            </h3>
          </div>
          <button 
            onClick={handleClose}
            className="text-white hover:text-gray-100 transition-colors"
          >
            <HiX className="h-5 w-5" />
          </button>
        </div>
        
        {/* Content */}
        <div className="p-5 dark:bg-gray-900">
          {/* Location info */}
          <div className="mb-6">
            <div className="flex items-start">
              <div className="bg-gray-100 dark:bg-gray-800 p-2 rounded-lg mr-3">
                <HiOutlineLocationMarker className="h-5 w-5 text-gray-600 dark:text-gray-400" />
              </div>
              <div>
                <h4 className="font-medium text-gray-900 dark:text-white text-lg">
                  {enhancedStop.stopDisplayName || enhancedStop.location.displayName}
                </h4>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {enhancedStop.location.addressLine}
                </p>
              </div>
            </div>
          </div>
          
          {/* Time info - endret til Tidsfrist */}
          <div className="mb-6">
            <div className="flex items-start">
              <div className="bg-gray-100 dark:bg-gray-800 p-2 rounded-lg mr-3">
                <HiOutlineClock className="h-5 w-5 text-gray-600 dark:text-gray-400" />
              </div>
              <div>
                <h4 className="font-medium text-gray-900 dark:text-white text-lg">
                  Tidsfrist
                </h4>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {formatDateTime(enhancedStop.stopDate)} {enhancedStop.timing?.latest ? "(senest)" : enhancedStop.timing?.earliest ? "(tidligst)" : ""}
                </p>
              </div>
            </div>
          </div>
          
          {/* Kunde info - endret fra Ordre til Kunde */}
          <div className="mb-6">
            <div className="flex items-start">
              <div className="bg-gray-100 dark:bg-gray-800 p-2 rounded-lg mr-3">
                <HiOutlineInformationCircle className="h-5 w-5 text-gray-600 dark:text-gray-400" />
              </div>
              <div>
                <h4 className="font-medium text-gray-900 dark:text-white text-lg">
                  Kunde
                </h4>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                  {enhancedStop.orderDisplayName || 'Ingen kundeinfo'}
                </p>
              </div>
            </div>
          </div>
          
          {/* Cargo info */}
          {enhancedStop.cargos && enhancedStop.cargos.length > 0 && (
            <div className="mt-6 border-t border-gray-200 dark:border-gray-800 pt-6">
              <h4 className="font-medium text-gray-900 dark:text-white mb-4 text-lg">
                Last
              </h4>
              <div className="space-y-3">
                {enhancedStop.cargos.map((cargo, index) => (
                  <div 
                    key={cargo.id || index} 
                    className="bg-gray-50 dark:bg-gray-800 p-4 rounded-lg flex justify-between items-center"
                  >
                    <div className="flex-1 min-w-0 mr-2">
                      <p className="text-base font-medium text-gray-900 dark:text-white">
                        {cargo.description || 'Vare'}
                      </p>
                      <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                        {enhancedStop.isPickup ? 'Hentes' : 'Leveres'}
                      </p>
                    </div>
                    <div className="bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-200 text-sm font-medium px-3 py-1.5 rounded-full whitespace-nowrap">
                      {cargo.quantity} stk
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        
        {/* Footer */}
        <div className="bg-gray-100 dark:bg-gray-800 p-4 flex justify-between">
          <StyledButton
            onClick={() => {
              // Try to get the formatted location parameter
              const locationParam = formatLocationForUrl();
              
              // Use the example URL if we couldn't generate a valid one
              const url = locationParam 
                ? `/resources/locations?location=${locationParam}` 
                : `/resources/locations?location=idrettsvegen-155-5353-straume`;
              
              window.open(url, '_blank');
            }}
            variant="outline"
            size="sm"
            icon={<HiOutlineExternalLink className="h-5 w-5" />}
            iconPosition="left"
          >
            Vis lokasjon
          </StyledButton>
          
          <StyledButton
            onClick={handleClose}
            variant="secondary"
            size="sm"
          >
            Lukk
          </StyledButton>
        </div>
      </div>
    </div>
  );
}; 