import { useState, useMemo, useEffect } from "react";
import { useLoadScript } from "@react-google-maps/api";
import { PageLayout } from "../../../components";
import { TabButton } from "../../../components/atoms/TabButton";
import { useTranslation } from "react-i18next";
import { OrderStatus } from "../../../types/order/IOrder";
import { useDrivers, useOrders } from "../../../hooks";
import { useWorkspace } from "../../../context/WorkspaceContext";
import { Order } from "../../../models";
import { useSearchParams } from "react-router-dom";
import { useUserSession } from "../../../context/UserContext";
import { upcomingMapPage } from "../../../components/atoms/Icons/illustrations";

import { OrdersView } from "./components/OrdersView";
import { DriverRoutesView } from "./components/DriverRoutesView";
import { IMapRoute, libraries } from "./types";
import { EnhancedRouteItem } from "../../../components/molecules/Planner/types";
import { DriverMapSkeleton } from "./components/DriverMapSkeleton";

export const DriverMapPage = function () {
  const { t } = useTranslation(["common", "map"]);
  const { isLoaded, loadError } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries,
  });
  const { activeWorkspace } = useWorkspace();
  const { userSession } = useUserSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const dateFromUrl = searchParams.get('date');
  const [activeTab, setActiveTab] = useState<"orders" | "routes">("routes");
  const [originalOrders, setOriginalOrders] = useState<IMapRoute[]>([]);
  const [filteredOrders, setfilteredOrders] = useState<IMapRoute[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(dateFromUrl ? new Date(dateFromUrl) : new Date());

  const query = useOrders(activeWorkspace?.workspaceId ?? "", [
    OrderStatus.Scheduled,
    OrderStatus.New,
    OrderStatus.InProgress,
    OrderStatus.Completed,
  ]);
  const driverQuery = useDrivers(activeWorkspace?.workspaceId ?? "");
  const orders = useMemo(() => query.data ?? [], [query]) as Order[];
  const drivers = useMemo(() => driverQuery.data ?? [], [driverQuery]);

  // Håndter bytte av fane
  const handleTabChange = (tab: "orders" | "routes") => {
    setActiveTab(tab);
    // Nullstill ordreId i URL når vi bytter til ordre-fanen
    // Dette vil trigge automatisk valg av første ordre i OrdersView
    if (tab === "orders") {
      setSearchParams({});
    }
  };

  useEffect(() => {
    let filtered = [...originalOrders];

    if (selectedTypes.length > 0) {
      filtered = filtered.filter((order) =>
        selectedTypes.includes(order.status),
      );
    }

    setfilteredOrders(filtered);
  }, [selectedTypes, originalOrders]);

  useEffect(() => {
    const mappedOrders = orders
      .filter((x) => x.status !== OrderStatus.Cancelled)
      .map((x) => ({
        customerName: x.customer.isCompany
          ? x.customer.name || ""
          : `${x.customer.firstName || ""} ${x.customer.lastName || ""}`.trim(),
        customer: x.customer,
        id: x.id,
        type: "Pickup",
        status: x.status,
        driverId: x.driverId,
        driver: drivers.find((d) => d.email === x.driverId),
        route: x.route.map(item => ({
          ...item,
          isAvailable: false,
          orderDisplayName: x.customer.isCompany ? x.customer.name : `${x.customer.firstName} ${x.customer.lastName}`.trim(),
          stopDisplayName: item.location.displayName,
          cargos: x.cargo?.map(cargo => ({
            id: cargo.id,
            quantity: cargo.quantity,
            description: cargo.descriptionShort,
            itinerary: {
              pickupStopNumber: cargo.itinerary?.pickupStopNumber,
              deliveryStopNumber: cargo.itinerary?.deliveryStopNumber
            }
          }))
        })) as EnhancedRouteItem[]
      }));
    setOriginalOrders(mappedOrders || []);
    setfilteredOrders(mappedOrders || []);
  }, [orders, drivers]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <DriverMapSkeleton />;

  if (!userSession?.user?.isAdmin) {
    return (
      <PageLayout>
        <div className="flex flex-col items-center justify-center py-8">
          {upcomingMapPage}
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4 mt-8">
            {t("common:map_coming_soon")}
          </h1>
          <p className="text-base text-gray-600 dark:text-lgb-grey-200">
            {t("common:feature_in_development")}
          </p>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <div className="space-y-4">
        {/* Tabs and Filters */}
        <div className="border-b border-gray-200 dark:border-gray-700">
          <div className="px-4">
            <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
              <TabButton
                label={t("map:tabs.orders")}
                isSelected={activeTab === "orders"}
                onClick={() => handleTabChange("orders")}
              />
              <TabButton
                label={t("map:tabs.routes")}
                isSelected={activeTab === "routes"}
                onClick={() => handleTabChange("routes")}
              />
            </ul>
          </div>
        </div>

        {/* Content */}
        <div className="px-4 pb-4">
          {activeTab === "orders" ? (
            <OrdersView
              filteredOrders={filteredOrders}
              selectedDate={selectedDate}
            />
          ) : (
            <DriverRoutesView
              drivers={drivers}
              selectedDate={selectedDate}
            />
          )}
        </div>
      </div>
    </PageLayout>
  );
}; 