import type { FC } from "react";
import {
  DndContext,
  DragEndEvent,
  useSensors,
  useSensor,
  PointerSensor,
} from "@dnd-kit/core";
import { IDriver, IOrder } from "../../types";
import { useEffect, useState, useCallback, useMemo } from "react";
import { OrderDroppableZone } from "../../components/molecules/Planner";
import { OrderStatus } from "../../types/order/IOrder";
import { useTranslation } from "react-i18next";
import { HiUser } from "react-icons/hi";
import firebase from "firebase/compat/app";

interface OrderPlannerProps {
  drivers: IDriver[];
  orders: IOrder[];
  onChange: (state: { 
    pendingChanges: { orderId: string; driverId: string }[]; 
    localOrders: IOrder[];
    hasChanges: boolean;
    changesCount: number;
  }) => void;
  reset?: boolean;
  selectedDate: Date;
  selectedStatuses: OrderStatus[];
  selectedDriver?: string;
  onSelectDriver?: (driverId: string) => void;
}

export const OrderPlanner: FC<OrderPlannerProps> = function ({ 
  drivers, 
  orders, 
  onChange, 
  reset,
  selectedDate,
  selectedStatuses,
  selectedDriver,
  onSelectDriver
}) {
  const { t } = useTranslation(["stats", "common", "planner"]);
  const [pendingChanges, setPendingChanges] = useState<Map<string, { orderId: string; driverId: string }>>(new Map());
  const [localOrders, setLocalOrders] = useState<IOrder[]>(orders);

  // Reset state when reset prop changes
  useEffect(() => {
    if (reset) {
      setPendingChanges(new Map());
      setLocalOrders(orders);
    }
  }, [reset, orders]);

  // Update local orders when orders prop changes
  useEffect(() => {
    setLocalOrders(orders);
  }, [orders]);

  // Reset changes when date or selected statuses change
  useEffect(() => {
    setPendingChanges(new Map());
    setLocalOrders(orders);
  }, [selectedDate, selectedStatuses, orders]);

  // Filter orders based on selected statuses and date
  const filteredOrders = useMemo(() => {
    return localOrders.filter(order => {
      // First check if the order matches selected status
      const matchesStatus = selectedStatuses.includes(order.status);
      if (!matchesStatus) return false;
      
      // Check date for all orders
      if (!order.route || order.route.length === 0) return false;
      
      const stopDate = order.route[0].stopDate;
      let orderDate: Date;
      
      if (stopDate instanceof firebase.firestore.Timestamp) {
        orderDate = stopDate.toDate();
      } else if (stopDate && 'seconds' in stopDate && 'nanoseconds' in stopDate) {
        // Handle plain timestamp object
        const timestamp = stopDate as { seconds: number; nanoseconds: number };
        orderDate = new firebase.firestore.Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate();
      } else {
        return false; // Invalid date format
      }
      
      return (
        orderDate.getFullYear() === selectedDate.getFullYear() &&
        orderDate.getMonth() === selectedDate.getMonth() &&
        orderDate.getDate() === selectedDate.getDate()
      );
    });
  }, [localOrders, selectedStatuses, selectedDate]);

  useEffect(() => {
    onChange({
      pendingChanges: Array.from(pendingChanges.values()),
      localOrders: filteredOrders,
      hasChanges: pendingChanges.size > 0,
      changesCount: pendingChanges.size
    });
  }, [onChange, pendingChanges, filteredOrders]);

  // Configure sensors for drag and drop
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const handleDragEnd = useCallback((event: DragEndEvent) => {
    const { active, over } = event;
    
    if (!over) return;

    const orderId = active.id as string;
    const newDriverId = over.id as string;
    
    // Find the order that was dragged
    const draggedOrder = localOrders.find(order => order.id === orderId);
    // Find the original order to compare
    const originalOrder = orders.find(order => order.id === orderId);
    
    // Only allow dragging NEW orders
    if (!draggedOrder || !originalOrder || draggedOrder.status !== OrderStatus.New || draggedOrder.driverId === newDriverId) return;

    // Update local state - remove the dragged order and add it at the end
    setLocalOrders(prevOrders => {
      const ordersWithoutDragged = prevOrders.filter(order => order.id !== orderId);
      const updatedOrder = { ...draggedOrder, driverId: newDriverId };
      
      return [
        ...ordersWithoutDragged,
        updatedOrder
      ];
    });

    // Update pending changes
    setPendingChanges(prev => {
      const newChanges = new Map(prev);
      
      // If the new driver is the same as the original driver, remove the change
      if (newDriverId === originalOrder.driverId) {
        newChanges.delete(orderId);
      } else {
        // Otherwise, update or add the change
        newChanges.set(orderId, { orderId, driverId: newDriverId });
      }
      
      return newChanges;
    });
  }, [localOrders, orders]);

  // Filter drivers based on selectedDriver
  const driversToShow = useMemo(() => {
    if (!selectedDriver) return drivers;
    return drivers.filter(driver => driver.email === selectedDriver);
  }, [drivers, selectedDriver]);

  return (
    <div className="flex flex-col gap-4">
      {/* Sjåfør dropdown for mobil */}
      <div className="md:hidden">
        <h2 className="text-lg dark:text-white font-medium mb-2">{t('planner:order_planner.select_driver')}</h2>
        <select 
          className="w-full bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-3 text-gray-900 dark:text-white"
          value={selectedDriver || ""}
          onChange={(e) => onSelectDriver && onSelectDriver(e.target.value)}
        >
          <option value="">{t('planner:order_planner.all_drivers')}</option>
          {drivers?.map(driver => (
            <option key={driver.email} value={driver.email}>
              {driver.firstName} {driver.lastName} ({driver.email})
            </option>
          ))}
        </select>
      </div>

      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <div className="space-y-3">
          {/* Driver rows */}
          {driversToShow.map((driver) => (
            <div
              key={driver.email}
              className="grid grid-cols-1 md:grid-cols-[180px_1fr] gap-3"
            >
              {/* Driver info column */}
              <div className="bg-white dark:bg-gray-800 p-3 rounded-lg border border-gray-200 dark:border-gray-700 shadow-sm">
                <div className="flex items-center gap-2">
                  <div className="bg-gray-100 dark:bg-gray-700 p-1.5 rounded-full flex-shrink-0">
                    <HiUser className="h-4 w-4 text-gray-600 dark:text-gray-400" />
                  </div>
                  <div className="min-w-0">
                    <p className="font-medium text-gray-900 dark:text-white leading-tight truncate">
                      {driver.firstName} {driver.lastName}
                    </p>
                    <p className="text-xs text-gray-600 dark:text-gray-400 leading-tight truncate">
                      {driver.email}
                    </p>
                  </div>
                </div>
              </div>

              {/* Orders column - droppable area */}
              <OrderDroppableZone 
                driver={driver} 
                orders={filteredOrders} 
                isDraggable={(order) => order.status === OrderStatus.New}
              />
            </div>
          ))}
        </div>
      </DndContext>
    </div>
  );
};
