/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState, type FC } from "react";
import { useTranslation } from "react-i18next";
import { ILocation, IRouteItem } from "../../../types";
import { DropdownSearch, DropdownSearchListItem } from "../Search";
import { Button } from "flowbite-react";
import { HiOutlinePlus, HiOutlineX, HiOutlineLocationMarker } from "react-icons/hi";

export interface SavedLocationsProp {
  locations: ILocation[];
  currentRoute: IRouteItem[];
  addLocation: (id: string) => void;
  close: () => void;
}

export const SavedLocations: FC<SavedLocationsProp> = (props) => {
  const { t } = useTranslation(["orders", "validation", "common"]);
  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState<ILocation[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  // Initialiser søkeresultater når lokasjoner endres
  useEffect(() => {
    setIsLoading(true);
    // Kort forsinkelse for å sikre at data er lastet
    const timer = setTimeout(() => {
      if (props.locations && props.locations.length > 0) {
        setSearchResults(props.locations);
      }
      setIsLoading(false);
    }, 300);
    
    return () => clearTimeout(timer);
  }, [props.locations]);

  // Oppdater søkeresultater basert på søketekst
  useEffect(() => {
    if (searchText.length) {
      const filteredLocations = props.locations?.filter(
        (x) =>
          (x.displayName?.toLowerCase().includes(searchText.toLowerCase()) ||
           x.addressLine?.toLowerCase().includes(searchText.toLowerCase()) ||
           x.city?.toLowerCase().includes(searchText.toLowerCase()) ||
           x.postCode?.toLowerCase().includes(searchText.toLowerCase()))
      ) || [];
      
      setSearchResults(filteredLocations);
    } else {
      // Hvis søketeksten er tom, vis alle tilgjengelige lokasjoner
      setSearchResults(props.locations || []);
    }
  }, [searchText, props.locations]);

  function handleSearch(val: string) {
    setSearchText(val);
  }

  function addLocation() {
    if (selectedLocation) {
      props.addLocation(selectedLocation);
      setSelectedLocation("");
    }
  }

  return (
    <div>
      <div className="relative mb-14">
        <DropdownSearch
          placeholder={t("create.route_selection.search_ph")}
          inputChanged={(val) => handleSearch(val)}
          showResults
          plain
        >
          {isLoading ? (
            <div className="p-4 text-center">
              <div className="animate-pulse h-4 bg-gray-200 rounded w-3/4 mx-auto mb-2.5"></div>
              <div className="animate-pulse h-4 bg-gray-200 rounded w-1/2 mx-auto"></div>
            </div>
          ) : searchResults.length > 0 ? (
            <div className="pt-2">
              {searchResults.map((location: ILocation) => {
                return (
                  <DropdownSearchListItem
                    key={location.id}
                    id={location.id}
                    title={location.displayName || t("create.route_selection.unnamed_location")}
                    subtitle={location.addressLine || t("create.route_selection.no_address")}
                    selected={
                      selectedLocation ? location.id === selectedLocation : false
                    }
                    buttonClickEvent={(id) => setSelectedLocation(id)}
                    iconType="location"
                  ></DropdownSearchListItem>
                );
              })}
            </div>
          ) : (
            <div className="p-4 text-center">
              <HiOutlineLocationMarker className="mx-auto h-8 w-8 text-gray-400 mb-2" />
              <p className="text-gray-500">{t("common:no_locations_found")}</p>
              <p className="text-sm text-gray-400 mt-1">{t("common:try_different_search")}</p>
            </div>
          )}
        </DropdownSearch>
      </div>
      <div
        className="absolute bottom-0 pb-6 bg-white rounded-lg dark:bg-lgb-on-dark-background"
        style={{ width: "calc(100% - 24px)" }}
      >
        <div className={"flex bg-white dark:bg-lgb-on-dark-background gap-4 justify-start mt-4"}>
          <Button
            disabled={!selectedLocation}
            color="primary"
            onClick={() => addLocation()}
          >
            <HiOutlinePlus className="mr-2 h-5 w-5" />
            {t("create.route_selection.add_saved_location_confirm")}
          </Button>
          <Button color="gray" onClick={props.close}>
            <HiOutlineX className="mr-2 h-5 w-5" />
            {t("common:close")}
          </Button>
        </div>
      </div>
    </div>
  );
};
