/* eslint-disable jsx-a11y/anchor-is-valid */
import { CustomFlowbiteTheme, Label, TextInput } from "flowbite-react";
import { ChangeEvent, useEffect, type FC, useState } from "react";
import { HiSearch } from "react-icons/hi";

export interface SearchProp {
  placeholder: string;
  label?: string;
  expandSearchbar?: React.ReactNode;
  children?: React.ReactNode; //This is the list of search results.
  selected?: string;
  showError?: boolean;
  errorText?: string;
  inputChanged?: (input: string) => void;
}

const selectedTheme: CustomFlowbiteTheme["textInput"] = {
  field: {
    input: {
      colors: {
        primary: "text-lgb-primary font-semibold cursor-pointer",
      },
    },
  },
};

export const Search: FC<SearchProp> = (props) => {
  const [value, setValue] = useState(props.selected || null);
  const [previousSearch, setPreviousSearch] = useState("");

  useEffect(() => {
    if (props.selected) {
      setValue(props.selected);
    }
  }, [props.selected]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    setPreviousSearch(event.target.value);
    return props.inputChanged ? props.inputChanged(event.target.value) : null;
  };

  const setFocusValue = () => {
    if (props.selected && props.inputChanged) {
      setValue(previousSearch);
      props.inputChanged(previousSearch);
    }
  };

  const setBlurValue = () => {
    if (props.selected && props.inputChanged) {
      setValue(props.selected);
      props.inputChanged(previousSearch);
    }
  };

  return (
    <div key={props.label}>
      <div className="md:flex lg:flex items-center justify-between pb-8">
        <div className="pb-2 block md:hidden lg:hidden">
          {props.expandSearchbar}
        </div>
        <div className="w-full">
          {props.label && <Label htmlFor="search" value={props.label} />}
          <TextInput
            className={"mb-2 relative"}
            theme={props.selected === value ? selectedTheme : undefined}
            color={props.showError && !props.selected ? "failure" : "primary"}
            helperText={
              props.showError &&
              !props.selected &&
              props.errorText &&
              props.errorText.length ? (
                <p className="absolute text-sm text-red-600 dark:text-red-500">
                  {props.errorText}
                </p>
              ) : (
                ""
              )
            }
            autoComplete="off"
            onFocus={() => setFocusValue()}
            onBlur={() => setBlurValue()}
            value={value || ""}
            icon={HiSearch}
            id="search"
            name="search"
            placeholder={props.placeholder}
            required
            size={32}
            type="search"
            onChange={(e) => {
              handleInputChange(e);
            }}
          />
        </div>
        <div className="hidden md:block lg:block">{props.expandSearchbar}</div>
      </div>

      {/* 
            Pass the list items as a slot to make the search component 
            and search results as reusable as possible 
        */}
      <ul className="pb-4 w-full divide-y divide-gray-200 dark:divide-gray-700">
        {props.children}
      </ul>
    </div>
  );
};
