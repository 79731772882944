/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IOrder, IOrderProduct, IRouteItem } from "../../../types";
import { Badge, Button } from "flowbite-react";
import { 
  HiOutlineCalendar, 
  HiOutlineUser, 
  HiOutlineLocationMarker, 
  HiOutlineTruck, 
  HiOutlineShoppingCart,
  HiOutlineScale,
  HiOutlineCube,
  HiOutlinePencil,
  HiOutlineClipboardCheck,
  HiOutlineExclamationCircle,
  HiOutlineChevronDown,
  HiOutlineChevronUp
} from "react-icons/hi";
import { CreateOrderStepsEnum } from "../../../pages/orders/create-order/CreateOrderStepsEnum";
import { useDrivers } from "../../../hooks";
import { useWorkspace } from "../../../context/WorkspaceContext";
import { Driver } from "../../../models";

export interface OrderPreviewProps {
  order: IOrder;
  currentStep: number;
  onStepChange: (step: number) => void;
}

export const OrderPreview: FC<OrderPreviewProps> = (props) => {
  const { t } = useTranslation("orders");
  const { order, currentStep, onStepChange } = props;
  const { activeWorkspace } = useWorkspace();
  const [driverName, setDriverName] = useState<string>("");
  const [expandedSections, setExpandedSections] = useState<number[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [showFullPreview, setShowFullPreview] = useState(false);
  
  // Sjekk om enheten er mobil
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);
  
  // Hent sjåfører
  const driversQuery = useDrivers(activeWorkspace?.workspaceId ?? "");
  const drivers = driversQuery.data;
  
  // Oppdater sjåførnavn når sjåfør endres
  useEffect(() => {
    if (order.driverId && drivers) {
      const driver = drivers.find((d: Driver) => d.email === order.driverId);
      if (driver) {
        setDriverName(`${driver.firstName} ${driver.lastName}`);
      }
    }
  }, [order.driverId, drivers]);

  // Hjelpefunksjoner
  const getCustomerName = () => {
    if (!order.customer) return t("create.customer_selection.title");
    return order.customer.isCompany
      ? order.customer.name
      : `${order.customer.firstName} ${order.customer.lastName}`;
  };

  const calculateTotalWeight = (): number => {
    return (order.cargo as IOrderProduct[])?.reduce((sum, item) => {
      return sum + (item.weight || 0) * (item.quantity || 1);
    }, 0) || 0;
  };

  const calculateTotalVolume = (): number => {
    return (order.cargo as IOrderProduct[])?.reduce((sum, item) => {
      const volume = (item.length || 0) * (item.width || 0) * (item.height || 0) / 1000000; // cm³ til m³
      return sum + volume * (item.quantity || 1);
    }, 0) || 0;
  };

  // Sorter ruten etter stopnummer
  const sortedRoute = [...(order.route || [])].sort((a, b) => a.stopNumber - b.stopNumber);

  // Finn hente- og leveringslokasjoner
  const getPickupAndDeliveryLocations = () => {
    if (!order.route || order.route.length === 0) {
      return { pickups: [], deliveries: [] };
    }

    // Hvis vi har isPickup-flagget, bruk det til å bestemme hvilke stopp som er henting og levering
    const routeWithPickupInfo = sortedRoute.filter(stop => stop.isPickup !== undefined);
    if (routeWithPickupInfo.length > 0) {
      return {
        pickups: sortedRoute.filter(stop => stop.isPickup === true),
        deliveries: sortedRoute.filter(stop => stop.isPickup === false)
      };
    }

    // Hvis vi har cargo-informasjon, bruk den til å bestemme hvilke stopp som er henting og levering
    if (order.cargo && order.cargo.length > 0) {
      const pickupStopNumbers = order.cargo
        .map(item => item.itinerary?.pickupStopNumber)
        .filter(num => num !== undefined);
      
      const deliveryStopNumbers = order.cargo
        .map(item => item.itinerary?.deliveryStopNumber)
        .filter(num => num !== undefined);

      return {
        pickups: sortedRoute.filter(stop => pickupStopNumbers.includes(stop.stopNumber)),
        deliveries: sortedRoute.filter(stop => deliveryStopNumbers.includes(stop.stopNumber))
      };
    }

    // Hvis vi ikke har noen av delene, anta at første stopp er henting og resten er levering
    return {
      pickups: sortedRoute.length > 0 ? [sortedRoute[0]] : [],
      deliveries: sortedRoute.length > 1 ? sortedRoute.slice(1) : []
    };
  };

  const { pickups, deliveries } = getPickupAndDeliveryLocations();

  // Formater dato
  const formatDate = (routeItem: IRouteItem): string => {
    if (!routeItem.stopDate) return "";
    const date = routeItem.stopDate.toDate();
    return date.toLocaleDateString();
  };

  // Sjekk om alle nødvendige steg er fullført
  const isOrderComplete = () => {
    return (
      order.customer?.id &&
      order.route?.length >= 2 &&
      order.cargo?.length > 0 &&
      order.driverId
    );
  };

  // Sjekk om et steg er redigert
  const isStepEdited = (step: number): boolean => {
    switch (step) {
      case CreateOrderStepsEnum.CUSTOMER_SELECTION:
        return !!order.customer?.id;
      case CreateOrderStepsEnum.ROUTE_SELECTION:
        // Returner false hvis ruten er tom eller ikke har noen gyldige lokasjoner
        return order.route.length > 0 && order.route.some(stop => 
          stop.location && (stop.location.displayName || stop.location.addressLine)
        );
      case CreateOrderStepsEnum.PRODUCT_MANAGEMENT:
        // Returner false hvis cargo er tom eller bare inneholder standardverdier
        return (order.cargo as IOrderProduct[])?.length > 0 && 
               (order.cargo as IOrderProduct[])?.some(product => 
                 product && 
                 (
                   // Sjekk at produktet har en beskrivelse som ikke er standardverdien
                   (product.description && product.description !== t("create.product_management.product")) ||
                   // Eller at det har en vekt eller volum
                   (product.weight && product.weight > 0) ||
                   ((product.length && product.width && product.height) && 
                    (product.length > 0 && product.width > 0 && product.height > 0))
                 )
               );
      case CreateOrderStepsEnum.DRIVER_SELECTION:
        return !!order.driverId;
      default:
        return false;
    }
  };

  // Vis "Ikke redigert ennå" melding
  const renderNotEditedYet = (step: number) => {
    return (
      <div className="mt-1 ml-7 text-sm text-gray-500 dark:text-gray-300 italic">
        {step <= currentStep 
          ? t("preview.not_edited_yet") 
          : t("preview.not_available_yet")}
      </div>
    );
  };
  
  // Håndter utvidelse/sammentrekning av seksjoner på mobil
  const toggleSection = (step: number) => {
    if (expandedSections.includes(step)) {
      setExpandedSections(expandedSections.filter(s => s !== step));
    } else {
      setExpandedSections([...expandedSections, step]);
    }
  };
  
  // Sjekk om en seksjon er utvidet
  const isSectionExpanded = (step: number) => {
    return expandedSections.includes(step) || currentStep === step || !isMobile;
  };

  return (
    <div className={`${isMobile ? 'rounded-full overflow-hidden shadow-sm border border-lgb-grey-200' : 'bg-white dark:bg-lgb-dark-background rounded-lg border border-lgb-grey-200 dark:border-gray-500 shadow-md overflow-hidden'}`}>
      {/* Mobil-minimert visning - Bare stegene */}
      {isMobile ? (
        <div className="grid grid-cols-4 gap-0">
          {[
            CreateOrderStepsEnum.CUSTOMER_SELECTION,
            CreateOrderStepsEnum.ROUTE_SELECTION,
            CreateOrderStepsEnum.PRODUCT_MANAGEMENT,
            CreateOrderStepsEnum.DRIVER_SELECTION
          ].map((step) => (
            <button
              key={`step-${step}`}
              onClick={() => onStepChange(step)}
              className={`flex items-center justify-center py-3 ${
                currentStep === step 
                  ? 'bg-purple-600 text-white font-medium' 
                  : 'bg-white dark:bg-lgb-dark-background text-gray-700 dark:text-gray-300'
              }`}
            >
              {step + 1}
            </button>
          ))}
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center p-4 md:p-5 border-b border-lgb-grey-200 dark:border-gray-500">
            <div className="flex items-center">
              <HiOutlineClipboardCheck className="text-lgb-blue-500 dark:text-lgb-blue-300 mr-2 h-5 w-5" />
              <h2 className="text-lg font-medium text-gray-900 dark:text-white">{t("preview.title")}</h2>
            </div>
            <Badge color={isOrderComplete() ? "success" : "gray"} className="px-3 py-1">
              {t("create.step")} {currentStep + 1}/4
            </Badge>
          </div>

          <div className="px-4 md:px-5 pt-4 mb-4">
            {/* Steg-knapper */}
            <div className="grid grid-cols-4 gap-2 md:gap-3">
              {[
                CreateOrderStepsEnum.CUSTOMER_SELECTION,
                CreateOrderStepsEnum.ROUTE_SELECTION,
                CreateOrderStepsEnum.PRODUCT_MANAGEMENT,
                CreateOrderStepsEnum.DRIVER_SELECTION
              ].map((step) => (
                <Button
                  key={`step-${step}`}
                  size="xs"
                  color={currentStep === step ? "blue" : "light"}
                  onClick={() => onStepChange(step)}
                  className={`w-full ${
                    currentStep === step 
                      ? 'bg-lgb-blue-300 dark:bg-lgb-blue-900/50 border-0 text-white dark:text-white font-medium shadow-sm' 
                      : 'bg-white dark:bg-lgb-on-dark-background border border-lgb-grey-200 dark:border-gray-500 hover:bg-lgb-grey-100 dark:hover:bg-lgb-grey-700'
                  }`}
                >
                  {step + 1}
                </Button>
              ))}
            </div>
          </div>
          
          <div className="px-4 md:px-5 pb-4">
            {/* Kunde-seksjon */}
            <div className="mb-3 md:mb-4">
              <div 
                className={`p-3 md:p-4 rounded-lg mb-2 transition-all duration-200 ${
                  currentStep === CreateOrderStepsEnum.CUSTOMER_SELECTION
                    ? "bg-lgb-blue-100 dark:bg-indigo-900/20 border border-lgb-blue-300 dark:border-gray-500 shadow-sm"
                    : "bg-white dark:bg-lgb-on-dark-background border border-lgb-grey-200 dark:border-gray-500"
                }`}
              >
                <div className="flex justify-between items-center" onClick={() => isMobile && toggleSection(CreateOrderStepsEnum.CUSTOMER_SELECTION)}>
                  <div className="flex items-center">
                    <HiOutlineUser className="text-lgb-blue-300 dark:text-lgb-blue-300 mr-2 h-5 w-5" />
                    <span className="font-medium text-sm text-gray-900 dark:text-white">{t("create.customer_selection.stepper_desc")}</span>
                  </div>
                  <div className="flex items-center">
                    {isStepEdited(CreateOrderStepsEnum.CUSTOMER_SELECTION) && currentStep !== CreateOrderStepsEnum.CUSTOMER_SELECTION && (
                      <Button
                        size="xs"
                        color="light"
                        onClick={() => onStepChange(CreateOrderStepsEnum.CUSTOMER_SELECTION)}
                      >
                        <HiOutlinePencil className="h-4 w-4" />
                      </Button>
                    )}
                  </div>
                </div>
                
                {isSectionExpanded(CreateOrderStepsEnum.CUSTOMER_SELECTION) && (
                  <>
                    {isStepEdited(CreateOrderStepsEnum.CUSTOMER_SELECTION) ? (
                      <div className="mt-1 ml-7 text-sm font-medium text-gray-900 dark:text-gray-200">
                        {getCustomerName()}
                      </div>
                    ) : (
                      renderNotEditedYet(CreateOrderStepsEnum.CUSTOMER_SELECTION)
                    )}
                  </>
                )}
              </div>
            </div>

            {/* Lokasjoner-seksjon */}
            <div className="mb-3 md:mb-4">
              <div 
                className={`p-3 md:p-4 rounded-lg mb-2 transition-all duration-200 ${
                  currentStep === CreateOrderStepsEnum.ROUTE_SELECTION
                    ? "bg-lgb-blue-100 dark:bg-indigo-900/20 border border-lgb-blue-300 dark:border-gray-500 shadow-sm"
                    : "bg-white dark:bg-lgb-on-dark-background border border-lgb-grey-200 dark:border-gray-500"
                }`}
              >
                <div className="flex justify-between items-center" onClick={() => isMobile && toggleSection(CreateOrderStepsEnum.ROUTE_SELECTION)}>
                  <div className="flex items-center">
                    <HiOutlineLocationMarker className="text-lgb-blue-300 dark:text-lgb-blue-300 mr-2 h-5 w-5" />
                    <span className="font-medium text-sm text-gray-900 dark:text-white">{t("create.route_selection.stepper_desc")}</span>
                  </div>
                  <div className="flex items-center">
                    {isStepEdited(CreateOrderStepsEnum.ROUTE_SELECTION) && currentStep !== CreateOrderStepsEnum.ROUTE_SELECTION && !isMobile && (
                      <Button
                        size="xs"
                        color="light"
                        onClick={() => onStepChange(CreateOrderStepsEnum.ROUTE_SELECTION)}
                      >
                        <HiOutlinePencil className="h-4 w-4" />
                      </Button>
                    )}
                  </div>
                </div>
                
                {isSectionExpanded(CreateOrderStepsEnum.ROUTE_SELECTION) && (
                  <>
                    {isStepEdited(CreateOrderStepsEnum.ROUTE_SELECTION) ? (
                      <div className="mt-2 ml-7">
                        {/* Dato - Alltid vis på både mobil og desktop */}
                        {sortedRoute.length > 0 && (
                          <div className="flex items-center mb-2 text-sm">
                            <HiOutlineCalendar className="text-lgb-grey-500 dark:text-lgb-blue-300 mr-2 h-4 w-4" />
                            <span className="text-gray-900 dark:text-gray-200">{formatDate(sortedRoute[0])}</span>
                          </div>
                        )}
                        
                        {/* Mobilvisning - Forenklet lokasjonsinformasjon */}
                        {isMobile && (
                          <div className="mb-2 text-gray-900 dark:text-gray-200">
                            {/* Vis kun antall lokasjoner på mobil */}
                            <div className="text-sm">
                              <Badge 
                                color="purple" 
                                className="mr-2"
                                size="xs"
                              >
                                {pickups.length} {t("pickup")}
                              </Badge>
                              <Badge 
                                color="green" 
                                className="mr-2"
                                size="xs"
                              >
                                {deliveries.length} {t("delivery")}
                              </Badge>
                            </div>
                          </div>
                        )}
                        
                        {/* Desktop visning - Full lokasjonsinformasjon */}
                        {!isMobile && (
                          <div className="mb-2 text-gray-900 dark:text-gray-200">
                            {sortedRoute.map((stop, index) => {
                              const isPickup = stop.isPickup !== undefined 
                                ? stop.isPickup 
                                : (pickups.some(p => p.stopNumber === stop.stopNumber));
                              
                              return (
                                <div key={`stop-${index}`} className="mb-2">
                                  <div className="flex items-center">
                                    <Badge 
                                      color={isPickup ? "purple" : "green"} 
                                      className="mr-2"
                                      size="xs"
                                    >
                                      {isPickup ? t("pickup") : t("delivery")}
                                    </Badge>
                                    <span className="text-sm font-medium">
                                      {stop.location.displayName || stop.location.addressLine || t("create.route_selection.unnamed_location")}
                                    </span>
                                  </div>
                                  {stop.timing && (
                                    <div className="ml-6 text-xs text-lgb-grey-500">
                                      {stop.timing.earliest ? t("orders:create.route_selection.earliest") : 
                                       stop.timing.latest ? t("orders:create.route_selection.latest") : 
                                       stop.timing.before && stop.timing.after ? `${t("orders:create.route_selection.between")} ${stop.timing.after} - ${stop.timing.before}` :
                                       stop.timing.before ? `${t("orders:create.route_selection.before")} ${stop.timing.before}` :
                                       stop.timing.after ? `${t("orders:create.route_selection.after")} ${stop.timing.after}` : ""}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    ) : (
                      renderNotEditedYet(CreateOrderStepsEnum.ROUTE_SELECTION)
                    )}
                  </>
                )}
              </div>
            </div>

            {/* Produkter-seksjon */}
            <div className="mb-3 md:mb-4">
              <div 
                className={`p-3 md:p-4 rounded-lg mb-2 transition-all duration-200 ${
                  currentStep === CreateOrderStepsEnum.PRODUCT_MANAGEMENT
                    ? "bg-lgb-blue-100 dark:bg-indigo-900/20 border border-lgb-blue-300 dark:border-gray-500 shadow-sm"
                    : "bg-white dark:bg-lgb-on-dark-background border border-lgb-grey-200 dark:border-gray-500"
                }`}
              >
                <div className="flex justify-between items-center" onClick={() => isMobile && toggleSection(CreateOrderStepsEnum.PRODUCT_MANAGEMENT)}>
                  <div className="flex items-center">
                    <HiOutlineCube className="text-lgb-blue-300 dark:text-lgb-blue-300 mr-2 h-5 w-5" />
                    <span className="font-medium text-sm text-gray-900 dark:text-white">{t("create.product_management.stepper_desc")}</span>
                  </div>
                  <div className="flex items-center">
                    {isStepEdited(CreateOrderStepsEnum.PRODUCT_MANAGEMENT) && currentStep !== CreateOrderStepsEnum.PRODUCT_MANAGEMENT && !isMobile && (
                      <Button
                        size="xs"
                        color="light"
                        onClick={() => onStepChange(CreateOrderStepsEnum.PRODUCT_MANAGEMENT)}
                      >
                        <HiOutlinePencil className="h-4 w-4" />
                      </Button>
                    )}
                  </div>
                </div>
                
                {isSectionExpanded(CreateOrderStepsEnum.PRODUCT_MANAGEMENT) && (
                  <>
                    {isStepEdited(CreateOrderStepsEnum.PRODUCT_MANAGEMENT) ? (
                      <div className="mt-2 ml-7">
                        <div className="grid grid-cols-2 gap-3 mb-2">
                          <div className="flex items-center text-sm">
                            <HiOutlineScale className="text-lgb-grey-500 dark:text-lgb-blue-300 mr-2 h-4 w-4" />
                            <span className="text-gray-900 dark:text-gray-200">{calculateTotalWeight()} kg</span>
                          </div>
                          <div className="flex items-center text-sm">
                            <HiOutlineCube className="text-lgb-grey-500 dark:text-lgb-blue-300 mr-2 h-4 w-4" />
                            <span className="text-gray-900 dark:text-gray-200">{calculateTotalVolume().toFixed(1)} m³</span>
                          </div>
                        </div>
                        
                        {/* På mobil, vis bare antall produkter */}
                        {isMobile ? (
                          <div className="text-sm text-lgb-grey-600 dark:text-lgb-grey-400">
                            {order.cargo.length} {t("create.product_management.products")}
                          </div>
                        ) : (
                          <>
                            <div className="text-xs text-lgb-grey-600 dark:text-lgb-grey-400 mb-1">{t("create.product_management.title")}:</div>
                            {order.cargo.map((product, index) => {
                              // Sjekk om produktet har meningsfull informasjon
                              const hasValidDescription = product.description && product.description !== t("create.product_management.product");
                              const hasValidDimensions = (product.weight && product.weight > 0) || 
                                                        ((product.length && product.width && product.height) && 
                                                        (product.length > 0 && product.width > 0 && product.height > 0));
                              
                              // Vis bare produktet hvis det har meningsfull informasjon
                              if (hasValidDescription || hasValidDimensions) {
                                return (
                                  <div key={`product-${index}`} className="ml-2 text-sm mb-1 text-gray-900 dark:text-gray-200 flex items-center">
                                    <HiOutlineCube className="text-lgb-grey-500 dark:text-lgb-blue-300 mr-2 h-4 w-4" />
                                    {product.quantity}x {product.description || t("create.product_management.product")}
                                  </div>
                                );
                              }
                              return null;
                            })}
                          </>
                        )}
                      </div>
                    ) : (
                      renderNotEditedYet(CreateOrderStepsEnum.PRODUCT_MANAGEMENT)
                    )}
                  </>
                )}
              </div>
            </div>

            {/* Sjåfør-seksjon */}
            <div className="mb-3 md:mb-4">
              <div 
                className={`p-3 md:p-4 rounded-lg mb-2 transition-all duration-200 ${
                  currentStep === CreateOrderStepsEnum.DRIVER_SELECTION
                    ? "bg-lgb-blue-100 dark:bg-indigo-900/20 border border-lgb-blue-300 dark:border-gray-500 shadow-sm"
                    : "bg-white dark:bg-lgb-on-dark-background border border-lgb-grey-200 dark:border-gray-500"
                }`}
              >
                <div className="flex justify-between items-center" onClick={() => isMobile && toggleSection(CreateOrderStepsEnum.DRIVER_SELECTION)}>
                  <div className="flex items-center">
                    <HiOutlineTruck className="text-lgb-blue-300 dark:text-lgb-blue-300 mr-2 h-5 w-5" />
                    <span className="font-medium text-sm text-gray-900 dark:text-white">{t("create.driver_selection.stepper_desc")}</span>
                  </div>
                  <div className="flex items-center">
                    {isStepEdited(CreateOrderStepsEnum.DRIVER_SELECTION) && currentStep !== CreateOrderStepsEnum.DRIVER_SELECTION && !isMobile && (
                      <Button
                        size="xs"
                        color="light"
                        onClick={() => onStepChange(CreateOrderStepsEnum.DRIVER_SELECTION)}
                      >
                        <HiOutlinePencil className="h-4 w-4" />
                      </Button>
                    )}
                  </div>
                </div>
                
                {isSectionExpanded(CreateOrderStepsEnum.DRIVER_SELECTION) && (
                  <>
                    {isStepEdited(CreateOrderStepsEnum.DRIVER_SELECTION) ? (
                      <div className="mt-1 ml-7 text-sm font-medium text-gray-900 dark:text-gray-200">
                        {driverName}
                      </div>
                    ) : (
                      renderNotEditedYet(CreateOrderStepsEnum.DRIVER_SELECTION)
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderPreview; 