/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useMemo, useEffect } from "react";
import { HiTrash, HiPencilAlt, HiViewList, HiViewGrid, HiOutlinePlus } from "react-icons/hi";
import {
  PageLayout,
  TableSkeleton,
  ProductModal,
  DialogForm,
  ParameterModal,
} from "../../components";
import { useWorkspace } from "../../context/WorkspaceContext";
import { useCache } from "../../context/CacheContext";
import { useProducts, useParameters } from "../../hooks";
import { useTranslation } from "react-i18next";
import { ParameterType, ITableRow, IProduct } from "../../types";
import { Parameter, Product } from "../../models";
import { emptyProductIllustration } from "../../components/atoms/Icons/illustrations";
import { EmptyState } from "../../components";
import { ProductsList } from "../../components/organisms/Product/ProductsList";
import { ProductCategories } from "../../components/organisms/Product/ProductCategories";
import { ProductDetailModal } from "../../components/organisms/Product/ProductDetailModal";
import { Button } from "flowbite-react";

export const ProductsPage: FC = function () {
  const [isLoading, setLoading] = useState(true);
  const [splitIsOpen, setSplitIsOpen] = useState(false);
  const { updateCacheKey } = useCache();

  const [isUpsertModalOpen, openUpsertModal] = useState(false);
  const [isDeleteModalOpen, openDeleteModal] = useState(false);
  const [isDetailModalOpen, setDetailModalOpen] = useState(false);
  const [isCategoryModalOpen, setCategoryModalOpen] = useState(false);
  const { activeWorkspace } = useWorkspace();
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const [productData, setProductData] = useState(Product.default());
  const [productCategories, setProductCategories] = useState([] as Parameter[]);
  const [viewMode, setViewMode] = useState<"list" | "grid">("grid");
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);

  const categoriesQuery = useParameters(
    activeWorkspace?.workspaceId ?? "",
    ParameterType.ProductType,
  );
  const query = useProducts(activeWorkspace?.workspaceId ?? "");
  const { t } = useTranslation(["common", "inventory", "products"]);

  const isQueryLoading = useMemo(
    () =>
      query.isLoading ||
      query.isRefetching ||
      categoriesQuery.isLoading ||
      categoriesQuery.isRefetching,
    [query, categoriesQuery],
  );

  const products = useMemo(() => query.data ?? [], [query]);

  const categories = useMemo(
    () => categoriesQuery.data ?? [],
    [categoriesQuery],
  );

  useEffect(() => {
    if (!isQueryLoading) {
      setProductCategories(categories);
    }
  }, [isQueryLoading, categories]);

  useEffect(() => {
    if (!isQueryLoading) {
      setLoading(false);
      setTableRows(
        products.map((product) => {
          return {
            id: product.id,
            cells: [
              {
                id: "descriptionShort",
                children: product.descriptionShort,
                showOnSmallScreen: true,
              },
            ],
            actions: [
              {
                id: "edit",
                icon: <HiPencilAlt />,
                onActionClick: () => {
                  setProductData(product);
                  openUpsertModal(true);
                },
              },
              {
                id: "delete",
                icon: <HiTrash />,
                color: "failure",
                onActionClick: () => {
                  setProductData(product);
                  openDeleteModal(true);
                },
              },
            ],
          };
        }),
      );
    }
  }, [isQueryLoading, products]);

  const handleSelectProduct = (product: Product) => {
    setProductData(product);
    if (Product.isNew(product)) {
      openUpsertModal(true);
    } else if (viewMode === "grid") {
      setDetailModalOpen(true);
    } else {
      setSplitIsOpen(true);
    }
  };

  const handleCreateProduct = () => {
    setProductData(Product.default());
    openUpsertModal(true);
  };

  const handleEditProduct = (product: Product) => {
    setProductData(product);
    setDetailModalOpen(false);
    openUpsertModal(true);
  };

  const handleDeleteProduct = (product: Product) => {
    setProductData(product);
    setDetailModalOpen(false);
    openDeleteModal(true);
  };

  const handleAddCategory = () => {
    setCategoryModalOpen(true);
  };

  const handleDeleteCategory = (categoryId: string) => {
    const categoryToDelete = categories.find(c => c.id === categoryId);
    if (!categoryToDelete) return;
    
    // Slett kategorien direkte uten bekreftelsesdialog (bekreftelsen skjer allerede i modal)
    Parameter.delete(categoryToDelete);
    updateCacheKey();
    setLoading(true);
    
    if (selectedCategoryId === categoryId) {
      setSelectedCategoryId(null);
    }
  };

  return (
    <PageLayout>
      {isLoading ? (
        <TableSkeleton />
      ) : products.length === 0 ? (
        // Render empty state when there are no products
        <EmptyState
          icon={emptyProductIllustration}
          title={t("products:empty_state.title")}
          description={t("products:empty_state.description")}
          buttonText={t("products:empty_state.button_text")}
          buttonClick={handleCreateProduct}
        />
      ) : (
        <div className="space-y-4">
          {/* Header with view toggle */}
          <div className="p-4">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
              <div>
                <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                  {t("products:title")}
                </h1>
                <p className="text-lgb-grey-600 text-base pt-2 dark:text-lgb-grey-200">
                  {t("products:description")}
                </p>
              </div>
              <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full sm:w-auto">
                <div className="flex items-center gap-2 w-full sm:w-auto">
                  <Button.Group className="w-full sm:w-auto">
                    <Button
                      color={viewMode === "grid" ? "purple" : "gray"}
                      onClick={() => setViewMode("grid")}
                      title={t("products:view.grid")}
                      className="flex-1 sm:flex-auto"
                    >
                      <HiViewGrid className="h-5 w-5" />
                    </Button>
                    <Button
                      color={viewMode === "list" ? "purple" : "gray"}
                      onClick={() => setViewMode("list")}
                      title={t("products:view.list")}
                      className="flex-1 sm:flex-auto"
                    >
                      <HiViewList className="h-5 w-5" />
                    </Button>
                  </Button.Group>
                </div>
                <Button
                  color="light"
                  onClick={handleCreateProduct}
                  className="font-medium w-full sm:w-auto border-lgb-grey-200 dark:border dark:border-gray-500"
                >
                  <HiOutlinePlus className="mr-2 h-5 w-5 dark:text-white" />
                  {t("products:new_product")}
                </Button>
              </div>
            </div>
          </div>

          {/* Content based on view mode */}
          {viewMode === "grid" ? (
            <div className="p-4">
              <ProductCategories
                products={products}
                categories={productCategories}
                isLoading={isQueryLoading}
                onSelectCategory={setSelectedCategoryId}
                onSelectProduct={handleSelectProduct}
                selectedCategoryId={selectedCategoryId}
                onAddCategory={handleAddCategory}
                onDeleteCategory={handleDeleteCategory}
              />
            </div>
          ) : (
            <ProductsList
              products={products}
              categories={productCategories}
              isLoading={isQueryLoading}
              isOpen={setSplitIsOpen}
              createOrUpdate={(formData: Product) => {
                setProductData(formData);
                openUpsertModal(true);
              }}
            />
          )}
        </div>
      )}

      {/* Product Detail Modal (for grid view) */}
      <ProductDetailModal
        product={productData}
        categories={productCategories}
        isOpen={isDetailModalOpen}
        onClose={() => setDetailModalOpen(false)}
        onEdit={handleEditProduct}
        onDelete={handleDeleteProduct}
      />

      {/* Product Create/Edit Modal */}
      <ProductModal
        key={productData.id || 'new'}
        data={productData}
        productTypes={productCategories}
        isShowing={isUpsertModalOpen}
        onConfirm={(formData: IProduct) => {
          if (Product.isNew(productData))
            Product.create(activeWorkspace?.workspaceId ?? "", formData);
          else Product.update(productData, formData);
          openUpsertModal(false);
          updateCacheKey();
          setLoading(true);
        }}
        onCancel={() => openUpsertModal(false)}
      />

      {/* Product Delete Confirmation */}
      <DialogForm
        title={
          t("inventory:delete_product") +
          " " +
          productData.descriptionShort +
          "?"
        }
        show={isDeleteModalOpen}
        toggleModal={() => openDeleteModal(false)}
        showHeader={false}
        buttonConfirmColor="failure"
        dismissible
        confirmButton={() => {
          Product.delete(productData);
          openDeleteModal(false);
          updateCacheKey();
          setLoading(true);
        }}
        buttonConfirmText={t("common:confirm")}
        buttonConfirmPrependIcon={<HiTrash className="mr-2 h-4 w-4" />}
        buttonCloseText={t("common:close")}
      />

      {/* Category Create Modal */}
      <ParameterModal
        data={Parameter.default()}
        predefinedType={ParameterType.ProductType}
        isShowing={isCategoryModalOpen}
        onConfirm={(formData) => {
          Parameter.create(activeWorkspace?.workspaceId ?? "", formData);
          setCategoryModalOpen(false);
          updateCacheKey();
          setLoading(true);
        }}
        onCancel={() => setCategoryModalOpen(false)}
      />
    </PageLayout>
  );
};
