import React, { useEffect, useMemo } from "react";
import { PageLayout } from "../components";
import { useWorkspace } from "../context/WorkspaceContext";
import { useTranslation } from "react-i18next";
import { Card, Badge, Avatar } from "flowbite-react";
import { OrderStatus } from "../types/order/IOrder";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useNavigate } from "react-router-dom";
import GreetingsWidget from "../components/molecules/Widgets/GreetingsWidget";
import { HiArrowRight } from "react-icons/hi";
import { useTodaysOrdersApi } from "../hooks/useOrdersApi";
import { useDriversApi, getActiveDriversFromOrders, ActiveDriverInfo } from "../hooks/useDriversApi";

// Register necessary ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend);

// Empty state component
interface EmptyStateProps {
  icon: React.ReactNode;
  message: string;
}

const EmptyState: React.FC<EmptyStateProps> = React.memo(({ icon, message }) => (
  <div className="flex flex-col items-center justify-center h-full">
    {icon}
    <p className="text-gray-500 dark:text-gray-400">{message}</p>
  </div>
));

// Progress bar component with pulsing background
interface ProgressBarProps {
  percentage: number;
  hasOrders: boolean;
  completedText: string;
}

const ProgressBar: React.FC<ProgressBarProps> = React.memo(({ percentage, hasOrders, completedText }) => (
  <div className="relative">
    <div className={`overflow-hidden h-6 text-xs flex rounded-full bg-lgb-blue-100 dark:bg-gray-700 relative`}>
      <div 
        style={{ 
          width: `${percentage > 0 ? percentage : 3}%`,
          background: 'linear-gradient(90deg, #7214FF 0%, #C7A1FF 100%)'
        }} 
        className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center rounded-full transition-all duration-500 z-10 ${hasOrders ? 'progress-bar-animated' : ''} ${percentage === 0 && hasOrders ? 'progress-bar-pulse' : ''}`}
      >
        {percentage > 0 && (
          <span className="text-xs font-semibold z-10 px-2">{percentage}% <span className="hidden md:inline">{completedText}</span></span>
        )}
      </div>
      {percentage === 0 && (
        <span className="text-xs font-semibold text-gray-600 dark:text-gray-300 mx-auto my-auto">{percentage}% <span className="hidden md:inline">{completedText}</span></span>
      )}
    </div>
  </div>
));

// Driver list component
interface DriverListProps {
  drivers: ActiveDriverInfo[];
  activeText: string;
  finishedText: string;
  notStartedText: string;
  ordersText: string;
}

const DriverList: React.FC<DriverListProps> = React.memo(({ drivers, activeText, finishedText, notStartedText, ordersText }) => (
  <div className="space-y-4">
    {drivers.map(driver => {
      // Determine status text and color based on the new logic
      let statusText: string;
      let statusColor: "indigo" | "success" | "gray";

      if (driver.hasInProgressOrders) {
        statusText = activeText;
        statusColor = "indigo";
      } else if (driver.hasCompletedOrders && driver.hasOtherOrders) {
        // NEW: Completed some, has others pending -> Show as Active
        statusText = activeText;
        statusColor = "indigo"; 
      } else if (driver.hasCompletedOrders) {
        // Only completed orders -> Show as Finished
        statusText = finishedText;
        statusColor = "success";
      } else {
        // Only has "Other" (Not Started) or no orders in relevant state
        statusText = notStartedText;
        statusColor = "gray";
      }

      return (
        <div key={driver.id} className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="mr-3">
              {/* Use a proper Avatar component if available, or initials */} 
              <Avatar rounded size="md" placeholderInitials={driver.name.charAt(0).toUpperCase()} />
            </div>
            <div>
              <p className="font-medium text-gray-900 dark:text-white">{driver.name}</p>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                {driver.orderCount} {ordersText}
              </p>
            </div>
          </div>
          <Badge color={statusColor}>
            {statusText}
          </Badge>
        </div>
      );
    })}
  </div>
));

// Icons for empty states
const ChartIcon = React.memo(() => (
  <svg className="w-12 h-12 text-gray-400 mb-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
  </svg>
));

// Skeleton loader component
const SkeletonLoader: React.FC = React.memo(() => (
  <PageLayout>
    {/* Thin progress bar at the top */}
    <div className="mb-4">
      <div className="overflow-hidden h-6 text-xs flex rounded-full bg-gray-200 dark:bg-gray-700 animate-pulse"></div>
    </div>
    
    {/* New layout for GreetingsWidget and KPI cards */}
    <div className="flex flex-col md:flex-row gap-4 mb-4">
      {/* GreetingsWidget skeleton (1/3 width) */}
      <div className="md:w-1/3 h-[220px] bg-gray-200 dark:bg-gray-700 rounded-lg animate-pulse"></div>
      
      {/* KPI cards skeleton (2/3 width) */}
      <div className="md:w-2/3 grid grid-cols-1 sm:grid-cols-2 gap-4">
        {[...Array(4)].map((_, index) => (
          <Card key={index} className="dark:bg-[#0b0f26] sm:py-2 py-1">
            <div className="flex justify-between items-center">
              <div className="h-5 bg-gray-200 dark:bg-gray-700 rounded w-24 animate-pulse"></div>
              <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-12 animate-pulse"></div>
            </div>
          </Card>
        ))}
      </div>
    </div>
    
    {/* KPI section skeleton */}
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
      {[...Array(2)].map((_, index) => (
        <Card key={index} className="dark:bg-[#0b0f26]">
          <div className="h-5 bg-gray-200 dark:bg-gray-700 rounded w-32 mb-4 animate-pulse"></div>
          <div className="h-64 bg-gray-200 dark:bg-gray-700 rounded animate-pulse"></div>
        </Card>
      ))}
    </div>
  </PageLayout>
));

// KPI card component
interface DashboardKpiCardProps {
  title: string;
  count: number;
  color: string;
  onClick?: () => void;
}

const DashboardKpiCard: React.FC<DashboardKpiCardProps> = React.memo(({ title, count, color, onClick }) => (
  <Card 
    className={`dark:bg-[#0b0f26] sm:py-2 py-1 h-full ${onClick ? 'cursor-pointer hover:shadow-lg hover:scale-[1.02] transition-all duration-200' : ''}`}
    onClick={onClick}
  >
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        <h5 className="text-sm sm:text-sm md:text-md font-bold tracking-tight text-gray-900 dark:text-white">
          {title}
        </h5>
        {onClick && (
          <HiArrowRight className="ml-1 sm:ml-2 text-gray-500 dark:text-gray-400 opacity-70 transition-opacity hover:opacity-100 w-3 h-3 sm:w-4 sm:h-4 flex-shrink-0" />
        )}
      </div>
      <div className={`text-xl sm:text-2xl md:text-3xl font-bold ${count === 0 ? 'text-gray-400 dark:text-gray-500' : color}`}>
        {count}
      </div>
    </div>
  </Card>
));

// CSS animation styles - defined outside the component to avoid re-creating on each render
const animationStyles = `
  @keyframes progressShimmer {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: 0% 0;
    }
  }
  .progress-bar-animated {
    background-size: 200% 100% !important;
    background-image: linear-gradient(90deg, 
      #7214FF 0%, 
      #C7A1FF 45%, 
      #E9DEFF 50%, 
      #C7A1FF 55%, 
      #7214FF 100%) !important;
    animation-name: progressShimmer;
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-delay: 7s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
  }
  
  @media (prefers-color-scheme: dark) {
    .progress-bar-animated {
      background-image: linear-gradient(90deg, 
        #5A10CC 0%, 
        #9B7AD6 45%, 
        #C7A1FF 50%, 
        #9B7AD6 55%, 
        #5A10CC 100%) !important;
    }
  }
  
  .progress-bar-pulse {
    position: relative;
    overflow: hidden;
    animation: scale-pulse 4s ease-in-out infinite;
    transform-origin: center;
  }
  
  @keyframes scale-pulse {
    0% {
      transform: scaleY(0.95);
    }
    50% {
      transform: scaleY(1);
    }
    100% {
      transform: scaleY(0.95);
    }
  }
  
  .progress-bar-pulse::after {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.1), transparent);
    animation: shimmer 3s infinite;
  }
  
  @keyframes shimmer {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }
  
  @media (prefers-color-scheme: dark) {
    .progress-bar-pulse::after {
      background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.05), transparent);
    }
  }
`;

const RealtimeDashboard: React.FC = () => {
  const { t } = useTranslation(["stats", "common"]);
  const { activeWorkspace } = useWorkspace();
  const navigate = useNavigate();
  
  // Add CSS for animation only once when component mounts
  useEffect(() => {
    const styleEl = document.createElement('style');
    styleEl.innerHTML = animationStyles;
    document.head.appendChild(styleEl);
    
    return () => {
      document.head.removeChild(styleEl);
    };
  }, []);
  
  // State for dashboard data
  // const [totalOrderValue] = useState<number>(0);
  
  // Fetch data from API
  const { data: todaysOrders, statusCounts, completionPercentage, isLoading: ordersLoading, isError: ordersError } = 
    useTodaysOrdersApi(activeWorkspace?.workspaceId ?? "", !!activeWorkspace?.workspaceId);
  
  // Capture the full react-query result object for drivers
  const driversQueryResult = 
    useDriversApi(activeWorkspace?.workspaceId ?? "", !!activeWorkspace?.workspaceId);
  
  // Correctly reference loading and error states from the query result object
  const isLoading = ordersLoading || driversQueryResult.isLoading;
  const isError = ordersError || driversQueryResult.isError;
  
  // Calculate active drivers
  const activeDrivers = useMemo<ActiveDriverInfo[]>(() => {
    const ordersReady = Array.isArray(todaysOrders);
    const driversQueryFinished = driversQueryResult.isSuccess; 

    if (ordersReady && driversQueryFinished) {
      const driversData = driversQueryResult.data?.drivers || [];
      const result = getActiveDriversFromOrders(driversData, todaysOrders);
      return result;
    } else {
      return []; // Return empty if prerequisites aren't met
    }
  }, [todaysOrders, driversQueryResult.isSuccess, driversQueryResult.data?.drivers]);
  
  // Prepare data for pie chart with memoization
  const chartData = useMemo(() => {
    // Create canvas element to create gradients
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    // Check if dark mode is active
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    
    // Define gradients if context is available
    let gradients = [];
    if (ctx) {
      if (isDarkMode) {
        // Darker gradients for dark mode
        // Gradient 1: Darker purple/blue
        const blueGradient = ctx.createLinearGradient(0, 0, 200, 0);
        blueGradient.addColorStop(0, '#7214FF');
        blueGradient.addColorStop(1, '#9B7AD6');
        
        // Gradient 2: Darker green
        const greenGradient = ctx.createLinearGradient(0, 0, 200, 0);
        greenGradient.addColorStop(0, '#10B981');
        greenGradient.addColorStop(1, '#7ABFB3');
        
        // Gradient 3: Darker pink
        const pinkGradient = ctx.createLinearGradient(0, 0, 200, 0);
        pinkGradient.addColorStop(0, '#EC4899');
        pinkGradient.addColorStop(1, '#D68AA0');
        
        // Gradient 4: Darker yellow/orange
        const yellowGradient = ctx.createLinearGradient(0, 0, 200, 0);
        yellowGradient.addColorStop(0, '#F59E0B');
        yellowGradient.addColorStop(1, '#D6C287');
        
        // Gradient 5: Darker red
        const redGradient = ctx.createLinearGradient(0, 0, 200, 0);
        redGradient.addColorStop(0, '#EF4444');
        redGradient.addColorStop(1, '#F87171');
        
        gradients = [blueGradient, greenGradient, pinkGradient, yellowGradient, redGradient];
      } else {
        // Lighter gradients for light mode
        // Gradient 1: Purple/blue
        const blueGradient = ctx.createLinearGradient(0, 0, 200, 0);
        blueGradient.addColorStop(0, '#8B5CF6');
        blueGradient.addColorStop(1, '#C7A1FF');
        
        // Gradient 2: Green
        const greenGradient = ctx.createLinearGradient(0, 0, 200, 0);
        greenGradient.addColorStop(0, '#10B981');
        greenGradient.addColorStop(1, '#9DDFCF');
        
        // Gradient 3: Pink
        const pinkGradient = ctx.createLinearGradient(0, 0, 200, 0);
        pinkGradient.addColorStop(0, '#EC4899');
        pinkGradient.addColorStop(1, '#FFACC2');
        
        // Gradient 4: Yellow/orange
        const yellowGradient = ctx.createLinearGradient(0, 0, 200, 0);
        yellowGradient.addColorStop(0, '#F59E0B');
        yellowGradient.addColorStop(1, '#FEE5A7');
        
        // Gradient 5: Red
        const redGradient = ctx.createLinearGradient(0, 0, 200, 0);
        redGradient.addColorStop(0, '#EF4444');
        redGradient.addColorStop(1, '#FCA5A5');
        
        gradients = [blueGradient, greenGradient, pinkGradient, yellowGradient, redGradient];
      }
    } else {
      // Fallback to solid colors if canvas is not available
      if (isDarkMode) {
        // Darker colors for dark mode
        gradients = [
          '#7214FF', // Purple
          '#10B981', // Green
          '#EC4899', // Pink
          '#F59E0B', // Yellow/orange
          '#EF4444', // Red
        ];
      } else {
        // Lighter colors for light mode
        gradients = [
          '#8B5CF6', // Purple
          '#10B981', // Green
          '#EC4899', // Pink
          '#F59E0B', // Yellow/orange
          '#EF4444', // Red
        ];
      }
    }
    
    // Define border colors based on dark mode
    const borderColors = isDarkMode ? [
      '#6D28D9', // Purple border
      '#059669', // Green border
      '#DB2777', // Pink border
      '#D97706', // Yellow/orange border
      '#DC2626', // Red border
    ] : [
      '#7C3AED', // Purple border
      '#059669', // Green border
      '#DB2777', // Pink border
      '#D97706', // Yellow/orange border
      '#DC2626', // Red border
    ];
    
    return {
      labels: Object.keys(statusCounts || {}).map(status => {
        // Translate statuses correctly
        if (status === OrderStatus.Draft) return t("stats:orderStatus.draft") || "Not planned";
        if (status === OrderStatus.Scheduled) return t("stats:orderStatus.scheduled") || "Not started";
        if (status === OrderStatus.InProgress) return t("stats:orderStatus.in_progress") || "In progress";
        if (status === OrderStatus.New) return t("stats:orderStatus.new") || "New";
        return t(`stats:orderStatus.${status.toLowerCase()}`) || status;
      }),
      datasets: [
        {
          data: Object.values(statusCounts || {}),
          backgroundColor: gradients,
          borderColor: borderColors,
          borderWidth: 1,
        },
      ],
    };
  }, [statusCounts, t]);
  
  // Translations with memoization
  const translations = useMemo(() => ({
    completionProgress: t("stats:dashboard.completion_progress") || "Today's delivery progress",
    ordersCompleted: t("stats:dashboard.orders_completed") || "completed orders",
    orderStatusDistribution: t("stats:order_status_distribution") || "Order status distribution",
    totalOrderValue: t("stats:total_order_value") || "Total order value",
    forToday: t("stats:for_today") || "for today",
    activeDrivers: t("stats:dashboard.active_drivers") || "Active drivers",
    active: t("stats:dashboard.active") || "Active",
    finished: t("stats:dashboard.finished") || "Finished",
    notStarted: t("stats:dashboard.not_started") || "Not started",
    orders: t("stats:orders") || "orders",
    noOrdersToday: "No orders today",
    noActiveDrivers: "No active drivers",
    inProgress: t("stats:orderStatus.in_progress") || t("stats:orders.in_progress") || "In progress",
    pending: t("stats:pending") || t("stats:orders.pending") || "Pending",
    completed: t("stats:orderStatus.completed") || t("stats:order_status.completed") || "Completed",
    cancelled: t("stats:orderStatus.cancelled") || t("stats:order_status.cancelled") || "Cancelled"
  }), [t]);
  
  // Calculate order counts by status
  const orderStatusCounts = useMemo(() => {
    const inProgressCount = statusCounts?.[OrderStatus.InProgress] || 0;
    const pendingCount = (statusCounts?.[OrderStatus.New] || 0) + (statusCounts?.[OrderStatus.Scheduled] || 0);
    const completedCount = statusCounts?.[OrderStatus.Completed] || 0;
    const cancelledCount = statusCounts?.[OrderStatus.Cancelled] || 0;
    
    return {
      inProgressCount,
      pendingCount,
      completedCount,
      cancelledCount
    };
  }, [statusCounts]);
  
  // Show skeleton loader if data is loading
  if (isLoading && !isError) {
    return <SkeletonLoader />;
  }
  
  // Show error message if there was an error fetching data
  if (isError) {
    return (
      <PageLayout>
        <div className="p-4 bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-100 rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Error loading dashboard data</h3>
          <p>There was a problem connecting to the API. Please try refreshing the page or contact support if the problem persists.</p>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      {/* Thin progress bar at the top - only shown when there are orders */}
      {todaysOrders && todaysOrders.length > 0 && (
        <div className="mb-4">
          <ProgressBar 
            percentage={completionPercentage} 
            hasOrders={todaysOrders.length > 0}
            completedText={translations.ordersCompleted}
          />
        </div>
      )}
      
      {/* New layout for GreetingsWidget and KPI cards */}
      <div className="flex flex-col md:flex-row gap-4 mb-4">
        {/* GreetingsWidget (1/3 width) */}
        <div className="md:w-1/3">
          <GreetingsWidget />
        </div>
        
        {/* Dashboard KPI cards (2/3 width) - grid on desktop, column on mobile */}
        <div className="md:w-2/3">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4">
            <DashboardKpiCard 
              title={translations.inProgress} 
              count={orderStatusCounts.inProgressCount} 
              color="text-indigo-600" 
              onClick={() => navigate("/orders?status=InProgress")}
            />
            <DashboardKpiCard 
              title={translations.pending} 
              count={orderStatusCounts.pendingCount} 
              color="text-yellow-500" 
              onClick={() => navigate("/orders?status=New,Scheduled")}
            />
            <DashboardKpiCard 
              title={translations.completed} 
              count={orderStatusCounts.completedCount} 
              color="text-green-500" 
              onClick={() => navigate("/orders?status=Completed")}
            />
            <DashboardKpiCard 
              title={translations.cancelled} 
              count={orderStatusCounts.cancelledCount} 
              color="text-red-500" 
              onClick={() => navigate("/orders?status=Cancelled")}
            />
          </div>
        </div>
      </div>
      
      {/* KPI section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <Card className="dark:bg-[#0b0f26]">
          <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
            {translations.orderStatusDistribution}
          </h5>
          <div className="h-64">
            {statusCounts && Object.keys(statusCounts).length > 0 ? (
              <Pie 
                data={chartData} 
                options={{ 
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      labels: {
                        color: '#6B7280'
                      }
                    }
                  }
                }} 
              />
            ) : (
              <EmptyState 
                icon={<ChartIcon />} 
                message={translations.noOrdersToday} 
              />
            )}
          </div>
        </Card>
        
        {/* Commented out because we don't calculate total value correctly
        <Card className="dark:bg-[#0b0f26]">
          <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white">
            {translations.totalOrderValue}
          </h5>
          <div className="flex items-center justify-center h-64">
            <div className="text-center">
              <IncomeIcon />
              <span className={`text-4xl font-bold ${totalOrderValue === 0 ? 'text-gray-400 dark:text-gray-500' : 'text-gray-900 dark:text-white'} block`}>
                {totalOrderValue.toLocaleString()} NOK
              </span>
              <p className="text-gray-500 dark:text-gray-400 mt-2">
                {translations.forToday}
              </p>
            </div>
          </div>
        </Card>
        */}
        
        <Card className="dark:bg-[#0b0f26]">
          <h5 className="text-lg font-bold tracking-tight text-gray-900 dark:text-white mb-4">
            {translations.activeDrivers}
          </h5>
          <div className="h-64 overflow-auto">
            {(() => {
              // Ensure loading/error states are checked *before* checking activeDrivers.length
              if (isLoading) {
                return <p className="text-center text-gray-500">Loading drivers...</p>;
              }
              if (isError) {
                return <p className="text-center text-red-500">Error loading drivers.</p>;
              }
              // Now check activeDrivers length
              const shouldRenderList = activeDrivers.length > 0;

              if (shouldRenderList) {
                return <DriverList 
                  drivers={activeDrivers} 
                  activeText={translations.active} 
                  finishedText={translations.finished} 
                  notStartedText={translations.notStarted} 
                  ordersText={translations.orders} 
                />;
              } else {
                // Only show "No active drivers" if not loading and no error
                return <p className="text-center text-gray-500">No active drivers found.</p>;
              }
            })()}
          </div>
        </Card>
      </div>
    </PageLayout>
  );
};

export default RealtimeDashboard; 