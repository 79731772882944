import type { FC } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { Button, Tooltip } from "flowbite-react";
import { HiArrowRight, HiArrowLeft } from "react-icons/hi";
import { StopCardWrapper, type StopCardProps } from "./StopCard";
import { useTranslation } from "react-i18next";

export const SortableStopCard: FC<StopCardProps> = ({ 
  stop, 
  onMoveToRoute, 
  onMoveToAvailable,
  isInvalid,
  routeStops
}) => {
  const { t } = useTranslation();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: stop.location.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  // Tooltip tekst basert på om stoppen er i ruten eller tilgjengelig
  const tooltipText = stop.isAvailable 
    ? t('planner:route_planner.add_to_route') 
    : t('planner:route_planner.move_to_available');

  // Tooltip for stopp-nummer
  const stopNumberTooltip = !stop.isAvailable 
    ? t('planner:route_planner.stop_number', { number: stop.stopNumber }) 
    : '';

  return (
    <div className="relative flex items-center gap-2 w-full group">
      {!stop.isAvailable && (
        <Tooltip content={tooltipText} placement="left" className="z-50">
          <Button
            size="xs"
            color="gray"
            className="!bg-lgb-red-100 !text-lgb-red-300 hover:!bg-lgb-red-200 border-0 shrink-0 opacity-0 group-hover:opacity-100 transition-opacity duration-150 ease-in-out"
            onClick={onMoveToAvailable}
          >
            <HiArrowLeft className="h-3.5 w-3.5" />
          </Button>
        </Tooltip>
      )}
      <div
        ref={setNodeRef}
        style={style}
        {...listeners}
        {...attributes}
        className="flex-1"
      >
        <StopCardWrapper 
          stop={stop} 
          isDragging={isDragging} 
          onMoveToRoute={onMoveToRoute} 
          onMoveToAvailable={onMoveToAvailable}
          isInvalid={isInvalid}
          routeStops={routeStops}
        />
      </div>
      {stop.isAvailable && (
        <Tooltip content={tooltipText} placement="right" className="z-50">
          <Button
            size="xs"
            color="gray"
            className="!bg-lgb-green-100 !text-lgb-green-300 hover:!bg-lgb-green-200 border-0 shrink-0 opacity-0 group-hover:opacity-100 transition-opacity duration-150 ease-in-out"
            onClick={onMoveToRoute}
          >
            <HiArrowRight className="h-3.5 w-3.5" />
          </Button>
        </Tooltip>
      )}
    </div>
  );
}; 