import type { FC } from "react";
import { useDroppable } from "@dnd-kit/core";
import { IDriver, IOrder } from "../../../types";
import { DraggableOrder } from "./DraggableOrder";
import { HiOutlineClipboardList } from "react-icons/hi";

interface DroppableZoneProps {
  driver: IDriver;
  orders: IOrder[];
  isDraggable?: (order: IOrder) => boolean;
}

export const OrderDroppableZone: FC<DroppableZoneProps> = ({ driver, orders, isDraggable }) => {
  const { setNodeRef, isOver } = useDroppable({
    id: driver.email,
  });

  // Filter orders for this driver
  const driverOrders = orders.filter(order => order.driverId === driver.email);
  const isEmpty = driverOrders.length === 0;

  return (
    <div
      ref={setNodeRef}
      className={`bg-gray-100/80 dark:bg-gray-900/50 rounded-lg border border-gray-200 dark:border-gray-700 shadow-[inset_0_0_8px_rgba(0,0,0,0.02)] transition-all duration-200 w-full
        ${isEmpty ? 'min-h-[60px] py-2 px-4' : 'min-h-[100px] p-4'} 
        ${isOver ? 'bg-purple-50/40 dark:bg-purple-900/10 border-lgb-blue-200/50 dark:border-lgb-blue-800/30 ring-1 ring-lgb-blue-300/30 dark:ring-lgb-blue-700/20' : ''}`}
    >
      {driverOrders.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
          {driverOrders.map(order => (
            <DraggableOrder 
              key={order.id} 
              order={order} 
              isDraggable={isDraggable ? isDraggable(order) : true}
            />
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-center h-full text-gray-500 dark:text-gray-400">
          <HiOutlineClipboardList className="h-5 w-5 mr-2 flex-shrink-0" />
          <p className="text-sm truncate">Ingen ordrer tildelt</p>
        </div>
      )}
    </div>
  );
}; 