import { IRouteItem } from "../../types/order/IRouteItem";

export interface RouteStop extends IRouteItem {
  isAvailable: boolean;
  orderDisplayName?: string;
  cargos?: {
    id: string;
    itinerary: {
      pickupStopNumber?: number;
      deliveryStopNumber?: number;
    };
  }[];
}

interface ValidationError {
  message: string;
  invalidStopIds: string[];
}

/**
 * Validates that for each cargo item, the pickup stop comes before the delivery stop.
 * @param stops Array of route stops to validate
 * @returns boolean indicating whether the route order is valid
 */
export const validateRouteOrder = (stops: RouteStop[]): boolean => {
  return getRouteValidationError(stops) === null;
};

/**
 * Gets a detailed error message if the route order is invalid.
 * @param stops Array of route stops to validate
 * @returns ValidationError object if invalid, null if valid
 */
export const getRouteValidationError = (stops: RouteStop[], availableStops: RouteStop[] = []): ValidationError | null => {
  // Create a map to store cargo validation info
  const cargoValidation = new Map<string, {
    pickupStop?: RouteStop;
    deliveryStop?: RouteStop;
    cargoId: string;
  }>();

  // First pass: collect all cargo pickup and delivery stops
  stops.forEach((stop, index) => {
    stop.cargos?.forEach(cargo => {
      if (!cargoValidation.has(cargo.id)) {
        cargoValidation.set(cargo.id, { cargoId: cargo.id });
      }

      const validation = cargoValidation.get(cargo.id)!;
      if (stop.isPickup) {
        validation.pickupStop = { ...stop, stopNumber: index + 1 };
      } else {
        validation.deliveryStop = { ...stop, stopNumber: index + 1 };
      }
    });
  });

  // Second pass: validate and collect errors
  const invalidStops: { stopId: string; message: string }[] = [];
  
  for (const [cargoId, validation] of Array.from(cargoValidation.entries())) {
    // Check for missing pickups when there's a delivery
    if (validation.deliveryStop && !validation.pickupStop) {
      // Check if the pickup exists in available stops
      const pickupExistsInAvailable = availableStops.some(stop => 
        stop.cargos?.some(cargo => cargo.id === cargoId && stop.isPickup)
      );

      // Show validation error if the pickup is in available stops
      // If pickup is not in available and not in route, we assume it's already been completed or is being picked up a different day
      if (pickupExistsInAvailable) {
        invalidStops.push({
          stopId: validation.deliveryStop.location.id,
          message: `Pickup stop must be added to route before delivery for cargo ${cargoId}`
        });
      }
    }
    
    // Check for invalid order (delivery before pickup)
    if (validation.pickupStop && validation.deliveryStop && 
        validation.deliveryStop.stopNumber <= validation.pickupStop.stopNumber) {
      invalidStops.push({
        stopId: validation.pickupStop.location.id,
        message: `Pickup stop #${validation.pickupStop.stopNumber} must come before delivery stop #${validation.deliveryStop.stopNumber} for cargo ${cargoId}`
      });
      invalidStops.push({
        stopId: validation.deliveryStop.location.id,
        message: `Delivery stop #${validation.deliveryStop.stopNumber} must come after pickup stop #${validation.pickupStop.stopNumber} for cargo ${cargoId}`
      });
    }
  }

  if (invalidStops.length > 0) {
    const uniqueInvalidStopIds = Array.from(new Set(invalidStops.map(stop => stop.stopId)));
    const messages = Array.from(new Set(invalidStops.map(stop => stop.message)));

    return {
      message: messages.join('. '),
      invalidStopIds: uniqueInvalidStopIds
    };
  }

  return null;
}; 