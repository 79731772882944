import { FC, useState, useEffect } from "react";
import { Marker } from "@react-google-maps/api";
import { MapMarkersProps } from "../types";

export const MapMarkers: FC<MapMarkersProps> = ({
  selectedRoute,
  driverPosition,
  startPin,
  waypointPin,
  driverPin,
  onStopSelect,
}) => {
  const [visibleMarkers, setVisibleMarkers] = useState<number[]>([]);
  const [hoverMarker, setHoverMarker] = useState<number | null>(null);

  // Animate markers sequentially when route changes
  useEffect(() => {
    if (!selectedRoute?.route?.length) {
      setVisibleMarkers([]);
      return;
    }

    // Reset visible markers
    setVisibleMarkers([]);

    // Animate markers one by one with a delay
    const totalMarkers = selectedRoute.route.length;
    const animationDelay = 100; // ms between each marker

    // Show markers one by one
    const timers: NodeJS.Timeout[] = [];
    
    selectedRoute.route.forEach((_, index) => {
      const timer = setTimeout(() => {
        setVisibleMarkers(prev => [...prev, index]);
      }, index * animationDelay);
      
      timers.push(timer);
    });

    return () => {
      // Clean up timers
      timers.forEach(timer => clearTimeout(timer));
    };
  }, [selectedRoute?.id, selectedRoute?.route]);

  // Create custom marker with pulse effect
  const createMarker = (index: number, position: google.maps.LatLngLiteral, icon: any) => {
    const isVisible = visibleMarkers.includes(index);
    const isHovered = hoverMarker === index;
    const isStart = index === 0;
    
    // Skip if not visible yet (for animation)
    if (!isVisible) return null;

    return (
      <div key={`marker-${index}`}>
        <Marker
          position={position}
          icon={icon}
          onClick={() => onStopSelect(selectedRoute!.route[index])}
          onMouseOver={() => setHoverMarker(index)}
          onMouseOut={() => setHoverMarker(null)}
          animation={google.maps.Animation.DROP}
          zIndex={isHovered ? 1000 : (isStart ? 100 : 10)}
        />
        
        {/* Pulse effect for start marker or hovered markers */}
        {(isStart || isHovered) && (
          <div className="absolute">
            <div 
              className={`absolute rounded-full animate-ping ${
                isStart ? 'bg-lgb-blue-300/50' : 'bg-lgb-blue-200/30'
              }`}
              style={{
                width: '30px',
                height: '30px',
                left: position.lat,
                top: position.lng,
                animationDuration: isStart ? '2s' : '1.5s',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {selectedRoute?.route.map((stop, index) => {
        const position = {
          lat: stop.location.coordinate?.lat ?? 0,
          lng: stop.location.coordinate?.lon ?? 0,
        };
        
        return createMarker(
          index,
          position,
          index === 0 ? startPin : waypointPin
        );
      })}
      
      {driverPosition && (
        <Marker 
          position={driverPosition} 
          icon={driverPin} 
          animation={google.maps.Animation.BOUNCE}
        />
      )}
    </>
  );
}; 