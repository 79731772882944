import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { 
  HiLocationMarker, 
  HiOfficeBuilding, 
  HiPhone, 
  HiMail, 
  HiClock, 
  HiTruck, 
  HiCube, 
  HiScale, 
  HiDocumentText,
  HiX,
  HiUser,
  HiCalendar
} from 'react-icons/hi';
import { EnhancedRouteItem } from './types';
import { formatDate } from '../../../utils/dateUtils';

interface StopDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  stop: EnhancedRouteItem | null;
}

interface InfoCardProps {
  children: React.ReactNode;
}

const InfoCard: FC<InfoCardProps> = ({ children }) => (
  <div className="bg-white dark:bg-gray-800 rounded-lg p-3 border border-gray-200 dark:border-gray-700 shadow-sm">
    {children}
  </div>
);

interface SectionTitleProps {
  icon: React.ReactNode;
  title: string;
}

const SectionTitle: FC<SectionTitleProps> = ({ icon, title }) => (
  <div className="flex items-center gap-2 mb-1">
    {icon}
    <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">
      {title}
    </h4>
  </div>
);

interface AddressInfoProps {
  address: string | null | undefined;
  postCode?: string;
  city?: string;
}

const AddressInfo: FC<AddressInfoProps> = ({ address, postCode, city }) => (
  <div className="flex items-start gap-3 mb-3">
    <HiLocationMarker className="h-5 w-5 text-gray-500 dark:text-gray-400 mt-0.5 shrink-0" />
    <div>
      <p className="text-gray-900 dark:text-white font-medium">{address || ''}</p>
      {postCode && city && (
        <p className="text-gray-600 dark:text-gray-300 text-sm">
          {postCode} {city}
        </p>
      )}
    </div>
  </div>
);

interface CustomerInfoProps {
  displayName: React.ReactNode;
  phone?: string;
}

const CustomerInfo: FC<CustomerInfoProps> = ({ displayName, phone }) => (
  <div className="flex items-center gap-3">
    <HiOfficeBuilding className="h-5 w-5 text-gray-500 dark:text-gray-400 shrink-0" />
    <div>
      <p className="text-gray-900 dark:text-white">{displayName}</p>
      {phone && (
        <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400 mt-1">
          <HiPhone className="h-3.5 w-3.5 shrink-0" />
          <span>{phone}</span>
        </div>
      )}
    </div>
  </div>
);

interface TimingInfoProps {
  timeAfter?: string;
  timeBefore?: string;
  date?: string;
  noTimeText: string;
  noDateText: string;
}

const TimingInfo: FC<TimingInfoProps> = ({ timeAfter, timeBefore, date, noTimeText, noDateText }) => (
  <div className="space-y-1 mt-1">
    <p className="text-sm text-gray-900 dark:text-white">
      {timeAfter && timeBefore ? (
        <>
          {timeAfter} - {timeBefore}
        </>
      ) : (
        <span className="text-gray-500 dark:text-gray-400 text-xs">{noTimeText}</span>
      )}
    </p>
    <div className="flex items-center gap-1 text-xs text-gray-500 dark:text-gray-400">
      <HiCalendar className="h-3 w-3" />
      <span>{date || noDateText}</span>
    </div>
  </div>
);

interface CargoSummaryProps {
  count: number;
  quantity: number;
  weight: number;
  volume: number;
  colorClass: string;
  unitsText: string;
  quantityText: string;
}

const CargoSummary: FC<CargoSummaryProps> = ({ 
  count, quantity, weight, volume, colorClass, unitsText, quantityText 
}) => (
  <div className="space-y-1 mt-1">
    <div className="flex items-center justify-between">
      <span className="text-sm text-gray-900 dark:text-white">
        {count} {unitsText}
      </span>
      <span className={`text-xs font-medium px-2 py-0.5 rounded-full ${colorClass}`}>
        {quantity} {quantityText}
      </span>
    </div>
    <div className="flex items-center gap-3 text-xs text-gray-500 dark:text-gray-400">
      <div className="flex items-center gap-1">
        <HiScale className="h-3 w-3" />
        <span>{weight} kg</span>
      </div>
      <div className="flex items-center gap-1">
        <HiCube className="h-3 w-3" />
        <span>{volume} m³</span>
      </div>
    </div>
  </div>
);

interface CargoItemProps {
  description: string;
  dimensions: string;
  quantity: number;
}

const CargoItem: FC<CargoItemProps> = ({ description, dimensions, quantity }) => (
  <div className="flex items-center justify-between py-1 border-b border-gray-100 dark:border-gray-700 last:border-0">
    <div className="flex-1">
      <p className="text-sm text-gray-900 dark:text-white">{description}</p>
      <div className="flex items-center gap-2 text-xs text-gray-500 dark:text-gray-400 mt-0.5">
        <span>{dimensions}</span>
      </div>
    </div>
    <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
      {quantity}x
    </span>
  </div>
);

export const StopDetailModal: FC<StopDetailModalProps> = ({ isOpen, onClose, stop }) => {
  const { t } = useTranslation();
  const [orderDetails, setOrderDetails] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen && stop) {
      // Simulerer henting av data - i en ekte implementasjon ville dette være et API-kall
      setLoading(true);
      setTimeout(() => {
        // Mock data basert på stop-informasjon
        setOrderDetails({
          customer: {
            name: stop.orderDisplayName || 'Kunde',
            contactEmail: 'kunde@example.com',
            phoneNumber: '+47 123 45 678',
            primaryAddress: {
              addressLine: stop.location.addressLine,
              city: stop.location.city || 'Oslo',
              postCode: stop.location.postCode || '0000',
              countryCode: stop.location.countryCode || 'NO'
            }
          },
          cargo: stop.cargos?.map(cargo => ({
            description: cargo.description || 'Produkt',
            quantity: cargo.quantity,
            weight: Math.round(Math.random() * 100),
            volume: Math.round(Math.random() * 10),
            dimensions: `${Math.round(Math.random() * 100)}x${Math.round(Math.random() * 100)}x${Math.round(Math.random() * 100)} cm`
          })) || []
        });
        setLoading(false);
      }, 500);
    }
  }, [isOpen, stop]);

  if (!stop || !isOpen) return null;

  const stopTypeColor = stop.isPickup 
    ? 'bg-lgb-blue-100 text-lgb-blue-500 dark:bg-lgb-blue-900/30 dark:text-lgb-blue-300' 
    : 'bg-lgb-green-100 text-lgb-green-500 dark:bg-lgb-green-900/30 dark:text-lgb-green-300';

  // Sjekk om stopDate er en gyldig dato
  const hasValidDate = stop.stopDate && typeof stop.stopDate.toString === 'function' && !isNaN(new Date(stop.stopDate.toString()).getTime());
  const formattedDate = hasValidDate ? formatDate(stop.stopDate.toString()) : '';

  // Beregn totaler for last
  const calculateTotals = () => {
    if (!orderDetails?.cargo?.length) return { totalQuantity: 0, totalWeight: 0, totalVolume: 0 };
    
    return {
      totalQuantity: orderDetails.cargo.reduce((sum: number, item: any) => sum + item.quantity, 0),
      totalWeight: orderDetails.cargo.reduce((sum: number, item: any) => sum + item.weight, 0),
      totalVolume: orderDetails.cargo.reduce((sum: number, item: any) => sum + item.volume, 0)
    };
  };

  const { totalQuantity, totalWeight, totalVolume } = calculateTotals();

  const renderModalHeader = () => (
    <>
      <div className={`absolute top-0 left-0 right-0 h-16 ${stop.isPickup ? 'bg-lgb-blue-50 dark:bg-lgb-blue-900/10' : 'bg-lgb-green-50 dark:bg-lgb-green-900/10'} rounded-t-lg z-0`}></div>
      
      <div className="relative z-10 px-4 pt-3 pb-0">
        <div className="flex items-center gap-3 mb-3">
          {!stop.isAvailable && (
            <div className={`flex items-center justify-center w-8 h-8 rounded-full ${stopTypeColor} font-medium text-sm`}>
              {stop.stopNumber}
            </div>
          )}
          <h3 className="text-lg font-medium text-gray-900 dark:text-white">
            {stop.isPickup ? t('planner:route_planner.pickup') : t('planner:route_planner.delivery')}
          </h3>
        </div>
      </div>
    </>
  );

  const renderAddressAndCustomer = () => {
    // Bruk en tom streng som fallback for kundenavnet
    const customerDisplayName = stop.orderDisplayName || '';
    
    return (
      <InfoCard>
        <AddressInfo 
          address={stop.location.addressLine}
          postCode={orderDetails?.customer?.primaryAddress?.postCode}
          city={orderDetails?.customer?.primaryAddress?.city}
        />
        <CustomerInfo 
          displayName={customerDisplayName}
          phone={orderDetails?.customer?.phoneNumber}
        />
      </InfoCard>
    );
  };

  const renderTimingAndCargoSummary = () => (
    <div className="grid grid-cols-2 gap-3">
      <InfoCard>
        <SectionTitle 
          icon={<HiClock className="h-4 w-4 text-gray-500 dark:text-gray-400" />}
          title={t('planner:route_planner.timing')}
        />
        <TimingInfo 
          timeAfter={stop.timing?.after}
          timeBefore={stop.timing?.before}
          date={formattedDate}
          noTimeText={t('planner:route_planner.no_time_constraint')}
          noDateText={t('planner:route_planner.no_date_set')}
        />
      </InfoCard>

      {orderDetails && orderDetails.cargo.length > 0 && (
        <InfoCard>
          <SectionTitle 
            icon={<HiCube className="h-4 w-4 text-gray-500 dark:text-gray-400" />}
            title={t('planner:route_planner.cargo')}
          />
          <CargoSummary 
            count={orderDetails.cargo.length}
            quantity={totalQuantity}
            weight={totalWeight}
            volume={totalVolume}
            colorClass={stopTypeColor}
            unitsText={t('common:units')}
            quantityText={t('common:quantity')}
          />
        </InfoCard>
      )}
    </div>
  );

  const renderCargoDetails = () => (
    orderDetails && orderDetails.cargo.length > 0 && (
      <InfoCard>
        <div className="flex items-center justify-between mb-2">
          <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300">
            {t('common:cargo')} {t('common:details')}
          </h4>
        </div>
        <div className="space-y-2">
          {orderDetails.cargo.map((item: any, index: number) => (
            <CargoItem 
              key={index}
              description={item.description}
              dimensions={item.dimensions}
              quantity={item.quantity}
            />
          ))}
        </div>
      </InfoCard>
    )
  );

  const renderLoadingState = () => (
    <div className="flex justify-center items-center h-48">
      <div className="animate-pulse text-gray-500">{t('common:loading')}</div>
    </div>
  );

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900/50 dark:bg-gray-900/80">
      <div className="relative max-w-md w-full bg-white dark:bg-gray-800 rounded-lg shadow-xl">
        {/* Lukkeknapp */}
        <button
          type="button"
          onClick={onClose}
          className="absolute top-3 right-3 z-20 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
        >
          <HiX className="w-5 h-5" />
          <span className="sr-only">{t('common:close')}</span>
        </button>

        {renderModalHeader()}

        <div className="px-4 py-3 relative z-10">
          {loading ? renderLoadingState() : (
            <div className="space-y-4">
              {renderAddressAndCustomer()}
              {renderTimingAndCargoSummary()}
              {renderCargoDetails()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}; 