/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  OrderSummary,
  ValidationToast,
} from "../../../components/";
import {
  CreateOrderStepsEnum,
  // ContactInfo,
  CustomerSelection,
  DriverSelection,
  ProductManagement,
  RouteSelection,
} from "./";
import isSmallScreen from "../../../helpers/is-small-screen";
import { Customer, Driver, Order, OrderProduct } from "../../../models";
import { Timestamp } from "../../../types";
import { IContactPerson, IRouteItem, IOrderProduct } from "../../../types";
import { Button } from "flowbite-react";
import { StyledButton } from "../../../components/atoms/Button";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi";
import { HiCheck } from "react-icons/hi";
import { useCustomers } from "../../../hooks";
import { useWorkspace } from "../../../context/WorkspaceContext";
import { RouteSelectionRef } from "./steps/RouteSelection";
import { ProductSelectionRef } from "./steps/ProductManagement";
import OrderPreview from "../../../components/molecules/OrderPreview/OrderPreview";
import { useNavigate } from "react-router-dom";

const CreateOrder: FC = function () {
  const { t } = useTranslation(["orders", "validation"]);
  const [currentStep, setStep] = useState(
    CreateOrderStepsEnum.CUSTOMER_SELECTION,
  );
  const [order, setOrder] = useState(Order.default());
  const [showSummaryDialog, setShowSummaryDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [stepValidatedFailed, setStepValidatonFailed] = useState(false);
  const [transition, setTransition] = useState("");
  const { activeWorkspace } = useWorkspace();
  const navigate = useNavigate();
  
  // Legg til state for ValidationToast
  const [showValidationToast, setShowValidationToast] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  const fadeInTransition =
    "animate-in slide-in-from-right fade-out duration-300";
  const fadeOutTransition =
    "animate-in slide-in-from-left fade-out duration-300";
  // const hasMounted = useRef(false);
  const query = useCustomers(activeWorkspace?.workspaceId ?? "");
  const customers = query.data;

  // Legg til CSS for responsiv layout - Behold desktop-utseende
  useEffect(() => {
    const styleElement = document.createElement('style');
    styleElement.id = 'navigation-buttons-style';
    styleElement.innerHTML = `
      /* Desktop styling - samme som før */
      @media (min-width: 768px) {
        .navigation-buttons-desktop {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 1rem;
        }
        .navigation-buttons-mobile {
          display: none;
        }
      }
      
      /* Mobil styling - forbedret layout */
      @media (max-width: 767px) {
        .navigation-buttons-desktop {
          display: none;
        }
        .navigation-buttons-mobile {
          display: flex;
          justify-content: space-between;
          margin-top: 0.5rem;
          margin-bottom: 1rem;
        }
      }
    `;
    document.head.appendChild(styleElement);

    return () => {
      const styleElement = document.getElementById('navigation-buttons-style');
      if (styleElement) {
        document.head.removeChild(styleElement);
      }
    };
  }, []);

  const set = (
    key: string,
    val:
      | string
      | number
      | Customer
      | IRouteItem[]
      | IOrderProduct[]
      | IContactPerson[]
      | Driver
      | Timestamp
      | null,
  ) => {
    setOrder((order) => ({ ...order, [key]: val }) as Order);
  };

  const handleStepChange = (step: number) => {
    // Hvis vi går bakover, tillat uten validering
    if (step < currentStep) {
      setStepValidatonFailed(false);
      setShowValidationToast(false); // Skjul toast hvis vi går bakover
      setTransition(fadeOutTransition);
      setStep(step);
      return;
    }
    
    // Først oppdaterer vi dataene basert på gjeldende steg
    if (currentStep === CreateOrderStepsEnum.ROUTE_SELECTION) {
      handleRouteUpdate();
    }
    if (currentStep === CreateOrderStepsEnum.PRODUCT_MANAGEMENT) {
      // For produktsteget, bruk returverdien fra handleProductUpdate
      const isValid = handleProductUpdate();
      if (!isValid) {
        // Hvis valideringen feiler, ikke gå videre
        return;
      }
    }
    
    // Hvis vi går til siste steg (sjåførvalg), tillat uten validering
    if (step === CreateOrderStepsEnum.DRIVER_SELECTION) {
      setStepValidatonFailed(false);
      setTransition(fadeInTransition);
      setStep(step);
      return;
    }
    
    // For andre steg, valider før vi går videre
    setTimeout(() => {
      setOrder((order) => {
        const validated = Order.validateStep(order, currentStep);
        if (validated) {
          setStepValidatonFailed(false);
          setShowValidationToast(false); // Skjul toast hvis validering er OK
          setTransition(fadeInTransition);
          setStep(step);
        } else {
          setStepValidatonFailed(true);
          
          // Vis ValidationToast med passende feilmelding basert på gjeldende steg
          let errorMessage = "";
          switch (currentStep) {
            case CreateOrderStepsEnum.CUSTOMER_SELECTION:
              errorMessage = t("validation:customer_required");
              break;
            case CreateOrderStepsEnum.ROUTE_SELECTION:
              errorMessage = t("validation:route_required");
              break;
            case CreateOrderStepsEnum.PRODUCT_MANAGEMENT:
              errorMessage = t("validation:products_required");
              break;
            default:
              errorMessage = t("validation:step_incomplete");
          }
          
          setValidationMessage(errorMessage);
          setShowValidationToast(true);
        }
        return order;
      });
    }, 100);
  };

  const routeSelectionRef = useRef<RouteSelectionRef>(null);
  const productSelectionRef = useRef<ProductSelectionRef>(null);

  const handleRouteUpdate = () => {
    if (routeSelectionRef.current) {
      routeSelectionRef.current.updateOrderRoute();
    }
  };

  const handleProductUpdate = (): boolean => {
    if (productSelectionRef.current) {
      // Kall updateOrderProducts og bruk returverdien for å sette stepValidatedFailed
      const isValid = productSelectionRef.current.updateOrderProducts();
      if (!isValid) {
        setStepValidatonFailed(true);
        
        // Sjekk om det faktisk mangler produkter
        if (order.cargo.length === 0) {
          // Vis feilmelding om manglende produkter
          setValidationMessage(t("validation:products_required"));
          setShowValidationToast(true);
        }
        
        return false;
      }
      return true;
    }
    return false; // Hvis ref ikke er tilgjengelig, returner false for å være på den sikre siden
  };

  const handleCancel = () => {
    // Navigerer tilbake til ordrelisten
    navigate("/orders");
  };

  const SummaryAndCompleteDialog = (
    <Dialog
      title={t("create.summary")}
      dismissible
      content={
        <OrderSummary order={order} close={() => setShowSummaryDialog(false)} />
      }
      show={showSummaryDialog}
      toggleModal={() => setShowSummaryDialog(false)}
      hideButtons
    />
  );

  const CancelDialog = (
    <Dialog
      title={t("create.cancel_title") || "Avbryt opprettelse av ordre"}
      dismissible
      content={
        <div>
          <p>{t("create.cancel_description") || "Er du sikker på at du vil avbryte opprettelsen av denne ordren? Alle endringer vil gå tapt."}</p>
        </div>
      }
      show={showCancelDialog}
      toggleModal={() => setShowCancelDialog(false)}
      confirmButton={() => handleCancel()}
      buttonConfirmText={t("create.cancel_confirm") || "Ja, avbryt"}
      buttonCloseText={t("create.cancel_cancel") || "Nei, fortsett"}
      confirmButtonColor="failure"
    />
  );

  // Navigasjonsknapper
  const NavigationButtons = (
    <div className="navigation-buttons-desktop">
      {currentStep !== CreateOrderStepsEnum.CUSTOMER_SELECTION && (
        <StyledButton
          variant="secondary"
          className="mr-2 md:text-base text-sm"
          onClick={() =>
            handleStepChange(currentStep > 0 ? currentStep - 1 : 0)
          }
          icon={<HiOutlineArrowLeft />}
        >
          {t("orders:create.back_button")}
        </StyledButton>
      )}
      {currentStep !== CreateOrderStepsEnum.DRIVER_SELECTION && (
        <StyledButton
          variant="primary"
          onClick={() =>
            handleStepChange(currentStep < 4 ? currentStep + 1 : 4)
          }
          icon={<HiOutlineArrowRight />}
          iconPosition="right"
          className="bg-lgb-blue-300 hover:bg-lgb-blue-400 text-white md:text-base text-sm"
        >
          {t("orders:create.proceed_button")}
        </StyledButton>
      )}
      {currentStep === CreateOrderStepsEnum.DRIVER_SELECTION && (
        <StyledButton
          variant="primary"
          disabled={!order.driverId}
          onClick={() => {
            setShowSummaryDialog(true);
          }}
          icon={<HiCheck />}
          iconPosition="right"
          className="bg-lgb-blue-300 hover:bg-lgb-blue-400 text-white md:text-base text-sm"
        >
          {t("create.summary_and_complete_button")}
        </StyledButton>
      )}
    </div>
  );

  // Render det aktuelle steget basert på currentStep
  const renderCurrentStep = () => {
    switch (currentStep) {
      case CreateOrderStepsEnum.CUSTOMER_SELECTION:
        return (
          <CustomerSelection
            selectedCustomer={order.customer as Customer}
            update={(c) => {
              set("customer", c);
              set("customerId", c.id);
            }}
            validationFailed={stepValidatedFailed}
          />
        );
      case CreateOrderStepsEnum.ROUTE_SELECTION:
        return (
          <RouteSelection
            ref={routeSelectionRef}
            route={[...order.route]}
            update={(r) => {
              set("route", r);
            }}
            validationFailed={stepValidatedFailed}
          />
        );
      case CreateOrderStepsEnum.PRODUCT_MANAGEMENT:
        return (
          <ProductManagement
            ref={productSelectionRef}
            route={order.route}
            orderItems={order.cargo as OrderProduct[]}
            validationFailed={stepValidatedFailed}
            update={(p: IOrderProduct[]) => {
              set("cargo", p);
            }}
          />
        );
      case CreateOrderStepsEnum.DRIVER_SELECTION:
        return (
          <DriverSelection
            order={order}
            emitOnClick
            update={(id) => set("driverId", id)}
            comment={order.comment}
            validationFailed={stepValidatedFailed}
            updateComment={(c) => set("comment", c)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="relative">
      {/* ValidationToast for å vise feilmeldinger */}
      <ValidationToast
        show={showValidationToast}
        title={t("validation:validation_error")}
        message={validationMessage}
        onClose={() => setShowValidationToast(false)}
        position="top-right"
      />
      
      {/* Responsiv layout - behold desktop, forbedre mobil */}
      <div className="flex flex-col md:flex-row gap-6 mb-6">
        {/* Forhåndsvisning - på mobil vises den øverst */}
        <div className="w-full md:hidden mb-2">
          <OrderPreview 
            order={order} 
            currentStep={currentStep} 
            onStepChange={handleStepChange} 
          />
        </div>
        
        {/* Venstre side på desktop - Hovedinnhold */}
        <div className="w-full md:w-2/3">
          {/* Desktop navigasjonsknapper */}
          <div className="hidden md:block">
            {NavigationButtons}
          </div>
          
          {/* Skjema for det aktuelle steget */}
          <div className={transition}>
            {renderCurrentStep()}
          </div>
        </div>
        
        {/* Høyre side på desktop - Forhåndsvisning */}
        <div className="w-full md:w-1/3 sticky top-4 self-start hidden md:block">
          <OrderPreview 
            order={order} 
            currentStep={currentStep} 
            onStepChange={handleStepChange} 
          />
        </div>
      </div>

      {/* Sticky navigasjonsknapper nederst på mobil */}
      <div className="fixed bottom-0 left-0 right-0 bg-white dark:bg-lgb-dark-background p-4 shadow-lg border-t border-gray-200 dark:border-gray-700 md:hidden z-10 flex justify-between items-center">
        {currentStep > CreateOrderStepsEnum.CUSTOMER_SELECTION ? (
          <StyledButton
            variant="secondary"
            onClick={() =>
              handleStepChange(currentStep > 0 ? currentStep - 1 : 0)
            }
            icon={<HiOutlineArrowLeft />}
            className="flex-1 mr-2"
          >
            {t("orders:create.back_button")}
          </StyledButton>
        ) : (
          <div className="flex-1 mr-2"></div>
        )}
        
        {currentStep < CreateOrderStepsEnum.DRIVER_SELECTION ? (
          <StyledButton
            variant="primary"
            onClick={() =>
              handleStepChange(currentStep < 4 ? currentStep + 1 : 4)
            }
            icon={<HiOutlineArrowRight />}
            iconPosition="right"
            className="bg-lgb-blue-300 hover:bg-lgb-blue-400 text-white flex-1"
          >
            {t("orders:create.proceed_button")}
          </StyledButton>
        ) : (
          <StyledButton
            variant="primary"
            disabled={!order.driverId}
            onClick={() => {
              setShowSummaryDialog(true);
            }}
            icon={<HiCheck />}
            iconPosition="right"
            className="bg-lgb-blue-300 hover:bg-lgb-blue-400 text-white flex-1"
          >
            {t("create.summary_and_complete_button")}
          </StyledButton>
        )}
      </div>

      {/* Legg til ekstra padding i bunnen på mobil for å unngå at innhold skjules bak sticky knapperad */}
      <div className="md:hidden h-20"></div>

      {SummaryAndCompleteDialog}
      {CancelDialog}
    </div>
  );
};

export default CreateOrder;
