/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useMemo, useEffect } from "react";
import { HiTrash, HiPencilAlt, HiViewList, HiViewGrid, HiOutlinePlus } from "react-icons/hi";
import {
  PageLayout,
  DialogForm,
  TableSkeleton,
  VehicleModal,
  ParameterModal,
} from "../../components";
import { useWorkspace } from "../../context/WorkspaceContext";
import { useCache } from "../../context/CacheContext";
import { useVehicles, useParameters, useCompanies } from "../../hooks";
import { useTranslation } from "react-i18next";
import { ITableRow, IVehicle, ParameterType } from "../../types";
import { Company, Vehicle, Parameter } from "../../models";
import { EmptyState } from "../../components";
import { emptyVehicleIllustration } from "../../components/atoms/Icons/illustrations";
import { VehicleList } from "../../components/organisms/Vehicle";
import { VehicleCategories } from "../../components/organisms/Vehicle/VehicleCategories";
import { VehicleDetailModal } from "../../components/organisms/Vehicle/VehicleDetailModal";
import { Button } from "flowbite-react";
import { useSearchParams } from "react-router-dom";

export const VehiclesPage: FC = function () {
  const [searchParams] = useSearchParams();
  const [isUpsertModalOpen, openUpsertModal] = useState(false);
  const [isDeleteModalOpen, openDeleteModal] = useState(false);
  const [isDetailModalOpen, setDetailModalOpen] = useState(false);
  const [isCategoryModalOpen, setCategoryModalOpen] = useState(false);
  const [vehicleCategories, setVehicleCategories] = useState([] as Parameter[]);
  const [workspaceCompanies, setWorkspaceCompanies] = useState([] as Company[]);
  const [isLoading, setLoading] = useState(true);
  const [tableRows, setTableRows] = useState([] as ITableRow[]);
  const [splitIsOpen, setSplitIsOpen] = useState(false);
  const [vehicleData, setVehicleData] = useState(Vehicle.default());
  const [viewMode, setViewMode] = useState<"list" | "grid">("grid");
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(undefined);

  // Sjekk om det finnes en reg-parameter i URL-en og sett visningsmodusen til liste hvis den finnes
  useEffect(() => {
    const regParam = searchParams.get('reg');
    if (regParam) {
      setViewMode("list");
      setSplitIsOpen(true);
    }
  }, [searchParams]);

  const { activeWorkspace } = useWorkspace();
  const { updateCacheKey } = useCache();
  const query = useVehicles(activeWorkspace?.workspaceId ?? "");
  const categoriesQuery = useParameters(
    activeWorkspace?.workspaceId ?? "",
    ParameterType.VehicleType,
  );
  const companiesQuery = useCompanies(activeWorkspace?.workspaceId ?? "");
  const { t } = useTranslation(["common", "inventory", "vehicles"]);

  const isQueryLoading = useMemo(
    () =>
      query.isLoading ||
      query.isRefetching ||
      categoriesQuery.isLoading ||
      categoriesQuery.isRefetching ||
      companiesQuery.isLoading ||
      companiesQuery.isRefetching,
    [query, categoriesQuery, companiesQuery],
  );

  const vehicles = useMemo(() => query.data ?? [], [query]);

  const categories = useMemo(
    () => categoriesQuery.data ?? [],
    [categoriesQuery],
  );

  const companies = useMemo(() => companiesQuery.data ?? [], [companiesQuery]);

  useEffect(() => {
    if (!isQueryLoading) {
      setVehicleCategories(categories);
    }
  }, [isQueryLoading, categories]);

  useEffect(() => {
    if (!isQueryLoading) {
      setWorkspaceCompanies(companies);
    }
  }, [isQueryLoading, companies]);

  useEffect(() => {
    if (!isQueryLoading) {
      setLoading(false);
      setTableRows(
        vehicles.map((vehicle) => {
          return {
            id: vehicle.id,
            cells: [
              {
                id: "categoryCode",
                children: vehicle.categoryCode,
                showOnSmallScreen: true,
              },
              {
                id: "registrationNumber",
                children: vehicle.registrationNumber,
                showOnSmallScreen: true,
              },
              {
                id: "name",
                children: vehicle.name,
              },
            ],
            actions: [
              {
                id: "edit",
                icon: <HiPencilAlt />,
                onActionClick: () => {
                  setVehicleData(vehicle);
                  openUpsertModal(true);
                },
              },
              {
                id: "delete",
                icon: <HiTrash />,
                color: "failure",
                onActionClick: () => {
                  setVehicleData(vehicle);
                  openDeleteModal(true);
                },
              },
            ],
          };
        }),
      );
    }
  }, [isQueryLoading, vehicles]);

  const handleSelectVehicle = (vehicle: Vehicle) => {
    setVehicleData(vehicle);
    // Find the company for this vehicle
    const company = companies.find(c => c.id === vehicle.companyId);
    setSelectedCompany(company);
    
    if (Vehicle.isNew(vehicle)) {
      // For new vehicles, open the upsert modal directly
      openUpsertModal(true);
    } else if (viewMode === "grid") {
      setDetailModalOpen(true);
    } else {
      setSplitIsOpen(true);
    }
  };

  const handleCreateVehicle = () => {
    setVehicleData(Vehicle.default());
    openUpsertModal(true);
  };

  const handleEditVehicle = (vehicle: Vehicle) => {
    setVehicleData(vehicle);
    setDetailModalOpen(false);
    openUpsertModal(true);
  };

  const handleDeleteVehicle = (vehicle: Vehicle) => {
    setVehicleData(vehicle);
    setDetailModalOpen(false);
    openDeleteModal(true);
  };

  const handleAddCategory = () => {
    setCategoryModalOpen(true);
  };

  const handleDeleteCategory = (categoryId: string) => {
    // Finn kategorien som skal slettes
    const categoryToDelete = categories.find(c => c.id === categoryId);
    if (!categoryToDelete) return;
    
    // Slett kategorien direkte uten bekreftelsesdialog (bekreftelsen skjer allerede i modal)
    Parameter.delete(categoryToDelete);
    updateCacheKey();
    setLoading(true);
    
    // Hvis den slettede kategorien var valgt, gå tilbake til "alle"
    if (selectedCategoryId === categoryId) {
      setSelectedCategoryId(null);
    }
  };

  return (
    <PageLayout>
      {isLoading ? (
        <TableSkeleton />
      ) : vehicles.length === 0 ? (
        // Render empty state when there are no vehicles
        <EmptyState
          icon={emptyVehicleIllustration}
          title={t("vehicles:empty_state.title")}
          description={t("vehicles:empty_state.description")}
          buttonText={t("vehicles:empty_state.button_text")}
          buttonClick={handleCreateVehicle}
        />
      ) : (
        <div className="space-y-4">
          {/* Header with view toggle */}
          <div className="p-4">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
              <div>
                <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
                  {t("vehicles:title")}
                </h1>
                <p className="text-lgb-grey-600 text-base pt-2 dark:text-lgb-grey-200">
                  {t("vehicles:description")}
                </p>
              </div>
              <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full sm:w-auto">
                <div className="flex items-center gap-2 w-full sm:w-auto">
                  <Button.Group className="w-full sm:w-auto">
                    <Button
                      color={viewMode === "grid" ? "purple" : "gray"}
                      onClick={() => setViewMode("grid")}
                      title={t("vehicles:view.grid")}
                      className="flex-1 sm:flex-auto"
                    >
                      <HiViewGrid className="h-5 w-5" />
                    </Button>
                    <Button
                      color={viewMode === "list" ? "purple" : "gray"}
                      onClick={() => setViewMode("list")}
                      title={t("vehicles:view.list")}
                      className="flex-1 sm:flex-auto"
                    >
                      <HiViewList className="h-5 w-5" />
                    </Button>
                  </Button.Group>
                </div>
                <Button
                  color="light"
                  onClick={handleCreateVehicle}
                  className="font-medium w-full sm:w-auto"
                >
                  <HiOutlinePlus className="mr-2 h-5 w-5" />
                  {t("vehicles:new_vehicle")}
                </Button>
              </div>
            </div>
          </div>

          {/* Content based on view mode */}
          {viewMode === "grid" ? (
            <div className="p-4">
              <VehicleCategories
                vehicles={vehicles}
                categories={vehicleCategories}
                isLoading={isQueryLoading}
                onSelectCategory={setSelectedCategoryId}
                onSelectVehicle={handleSelectVehicle}
                selectedCategoryId={selectedCategoryId}
                onAddCategory={handleAddCategory}
                onDeleteCategory={handleDeleteCategory}
              />
            </div>
          ) : (
            <VehicleList
              vehicles={vehicles}
              isLoading={isQueryLoading}
              companies={companies}
              categories={vehicleCategories}
              isOpen={setSplitIsOpen}
              createOrUpdate={(formData: Vehicle) => {
                setVehicleData(formData);
                openUpsertModal(true);
              }}
            />
          )}
        </div>
      )}

      {/* Vehicle Detail Modal (for grid view) */}
      <VehicleDetailModal
        vehicle={vehicleData}
        categories={vehicleCategories}
        company={selectedCompany}
        isOpen={isDetailModalOpen}
        onClose={() => setDetailModalOpen(false)}
        onEdit={handleEditVehicle}
        onDelete={handleDeleteVehicle}
      />

      {/* Vehicle Create/Edit Modal */}
      <VehicleModal
        data={vehicleData}
        cateogries={vehicleCategories}
        companies={workspaceCompanies}
        isShowing={isUpsertModalOpen}
        onConfirm={(formData: IVehicle) => {
          if (Vehicle.isNew(vehicleData))
            Vehicle.create(activeWorkspace?.workspaceId ?? "", formData);
          else Vehicle.update(vehicleData, formData);
          openUpsertModal(false);
          updateCacheKey();
          setLoading(true);
        }}
        onCancel={() => openUpsertModal(false)}
      />

      {/* Vehicle Delete Confirmation */}
      <DialogForm
        title={t("inventory:delete_vehicle") + " " + vehicleData.registrationNumber + "?"}
        show={isDeleteModalOpen}
        toggleModal={() => openDeleteModal(false)}
        showHeader={false}
        buttonConfirmColor="failure"
        dismissible
        confirmButton={() => {
          Vehicle.delete(vehicleData);
          openDeleteModal(false);
          updateCacheKey();
          setLoading(true);
        }}
        buttonConfirmText={t("common:confirm")}
        buttonConfirmPrependIcon={<HiTrash className="mr-2 h-4 w-4" />}
        buttonCloseText={t("common:close")}
      />

      {/* Category Create Modal */}
      <ParameterModal
        data={Parameter.default()}
        predefinedType={ParameterType.VehicleType}
        isShowing={isCategoryModalOpen}
        onConfirm={(formData) => {
          Parameter.create(activeWorkspace?.workspaceId ?? "", formData);
          setCategoryModalOpen(false);
          updateCacheKey();
          setLoading(true);
        }}
        onCancel={() => setCategoryModalOpen(false)}
      />
    </PageLayout>
  );
};
