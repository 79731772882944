import { Button, CustomFlowbiteTheme, TextInput } from "flowbite-react";
import { StyledButton } from "../../atoms/Button";
import { ChangeEvent, useEffect, useRef, useState, type FC, Children } from "react";
import { HiSearch, HiOutlinePlus } from "react-icons/hi";

export interface DropdownSearchProp {
  placeholder: string;
  value?: string; // Accept a value prop to control the input
  children?: React.ReactNode; // The list of search result items
  showResults?: boolean;
  childrenAbsolute?: boolean;
  plain?: boolean;
  buttonText?: string;
  inputChanged?: (input: string) => void;
  buttonClick?: () => void;
  clickOutside?: (show: boolean) => void;
  // Added onFocus and onBlur properties:
  onFocus?: () => void;
  onBlur?: () => void;
  // New pagination props:
  paginate?: boolean;
  pageSize?: number;
}

const selectedTheme: CustomFlowbiteTheme = {
  textInput: {
    field: {
      input: {
        colors: {
          primary:
            "cursor-pointer dark:text-white dark:bg-lgb-dark-background w-full",
        },
      },
    },
  },
};

export const DropdownSearch: FC<DropdownSearchProp> = (props) => {
  const [value, setValue] = useState(props.value || "");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(1);
  const defaultPageSize = props.pageSize || 15;

  // Reset pagination when the search value or children change.
  useEffect(() => {
    setPage(1);
  }, [props.value, props.children]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        props.clickOutside && props.clickOutside(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props, props.clickOutside]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    props.inputChanged && props.inputChanged(event.target.value);
  };

  useEffect(() => {
    setValue(props.value || "");
  }, [props.value]);

  // Convert children into an array so we can paginate them.
  const childrenArray = Children.toArray(props.children);
  const displayedChildren = props.paginate
    ? childrenArray.slice(0, page * defaultPageSize)
    : childrenArray;

  const loadMore = () => {
    setPage((prev) => prev + 1);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="flex gap-4 items-center align-center">
        <div className="grow">
          <TextInput
            theme={selectedTheme.textInput}
            color="primary"
            autoComplete="off"
            value={value || ""}
            icon={HiSearch}
            id="search"
            name="search"
            placeholder={props.placeholder}
            required
            size={32}
            type="search"
            onChange={handleInputChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
          />
        </div>
        {props.buttonText && (
          <div className="whitespace-nowrap flex items-center justify-start">
            <StyledButton
              variant="secondary"
              onClick={props.buttonClick ? props.buttonClick : () => {}}
              icon={<HiOutlinePlus className="dark:text-white" />}
              className="border-lgb-grey-200 dark:border dark:border-gray-500"
            >
              {props.buttonText}
            </StyledButton>
          </div>
        )}
      </div>

      {/* Render the search results */}
      {props.showResults && (
        <ul
          className={
            "mt-1 divide-y divide-gray-200 dark:divide-gray-700 z-10 dark:bg-lgb-dark-background dark:border-lgb-grey-600 dark:text-lgb-green-300 w-full" +
            (props.childrenAbsolute ? " bg-white absolute" : "") +
            (props.plain ? "" : " shadow-xl rounded-xl p-4")
          }
        >
          {displayedChildren}
          {props.paginate && displayedChildren.length < childrenArray.length && (
            <li>
              <p
                className="pt-4 text-md underline font-normal cursor-pointer hover:text-lgb-primary"
                onClick={loadMore}
              >
                View More
              </p>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
