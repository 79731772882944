import type { FC } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { SortableStopCard } from "./SortableStopCard";
import { useTranslation } from "react-i18next";
import { EnhancedRouteItem } from "./types";
import { HiChevronDown, HiChevronRight, HiOfficeBuilding } from "react-icons/hi";
import { useState } from "react";

interface SortableOrderGroupProps {
  orderId: string;
  groupStops: EnhancedRouteItem[];
  orderDisplayName: string;
  onMoveToRoute?: (stopId: string) => void;
  onMoveToAvailable?: (stopId: string) => void;
  invalidStopIds?: string[];
}

export const SortableOrderGroup: FC<SortableOrderGroupProps> = ({ 
  orderId, 
  groupStops, 
  orderDisplayName, 
  onMoveToRoute, 
  onMoveToAvailable, 
  invalidStopIds = [] 
}) => {
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: `group-${orderId}`,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const hasInvalidStops = groupStops.some(stop => invalidStopIds.includes(stop.location.id));

  return (
    <div 
      ref={setNodeRef}
      style={style}
      className={`flex flex-col rounded-lg border border-gray-200 dark:border-gray-700 overflow-hidden
        ${isDragging ? 'opacity-70 scale-[1.02] shadow-lg z-10' : ''}
        ${hasInvalidStops ? 'border-red-200 dark:border-red-700' : ''}
        transition-all duration-150 ease-in-out
        hover:border-gray-300 dark:hover:border-gray-600
        bg-white/50 dark:bg-gray-800/50`}
    >
      <div 
        {...attributes} 
        {...listeners}
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="text-sm font-medium text-gray-700 dark:text-gray-300 p-2 cursor-move 
          hover:text-gray-900 dark:hover:text-gray-100 select-none flex items-center gap-2
          bg-gray-50 dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700
          group transition-colors"
      >
        {isCollapsed ? (
          <HiChevronRight className="h-4 w-4 text-gray-400 group-hover:text-gray-600 dark:group-hover:text-gray-300 transition-colors" />
        ) : (
          <HiChevronDown className="h-4 w-4 text-gray-400 group-hover:text-gray-600 dark:group-hover:text-gray-300 transition-colors" />
        )}
        <HiOfficeBuilding className="h-4 w-4 text-gray-500 dark:text-gray-400" />
        <div className="flex-1 truncate">{orderDisplayName}</div>
        <div className="text-xs text-gray-500 bg-gray-100 dark:bg-gray-700 px-1.5 py-0.5 rounded-full">
          {groupStops.length} {groupStops.length === 1 ? t('planner:route_planner.stop') : t('planner:route_planner.stops')}
        </div>
      </div>
      
      <div className={`flex flex-col gap-2 p-2 ${isCollapsed ? 'hidden' : 'block'}`}>
        {groupStops.map((stop) => (
          <SortableStopCard 
            key={stop.location.id} 
            stop={stop} 
            onMoveToRoute={onMoveToRoute ? () => onMoveToRoute(stop.location.id) : undefined}
            onMoveToAvailable={onMoveToAvailable ? () => onMoveToAvailable(stop.location.id) : undefined}
            isInvalid={invalidStopIds?.includes(stop.location.id)}
            routeStops={groupStops}
          />
        ))}
      </div>
    </div>
  );
}; 