import { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Customer from "../../../models/Customer";
import { Button, Spinner } from "flowbite-react";
import { HiCheck, HiOutlineClock } from "react-icons/hi";
import { Timestamp } from "../../../types";
import {
  noCompletedOrders,
  noUpcomingOrders,
} from "../../atoms/Icons/illustrations";
import { OrderStatus } from "../../../types/order/IOrder";
import { Order } from "../../../models";
import { TableSkeletonsByAmount } from "../../atoms/Skeleton/TableSkeleton";

interface RelatedOrdersProps {
  orders: Order[];
  loading?: boolean;
  driverId?: string;
}

export const RelatedOrders: FC<RelatedOrdersProps> = ({ orders, loading, driverId }) => {
  const { t } = useTranslation(["common", "customer"]);
  const location = useLocation();
  
  // Orders Data
  const [completedOrders, setCompletedOrders] = useState<
    {
      title: string;
      value: string;
      stopDate: Timestamp | null;
      orderId: string;
    }[]
  >([]);

  const [upcomingOrders, setUpcomingOrders] = useState<
    {
      title: string;
      value: string;
      stopDate: Timestamp | null;
      orderId: string;
    }[]
  >([]);

  const [hasLoadedMoreUpcoming, setHasLoadedMoreUpcoming] = useState(false);
  const [hasLoadedMoreCompleted, setHasLoadedMoreCompleted] = useState(false);
  const [allCompletedOrdersCount, setAllCompletedOrdersCount] = useState(0);
  const [allUpcomingOrdersCount, setAllUpcomingOrdersCount] = useState(0);

  const [activeTab, setActiveTab] = useState<"completed" | "upcoming">(
    "completed",
  );
  const [completedPage, setCompletedPage] = useState(1);
  const [upcomingPage, setUpcomingPage] = useState(1);

  const ordersPerPage = 5;

  const formatTimestamp = (
    timestamp: Timestamp | string | undefined,
  ): string => {
    if (!timestamp) return t("common:unknown_date");
    if (typeof timestamp === "string") return timestamp;
    return new Date(timestamp.seconds * 1000).toLocaleDateString(); // Converts Firestore Timestamp to a readable date
  };

  // Hent nåværende URL for å bruke som state i lenkene
  const currentPath = location.pathname + location.search;

  // Generer URL med query-parametere for sjåfør
  const getOrderUrl = (orderId: string) => {
    const params = new URLSearchParams();
    params.set('id', orderId);
    
    if (driverId) {
      params.set('driver', driverId);
    }
    
    return `/orders?${params.toString()}`;
  };

  // Fetch KPIs and initial orders
  useEffect(() => {
    if (!orders) return;

    const prepareData = async () => {
      try {
        // Calculate KPIs
        const completed = orders.filter(
          (order) => order.status === OrderStatus.Completed,
        );
        const upcoming = orders.filter(
          (order) =>
            order.status === OrderStatus.New ||
            order.status === OrderStatus.Scheduled ||
            order.status === OrderStatus.InProgress,
        );

        // Prepare orders for display
        const completedOrdersList = completed.map((order) => ({
          title: Customer.getCustomerName(order.customer as Customer),
          value: `From ${
            order.route[0]?.location?.addressLine ||
            t("customer:unknown_address")
          } to ${
            order.route[order.route.length - 1]?.location?.addressLine ||
            t("customer:unknown_address")
          }`,
          stopDate: order.route[order.route.length - 1]?.stopDate || null,
          orderId: order.id,
        }));

        const upcomingOrdersList = upcoming.map((order) => ({
          title: Customer.getCustomerName(order.customer as Customer),
          value: `From ${
            order.route[0]?.location?.addressLine ||
            t("customer:unknown_address")
          } to ${
            order.route[order.route.length - 1]?.location?.addressLine ||
            t("customer:unknown_address")
          }`,
          stopDate: order.route[order.route.length - 1]?.stopDate || null,
          orderId: order.id,
        }));

        // Sort orders by latest date
        completedOrdersList.sort(
          (a, b) => (b.stopDate?.seconds || 0) - (a.stopDate?.seconds || 0),
        );
        upcomingOrdersList.sort(
          (a, b) => (b.stopDate?.seconds || 0) - (a.stopDate?.seconds || 0),
        );

        setCompletedOrders(completedOrdersList);
        setUpcomingOrders(upcomingOrdersList);

        setCompletedOrders(completedOrdersList);
        setUpcomingOrders(upcomingOrdersList);
        setAllCompletedOrdersCount(completedOrdersList.length);
        setAllUpcomingOrdersCount(upcomingOrdersList.length);
      } catch (error) {
        console.error("Error fetching relatec data:", error);
      }
    };

    prepareData();
  }, [orders, t]);

  // Fetch more orders based on pagination
  const updateOrders = async (page: number, type: "completed" | "upcoming") => {
    if (!orders) return;

    try {
      const isCompleted = (status: OrderStatus) =>
        status === OrderStatus.Completed;
      const isUpcoming = (status: OrderStatus) =>
        status === OrderStatus.New ||
        status === OrderStatus.Scheduled ||
        status === OrderStatus.InProgress;

      let filteredOrders: Order[] = [];
      if (type === "completed") {
        filteredOrders = orders.filter((order) => isCompleted(order.status));
      } else {
        filteredOrders = orders.filter((order) => isUpcoming(order.status));
      }

      const ordersList = filteredOrders.map((order) => ({
        title: Customer.getCustomerName(order.customer as Customer),
        value: `From ${
          order.route[0]?.location?.addressLine || t("customer:unknown_address")
        } to ${
          order.route[order.route.length - 1]?.location?.addressLine ||
          t("customer:unknown_address")
        }`,
        stopDate: order.route[order.route.length - 1]?.stopDate || null,
        orderId: order.id,
      }));

      ordersList.sort(
        (a, b) => (b.stopDate?.seconds || 0) - (a.stopDate?.seconds || 0),
      );

      const start = (page - 1) * ordersPerPage;
      const end = start + ordersPerPage;

      if (type === "completed") {
        const paginated = ordersList.slice(start, end);
        setCompletedOrders((prev) =>
          page === 1 ? paginated : [...prev, ...paginated],
        );
      } else {
        const paginated = ordersList.slice(start, end);
        setUpcomingOrders((prev) =>
          page === 1 ? paginated : [...prev, ...paginated],
        );
      }
    } catch (error) {
      console.error("Error fetching orders from Firestore:", error);
      // Optionally, set error state to display a message to the user
    }
  };

  const loadMoreOrders = (type: "completed" | "upcoming") => {
    if (type === "completed") {
      const nextPage = completedPage + 1;
      setCompletedPage(nextPage);
      updateOrders(nextPage, "completed");
      setHasLoadedMoreCompleted(true);
    } else {
      const nextPage = upcomingPage + 1;
      setUpcomingPage(nextPage);
      updateOrders(nextPage, "upcoming");
      setHasLoadedMoreUpcoming(true);
    }
  };

  return (
    <>
      <div className="mt-6">
        <p className="text-xl font-medium dark:text-lgb-grey-100 mb-4">
          {t("customer:related_orders")}
        </p>

        <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
            <li className="mr-2">
              <button
                onClick={() => setActiveTab("completed")}
                className={`inline-block p-4 border-b-2 rounded-t-lg ${
                  activeTab === "completed"
                    ? "text-purple-600 border-purple-600"
                    : "text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300"
                }`}
              >
                {t("customer:completed_orders")}
              </button>
            </li>
            <li className="mr-2">
              <button
                onClick={() => setActiveTab("upcoming")}
                className={`inline-block p-4 border-b-2 rounded-t-lg ${
                  activeTab === "upcoming"
                    ? "text-purple-600 border-purple-600"
                    : "text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300"
                }`}
              >
                {t("customer:upcoming_orders")}
              </button>
            </li>
          </ul>
        </div>

        {/* Orders List */}
        <AnimatePresence mode="wait">
          {activeTab === "completed" ? (
            <motion.div
              key="completed"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ duration: 0.3 }}
            >
              {loading ? (
                <TableSkeletonsByAmount quantity={4} />
              ) : completedOrders.length === 0 ? (
                <div className="flex flex-col items-center">
                  <div className="mb-8 mt-8">{noCompletedOrders}</div>
                  <p className="text-lgb-grey-500">
                    {t("customer:no_completed_orders")}
                  </p>
                </div>
              ) : (
                <div className="pt-4 pb-4">
                  <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                    {completedOrders
                      .slice(
                        0,
                        hasLoadedMoreCompleted
                          ? completedOrders.length
                          : ordersPerPage,
                      )
                      .map(({ title, value, stopDate, orderId }, index) => (
                        <motion.li
                          key={orderId}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={
                            hasLoadedMoreCompleted
                              ? undefined // Prevent exit animation for "Show Less"
                              : { opacity: 0, y: -20 } // Apply exit animation for actual removal
                          }
                          transition={{ duration: 0.3 }}
                          className="pb-3 sm:pb-4"
                        >
                          <Link to={getOrderUrl(orderId)} state={{ from: currentPath, driverId: driverId }}>
                            <div className="w-full flex items-center justify-between bg-white shadow-custom rounded-lg p-4 dark:bg-darkBlue hover:shadow-md transition-shadow duration-200 cursor-pointer border border-lgb-grey-100 dark:border-lgb-grey-600">
                              <div className="flex items-center space-x-4 rtl:space-x-reverse max-w-[70%]">
                                <div className="flex-shrink-0">
                                  <HiCheck className="h-6 w-6 text-green-500" />
                                </div>
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                    {title || t("customer:unknown_customer")}
                                  </p>
                                  <p className="text-sm text-gray-500 line-clamp-2 dark:text-gray-400">
                                    {value}
                                  </p>
                                </div>
                              </div>
                              <div className="text-sm text-gray-500 text-right min-w-[80px]">
                                {stopDate
                                  ? formatTimestamp(stopDate)
                                  : t("customer:unknown_date")}
                              </div>
                            </div>
                          </Link>
                        </motion.li>
                      ))}
                  </ul>

                  {/* Toggle Button for Completed */}
                  {ordersPerPage < allCompletedOrdersCount &&
                    !hasLoadedMoreCompleted && (
                      <Button
                        className="mt-4 bg-white border-lgb-grey-600 text-lgb-grey-600 hover:bg-lgb-grey-100"
                        onClick={() => {
                          loadMoreOrders("completed");
                          setHasLoadedMoreCompleted(true); // Track that user loaded more
                        }}
                      >
                        {t("customer:load_more_completed")}
                      </Button>
                    )}
                  {hasLoadedMoreCompleted && (
                    <Button
                      className="mt-2 bg-white border-lgb-grey-600 text-lgb-grey-600 hover:bg-lgb-grey-100"
                      onClick={() => {
                        setCompletedOrders((prev) =>
                          prev.slice(0, ordersPerPage),
                        ); // Reset to initial 5
                        setHasLoadedMoreCompleted(false); // Reset the "loaded more" state
                        setCompletedPage(1); // Reset pagination state if necessary
                      }}
                    >
                      {t("customer:show_less")}
                    </Button>
                  )}
                </div>
              )}
            </motion.div>
          ) : (
            <motion.div
              key="upcoming"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
            >
              {loading ? (
                <TableSkeletonsByAmount quantity={4} />
              ) : upcomingOrders.length === 0 ? (
                <div className="flex flex-col items-center">
                  <div className="mb-8 mt-8">{noUpcomingOrders}</div>
                  <p className="text-lgb-grey-500">
                    {t("customer:no_upcoming_orders")}
                  </p>
                </div>
              ) : (
                <div className="pt-4 pb-4">
                  <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                    {upcomingOrders
                      .slice(
                        0,
                        hasLoadedMoreUpcoming
                          ? upcomingOrders.length
                          : ordersPerPage,
                      )
                      .map(({ title, value, stopDate, orderId }, index) => (
                        <motion.li
                          key={orderId}
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -20 }}
                          transition={{ duration: 0.3 }}
                          className="pb-3 sm:pb-4"
                        >
                          <Link to={getOrderUrl(orderId)} state={{ from: currentPath, driverId: driverId }}>
                            <div className="w-full flex items-center justify-between bg-white shadow-custom rounded-lg p-4 dark:bg-darkBlue hover:shadow-md transition-shadow duration-200 cursor-pointer border border-lgb-grey-100 dark:border-lgb-grey-600">
                              <div className="flex items-center space-x-4 rtl:space-x-reverse max-w-[70%]">
                                <div className="flex-shrink-0">
                                  <HiOutlineClock className="h-6 w-6 text-yellow-500" />
                                </div>
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                    {title || t("customer:unknown_customer")}
                                  </p>
                                  <p className="text-sm text-gray-500 line-clamp-2 dark:text-gray-400">
                                    {value}
                                  </p>
                                </div>
                              </div>
                              <div className="text-sm text-gray-500 text-right min-w-[80px]">
                                {stopDate
                                  ? formatTimestamp(stopDate)
                                  : t("customer:unknown_date")}
                              </div>
                            </div>
                          </Link>
                        </motion.li>
                      ))}
                  </ul>

                  {/* Toggle Button for Upcoming */}
                  {ordersPerPage < allUpcomingOrdersCount &&
                    !hasLoadedMoreUpcoming && (
                      <Button
                        className="mt-4 bg-white border-lgb-grey-600 text-lgb-grey-600 hover:bg-lgb-grey-100"
                        onClick={() => {
                          loadMoreOrders("upcoming");
                          setHasLoadedMoreUpcoming(true); // Track that user loaded more
                        }}
                      >
                        {t("customer:load_more_upcoming")}
                      </Button>
                    )}
                  {hasLoadedMoreUpcoming && (
                    <Button
                      className="mt-2 bg-white border-lgb-grey-600 text-lgb-grey-600 hover:bg-lgb-grey-100"
                      onClick={() => {
                        setUpcomingOrders((prev) =>
                          prev.slice(0, ordersPerPage),
                        ); // Reset to initial 5
                        setHasLoadedMoreUpcoming(false); // Reset the "loaded more" state
                        setUpcomingPage(1); // Reset pagination state if necessary
                      }}
                    >
                      {t("customer:show_less")}
                    </Button>
                  )}
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};
