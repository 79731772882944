/* eslint-disable jsx-a11y/anchor-is-valid */
import type { FC } from "react";
import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useWorkspace } from "../../context/WorkspaceContext";
import useLocations from "../../hooks/useLocations";
import useDrivers from "../../hooks/useDrivers";
import { useOrders } from "../../hooks/useOrders";
import { LocationModal, PageLayout, TableSkeleton, InfoMessage } from "../../components";
import { Button, Dropdown, TextInput, Toast } from "flowbite-react";
import { 
  HiOutlinePlus, 
  HiOutlineX, 
  HiOutlineUser,
  HiSearch,
  HiCheck,
  HiOutlineFilter
} from "react-icons/hi";
import { EmptyState } from "../../components";
import { emptyOrdersIllustration } from "../../components/atoms/Icons/illustrations";
import firebase from "firebase/compat/app";
import { Location, Order } from "../../models";
import { OrderStatus } from "../../types/order/IOrder";
import { LocationsList } from "../../components/organisms/LocationsList";
import { useCache } from "../../context/CacheContext";
import isSmallScreen from "../../helpers/is-small-screen";
import { debounce } from "lodash";
import { StyledButton } from "../../components/atoms/Button";

export const Locations: FC = function () {
  const { t } = useTranslation(["common", "orders", "validation", "location"]);
  const { activeWorkspace } = useWorkspace();
  const [splitIsOpen, setSplitIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isInsertModalOpen, openInsertModal] = useState(false);
  const [locationData, setLocationData] = useState(Location.defaultLocation());
  const { updateCacheKey } = useCache();
  
  // Filter states
  const [driverFilter, setDriverFilter] = useState<string | undefined>(undefined);
  const [searchText, setSearchText] = useState<string>("");
  const [additionalWhere, setAdditionalWhere] = useState<[string, firebase.firestore.WhereFilterOp, string][]>([]);
  const [filteredLocations, setFilteredLocations] = useState<Location[]>([]);
  
  // Flag to track if filtering is in progress
  const [isFiltering, setIsFiltering] = useState(false);
  
  // Dropdown states to control opening/closing
  const [driverDropdownOpen, setDriverDropdownOpen] = useState(false);
  
  // State for dropdown positioning
  const [dropdownPosition, setDropdownPosition] = useState<'left' | 'right'>('left');
  
  // State for hiding driver filter info box
  const [hideDriverFilterInfo, setHideDriverFilterInfo] = useState(false);
  
  // Refs for dropdown elements
  const driverDropdownRef = useRef<HTMLDivElement>(null);

  // Update dropdown position when it opens or window resizes
  useEffect(() => {
    const updateDropdownPosition = () => {
      if (driverDropdownOpen && driverDropdownRef.current) {
        const rect = driverDropdownRef.current.getBoundingClientRect();
        const spaceOnRight = window.innerWidth - rect.right;
        
        // If there's not enough space on the right, position dropdown to the left
        if (spaceOnRight < 240) {
          setDropdownPosition('right');
        } else {
          setDropdownPosition('left');
        }
      }
    };

    // Initial position check
    updateDropdownPosition();
    
    // Add resize listener
    window.addEventListener('resize', updateDropdownPosition);
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', updateDropdownPosition);
    };
  }, [driverDropdownOpen]);

  // Get drivers for filter dropdown
  const driversQuery = useDrivers(activeWorkspace?.workspaceId ?? "");
  const drivers = useMemo(() => driversQuery.data ?? [], [driversQuery.data]);

  // Get locations with filters
  const query = useLocations(activeWorkspace?.workspaceId ?? "", additionalWhere);
  const locations = useMemo(() => query.data ?? [], [query.data]);

  // Get orders for the selected driver
  const ordersQuery = useOrders(
    activeWorkspace?.workspaceId ?? "", 
    Object.values(OrderStatus), // Hent alle ordrestatuser
    driverFilter ? [["driverId", "==", driverFilter]] : []
  );
  const driverOrders = useMemo(() => ordersQuery.data ?? [], [ordersQuery.data]);

  // Logic to handle adding or updating locations
  const handleCreateOrUpdate = (location: Location) => {
    // Validate workspaceId
    const workspaceId = activeWorkspace?.workspaceId;
    if (!workspaceId) {
      console.error(
        "Workspace ID is undefined. Cannot create or update location.",
      );
      return;
    }

    const isNewLocation = Location.isNew(locationData);

    if (isNewLocation) {
      Location.create({
        ...location,
        workspaceId, // Now guaranteed to be a string
      })
        .then(() => {
          updateCacheKey(); // Sync UI
          query.refetch(); // Refresh location list
        })
        .catch((error) => {
          console.error("Error creating location:", error);
        });
    } else {
      Location.update(locationData, {
        ...location,
        workspaceId, // Now guaranteed to be a string
      })
        .then(() => {
          updateCacheKey(); // Sync UI
          query.refetch(); // Refresh location list
        })
        .catch((error) => {
          console.error("Error updating location:", error);
        });
    }

    openInsertModal(false); // Close modal after action
  };

  // Initialize filters from URL parameters when component mounts
  useEffect(() => {
    // Get driver filter from URL
    const driverParam = searchParams.get('driver');
    if (driverParam) {
      setDriverFilter(driverParam);
    }
    
    // Get search text from URL
    const searchParam = searchParams.get('search');
    if (searchParam) {
      setSearchText(searchParam);
    }
  }, []); // Kjør kun ved montering

  // Update URL when filters change
  useEffect(() => {
    const newSearchParams = new URLSearchParams();
    
    // Preserve the location ID if it exists
    const locationParam = searchParams.get('location');
    if (locationParam) {
      newSearchParams.set('location', locationParam);
    }
    
    // Update driver parameter
    if (driverFilter) {
      newSearchParams.set('driver', driverFilter);
    }
    
    // Update search parameter
    if (searchText) {
      newSearchParams.set('search', searchText);
    }
    
    // Update URL without reloading the page
    setSearchParams(newSearchParams, { replace: true });
  }, [driverFilter, searchText, setSearchParams]);

  // Update additionalWhere when searchText changes
  useEffect(() => {
    // Vi bruker ikke additionalWhere for søketekst lenger, siden det krever en sammensatt indeks
    // som ikke er opprettet. Vi filtrerer i stedet på klientsiden.
    setAdditionalWhere([]);
  }, [searchText]);

  // Funksjon for å finne lokasjoner fra ordrer
  const getLocationIdsFromOrders = useCallback((orders: Order[]) => {
    const locationIds = new Set<string>();
    
    orders.forEach(order => {
      if (order.route && order.route.length > 0) {
        order.route.forEach(routeItem => {
          if (routeItem.location && routeItem.location.id) {
            locationIds.add(routeItem.location.id);
          }
        });
      }
    });
    
    return Array.from(locationIds);
  }, []);

  // Debounced filtering function
  const debouncedFilter = useCallback(
    debounce((searchValue: string, driverValue: string | undefined, locationsList: Location[], driverOrdersList: Order[]) => {
      let filtered = [...locationsList];
      
      // Apply search text filter (client-side for all fields)
      if (searchValue) {
        filtered = Location.filterBySearchText(filtered, searchValue);
      }
      
      // Apply driver filter if selected
      if (driverValue && driverOrdersList.length > 0) {
        const locationIds = getLocationIdsFromOrders(driverOrdersList);
        filtered = filtered.filter(location => locationIds.includes(location.id));
      }
      
      setFilteredLocations(filtered);
      
      // Reset filtering flag when done
      setIsFiltering(false);
    }, 300),
    [getLocationIdsFromOrders]
  );

  // Apply client-side filtering when locations or filters change
  useEffect(() => {
    if (locations) {
      // Only set filtering flag if we have data to filter
      if (locations.length > 0) {
        setIsFiltering(true);
      }
      debouncedFilter(searchText, driverFilter, locations, driverOrders);
    }
  }, [locations, searchText, driverFilter, driverOrders, debouncedFilter]);

  // Handle click outside to close dropdowns
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // Close driver dropdown if click is outside
      if (
        driverDropdownRef.current && 
        !driverDropdownRef.current.contains(event.target as Node) &&
        driverDropdownOpen
      ) {
        setDriverDropdownOpen(false);
      }
    }

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);
    
    // Clean up
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [driverDropdownOpen]);

  // Handle driver filter change
  const handleDriverFilterChange = (driverId: string, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent click from propagating
    setIsFiltering(true); // Set filtering flag
    
    // Hvis samme driver er valgt, fjern filteret (toggle off)
    // Ellers, sett filteret til den valgte driveren (single select)
    if (driverFilter === driverId) {
      setDriverFilter(undefined);
    } else {
      setDriverFilter(driverId);
      setHideDriverFilterInfo(false); // Reset the hide state when changing filter
    }
    
    // Lukk dropdown etter valg
    setDriverDropdownOpen(false);
  };

  // Handle search - oppdater søketeksten umiddelbart
  const handleSearch = (value: string) => {
    setSearchText(value);
    setIsFiltering(true);
  };

  // Clear all filters
  const clearFilters = () => {
    setIsFiltering(true); // Set filtering flag
    setDriverFilter(undefined);
    setSearchText("");
    setHideDriverFilterInfo(false); // Reset the hide state when clearing filters
    
    // Preserve the location ID when clearing filters
    const locationParam = searchParams.get('location');
    if (locationParam) {
      const newSearchParams = new URLSearchParams();
      newSearchParams.set('location', locationParam);
      setSearchParams(newSearchParams, { replace: true });
    } else {
      // Hvis det ikke er noen location-parameter, fjern alle parametere
      setSearchParams({}, { replace: true });
    }
  };

  // Custom dropdown toggle handler
  const toggleDriverDropdown = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent click from propagating to elements behind
    // Bruk setTimeout for å forhindre at ResizeObserver-hendelser stables opp
    setTimeout(() => {
      setDriverDropdownOpen(!driverDropdownOpen);
    }, 10);
  };

  // State for toast notification
  const [showLinkCopiedToast, setShowLinkCopiedToast] = useState(false);

  // Function to generate a shareable link with current filters
  const getShareableLink = () => {
    const baseUrl = window.location.origin + window.location.pathname;
    const queryParams = new URLSearchParams();
    
    // Preserve the location ID if it exists
    const locationParam = searchParams.get('location');
    if (locationParam) {
      queryParams.set('location', locationParam);
    }
    
    if (driverFilter) {
      queryParams.set('driver', driverFilter);
    }
    
    if (searchText) {
      queryParams.set('search', searchText);
    }
    
    return baseUrl + '?' + queryParams.toString();
  };

  // Function to copy shareable link to clipboard
  const copyShareableLink = () => {
    const link = getShareableLink();
    navigator.clipboard.writeText(link)
      .then(() => {
        // Show toast notification
        setShowLinkCopiedToast(true);
        // Hide toast after 3 seconds
        setTimeout(() => setShowLinkCopiedToast(false), 3000);
      })
      .catch(err => {
        console.error('Failed to copy link: ', err);
      });
  };

  const hasLocations = filteredLocations.length > 0;
  const hasAnyLocations = locations.length > 0;
  const hasActiveFilters = driverFilter !== undefined || searchText.length > 0;
  // Combine all loading states into one
  const isLoading = query.isLoading || query.isRefetching || driversQuery.isLoading || driversQuery.isRefetching || ordersQuery.isLoading || ordersQuery.isRefetching;
  // Separate filtering state from loading state
  const isContentReady = !isLoading && !isFiltering;

  return (
    <PageLayout>
      {/* Toast notification for link copied */}
      {showLinkCopiedToast && (
        <div className="fixed bottom-5 right-5 z-50">
          <Toast>
            <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
              <HiCheck className="h-5 w-5" />
            </div>
            <div className="ml-3 text-sm font-normal">
              {t("common:link_copied")}
            </div>
            <button
              type="button"
              className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
              onClick={() => setShowLinkCopiedToast(false)}
              aria-label="Close"
            >
              <span className="sr-only">Close</span>
              <HiOutlineX className="w-5 h-5" />
            </button>
          </Toast>
        </div>
      )}

      {/* Header Section */}
      <div className="flex justify-between items-start lg:p-4">
        <div className={splitIsOpen ? "hidden lg:block" : ""}>
          <h1 className="text-xl font-semibold text-gray-900 dark:text-white sm:text-2xl">
            {t("location:page_title")}
          </h1>
          <p className="text-lgb-grey-600 text-base pt-2 pb-4 dark:text-lgb-grey-200">
            {t("location:page_description")}
          </p>
        </div>
      </div>

      {/* Filter bar - only show when content is ready and we have locations */}
      {isContentReady && (hasLocations || hasAnyLocations) && (
        <div className="flex flex-col md:flex-row gap-3 mb-4 px-4">
          {/* Search input */}
          <div className="flex-grow relative">
            <div className="flex">
              <div className="relative w-full">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <HiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                </div>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder={t("location:search_placeholder", { defaultValue: "Search for name, address, city or postal code..." })}
                  value={searchText}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                {searchText && (
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 flex items-center pr-3"
                    onClick={() => handleSearch("")}
                  >
                    <HiOutlineX className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                  </button>
                )}
              </div>
            </div>
          </div>
          
          <div className="flex gap-3 items-center">
            {/* Driver filter dropdown */}
            <div className="relative" ref={driverDropdownRef}>
              <button
                onClick={toggleDriverDropdown}
                className={`flex items-center justify-between bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${driverFilter ? 'border-lgb-blue-300 dark:border-lgb-blue-300' : ''}`}
              >
                <div className="flex items-center">
                  <HiOutlineUser className="mr-2 h-5 w-5" />
                  <span>{t("common:driver")}</span>
                  {driverFilter && (
                    <span className="ml-2 bg-lgb-blue-300 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                      1
                    </span>
                  )}
                </div>
              </button>
              
              {driverDropdownOpen && (
                <div 
                  className="absolute z-10 mt-1 bg-white rounded-lg shadow-lg dark:bg-gray-700 dark:border-gray-600"
                  onClick={(e) => e.stopPropagation()}
                  style={{ 
                    width: '240px', 
                    height: 'auto', 
                    minHeight: '200px', 
                    maxHeight: '300px',
                    right: dropdownPosition === 'right' ? 0 : 'auto'
                  }}
                >
                  <div className="p-3 border-b border-gray-200 dark:border-gray-600">
                    <div className="flex justify-between items-center">
                      <span className="font-medium dark:text-white">{t("common:drivers")}</span>
                      {driverFilter && (
                        <button
                          className="text-sm text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
                          onClick={(e) => {
                            e.stopPropagation();
                            setDriverFilter(undefined);
                          }}
                        >
                          <HiOutlineX className="mr-1 h-4 w-4 inline" />
                          {t("common:Clear")}
                        </button>
                      )}
                    </div>
                  </div>
                  <ul className="py-2 max-h-60 overflow-y-auto">
                    {drivers.map((driver) => (
                      <li key={driver.id}>
                        <button
                          onClick={(e) => handleDriverFilterChange(driver.email, e)}
                          className={`flex items-center w-full px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 ${driverFilter === driver.email ? 'bg-lgb-blue-100 dark:bg-lgb-blue-400/30' : ''}`}
                        >
                          <input
                            type="radio"
                            checked={driverFilter === driver.email}
                            onChange={() => {}}
                            className="mr-2 w-4 h-4 text-lgb-blue-300 bg-gray-100 border-gray-300 rounded-full focus:ring-lgb-blue-300 dark:focus:ring-lgb-blue-300 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            name="driverFilter"
                          />
                          <span className="dark:text-white">{driver.firstName} {driver.lastName}</span>
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            
            {/* Clear filters button */}
            {hasActiveFilters && (
              <button
                onClick={clearFilters}
                className="flex items-center justify-center p-2.5 text-red-500 hover:text-red-700 bg-gray-50 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-red-400 dark:hover:text-red-300"
                title={t("common:clear_filters")}
              >
                <HiOutlineX className="h-5 w-5" />
              </button>
            )}
            
            {/* Copy link button */}
            {hasActiveFilters && (
              <button
                onClick={copyShareableLink}
                className="flex items-center justify-center p-2.5 text-gray-700 hover:text-gray-900 bg-gray-50 border border-gray-300 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 dark:hover:text-gray-100"
                title={t("common:copy_link")}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
              </button>
            )}
          </div>
        </div>
      )}

      {/* Driver filter info box */}
      {isContentReady && driverFilter && !hideDriverFilterInfo && (
        <div className="mx-4 mb-4">
          <InfoMessage 
            variant="info"
            title={t("location:driver_filter_info.title", { defaultValue: "Filtrerte lokasjoner" })}
            dismissible
            onDismiss={() => setHideDriverFilterInfo(true)}
          >
            <p>
              {t("location:driver_filter_info.description", { 
                defaultValue: "Viser kun lokasjoner som er knyttet til ordrer for sjåføren {{driver}}.",
                driver: drivers.find(d => d.email === driverFilter) 
                  ? `${drivers.find(d => d.email === driverFilter)?.firstName} ${drivers.find(d => d.email === driverFilter)?.lastName}`
                  : driverFilter
              })}
            </p>
          </InfoMessage>
        </div>
      )}

      {/* Empty State or Locations List */}
      {!isContentReady ? (
        <TableSkeleton />
      ) : !hasLocations ? (
        hasAnyLocations && hasActiveFilters ? (
          // Empty state for when there are locations in the workspace but none match the current filters
          <EmptyState
            icon={
              <div className="flex justify-center items-center w-20 h-20 rounded-full bg-gray-100 dark:bg-gray-700">
                <HiOutlineFilter className="w-10 h-10 text-gray-500 dark:text-gray-400" />
              </div>
            }
            title={t("location:empty_state.filtered.title", { defaultValue: "No locations match your filters" })}
            description={t("location:empty_state.filtered.description", { defaultValue: "Try adjusting your search or filter criteria to find what you're looking for." })}
            buttonText={t("location:empty_state.filtered.button_text", { defaultValue: "Clear filters" })}
            buttonClick={clearFilters}
            buttonIcon="x"
            buttonColor="gray"
          />
        ) : (
          // Empty state for when there are no locations at all in the workspace
          <EmptyState
            icon={emptyOrdersIllustration}
            title={t("location:empty_state.general.title")}
            description={t("location:empty_state.general.description")}
            buttonText={t("location:empty_state.general.button_text")}
            buttonClick={() => {
              setLocationData(Location.defaultLocation());
              openInsertModal(true);
            }}
          />
        )
      ) : (
        <div className="px-4">
          <LocationsList
            locations={filteredLocations}
            isLoading={false}
            isOpen={setSplitIsOpen}
          />
        </div>
      )}

      {/* Location Modal */}
      <LocationModal
        data={locationData}
        isShowing={isInsertModalOpen}
        onConfirm={(location: Location) => handleCreateOrUpdate(location)}
        onCancel={() => openInsertModal(false)}
      />
    </PageLayout>
  );
};
