import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Button, Modal } from "flowbite-react";
import { HiOutlinePencilAlt, HiOutlineTrash } from "react-icons/hi";
import { Parameter, Product } from "../../../models";
import { getProductTypeAsArray, formatFirestoreDate } from "../../../utils/productHelpers";

interface ProductDetailModalProps {
  product: Product;
  categories: Parameter[];
  isOpen: boolean;
  onClose: () => void;
  onEdit: (product: Product) => void;
  onDelete: (product: Product) => void;
}

// Separate component for dimensions to improve readability
const DimensionItem: FC<{ label: string; value: string | number | null }> = ({ label, value }) => (
  <div className="bg-gray-50 dark:bg-lgb-modal-bg rounded-lg p-2 flex flex-col">
    <h4 className="text-xs font-medium text-gray-500 dark:text-gray-400">
      {label}
    </h4>
    <p className="text-sm sm:text-base font-medium text-gray-900 dark:text-white mt-0.5">
      {value ? `${value} ${label.includes("weight") ? "kg" : "cm"}` : "-"}
    </p>
  </div>
);

// Size visualization component - simplified 2D version
const ProductSizeVisualization: FC<{ 
  height: number; 
  width: number; 
  length: number;
}> = ({ height, width, length }) => {
  const { t } = useTranslation(["products"]);
  
  // Reference height (person is 180cm)
  const personHeight = 180;
  
  // Calculate scale to fit in the container
  const scale = Math.min(0.35, 70 / personHeight);
  
  // Calculate scaled dimensions for visualization
  const scaledPersonHeight = personHeight * scale;
  const scaledProductHeight = height * scale;
  const scaledProductWidth = width * scale;
  
  return (
    <div className="bg-gray-50 dark:bg-lgb-modal-bg rounded-lg p-2">
      <div className="relative h-[130px] sm:h-[150px] w-[180px] sm:w-[200px] mx-auto border border-gray-200 dark:border-gray-700 rounded-lg bg-white dark:bg-backgroundBlue p-2">
        <div className="flex items-end justify-center h-full gap-4 sm:gap-5">
          {/* Person silhouette */}
          <div className="flex flex-col items-center">
            <div className="text-xs text-gray-500 dark:text-gray-300 mb-1">180 cm</div>
            <div className="relative" style={{ height: `${scaledPersonHeight}px`, width: '22px' }}>
              <svg 
                viewBox="0 0 50 180" 
                className="h-full w-auto fill-current text-gray-400 dark:text-gray-400"
                preserveAspectRatio="xMidYMax meet"
              >
                <path d="M25,30 C31,30 36,25 36,19 C36,13 31,8 25,8 C19,8 14,13 14,19 C14,25 19,30 25,30 Z M25,35 C14,35 5,44 5,55 L5,155 C5,160 9,165 14,165 C19,165 23,160 23,155 L23,100 L27,100 L27,155 C27,160 31,165 36,165 C41,165 45,160 45,155 L45,55 C45,44 36,35 25,35 Z" />
              </svg>
            </div>
          </div>
          
          {/* Product rectangle */}
          <div className="flex flex-col items-center">
            <div className="text-xs text-gray-500 dark:text-gray-300 mb-1 invisible">180 cm</div>
            <div 
              className="bg-purple-100 dark:bg-purple-800 border border-purple-300 dark:border-purple-600 rounded"
              style={{ 
                height: `${scaledProductHeight}px`, 
                width: `${scaledProductWidth}px`,
                minHeight: '15px',
                minWidth: '15px'
              }}
            >
              {/* Removed dimensions text */}
            </div>
          </div>
        </div>
      </div>
      <p className="text-xs text-gray-500 dark:text-gray-300 mt-1.5 text-center">
        {t("products:size_comparison_note")}
      </p>
    </div>
  );
};

export const ProductDetailModal: FC<ProductDetailModalProps> = ({
  product,
  categories,
  isOpen,
  onClose,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation(["common", "products"]);

  // Memoize category badges to avoid unnecessary re-renders
  const categoryBadges = useMemo(() => {
    const productTypes = getProductTypeAsArray(product);
    
    if (productTypes.length === 0) {
      return (
        <Badge color="gray" className="text-xs px-2 py-1">
          {t("products:categories.uncategorized")}
        </Badge>
      );
    }
    
    return productTypes.map((typeId) => {
      const category = categories.find(c => c.id === typeId);
      return category ? (
        <Badge key={typeId} className="text-xs px-2 py-1 bg-purple-100 text-purple-800 dark:bg-purple-800 dark:text-purple-200">
          {category.code}
        </Badge>
      ) : null;
    });
  }, [product, categories, t]);

  // Format creation date
  const formattedDate = useMemo(() => {
    return formatFirestoreDate(product.createdAt);
  }, [product.createdAt]);

  // Check if product has valid dimensions for visualization
  const hasValidDimensions = useMemo(() => {
    return (
      product.height > 0 && 
      product.width > 0 && 
      product.length > 0
    );
  }, [product.height, product.width, product.length]);

  return (
    <Modal 
      show={isOpen} 
      onClose={onClose} 
      size="lg" 
      popup={false}
      theme={{
        root: {
          base: "fixed top-0 right-0 left-0 z-50 h-modal h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full",
          show: {
            on: "flex bg-gray-900 bg-opacity-70 dark:bg-opacity-90",
            off: "hidden"
          }
        },
        content: {
          base: "relative h-full w-full p-2 sm:p-4 md:h-auto",
          inner: "relative rounded-lg bg-white shadow-lg dark:bg-darkBlue border dark:border-gray-700 flex flex-col max-h-[95vh] sm:max-h-[90vh] shadow-xl dark:shadow-gray-900/50"
        }
      }}
    >
      <Modal.Header className="border-b border-gray-200 dark:border-gray-700 py-2 sm:py-3">
        <div className="flex flex-wrap items-center gap-2">
          <h3 className="text-base sm:text-lg font-semibold text-gray-900 dark:text-white">
            {product.descriptionShort || t("products:unknown_product")}
          </h3>
          <div className="flex flex-wrap gap-1">
            {categoryBadges}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0 overflow-y-auto">
        <div className="p-2 sm:p-3">
          {/* Product Details */}
          <div className="space-y-3">
            {/* Mobile view - stacked layout */}
            <div className="block lg:hidden space-y-3">
              {/* Dimensions */}
              <div className="grid grid-cols-2 gap-2">
                <DimensionItem 
                  label={t("products:details.height")} 
                  value={product.height} 
                />
                <DimensionItem 
                  label={t("products:details.width")} 
                  value={product.width} 
                />
                <DimensionItem 
                  label={t("products:details.length")} 
                  value={product.length} 
                />
                <DimensionItem 
                  label={t("products:details.weight")} 
                  value={product.weight} 
                />
              </div>
              
              {/* Size Visualization */}
              {hasValidDimensions && (
                <ProductSizeVisualization 
                  height={product.height} 
                  width={product.width} 
                  length={product.length} 
                />
              )}
              
              {/* Description */}
              <div className="bg-gray-50 dark:bg-lgb-modal-bg rounded-lg p-2 sm:p-3">
                <h4 className="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1">
                  {t("common:description")}
                </h4>
                <p className="text-sm text-gray-900 dark:text-white break-words">
                  {product.description || t("products:no_description")}
                </p>
              </div>
              
              {/* Created Date */}
              <div className="bg-gray-50 dark:bg-lgb-modal-bg rounded-lg p-2 sm:p-3">
                <h4 className="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1">
                  {t("common:created_at")}
                </h4>
                <p className="text-sm font-medium text-gray-900 dark:text-white">
                  {formattedDate}
                </p>
              </div>
            </div>
            
            {/* Desktop view - two column layout */}
            <div className="hidden lg:grid lg:grid-cols-2 gap-3">
              {/* Left column - Description and created date */}
              <div className="space-y-3">
                <div className="bg-gray-50 dark:bg-lgb-modal-bg rounded-lg p-3">
                  <h4 className="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1.5">
                    {t("common:description")}
                  </h4>
                  <p className="text-sm text-gray-900 dark:text-white break-words">
                    {product.description || t("products:no_description")}
                  </p>
                </div>
                
                {/* Created Date */}
                <div className="bg-gray-50 dark:bg-lgb-modal-bg rounded-lg p-3">
                  <h4 className="text-xs font-medium text-gray-500 dark:text-gray-400 mb-1">
                    {t("common:created_at")}
                  </h4>
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    {formattedDate}
                  </p>
                </div>
              </div>
              
              {/* Right column - Size visualization and dimensions */}
              <div className="space-y-3">
                {/* Size Visualization */}
                {hasValidDimensions && (
                  <ProductSizeVisualization 
                    height={product.height} 
                    width={product.width} 
                    length={product.length} 
                  />
                )}
                
                {/* Dimensions */}
                <div className="grid grid-cols-2 gap-2">
                  <DimensionItem 
                    label={t("products:details.height")} 
                    value={product.height} 
                  />
                  <DimensionItem 
                    label={t("products:details.width")} 
                    value={product.width} 
                  />
                  <DimensionItem 
                    label={t("products:details.length")} 
                    value={product.length} 
                  />
                  <DimensionItem 
                    label={t("products:details.weight")} 
                    value={product.weight} 
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex flex-col sm:flex-row justify-between border-t border-gray-200 dark:border-gray-700 gap-2 py-2 sm:py-3 dark:bg-darkBlue">
        <Button 
          color="failure" 
          onClick={() => onDelete(product)}
          className="flex items-center w-full sm:w-auto"
          size="sm"
        >
          <HiOutlineTrash className="mr-2 h-4 w-4" />
          {t("products:actions.delete")}
        </Button>
        <div className="flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
          <Button 
            color="gray" 
            onClick={onClose}
            className="w-full sm:w-auto"
            size="sm"
          >
            {t("common:close")}
          </Button>
          <Button 
            color="purple" 
            onClick={() => onEdit(product)}
            className="flex items-center justify-center w-full sm:w-auto"
            size="sm"
          >
            <HiOutlinePencilAlt className="mr-2 h-4 w-4" />
            {t("products:actions.edit")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}; 