import { useQuery } from "react-query";
import { getOrders } from "../api/client";
import { useUserSession } from "../context/UserContext";
import { IOrder, OrderStatus } from "../types/order/IOrder";
import { useMemo } from "react";

interface OrderResponse {
  success: boolean;
  error?: string;
  orders: IOrder[] & { orderDate?: string | Date };
}

// Helper type for date handling
type DateLike = string | Date | {
  toDate?: () => Date;
  seconds?: number;
  nanoseconds?: number;
} | any;

/**
 * Helper function to safely get YYYY-MM-DD from any date-like value
 */
const getDateString = (value: DateLike): string => {
  try {
    if (typeof value === 'string') {
      return value.split('T')[0];
    } else if (value instanceof Date) {
      return value.toISOString().split('T')[0];
    } else if (value && typeof value.toDate === 'function') {
      return value.toDate().toISOString().split('T')[0];
    } else if (value && typeof value.seconds === 'number') {
      return new Date(value.seconds * 1000).toISOString().split('T')[0];
    }
  } catch (error) {
    console.error("Error parsing date:", error);
  }
  return "";
};

/**
 * Hook to fetch orders from the API
 * @param workspaceId - The ID of the current workspace
 * @param enabled - Whether the query should run automatically
 * @returns Query object with orders data
 */
export const useOrdersApi = (workspaceId: string, enabled = true) => {
  const { userSession, isAuthenticated } = useUserSession();
  
  return useQuery<OrderResponse, Error>(
    ["ordersApi", workspaceId],
    async () => {
      if (!userSession?.token || !workspaceId || !isAuthenticated()) {
        throw new Error("Authentication or workspace data missing");
      }
      return await getOrders(userSession.token.accessToken, workspaceId);
    },
    {
      enabled: enabled && !!userSession?.token && !!workspaceId && isAuthenticated(),
      // refetchInterval: 30000, // Refetch data every 30 seconds for real-time updates - Temporarily disabled for debugging
      // staleTime: 10000, // Consider data stale after 10 seconds - Temporarily disabled for debugging
    }
  );
};

/**
 * Returns only orders from today in the given workspace
 */
export const useTodaysOrdersApi = (workspaceId: string, enabled = true) => {
  const ordersQuery = useOrdersApi(workspaceId, enabled);
  
  // Filter orders based on today's date and workspace
  const todaysOrders = useMemo(() => {
    // IMPORTANT: Only filter if the query was successful and data is an array
    if (!ordersQuery.isSuccess || !Array.isArray(ordersQuery.data)) return []; 
    // Get today's date now that we know we have data to filter
    const today = new Date(); // This will use the system clock (2025 in your case)
    const todayStr = today.toISOString().split('T')[0]; // YYYY-MM-DD format
    const allOrders = ordersQuery.data; // Access the data directly as the array

    const filtered = allOrders.filter(order => {
      // Check 1: Workspace ID must match
      if (order.workspaceId !== workspaceId) return false;

      // Check 2 (was 3): Check orderDate against today's date
      const orderDateStr = getDateString((order as any).orderDate); // Use helper to get YYYY-MM-DD
      
      if (orderDateStr && orderDateStr === todayStr) return true;
        
      // Fallback checks only if orderDate didn't match or was missing
      const createdAtStr = getDateString(order.createdAt);
      if (createdAtStr === todayStr) return true;

      if (order.route && order.route.length > 0 && order.route[0].stopDate) {
          const routeDateStr = getDateString(order.route[0].stopDate);
          if (routeDateStr === todayStr) return true;   
      }

      return false;
    });

    return filtered;

  }, [ordersQuery.data, ordersQuery.isSuccess, workspaceId]);

  // Calculate counts and percentage based on the filtered list
  const statusCount = useMemo(() => calculateStatusCounts(todaysOrders), [todaysOrders]);
  const completionPercentage = useMemo(() => calculateCompletionPercentage(todaysOrders), [todaysOrders]);
  
  return {
    ...ordersQuery,
    data: todaysOrders,
    todaysOrderCount: todaysOrders.length,
    statusCounts: statusCount,
    completionPercentage: completionPercentage,
  };
};

/**
 * Calculates counts of orders by status
 */
const calculateStatusCounts = (orders: IOrder[]) => {
  const counts: Record<string, number> = {};
  
  orders.forEach(order => {
    const status = order.status || "Unknown";
    counts[status] = (counts[status] || 0) + 1;
  });
  
  return counts;
};

/**
 * Calculates the percentage of completed orders
 */
const calculateCompletionPercentage = (orders: IOrder[]) => {
  const totalOrders = orders.length;
  if (totalOrders === 0) return 0;
  
  const completedOrders = orders.filter(order => 
    order.status === OrderStatus.Completed
  ).length;
  
  return Math.round((completedOrders / totalOrders) * 100);
}; 