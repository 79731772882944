import React from "react";
import { Toast } from "flowbite-react";
import { HiOutlineX } from "react-icons/hi";

export interface ValidationToastProps {
  /**
   * Tittel på feilmeldingen
   */
  title: string;
  /**
   * Innholdet i feilmeldingen
   */
  message: string;
  /**
   * Om toast-meldingen skal vises
   */
  show: boolean;
  /**
   * Funksjon som kalles når toast-meldingen lukkes
   */
  onClose: () => void;
  /**
   * Posisjon for toast-meldingen
   * @default "top-right"
   */
  position?: "top-right" | "top-left" | "bottom-right" | "bottom-left";
}

/**
 * ValidationToast - En gjenbrukbar komponent for å vise valideringsfeil
 */
const ValidationToast: React.FC<ValidationToastProps> = ({
  title,
  message,
  show,
  onClose,
  position = "top-right",
}) => {
  if (!show) return null;

  // Bestem posisjon basert på position-prop
  const positionClasses = {
    "top-right": "top-5 right-5",
    "top-left": "top-5 left-5",
    "bottom-right": "bottom-5 right-5",
    "bottom-left": "bottom-5 left-5",
  };

  return (
    <div className={`fixed ${positionClasses[position]} z-50`}>
      <Toast className="!bg-lgb-red-100 dark:!bg-lgb-red-400 !text-lgb-red-400 dark:!text-white min-w-[350px] max-w-[450px] shadow-lg">
        <div className="inline-flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-lgb-red-400 text-white dark:bg-lgb-red-400 dark:text-white">
          <HiOutlineX className="h-6 w-6" />
        </div>
        <div className="ml-3 text-base font-medium mr-8">
          <p className="font-bold text-lgb-red-400 dark:text-white">{title}</p>
          <p className="text-lgb-red-400 dark:text-white">{message}</p>
        </div>
        <Toast.Toggle 
          onClick={onClose}
          className="absolute top-2 right-2 bg-transparent text-lgb-red-400 hover:text-lgb-red-300 rounded-lg focus:ring-2 focus:ring-lgb-red-200 p-1.5 hover:bg-lgb-red-400/20 inline-flex h-8 w-8 dark:bg-transparent dark:text-white dark:hover:text-white dark:hover:bg-lgb-red-400/40"
        />
      </Toast>
    </div>
  );
};

export default ValidationToast; 