/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChangeEvent, type FC } from "react";
import { HiSearch, HiOutlineX } from "react-icons/hi";

export interface InstantSearchProps {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

export const InstantSearch: FC<InstantSearchProps> = ({
  placeholder,
  value,
  onChange,
  className = "",
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const clearSearch = () => {
    onChange("");
  };

  return (
    <div className={`flex-grow relative ${className}`}>
      <div className="flex">
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder={placeholder}
            value={value}
            onChange={handleInputChange}
          />
          {value && (
            <button
              type="button"
              className="absolute inset-y-0 right-0 flex items-center pr-3"
              onClick={clearSearch}
            >
              <HiOutlineX className="w-5 h-5 text-gray-500 dark:text-gray-400" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}; 