import { useFirestoreQueryData } from "@react-query-firebase/firestore";
import { Location } from "../models";
import { useCache } from "../context/CacheContext";
import firebase from "firebase/compat/app";

const useLocations = (
  workspaceId: string,
  additionalWhere: [string, firebase.firestore.WhereFilterOp, string][] = []
) => {
  const { cacheKey } = useCache();
  return useFirestoreQueryData(
    [Location.collectionName + "_" + workspaceId, cacheKey, additionalWhere],
    Location.list(workspaceId, additionalWhere),
    {
      subscribe: false,
    },
  );
};

export default useLocations;
